import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguageHandler } from './utils/languageHelper';

import announcementTranslationEnglish from './i18n/en/announcement-en-translation.json';
import commonTranslationEnglish from './i18n/en/common-en-translation.json';
import dashboardTranslationEnglish from './i18n/en/dashboard-en-translation.json';
import translationEnglish from './i18n/en/en-translation.json';
import eventsTranslationEnglish from './i18n/en/events-en-translation.json';
import reportTranslationEnglish from './i18n/en/report-en-translation.json';
import supportTranslationEnglish from './i18n/en/support-en-translation.json';
import userManagementTranslationEnglish from './i18n/en/userManagment-en-translation.json';
import weatherTranslationEnglish from './i18n/en/weather-en-translation.json';
import announcementTranslationSpanish from './i18n/es/announcement-es-translation.json';
import commonTranslationSpanish from './i18n/es/common-es-translation.json';
import dashboardTranslationSpanish from './i18n/es/dashboard-es-translation.json';
import translationSpanish from './i18n/es/es-translation.json';
import eventsTranslationSpanish from './i18n/es/events-es-translation.json';
import reportTranslationSpanish from './i18n/es/report-es-translation.json';
import supportTranslationSpanish from './i18n/es/support-es-translation.json';
import userManagementTranslationSpanish from './i18n/es/userManagment-es-translation.json';
import weatherTranslationSpanish from './i18n/es/weather-es-translation.json';
import announcementTranslationFrench from './i18n/fr/announcement-fr-translation.json';
import commonTranslationFrench from './i18n/fr/common-fr-translation.json';
import dashboardTranslationFrench from './i18n/fr/dashboard-fr-translation.json';
import eventsTranslationFrench from './i18n/fr/events-fr-translation.json';
import translationFrench from './i18n/fr/fr-translation.json';
import reportTranslationFrench from './i18n/fr/report-fr-translation.json';
import supportTranslationFrench from './i18n/fr/support-fr-translation.json';
import userManagementTranslationFrench from './i18n/fr/userManagment-fr-translation.json';
import weatherTranslationFrench from './i18n/fr/weather-fr-translation.json';

import ptiEnglish from '@driscollsinc/pti-module-ui/dist/ptic.translations.en.json';
import ptiSpanish from '@driscollsinc/pti-module-ui/dist/ptic.translations.es.json';
import ptiFrench from '@driscollsinc/pti-module-ui/dist/ptic.translations.fr.json';

export enum NAMESPACE {
    MAIN = 'main',
    COMMON = 'common',
    ANNOUNCEMENT = 'announcement',
    DASHBOARD = 'dashboard',
    SUPPORT = 'support',
    REPORT = 'report',
    USERS = 'users',
    EVENTS = 'events',
    WEATHER = 'weather',
    PTI = 'pti',
    ANALYTICS = 'analytics',
    BERRYCONNECT = 'berryconnect'
}

//---Using different namespaces
const resources = {
    en: {
        [NAMESPACE.MAIN]: translationEnglish,
        [NAMESPACE.COMMON]: commonTranslationEnglish,
        [NAMESPACE.ANNOUNCEMENT]: announcementTranslationEnglish,
        [NAMESPACE.DASHBOARD]: dashboardTranslationEnglish,
        [NAMESPACE.SUPPORT]: supportTranslationEnglish,
        [NAMESPACE.REPORT]: reportTranslationEnglish,
        [NAMESPACE.USERS]: userManagementTranslationEnglish,
        [NAMESPACE.EVENTS]: eventsTranslationEnglish,
        [NAMESPACE.WEATHER]: weatherTranslationEnglish,
        [NAMESPACE.PTI]: ptiEnglish
    },
    es: {
        [NAMESPACE.MAIN]: translationSpanish,
        [NAMESPACE.COMMON]: commonTranslationSpanish,
        [NAMESPACE.ANNOUNCEMENT]: announcementTranslationSpanish,
        [NAMESPACE.DASHBOARD]: dashboardTranslationSpanish,
        [NAMESPACE.SUPPORT]: supportTranslationSpanish,
        [NAMESPACE.REPORT]: reportTranslationSpanish,
        [NAMESPACE.USERS]: userManagementTranslationSpanish,
        [NAMESPACE.EVENTS]: eventsTranslationSpanish,
        [NAMESPACE.WEATHER]: weatherTranslationSpanish,
        [NAMESPACE.PTI]: ptiSpanish
    },
    fr: {
        [NAMESPACE.MAIN]: translationFrench,
        [NAMESPACE.COMMON]: commonTranslationFrench,
        [NAMESPACE.ANNOUNCEMENT]: announcementTranslationFrench,
        [NAMESPACE.DASHBOARD]: dashboardTranslationFrench,
        [NAMESPACE.SUPPORT]: supportTranslationFrench,
        [NAMESPACE.REPORT]: reportTranslationFrench,
        [NAMESPACE.USERS]: userManagementTranslationFrench,
        [NAMESPACE.EVENTS]: eventsTranslationFrench,
        [NAMESPACE.WEATHER]: weatherTranslationFrench,
        [NAMESPACE.PTI]: ptiFrench
    }
};
const defaultLanguage = defaultLanguageHandler();
i18next.use(initReactI18next).init({
    resources,
    lng: defaultLanguage
});

export default i18next;
