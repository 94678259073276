import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import logoutLogo from '../../Images/Bye-bro.svg';

const LogOut = (props) => {
    const { oktaAuth } = props;

    const recoverPersistentStorage = (storage) => {
        for (const [key, value] of Object.entries(storage)) {
            if (value !== null) {
                localStorage.setItem(key, value);
            }
        }
    };

    const createPersistentStorage = () => {
        const persistentStorage = {};
        persistentStorage['pti-module-ui-required-filters'] = localStorage.getItem('pti-module-ui-required-filters');
        persistentStorage['HTML5_QRCODE_DATA'] = localStorage.getItem('HTML5_QRCODE_DATA');
        persistentStorage['kioskMode'] = localStorage.getItem('kioskMode');
        return persistentStorage;
    };

    useEffect(() => {
        const persistentStorage = createPersistentStorage();
        oktaAuth.signOut('/login');
        localStorage.clear();
        recoverPersistentStorage(persistentStorage);
    }, [oktaAuth]);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
            <h3>Please Wait... Logging you out shortly...</h3>
            <Box component="img" src={logoutLogo} sx={{ width: 400 }} />
        </Box>
    );
};
export default withOktaAuth(LogOut);
