import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import style from './ImageComponent.module.scss';

const ImageComponent = ({
    src,
    caption,
    size,
    brokenImage,
    className,
    imageFilter
}: {
    src: string;
    caption?: string;
    size?: 'smaller' | 'small' | 'medium' | 'large';
    brokenImage?: string;
    className?: string;
    imageFilter?: string;
}) => {
    return (
        <div className={`${style.imageComponent} ${className || ''}`}>
            <span className={`${style.imageContainer} ${style[size || 'medium']}`}>
                <img
                    alt={caption}
                    src={src}
                    onError={(e) => {
                        e.currentTarget.src = brokenImage;
                    }}
                    className={imageFilter || ''}
                />
            </span>
            {caption && (
                <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="overline" className={style.caption}>
                    {caption}
                </TypographyTranslated>
            )}
        </div>
    );
};

export default ImageComponent;
