import { DriscollsSpinner, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Grid, List, ListItem, ListItemText, Radio, TextField } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { IReportObject } from 'components/reports/ReportTypes';
import DrcDialog from 'components/shared/drc/DrcDialog';
import ApplicationContext from 'context/ApplicationContext';
import { IUserManagementForm } from 'context/UserManageFormContext';
import { IInviteForms } from 'data/config/DataSetupTypes';
import useCheckUserEmail from 'hooks/useCheckUserEmail';
import useFetchReports from 'hooks/useFetchReports';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import { isValidEmail, isValidPhoneNumber } from 'utils/validations';
import styles from './ExistingUserInvite.module.scss';

interface IExistingUserInviteProps extends IOktaContext {
    openSearchDialogBox: boolean;
    setOpenSearchDialogBox: (value: boolean) => void;
    selectedPersonaIds: number[];
    personaForm: IInviteForms | Record<string, never>;
    getExistingUserDetails: (value) => void;
    setAssignedReports: (reports: Array<IReportObject>) => void;
}

interface ISelectedUser extends IUserManagementForm {
    UserOktaId?: string;
    PersonaName?: string;
    PersonaId?: number;
}

export interface IMultiUserObject {
    UserDetails: Array<ISelectedUser>;
    cognitoEdit: boolean;
}

const ExistingUserInvite = (props: IExistingUserInviteProps) => {
    const { openSearchDialogBox, setOpenSearchDialogBox, selectedPersonaIds, personaForm, oktaAuth, getExistingUserDetails, setAssignedReports } =
        props;
    const [searchData, setSearchData] = useState<string>('');
    const [isMultipleUser, setIsMultipleUsers] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<ISelectedUser>({});
    const [multiUsers, setMultiUsers] = useState<IMultiUserObject>();
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const { checkUserEmail, error: checkUserEmailError, loading } = useCheckUserEmail(oktaAuth);
    const userLang = getCurrentLanguageSelected();
    const { getAssignedReports } = useFetchReports(oktaAuth);
    const { setShowToast } = useContext(ApplicationContext);

    useEffect(() => {
        fetchReports();
    }, [selectedUser]);

    const fetchReports = async () => {
        if (selectedUser[0]?.UserOktaId || selectedUser?.UserOktaId) {
            const reports = await getAssignedReports(userLang, selectedUser[0]?.UserOktaId || selectedUser?.UserOktaId);
            setAssignedReports(reports);
        }
    };

    const getCognitoData = async () => {
        setOpenSearchDialogBox(false);
        const loginField = isValidPhoneNumber(searchData) === 'Please Enter a valid phone number.' ? 'email' : 'phone';
        const email = searchData.includes('@') ? searchData : '';
        const phone = searchData.includes('+') ? searchData : '';
        const emailData = await checkUserEmail(email, phone, personaForm.persona, loginField);

        if ((emailData?.UserDetails || []).length === 0 || checkUserEmailError) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'User does not exist!' });
        } else if (emailData?.UserDetails.length === 1) {
            getExistingUserDetails({ UserDetails: emailData.UserDetails[0], cognitoEdit: emailData.cognitoEdit });
            setSelectedUser(emailData.UserDetails[0]);
            setOpenSearchDialogBox(false);
        } else {
            setIsMultipleUsers(true);
            setMultiUsers(emailData);
        }
        setSearchData('');
    };

    return (
        <>
            <Grid item xs={12} sm={12} md={12} className={(selectedPersonaIds || []).length > 0 ? styles.displaySection : styles.hideSection}>
                <TranslatedDrcButton nameSpacing={NAMESPACE.USERS} onClick={() => setOpenSearchDialogBox(true)}>
                    Invite An Existing user
                </TranslatedDrcButton>
            </Grid>
            <DrcDialog
                open={openSearchDialogBox}
                title={getTextTranslated('Search User', NAMESPACE.USERS)}
                buttons={
                    <>
                        <TranslatedDrcButton isSecondary nameSpacing={NAMESPACE.COMMON} onClick={() => setOpenSearchDialogBox(false)}>
                            Cancel
                        </TranslatedDrcButton>
                        <TranslatedDrcButton
                            isPrimary
                            nameSpacing={NAMESPACE.COMMON}
                            onClick={getCognitoData}
                            disabled={!!(searchData.includes('+') ? isValidPhoneNumber(searchData) : isValidEmail(searchData))}
                        >
                            Search
                        </TranslatedDrcButton>
                    </>
                }
            >
                <TextField
                    label={getTextTranslated('Enter Email/Mobile', NAMESPACE.USERS)}
                    value={searchData}
                    onChange={(event) => setSearchData(event.target.value)}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter' && !(searchData.includes('+') ? isValidPhoneNumber(searchData) : isValidEmail(searchData)))
                            getCognitoData();
                    }}
                />
            </DrcDialog>

            <DrcDialog
                open={isMultipleUser}
                title={getTextTranslated('Select User To Invite', NAMESPACE.USERS)}
                buttons={
                    <>
                        <TranslatedDrcButton isSecondary nameSpacing={NAMESPACE.COMMON} onClick={() => setIsMultipleUsers(false)}>
                            Cancel
                        </TranslatedDrcButton>
                        <TranslatedDrcButton
                            isPrimary
                            nameSpacing={NAMESPACE.COMMON}
                            onClick={() => {
                                getExistingUserDetails({ UserDetails: new Array(selectedUser), cognitoEdit: multiUsers.cognitoEdit });
                                setIsMultipleUsers(false);
                                setSearchData('');
                            }}
                        >
                            Select
                        </TranslatedDrcButton>
                    </>
                }
            >
                <List component="nav" aria-label="list-personas">
                    {multiUsers?.UserDetails.map((user, index) => {
                        const { PersonaName, firstName, lastName, phone, email, UserOktaId } = user[0];
                        return (
                            <ListItem
                                key={UserOktaId}
                                secondaryAction={
                                    <Radio
                                        edge="end"
                                        checked={user[0].UserOktaId === selectedUser.UserOktaId}
                                        onClick={() => setSelectedUser(user[0])}
                                    />
                                }
                            >
                                <ListItemText
                                    primary={`${index + 1}.  ${firstName} ${lastName}`}
                                    secondary={
                                        <div className={styles.listContent}>
                                            <div className={styles.listItem}>
                                                <b>{getTextTranslated('Persona', NAMESPACE.COMMON)}: </b> {PersonaName}
                                            </div>
                                            <div className={styles.listItem}>
                                                <b>{getTextTranslated('Phone', NAMESPACE.USERS)}: </b>
                                                {phone}
                                            </div>
                                            <div className={styles.listItem}>
                                                <b>{getTextTranslated('Email', NAMESPACE.COMMON)}: </b>
                                                {email}
                                            </div>
                                        </div>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DrcDialog>
            {loading && <DriscollsSpinner text={getTextTranslated('Searching User', NAMESPACE.USERS)} />}
        </>
    );
};

export default withOktaAuth(ExistingUserInvite);
