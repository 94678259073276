import AppProvider from 'context/AppProvider';
import useLang, { Lang } from 'hooks/useLang';
import React, { useEffect} from 'react';
import App from './App';
import './i18n';
import {defaultLanguageHandler} from './utils/languageHelper';

const Application = () => {
    const { changeLanguage } = useLang();
    useEffect(() => {
        // init language
        const defaultLanguageCode = defaultLanguageHandler() as Lang;
        changeLanguage(defaultLanguageCode);
    }, []);

    return (
        <AppProvider>
            <App />
        </AppProvider>
    );
};
export default Application;
