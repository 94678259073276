import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Box, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logError, logEvent } from '../../analytics/AnalyticsHandler';
import DrcPhoneInput from '../../components/shared/drc/DrcPhoneInput';
import { EventConstants } from '../../constants/EventConstants';
import { ROUTES } from '../../constants/routes';
import { isValidPhoneNumber } from '../../utils/validations';
import styles from './Login.module.scss';

const CognitoLogin = () => {
    const [phone, setPhone] = useState('');
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [cognitoUser, setCognitoUser] = useState(null);
    const [cognitoOTP, setCognitoOTP] = useState('');
    const [isEnteredPhoneNumberValid, setIsEnteredPhoneNumberValid] = useState(false);
    const navigate = useNavigate();
    const { setShowToast } = useContext(ApplicationContext);

    useEffect(() => {
        const event = { target: { value: phone } };
        handleBlur(event);
    }, [phone]);

    const handleChange = (e) => {
        setPhone('+' + e);
    };

    const handleClick = () => {
        setButtonLoading(true);
        Auth.signIn(phone)
            .then((user) => {
                console.log(user);
                setCognitoUser(user);
                setIsOTPSent(true);
            })
            .catch((err) => {
                console.error(err);
                logError(EventConstants.OTP_ERROR, { error: err?.message });
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: err.message });
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    const verifyOTP = () => {
        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
            setButtonLoading(true);
            Auth.sendCustomChallengeAnswer(cognitoUser, cognitoOTP)
                .then((res) => {
                    localStorage.clear();
                    localStorage.setItem('cognitouser', JSON.stringify(res));
                    localStorage.setItem('authenticationProvider', 'cognito');
                    logEvent(EventConstants.USER_LOGGED_IN, { login: phone, idp: 'cognito' });
                    navigate(ROUTES.INITIALIZE_APP);
                })
                .catch((err) => {
                    console.error('otp error ', err);
                    logError(EventConstants.LOGIN_ERROR, { error: err });
                    setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: err.message });
                    setIsOTPSent(false);
                })
                .finally(() => {
                    setButtonLoading(false);
                });
        }
    };

    const handleBlur = (event) => {
        let isNotPhoneNumber = isValidPhoneNumber(event.target.value);
        setIsEnteredPhoneNumberValid(!isNotPhoneNumber);
    };

    const handleKeypressOnPassword = (e) => {
        if (e.keyCode === 13) {
            if (isOTPSent) {
                verifyOTP();
            }
            return false;
        }
    };

    const { getTextTranslated } = useLang();
    return (
        <Box>
            <DrcPhoneInput
                style={{ marginBottom: '32px', height: '56px', width: '100%' }}
                label={getTextTranslated('Phone Number', NAMESPACE.COMMON)}
                handleChange={handleChange}
                onEnterKeyPress={isEnteredPhoneNumberValid && handleClick}
            />
            {isOTPSent ? (
                <TextField
                    sx={{ marginBottom: '32px' }}
                    fullWidth
                    name="otp"
                    label="OTP"
                    onChange={(e) => setCognitoOTP(e.target.value)}
                    onKeyUp={handleKeypressOnPassword}
                />
            ) : null}
            {!isOTPSent && (
                <DriscollsLoadingButton
                    loading={buttonLoading}
                    disabled={!isEnteredPhoneNumberValid}
                    className={styles.fullWidth}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleClick}
                    label={getTextTranslated('Login', NAMESPACE.COMMON)}
                />
            )}
            {isOTPSent ? (
                <DriscollsLoadingButton
                    loading={buttonLoading}
                    disabled={cognitoOTP.length < 1}
                    className={styles.fullWidth}
                    label={getTextTranslated('Verify OTP', NAMESPACE.COMMON)}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    onClick={verifyOTP}
                />
            ) : null}
        </Box>
    );
};

export default CognitoLogin;
