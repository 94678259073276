import { Call } from '@driscollsinc/driscolls-display-rules';
import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Delete } from '@mui/icons-material';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { ComponentName } from 'constants/ComponentEnum';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { PERSONA_NAME } from 'data/config/persona';
import useEditUser from 'hooks/useEditUser';
import { NAMESPACE } from 'i18n';
import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logError, logEvent } from '../../analytics/AnalyticsHandler';
import DrcDialog from '../../components/shared/drc/DrcDialog';
import { EventConstants } from '../../constants/EventConstants';
import { isPersonaAllowedInTheApp, isPersonaIsOKTA } from '../../data/config/datasetup';
import useLang from '../../hooks/useLang';
import APIEndPoints from '../../services/api';
import DriscollsToolTip from '../../ui/DriscollsToolTip/DriscollsToolTip';
import { getPersonaIdFromPersona } from '../../utils/helper';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import DuAuthenticationUtilities from '../shared/Utilities/DuAuthenticationUtilities';

const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    justifyContent: 'space-between',
    height: 36
});

const StyledDeleteIcon = styled(Delete)(({ theme }) => ({
    color: theme.palette.error.dark
}));

const ChangePersona = (props) => {
    const { availablePersona, setAvailablePersona, personaId, defaultPersona, parentComponent, oktaAuth, selectedUserOktaId } = props;
    const [warningDialog, setWarningDialog] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState('');
    const [currentPersona, setCurrentPersona] = useState(Number(personaId));
    const [loading, setLoading] = useState(false);
    const { getTextTranslated } = useLang();
    const { removeGrowerPersona } = useEditUser(props.oktaAuth);
    const navigate = useNavigate();
    const { setShowToast } = useContext(ApplicationContext);

    const activePersonas =
        parentComponent === ComponentName.UserProfile
            ? availablePersona.filter((itm) => isPersonaAllowedInTheApp(itm.PersonaId))
            : [...availablePersona];

    const handleRemovePersona = (event, currentPersona) => {
        event.stopPropagation();
        setSelectedPersona(currentPersona);
        setWarningDialog(true);
    };

    const removePersona = async () => {
        setLoading(true);
        const token = oktaAuth.getAccessToken();
        const userEmail = DuAuthenticationUtilities.GetUserId(token);
        const isOktaUser = isPersonaIsOKTA(getPersonaIdFromPersona(selectedPersona));
        const payload = isOktaUser
            ? {
                  Status: 0,
                  UserOktaId: selectedUserOktaId,
                  UpdatedBy: userEmail,
                  PersonaId: getPersonaIdFromPersona(selectedPersona)
              }
            : {
                  username: selectedUserOktaId
              };
        try {
            const data = await Call(
                {
                    name: 'user',
                    url: APIEndPoints.DEACTIVATE_USER(selectedUserOktaId, isOktaUser),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            } else {
                await removeGrowerPersona(getPersonaIdFromPersona(selectedPersona), selectedUserOktaId);
                setAvailablePersona(availablePersona.filter((itm) => itm.PersonaName !== selectedPersona));
                logEvent(EventConstants.REMOVE_PERSONA, { selectedUser: selectedUserOktaId, isOktaUser: isOktaUser, updatedBy: userEmail });
                setShowToast({ toastMessage: 'Persona Removed Successfully!', nameSpacing: NAMESPACE.EVENTS });
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            logError(EventConstants.REMOVE_PERSONA_ERROR, { error: error });
        } finally {
            setLoading(false);
            setWarningDialog(false);
        }
    };

    return (
        <Fragment>
            <FormControl fullWidth sx={{ maxWidth: '350px', mt: 5, ml: 5 }}>
                {activePersonas && activePersonas.length > 1 ? (
                    <Fragment>
                        <InputLabel id="gep-simple-select-label">
                            <TypographyTranslated nameSpacing={NAMESPACE.USERS}>Select/Remove Persona</TypographyTranslated>
                        </InputLabel>
                        <Select
                            value={currentPersona}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setCurrentPersona(Number(e.target.value));
                                navigate(
                                    `${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}${ROUTES.SEPARATOR}${ROUTES.EDITUSER}${activePersonas[0].UserOktaId}${ROUTES.SEPARATOR}${e.target.value}`
                                );
                            }}
                            label={getTextTranslated('Select/Remove Persona', NAMESPACE.USERS)}
                            labelId="gep-simple-select-label"
                        >
                            {activePersonas.map((itm) => (
                                <StyledMenuItem key={itm.PersonaId} value={itm.PersonaId}>
                                    {<TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{itm.PersonaName || itm.label}</TypographyTranslated>}
                                    {parentComponent !== ComponentName.UserProfile &&
                                        itm.PersonaId !== currentPersona &&
                                        itm.label !== defaultPersona &&
                                        itm.PersonaName !== PERSONA_NAME.SUPERADMIN && (
                                            <DriscollsToolTip
                                                headingTitle=""
                                                description={getTextTranslated('Remove Persona', NAMESPACE.USERS)}
                                                arrow={false}
                                                placement="bottom"
                                                className={null}
                                            >
                                                <IconButton onClick={(event) => handleRemovePersona(event, itm.PersonaName || itm.label)}>
                                                    <StyledDeleteIcon />
                                                </IconButton>
                                            </DriscollsToolTip>
                                        )}
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </Fragment>
                ) : (
                    <TextField
                        disabled
                        label={getTextTranslated('Persona Type', NAMESPACE.USERS)}
                        value={defaultPersona === 'Ranch Admin' ? 'Grower Admin / Ranch Admin' : defaultPersona}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                )}
            </FormControl>
            <DrcDialog
                title={getTextTranslated('Are you sure?', NAMESPACE.COMMON)}
                open={warningDialog}
                buttons={
                    <>
                        <DriscollsLoadingButton
                            variant={ButtonVariant.SECONDARY}
                            onClick={() => setWarningDialog(false)}
                            label={getTextTranslated('Cancel', NAMESPACE.MAIN)}
                        />
                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            onClick={removePersona}
                            loading={loading}
                            label={getTextTranslated('Yes', NAMESPACE.COMMON)}
                        />
                    </>
                }
            >
                <div>{`${getTextTranslated('Do you want to remove the selected Persona', NAMESPACE.USERS)} - ${selectedPersona}?`}</div>
            </DrcDialog>
        </Fragment>
    );
};

export default withOktaAuth(ChangePersona);
