import { List } from '@mui/material';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import { isPersonaAllowedInTheApp } from 'data/config/datasetup';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import style from './AppInitializer.module.scss';
import { IPersona } from './IUserPersona';
import PersonaListItem from './PersonaListItem';

interface IInvalidPersonaComponentProps {
    defaultPersonaName: string;
    invalidPersona: Array<IPersona>;
    makeDefaultPersona: (persona: number, oktaId?: string) => void;
}

const InvalidPersonaComponent = (props: IInvalidPersonaComponentProps) => {
    const { defaultPersonaName, invalidPersona, makeDefaultPersona } = props;
    const [logout, setLogout] = useState<boolean>(false);
    return (
        <>
            {logout && <Navigate to={'/Logout'} />}
            <section>
                <TypographyTranslated align="center">{defaultPersonaName ? 'NotSupportedPersona' : 'SelectDefaultPersona'}</TypographyTranslated>
                {invalidPersona.length > 1 && <TypographyTranslated align="center">{'SelectPersona'}</TypographyTranslated>}
            </section>
            <section className={`row ${style.flexBoxCenter}`}>
                <List className={style.personaList}>
                    {invalidPersona.map((item) => {
                        return (
                            <PersonaListItem
                                makeDefaultPersona={makeDefaultPersona}
                                key={item.PersonaId}
                                personaId={item.PersonaId}
                                personaName={item.PersonaName}
                                allowed={isPersonaAllowedInTheApp(item.PersonaId)}
                            />
                        );
                    })}
                </List>
            </section>
            <section className={`row ${style.flexBoxCenter}`}>
                <div className="col-xs-1">
                    <TranslatedDrcButton isPrimary fullWidth onClick={() => setLogout(true)}>
                        {'Logout'}
                    </TranslatedDrcButton>
                </div>
            </section>
        </>
    );
};

export default InvalidPersonaComponent;
