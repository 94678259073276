export const AdjustTimezoneTime = (date: string) => new Date(date + 'Z');

function adjustTimeZoneField(data, field: string) {
    data[field] = AdjustTimezoneTime(data[field]);
}

export const AdjustTimeZonesFetchEvent = ({ data, loading, error }, dateFields: string | string[]) => {
    if (data !== null) {
        if (Array.isArray(dateFields)) {
            dateFields.forEach((field) => adjustTimeZoneField(data, field));
        } else {
            adjustTimeZoneField(data, dateFields);
        }
    }
    return { data, loading, error };
};
