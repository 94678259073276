import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import styles from './GenericRenderer.module.css';

const ActionColumnRenderer = (props) => {
    const isEditable = props.isEditable(props);
    props.isRemovable(props);
    return (
        <ButtonBase className={`${styles.actionButton} ${styles.primary}`} onClick={() => props?.editRow(props.data)} disabled={!isEditable}>
            {props.isReadOnly ? <InfoIcon /> : <EditIcon />}
        </ButtonBase>
    );
};

export default ActionColumnRenderer;
