import { Box } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import ApplicationContext from 'context/ApplicationContext';
import useGetQAInspectionReports from 'hooks/useGetQAInspectionReports';
import { NAMESPACE } from 'i18n';
import React, { useContext, useState } from 'react';
import ReportDesktop from '../../components/report/ReportDesktop';
import ReportGridContainer from '../../components/report/ReportGridContainer';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import GenericFilterSideBar from '../../layouts/dashboard/filters/GenericFilterSideBar';
import { ReportType } from './ReportTypes';

const DailyWeeklyReport = (props) => {
    const [searchInput, setSearchInput] = useState('');
    const { reportsList, pageCount, totalReports, fetchWithFilters, executionTime, loading } = useGetQAInspectionReports(props.oktaAuth);
    const [page, setPage] = useState(1);
    const isDesktop = useResponsive('up', 'md');
    const { filters } = useContext(ApplicationContext);

    const handlePageChange = (event, value) => {
        setPage(value);
        pageChanged(value);
    };

    const resetFilters = (event) => {
        handlePageChange(event, 1);
    };
    const pageChanged = (value) => {
        fetchWithFilters(page, searchInput);
    };

    const doGenericSearch = () => {
        fetchWithFilters(page, searchInput);
    };

    const applyFilter = () => {
        fetchWithFilters(page, searchInput, filters[0].selected[0]);
    };

    const [openFilter, setOpenFilter] = useState(false);
    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };
    const { getTextTranslated } = useLang();
    return (
        <DrcContainer heading={getTextTranslated('Daily and Weekly Reports', NAMESPACE.REPORT)}>
            {isDesktop ? (
                <ReportDesktop
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    doGenericSearch={doGenericSearch}
                    applyFilter={applyFilter}
                />
            ) : (
                <>
                    <Box sx={{ display: 'flex', mb: 2, px: 1, width: '100%' }}>
                        <GenericFilterSideBar
                            getData={applyFilter}
                            openFilter={openFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                            filters={filters}
                            filterNames={['Report Type']}
                            resetFilters={resetFilters}
                        />
                    </Box>
                </>
            )}
            <ReportGridContainer
                handlePageChange={handlePageChange}
                page={page}
                totalUsers={totalReports}
                executionTime={executionTime}
                loading={loading}
                reportList={reportsList}
                pageCount={pageCount}
                reportType={ReportType.DailyWeekly}
            />
        </DrcContainer>
    );
};

export default withOktaAuth(DailyWeeklyReport);
