import { Card, Typography } from '@mui/material';
import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { getReportPath } from 'routes/routesUtils';
import styles from './ReportTile.module.scss';
import { IReportObject } from './ReportTypes';

interface IReportTypeProps {
    report: IReportObject;
}

export default function ReportTile(props: PropsWithChildren<IReportTypeProps>) {
    const { report, children, ...other } = props;
    const { Description: title, ReportURL: url, CategoryCode: icon, ReportName: name } = report;
    return (
        <Card className={styles.cardContainer} {...other}>
            <ImageComponent size="small" src={`/icons/reports/${icon}.svg`} brokenImage={'/icons/reports/Others.svg'} />
            <Link className={styles.linkContainer} to={getReportPath(title)} state={{ data: url }}>
                <Typography variant="subtitle2" sx={{ opacity: 0.72, padding: 2 }}>
                    {name}
                </Typography>
                {children}
            </Link>
        </Card>
    );
}
