import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import style from './SectionHeader.module.scss';

type SectionHeaderProps = {
    icon?: React.ReactNode;
    title: string;
    capitalize?: boolean;
    className?: string;
};
const SectionHeader = ({ title, capitalize, className }: SectionHeaderProps) => {
    return (
        <header className={`${style.headerContainer} ${className || ''}`}>
            <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="h5" className={`${capitalize ? style.capitalize : ''}`}>
                {title}
            </TypographyTranslated>
        </header>
    );
};

export default SectionHeader;
