import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useEffect, useState } from 'react';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import { getFileNameFromS3URL } from 'utils/helper';
import { isOktaUser } from 'utils/personasUtils';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the attached file url
 * {@link APIEndPoints.GET_ATTACHMENT_URL}
 *
 */

const useGetAttachedFiles = (oktaAuth: any, fileKeys: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [fileURL, setFileURL] = useState([]);
    const [isFileLoading, setIsFileLoading] = useState(true);
    const [error, setError] = useState(null);
    const { setShowToast } = useContext(ApplicationContext);

    const fetch = async (file: string, format: string, index: number) => {
        if (showAttachmentFeature() && file && !['null', 'undefined'].includes(file)) {
            setIsFileLoading(true);
            try {
                const { token } = await getTokenAndId(oktaAuth);
                const data = await Call(
                    {
                        name: 'DownloadFile',
                        url: APIEndPoints.GET_ATTACHMENT_URL(file, isOktaUser()),
                        method: 'GET',
                        options: {}
                    },
                    token
                );
                if (data.display) {
                    setFileURL((oldArray) => [
                        ...oldArray,
                        { fileName: getFileNameFromS3URL(data.display.url) || `file${index + 1}.${format}`, url: data.display.url }
                    ]);
                }
            } catch (error) {
                setError('ERROR');
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
        }
        setIsFileLoading(false);
    };

    useEffect(() => {
        const files = fileKeys ? fileKeys.split(',') : [];
        if (files.length > 0) files.forEach((file, index) => fetch(file.replace(/\.[^/.]+$/, ''), file.split('.').pop(), index));
        //separating an extension from file key
        else setIsFileLoading(false);
    }, [fileKeys]);

    return { fileURL, error, isFileLoading };
};
export default useGetAttachedFiles;
