import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import DrcAccordion from 'components/shared/drc/DrcAccordion';
import { reportCategories } from 'constants/reportsEnum';
import React, { Fragment } from 'react';
import style from './CategorizedReport.module.scss';
import ReportTile from './ReportTile';
import { ICategorizedReportItem, ICategoryReport, IReportObject } from './ReportTypes';

interface ICategorizedReportProps {
    reports: Array<IReportObject>;
    userPersona: number;
}

const { Financials, QAReport } = reportCategories;

const CategorizedReport = (props: ICategorizedReportProps) => {
    const categorywiseReports = props.reports.reduce((result: ICategoryReport | Record<string, never>, currentValue: IReportObject) => {
        (result[currentValue.CategoryCode] = result[currentValue.CategoryCode] || []).push(currentValue);
        return result;
    }, {});
    return (
        <Fragment>
            {(Object.entries(categorywiseReports) || []).map(
                (item: ICategorizedReportItem, index: number) =>
                    item.length > 0 && (
                        <DrcAccordion
                            key={item[0]}
                            header={item[1][0].Category || 'Other Reports'}
                            icon={
                                <ImageComponent
                                    brokenImage={'/icons/reports/Others.svg'}
                                    size="small"
                                    src={`/icons/reports/${item[1][0].CategoryCode}.svg`}
                                />
                            }
                            secondaryIcon={
                                ![QAReport, Financials].includes(item[1][0].CategoryCode) && (
                                    <ImageComponent className={style.tableauIcon} size="smaller" src={'/icons/reports/tableau.svg'} />
                                )
                            }
                        >
                            <div className={style.reportsContainer}>
                                {(item[1] || []).map((itm: IReportObject) => (
                                    <ReportTile key={itm.ReportId} report={itm} />
                                ))}
                            </div>
                        </DrcAccordion>
                    )
            )}
        </Fragment>
    );
};

export default CategorizedReport;
