import { Link } from '@mui/material';
import { listingTypes } from 'components/documentlibrary/documentLibraryEnum';
import { urls } from 'constants/urls';
import React from 'react';
import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './FileColumnRenderer.module.css';

const Icon = ({ name }) => (
    <ImageComponent
        size={'small'}
        src={`/icons/sharepoint/${name}.svg`}
        brokenImage={`/icons/sharepoint/genericfile.svg`}
        className={styles.iconStyle}
    />
);

const FileColumnRenderer = (props) => {
    const supportedTypes = ['docx', 'pptx', 'xlsx'];
    const fileName = decodeURI(props.data?.webUrl.split('/').pop());
    const fileType = fileName.split('.').pop();
    const eTag = props.data?.eTag ? props.data?.eTag?.split('"')[1].split(',')[0] : props.data?.id; //id is when item is a searched result item
    const webUrl = decodeURI(props.data?.webUrl);
    const fileOpenURL = supportedTypes.includes(fileType)
        ? webUrl.split(urls.SHARED_DOCUMENTS)[0] + `${urls.LAYOUT}{${eTag}}&file=${fileName}${urls.DEFAULT_ACTION}`
        : webUrl;

    if ((!props.data?.contentType || props.data?.contentType?.name === listingTypes.FOLDER) && props.data.name) {
        return (
            <div className={styles.fileContainer + ' ' + styles.foldertype} onClick={() => props?.openFolder(props.data)}>
                <Icon name="folder" />
                <span>{props.data.displayName || fileName}</span>
            </div>
        );
    } else if (props.data?.contentType?.name === listingTypes.DOCUMENT || !props.data.name) {
        return (
            <div className={styles.fileContainer}>
                <Icon name={fileType} />
                <Link
                    onClick={() => {
                        props?.downloadDocument(eTag, props.data?.parentReference?.siteId, fileOpenURL);
                    }}
                    component="button"
                    className={styles.doclink}
                >
                    <span>{fileName}</span>
                </Link>
            </div>
        );
    }
};

export default FileColumnRenderer;
