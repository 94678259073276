import { Box } from '@mui/material';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import logo2 from '../../Images/Logo_Small_Transparent.png';
import { AppTitle } from '../../layouts/dashboard/header';
import ImageComponent from '../shared/ImageComponent/ImageComponent';
import NavigationBarSection from './NavigationBarSection';
import style from './NavigationMobileContent.module.scss';
import { GEP_TITLE } from './constants';
import { NavigationBarData } from './navigationBarData';

const NavigationMobileContent = () => {
    const { getTextTranslated } = useLang();

    return (
        <>
            <Box className={style.imageBox}>
                <ImageComponent src={logo2} size={'large'} />
            </Box>
            <Box className={style.titleBox}>
                <AppTitle appTitle={GEP_TITLE} />
            </Box>
            <NavigationBarSection navBarData={NavigationBarData} />
            <Box className={style.bottomBox}>
                <p>{`${getTextTranslated('App Version', NAMESPACE.COMMON)}[${process.env.REACT_APP_VERSION}]`}</p>
            </Box>
        </>
    );
};

export default NavigationMobileContent;
