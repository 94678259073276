import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import QueryForm from './QueryForm';
import ResponseForm from './ResponseForm';
import Support from './Support';
import ViewResponse from './ViewResponse';

const SupportRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.GROWER_ADMIN,
                            Persona.QUALITY_VIEWER,
                            Persona.HARVEST_PLANNER,
                            Persona.QA_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.PALLET_BUILDER
                        ]}
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <Support />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.SUPPORT_QUERY}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN, Persona.QUALITY_VIEWER]}>
                            <QueryForm />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.RESOLUTIONPARAMS}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}>
                            <ResponseForm />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.VIEW_RESPONSE_PARAMS}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN, Persona.QUALITY_VIEWER]}>
                            <ViewResponse />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default SupportRoutes;
