import Loader from 'components/shared/Loader/Loader';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../../../hooks/useLang';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DashboardSection from '../Section';
import SectionHeader from '../Section/SectionHeader';
import Notification from './Notification';
import { INotification } from './NotificationType';
import style from './NotificationsComponent.module.scss';

interface NotificationsComponentProps {
    loading: boolean;
    error: string | null;
    notifications: Array<INotification>;
    classNameContainer?: string;
    persona?: number;
}

const NotificationsComponent = ({ loading, error, notifications, classNameContainer }: NotificationsComponentProps) => {
    const { getTextTranslated } = useLang();
    return (
        <DashboardSection classNameContainer={`${style.notificationsContainer} ${classNameContainer}`}>
            <SectionHeader title={getTextTranslated('Notifications', NAMESPACE.DASHBOARD)} />
            {loading && <Loader circular />}
            {error && <TypographyTranslated nameSpacing={NAMESPACE.DASHBOARD}>{'Notifications Error Msg'}</TypographyTranslated>}

            {!loading && !error && (
                <section className={style.notifications}>
                    {notifications?.map((notification) => {
                        return <Notification key={notification.Entity} notification={notification} />;
                    })}
                </section>
            )}
        </DashboardSection>
    );
};

export default NotificationsComponent;
