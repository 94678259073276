import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import {
    GET_BERRY_CONNECT_BUSINESS_UNITS,
    GET_BERRY_CONNECT_CONTENT,
    GET_BERRY_CONNECT_SEARCH,
    GET_DRIVE_LIST_ITEMS
} from 'services/BerryConnectAPI';
import useGetApiTokens from './useGetApiTokens';
import useLang from './useLang';

/**
 * This fetches the berry connect sharepoint library
 * @param oktaAuth Okta auth object
 * @id has to be the identifier for the folder
 */
const useBerryConnect = (oktaAuth: any, language: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [documentList, setDocumentList] = useState<any | object>({});
    const [content, setContent] = useState<any | object>({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [contentLoading, setContentLoading] = useState(true);
    const { getTextTranslated } = useLang();
    const DOTA_SITE_ID = window.config.sharepoint_berry_connect_site_id;

    const getBusinessUnits = async (siteId?: string) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_BERRY_CONNECT_BUSINESS_UNITS(siteId ? siteId : DOTA_SITE_ID),
                    method: 'GET',
                    options: {}
                },
                token
            );
            response.display.Data = response.display.value.map((item) => {
                return { ...item, IsFolder: true, Name: item.displayName };
            });
            setDocumentList(response.display);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const getDriveItemListing = async (siteId?: string, subsiteId?: string, itemid?: string, requestType = 'folders') => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_DRIVE_LIST_ITEMS(userOktaId, siteId ? siteId : DOTA_SITE_ID, subsiteId, itemid, requestType, language),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setDocumentList(response.display);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const searchBerryConnectSite = async (query: string) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_BERRY_CONNECT_SEARCH(query, DOTA_SITE_ID, userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setDocumentList(response.display);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const downloadContent = async (url: string, token: string) => {
        const responseText = await Call(
            {
                url: url,
                method: 'GET',
                options: {}
            },
            token
        );
        return responseText.display;
    };

    const getContent = async (siteId?: string) => {
        setContentLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_BERRY_CONNECT_CONTENT(userOktaId, siteId ? siteId : DOTA_SITE_ID, language),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const { Messages } = response.display;
            for (const message of Messages) {
                const responseText = await downloadContent(message?.DownloadUrl, token);
                message['Content'] = responseText;
            }
            setContent(response.display);
        } catch (err) {
            setError(err);
        } finally {
            setContentLoading(false);
        }
    };

    return { content, getContent, documentList, error, loading, contentLoading, getDriveItemListing, getBusinessUnits, searchBerryConnectSite };
};
export default useBerryConnect;
