import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useGetLocale from '../../hooks/useGetLocale';
const ExpiryDatePicker = ({ value, onChange, ...props }) => {
    const { getTextTranslated } = useLang();
    const { locale } = useGetLocale();
    return (
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker label={getTextTranslated('Expiration Date', NAMESPACE.COMMON)} value={value} onChange={onChange} disablePast {...props} />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default ExpiryDatePicker;
