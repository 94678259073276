import CloseIcon from '@mui/icons-material/Close';
import TimerIcon from '@mui/icons-material/Timer';
import { IconButton, Snackbar } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../hooks/useLang';

interface IInactiveMessage {
    open: boolean;
    timeOutEvent: () => void;
    cancelEvent: () => void;
    timeOut: number;
}

const inactiveMessage = (props: IInactiveMessage) => {
    const { open, timeOutEvent, cancelEvent, timeOut } = props;
    const { getTextTranslated } = useLang();
    const AreYouStillHere = getTextTranslated('Are you still here?', NAMESPACE.COMMON);
    const YouWillBeLoggedOut = getTextTranslated('You will automatically be logged off from this application, due to inactivity.', NAMESPACE.COMMON);
    const CloseToCancelLogout = getTextTranslated('Close this message to prevent being logged out.', NAMESPACE.COMMON);

    const onClose = (event, reason) => {
        console.log(reason);
        if (reason === 'clickaway' || reason === undefined) {
            cancelEvent();
        } else if (reason === 'timeout') {
            console.log('called');
            timeOutEvent();
        }
    };
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={open}
            autoHideDuration={timeOut}
            onClose={onClose}
            ContentProps={{
                'aria-describedby': 'message-id'
            }}
            message={
                <>
                    <TimerIcon />
                    <span id="message-id">
                        <strong>{AreYouStillHere}</strong>
                        <br />
                        {YouWillBeLoggedOut}
                        <br />
                        {CloseToCancelLogout}
                    </span>
                </>
            }
            action={[
                <IconButton key="close" edge="end" aria-label="close" onClick={() => onClose('', 'clickaway')}>
                    <CloseIcon />
                </IconButton>
            ]}
        />
    );
};

export default inactiveMessage;
