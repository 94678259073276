import { Call } from '@driscollsinc/driscolls-display-rules';
import { ICalendarEvent } from 'components/dashboard/Calendar/CalendarTypes';
import { useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of events
 * {@link APIEndPoints.GET_RECENTEVENTS}
 * {@param {Object} oktaAuth Okta authentication object}
 * {@param {number} count is the number of events required in the response}
 * {@param {string} firstDate is the starting date of the range of events}
 * {@param {string} lastDate  is the ending date of the range of events}
 */
const useFetchEvents = (oktaAuth, count?: number, startDate?: string, endDate?: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<Array<ICalendarEvent> | []>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchEvents = async (startDate?: string, endDate?: string) => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: count ? APIEndPoints.GET_RECENTEVENTS(count, userOktaId) : APIEndPoints.GET_EVENTS(startDate, endDate, userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setData(response.display);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    };
    useEffect(() => {
        fetchEvents(startDate, endDate);
    }, []);
    return { data, loading, error, fetchEvents };
};
export default useFetchEvents;
