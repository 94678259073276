import { createContext } from 'react';

interface IBerryConnectContext {
    siteId: string | null;
    setSiteId: (id: string) => void;
    subsiteId: string | null;
    setSubsiteId: (id: string) => void;
}

const BerryConnectContext = createContext<IBerryConnectContext>({} as IBerryConnectContext);

export default BerryConnectContext;
