import { Call } from '@driscollsinc/driscolls-display-rules';
import { Middleware } from '@driscollsinc/one-ring';
import { logEvent } from 'analytics/AnalyticsHandler';
import DuAuthenticationUtilities from 'components/shared/Utilities/DuAuthenticationUtilities';
import { EventConstants } from 'constants/EventConstants';
import { isPersonaIsOKTA, persona } from 'data/config/datasetup';
import { capitalize } from 'lodash';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useCreateAssignments from './useCreateAssignment';
import useGetApiTokens from './useGetApiTokens';
import useManageReports from './useManageReports';

/**
 * This hook creates a gep user and assigns different entities to the user
 * {@link APIEndPoints.POST_CREATE_OKTA_USER}
 * {@link APIEndPoints.PUT_UPDATE_COGNITO_USER}
 *
 */

export enum exceptions {
    UsernameExistsException = 'UsernameExistsException'
}

const useInviteUser = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { createAssignments } = useCreateAssignments(oktaAuth);
    const { assignReports } = useManageReports(oktaAuth);

    const inviteUser = async (
        selectedPersonaIds,
        assignmentPayload,
        reportUrls,
        mobilePhone,
        email,
        userForm,
        cognitoUserEdit,
        cognitoUserId,
        cognitoUser,
        defaultPersona,
        existingPersonas
    ) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        const isOktaUser = isPersonaIsOKTA(selectedPersonaIds[0]); //Multiple personas, but assuming they are the same idp always
        const selectedTitles = (existingPersonas || []).map((item) => {
            const objP = persona.find((item2) => item2.Id === item);
            return objP?.title;
        });

        const payload = cognitoUserEdit ? {} : assignmentPayload.PersonaDetails || { usernamefield: 'phone' };
        if (isOktaUser) {
            payload.PersonaId = selectedPersonaIds;
            payload.Login = email;
        } else {
            //Creating Cognito User
            if (cognitoUserEdit) {
                payload.username = cognitoUserId;
                payload.locale = cognitoUser.locale;
                payload.groups = selectedTitles;
                payload.firstName = cognitoUser?.firstName;
                payload.lastName = cognitoUser?.lastName;
                payload.isWhatsAppUser = cognitoUser?.isWhatsAppUser;
            } else {
                const userEmail = DuAuthenticationUtilities.GetUserId(token);
                payload.email = email;
                payload.mobilePhone = mobilePhone;
                payload.password = 'gsfgfs65665';
                payload.username = payload.usernamefield === 'phone' ? mobilePhone : email;
                payload.lang = payload.gpaLang;
                payload.title = selectedTitles;
                payload.createdBy = userEmail;
                payload.lastModifiedBy = userEmail;
            }
        }
        try {
            if (isOktaUser) {
                const data = await Call(
                    {
                        name: 'ManageUser',
                        url: APIEndPoints.POST_CREATE_OKTA_USER(true),
                        method: 'POST',
                        options: {},
                        data: payload
                    },
                    token
                );
                if (data.display?.id) {
                    await createAssignments(selectedPersonaIds, persona, assignmentPayload, data.display, userForm, false, defaultPersona);
                    reportUrls && (await assignReports(data.display.id, reportUrls));
                    return true;
                } else {
                    setError('An error occurred while creating OKTA user. Please try again');
                }
            } else {
                const res = await Middleware.Send(
                    'ManageUser',
                    token,
                    cognitoUserEdit ? APIEndPoints.PUT_UPDATE_COGNITO_USER : APIEndPoints.POST_CREATE_OKTA_USER(false),
                    cognitoUserEdit ? 'PUT' : 'POST',
                    payload,
                    {
                        overrideRequestMapping: true,
                        overrideResponseMapping: true
                    }
                );
                if (res.response?.UserSub || res?.updatedUserData) {
                    await createAssignments(
                        selectedPersonaIds,
                        persona,
                        assignmentPayload,
                        res.response || res,
                        userForm,
                        false,
                        defaultPersona,
                        capitalize(payload?.usernamefield || '')
                    );
                    reportUrls && (await assignReports(res?.response?.UserSub || res?.updatedUserData?.Username, reportUrls));
                    return true;
                } else {
                    setError('An error occurred while creating Cognito user. Please try again');
                }
            }
        } catch (err) {
            if (err?.data?.response?.data?.code === exceptions.UsernameExistsException) {
                setError("User already exists, to add a persona to existing user please click on 'Invite An Existing User'!");
                return exceptions.UsernameExistsException;
            }
            // sometimes aws sends us success data with 406 status so handling it here
            if (err.data?.response?.data?.response?.UserSub) {
                await createAssignments(
                    selectedPersonaIds,
                    persona,
                    assignmentPayload,
                    err.data?.response?.data?.response || err.data?.response?.data,
                    userForm,
                    false,
                    defaultPersona,
                    capitalize(payload?.usernamefield || '')
                );
                reportUrls &&
                    (await assignReports(
                        err?.data?.response?.data?.response.UserSub || err?.data?.response?.data?.updatedUserData?.Username,
                        reportUrls
                    ));
                return true;
            } else {
                setError(err);
                logEvent(EventConstants.INVITE_USER_ERROR, err);
            }
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, inviteUser };
};
export default useInviteUser;
