import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'constants/routes';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import getRoutePath from 'routes/routesUtils';
import DriscollsToolTip from 'ui/DriscollsToolTip/DriscollsToolTip';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DLink from '../Link';
import style from './Notification.module.scss';
import { INotification } from './NotificationType';

interface INotificationProps {
    notification: INotification;
    classNameContainer?: string;
}

/**
 *
 * Simple Item for notification
 */

const Notification = ({ notification, classNameContainer }: INotificationProps) => {
    const { getTextTranslated } = useLang();
    const getRoute = () => {
        switch (notification.Entity) {
            case 'Library':
                return getRoutePath(ROUTES.DOCUMENT_LIBRARY);
            case 'Report':
                return getRoutePath(ROUTES.REPORTS);
            case 'QA Inspection':
                return getRoutePath(ROUTES.VIEW_WEEKLY_REPORT);
            case 'Grower Settlement':
                return getRoutePath(ROUTES.VIEW_GROWER_SETTLEMENT_REPORT);
            case 'Support':
                return getRoutePath(ROUTES.SUPPORT);
            case 'Announcement':
                return getRoutePath(ROUTES.ANNOUNCEMENT);
            case 'Company':
            case 'District':
            case 'Ranch':
                return getRoutePath(ROUTES.USER_ASSIGNMENT, notification.Entity?.toLowerCase());
            default:
                return getRoutePath(ROUTES.ROOT);
        }
    };

    const { Entity, Count } = notification;
    const translatedEntity = getTextTranslated(Entity, NAMESPACE.COMMON);

    return (
        <div className={`${style.notificationContainer} ${style[Entity.split(' ').join('')]} ${classNameContainer || ''}`}>
            <Card className={style.notification} variant="outlined">
                <CardContent className={style.notificationContent}>
                    <DriscollsToolTip headingTitle={translatedEntity}>
                        <Typography
                            variant="overline"
                            display="block"
                            gutterBottom
                            color="text.secondary"
                            className={`${style.notificationType} ${style[Entity.split(' ').join('')]} ${style.overflow}`}
                        >
                            {translatedEntity}
                        </Typography>
                    </DriscollsToolTip>
                    <Typography variant="h3" color="text.secondary">
                        {Count || 0}
                    </Typography>
                    <TypographyTranslated variant="body2" color="text.secondary" className={style.smallText} nameSpacing={NAMESPACE.DASHBOARD}>
                        {'Notifications'}
                    </TypographyTranslated>
                    <span className={style.quickViewContainer}>
                        <DLink href={getRoute()} underline="none" variant="overline">
                            <TypographyTranslated className={style.quickView} nameSpacing={NAMESPACE.DASHBOARD}>
                                {'Quick View'}
                            </TypographyTranslated>
                        </DLink>
                    </span>
                </CardContent>
            </Card>
        </div>
    );
};

export default Notification;
