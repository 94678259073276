import { Call } from '@driscollsinc/driscolls-display-rules';
import { Middleware } from '@driscollsinc/one-ring';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { logError, logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook de activates okta and cognito users
 * {@link APIEndPoints.DEACTIVATE_USER}
 *
 */

const useEditUser = (oktaAuth: IOktaContext) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const { setShowToast } = useContext(ApplicationContext);

    const getUserStatus = async (currentUserAuthId: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'status',
                    url: APIEndPoints.GET_USER_STATUS(currentUserAuthId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display.User) {
                setIsActive(data.display.User.IsActive);
                return data.display.User.IsActive;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deactivateUser = async (currentUserAuthId: string, isOktaUser: boolean, personaId?: number) => {
        setLoading(true);
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        const payload = isOktaUser
            ? {
                  Status: 0,
                  UserOktaId: currentUserAuthId,
                  UpdatedBy: userEmail,
                  ...(personaId && { PersonaId: personaId })
              }
            : {
                  username: currentUserAuthId
              };
        try {
            const data = await Call(
                {
                    name: 'user',
                    url: APIEndPoints.DEACTIVATE_USER(currentUserAuthId, isOktaUser),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            } else {
                if (isOktaUser) {
                    logEvent(EventConstants.OKTA_USER_DEACTIVATION, { selectedUser: currentUserAuthId });
                    setShowToast({ toastMessage: 'User Status Changed Successfully!', nameSpacing: NAMESPACE.USERS });
                } else {
                    await removeCognitoUser(currentUserAuthId);
                }
                return true;
            }
        } catch (err) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Server error' });
            logError(EventConstants.INVITE_USER_ERROR, err);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const removeCognitoUser = async (cognitoId: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const payload = { username: cognitoId };
            const res = await Middleware.Send('ManageUser', token, APIEndPoints.DELETE_COGNITOUSER(), 'DELETE', payload, {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            });
            if (res?.message && res?.message === 'Success') {
                logEvent(EventConstants.COGNITO_USER_DEACTIVATION, { selectedUser: cognitoId });
                await deactivateUser(cognitoId, true);
            } else {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: res?.message ?? 'An error occurred while removing Cognito user.Please try again',
                    nameSpacing: NAMESPACE.USERS
                });
            }
        } catch (error) {
            console.log('error');
        }
    };

    const removeGrowerPersona = async (personaId: number, oktaUserId: string) => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const payload = {
            Status: 0,
            PersonaId: personaId,
            UserOktaId: oktaUserId,
            UpdatedBy: userOktaId,
            UpdatedAt: new Date().toISOString()
        };

        try {
            const data = await Call(
                {
                    name: 'removePersona',
                    url: APIEndPoints.REMOVE_PERSONA(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
        } catch (error) {
            console.log(error);
        }
    };

    return { loading, error, deactivateUser, removeCognitoUser, getUserStatus, removeGrowerPersona, isActive };
};
export default useEditUser;
