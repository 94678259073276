import React, { List, ListItem, ListItemText } from '@mui/material';

interface IUsers {
    Email?: string;
    Persona?: string;
    FirstName?: string;
    LastName?: string;
    AuthId?: string;
}

const UserListComponent = ({ users }: { users: Array<IUsers> }) => {
    return (
        <List dense={true}>
            {(users || []).map((user) => {
                return (
                    <ListItem key={user?.Email + user?.Persona}>
                        <ListItemText primary={user?.FirstName + ' ' + (user?.LastName || '')} secondary={user?.Persona} />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default UserListComponent;
