import { AssignmentTypes } from 'data/config/DataSetupTypes';
import React, { useContext } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import BerriesAssignment from './BerriesAssignment';
import CompanyAssignment from './CompanyAssignment';
import DistrictAssignment from './DistrictAssignment';
import RanchAssignments from './RanchAssignments';
//Gets the assignment based on the persona
const RenderAssignments = ({
    assignmentType,
    districtCompanies,
    onChangeDistrictHandler,
    addAssignments,
    selectedDistrict,
    selectedCompany,
    addRanchAssignments,
    onAddRanchHandler,
    onChangeCompanyHandler,
    companyRanches,
    onAddCompaniesHandler,
    districtBerries,
    onAddBerriesHandler
}) => {
    const { ranches, districtOptions, districtBerries: masterDistrictBerries } = useContext(ApplicationContext);

    return (
        <>
            {assignmentType === AssignmentTypes.DISTRICTS && (
                <DistrictAssignment
                    districts={districtOptions}
                    onChangeHandler={onChangeDistrictHandler}
                    addAssignments={addAssignments}
                    value={selectedDistrict}
                    districtLabel="DISTRICTS"
                />
            )}
            {assignmentType === AssignmentTypes.DISTRICT_BERRIES && (
                <BerriesAssignment
                    districts={masterDistrictBerries}
                    districtBerries={districtBerries}
                    onChangeHandler={onChangeDistrictHandler}
                    onAddBerriesHandler={onAddBerriesHandler}
                    addAssignments={addAssignments}
                    value={selectedDistrict}
                    districtLabel="DISTRICTS"
                    secondaryLabel="Commodity"
                />
            )}
            {assignmentType === AssignmentTypes.COMPANY_RANCH && (
                <RanchAssignments
                    districts={ranches}
                    districtCompanies={companyRanches}
                    onChangeHandler={onChangeCompanyHandler}
                    onAddRanchHandler={onAddRanchHandler}
                    addAssignments={addRanchAssignments}
                    value={selectedCompany}
                    districtLabel="COMPANIES"
                    companyLabel="RANCHES"
                />
            )}
            {assignmentType === AssignmentTypes.DISTRICT_COMPANY && (
                <CompanyAssignment
                    districts={districtOptions}
                    districtCompanies={districtCompanies}
                    onChangeHandler={onChangeDistrictHandler}
                    onAddCompaniesHandler={onAddCompaniesHandler}
                    addAssignments={addAssignments}
                    value={selectedDistrict}
                    districtLabel="DISTRICTS"
                    companyLabel="COMPANIES"
                />
            )}
        </>
    );
};

export default RenderAssignments;
