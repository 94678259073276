import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { logError } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';
import useLang from './useLang';

/**
 * This hook fetches the list of users whom we can assign an entity
 * {@link APIEndPoints.GET_ENTITY_ASSIGNED_USERS}
 * {@param {Object} oktaAuth Okta authentication object}
 * {@param {number} personaId is the numeric persona id .See PersonaID for more}
 * {@param {string} filterEntity is the entity needed to be in the list.[Companies | Districts | Ranches]}
 *
 *
 */
const useGetEntityRelatedUsers = (oktaAuth, personaId, filterEntity) => {
    const { getTokenAndId } = useGetApiTokens();
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { getTextTranslated } = useLang();
    const { setShowToast } = useContext(ApplicationContext);

    const getAssignedUSers = async (personaid, requestorId, entity) => {
        try {
            setLoading(true);
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: APIEndPoints.GET_ENTITY_ASSIGNED_USERS(personaId, userOktaId, entity),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const formattedData = response.display.filter((item) => {
                return item.Email?.indexOf('@') > -1;
            });

            setAssignedUsers(formattedData);
        } catch (err) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Error getting entity assignments', nameSpacing: NAMESPACE.USERS });
            logError(EventConstants.GET_ENTITY_ASSIGNMENT_ERROR);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { assignedUsers, setAssignedUsers, error, getAssignedUSers, loading };
};
export default useGetEntityRelatedUsers;
