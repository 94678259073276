import React from 'react';
import { isJsonString } from 'utils/helper';

const RichTextRenderer = (props) => {
    const value = isJsonString(props?.value)
        ? JSON.parse(props.value)
              .blocks.map((itm) => itm.text)
              .join(' ')
        : props.value;

    return props.value && <span style={{ fontWeight: props.data?.ResponseId ? 'normal' : 'bold' }}>{value}</span>;
};

export default RichTextRenderer;
