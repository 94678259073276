import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'Announcement',
        [
            {
                name: 'AnnouncementId',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TextHeader',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FirstName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LastName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TargetCriteria',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TextBody',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RecipientType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'MessageRead',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ExpirationDate',
                type: FIELD_UNITS.DATE
            }
        ],
        {}, //options
        (data) => {
            console.log('Announcement REDUCER-- ', data);
        }
    );
}

const Announcement = {
    configureSetupCall
};

export default Announcement;
