import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Box, Button, Card, CardContent, CardHeader, List, ListItemButton, ListItemIcon } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import GenericSearchBox from 'components/shared/search/GenericSearchBox';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useEffect } from 'react';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import styles from './AssignUsersCard.module.scss';

const AssignmentUsersCard = ({ users, handleAssignment, loading }) => {
    const [checked, setChecked] = React.useState([]);
    const [filteredUsers, setFilteredUsers] = React.useState(users);
    const { getTextTranslated } = useLang();

    const handleToggleSelection = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const callAssignmentAction = () => {
        const selectedItems = users.filter((user, index) => {
            return checked.indexOf(user?.AuthId + user?.Persona) > -1;
        });
        handleAssignment(selectedItems);
    };

    const searchUsers = (searchParam) => {
        const availableUsers = users.filter((user, index) => {
            return (
                user.Email?.indexOf(searchParam) > -1 ||
                user.FirstName?.indexOf(searchParam) > -1 ||
                user.Persona?.indexOf(searchParam) > -1 ||
                user.Phone?.indexOf(searchParam) > -1
            );
        });

        setFilteredUsers(availableUsers);
    };

    useEffect(() => {
        if (users && users.length > 0) {
            setChecked([]);
            setFilteredUsers(users);
        }
    }, [users]);

    return (
        <Card>
            <CardHeader title="Users"></CardHeader>
            <CardContent className={styles.cardcontent}>
                <section className={styles.cardsearch}>
                    <GenericSearchBox className={styles.searchbox} setValue={searchUsers} />
                </section>
                <article>
                    <List className={styles.userslist}>
                        {loading ? (
                            <DriscollsSpinner text={getTextTranslated('Loading Users', NAMESPACE.COMMON)} />
                        ) : (
                            (filteredUsers || []).map((user, index) => {
                                return (
                                    <>
                                        <ListItemButton
                                            role={undefined}
                                            key={index}
                                            onClick={(event) => handleToggleSelection(user?.AuthId + user?.Persona)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(user?.AuthId + user?.Persona) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={user.FirstName + ' ' + (user.LastName || '')}
                                                secondary={
                                                    <>
                                                        <p className={styles.secondaryLabel}>{user.Email}</p>
                                                        <p className={styles.secondaryLabel}>{user.Persona}</p>
                                                    </>
                                                }
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                );
                            })
                        )}
                    </List>
                    <Box className={styles.usercardfooter}>
                        <Button disabled={checked.length === 0} variant="contained" onClick={(event) => callAssignmentAction()}>
                            <TypographyTranslated>{'Assign'}</TypographyTranslated>
                        </Button>
                    </Box>
                </article>
            </CardContent>
        </Card>
    );
};

export default AssignmentUsersCard;
