import CallIcon from '@mui/icons-material/Call';
import React from 'react';
import style from './PhoneItem.module.scss';

const PhoneItem = ({ phoneNumber, showIcon }: { phoneNumber: string; showIcon?: boolean }) => {
    return (
        <div className={style.container}>
            {showIcon && phoneNumber !== '' && <CallIcon />}
            <span>{phoneNumber}</span>
        </div>
    );
};

export default PhoneItem;
