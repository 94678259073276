import React from 'react';
import { styled } from '@mui/material/styles';

let environment = '';

/**
 * @description DrcEnvironmentMarker is an environment specific marker
 * @class DrcEnvironmentMarker
 * @property {object} classes Sets the style of marker
 * @example
 * <DrcEnvironmentMarker />
 * @donotprint true
 * @category Environment
 * @tags environment marker notice alert warning
 * @extends {React.Component}
 */

class DrcEnvironmentMarker extends React.Component {
    /**
     * Creates an instance of DrcEnvironmentMarker.
     * @param {*} props
     * @memberof DrcEnvironmentMarker
     */

    constructor(props) {
        super(props);

        const hostname = window.location.hostname.toLowerCase();

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'Dev';
        } else if (hostname.includes('stage')) {
            environment = 'Stage';
        } else if (hostname.includes('uat')) {
            environment = 'UAT';
        } else if (hostname.includes('qa')) {
            environment = 'QA';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'Test';
        } else {
            environment = ''; //Production, do not show to user
        }
    }

    /**
     * @return {*}
     * @memberof DrcEnvironmentMarker
     */

    render() {
        if (environment.length === 0) {
            return null;
        }

        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: 0,
                    borderBottom: '48px solid transparent',
                    borderRight: '48px solid ' + '#4F7A44',
                    zIndex: 1200,
                    pointerEvents: 'none',
                    '@media print': {
                        display: 'none'
                    }
                }}
            >
                <span
                    style={{
                        position: 'fixed',
                        color: '#F6F8F5',
                        fontSize: '15px',
                        transform: 'rotate(45deg)',
                        textAlign: 'center',
                        top: 6,
                        right: -6,
                        width: 48
                    }}
                >
                    {environment}
                </span>
            </div>
        );
    }
}

export default DrcEnvironmentMarker;
