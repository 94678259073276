import { DriscollsHContainer, DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';
import { useOktaAuth } from '@okta/okta-react';
import DrcOktaWidget from 'components/shared/drc/DrcOktaWidget';
import { EventConstants } from 'constants/EventConstants';
import useCheckUserToken from 'hooks/useCheckUserToken';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { logEvent } from '../../analytics/AnalyticsHandler';
import style from './LoginPage.module.scss';

const LoginPage = () => {
    const { oktaAuth } = useOktaAuth();
    const isLoggedIn = useCheckUserToken();

    const onError = (err) => {
        console.error('error logging in', err);
    };
    const onSuccess = (res) => {
        if (res.status === 'SUCCESS') {
            logEvent(EventConstants.USER_LOGGED_IN, { login: res.user.profile.login, idp: 'okta' });
            localStorage.setItem('authenticationProvider', 'okta');
            return oktaAuth.signInWithRedirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    };
    return (
        isLoggedIn ? (
            <Navigate to={'/InitializeApplication/'} />
        ) :
            <DriscollsHContainer className={style.loginPage} noPadding  >
                <div className={style.loginImage} ></div>
                <DriscollsVContainer className={style.loginForm} noPadding >
                    <DrcOktaWidget baseUrl={window.config.OKTA_URL} onSuccess={onSuccess} onError={onError} config={{}} />
                </DriscollsVContainer>
            </DriscollsHContainer>

    );
};

export default LoginPage;
