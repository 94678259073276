import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { CardActions, CardContent, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import { withOktaAuth } from '@okta/okta-react';
import EventFieldsFormControl from 'components/events/EventFieldsFormControl';
import ApplicationContext from 'context/ApplicationContext';
import { IFormContext } from 'context/FormContext';
import useCreateEvent, { ICreateEventPayload } from 'hooks/useCreateEvent';
import { NAMESPACE } from 'i18n';
import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { viewsPerRoute } from 'routes/routesEnum';
import { getRouteByView } from 'routes/routesUtils';
import { default as EventFormContext, IEventForm } from '../../context/EventFormContext';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import getValidationResolver from '../../utils/ValidationResolver';
import style from './CreateEvent.module.scss';
import getEventFieldsValidationSchema from './EventFieldsValidationSchema';

const CreateEvent = (props) => {
    const { oktaAuth } = props;
    const navigate = useNavigate();
    const { create, loading: saveEventLoading } = useCreateEvent(oktaAuth);
    const { getTextTranslated } = useLang();
    const { setShowToast } = useContext(ApplicationContext);
    const REQUIRED_ERROR_MESSAGE = getTextTranslated('This field is required', NAMESPACE.EVENTS);

    const [formValues, setFormValues] = useState<IEventForm>({
        IsActive: '1',
        Name: '',
        Description: '',
        StartDate: '',
        EndDate: '',
        DistrictCode: []
    });

    const validationSchema = getEventFieldsValidationSchema(REQUIRED_ERROR_MESSAGE);
    const resolver = getValidationResolver(validationSchema);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm({ resolver, mode: 'onBlur' });

    const validateField = (name, focus = false) => {
        trigger(name, { shouldFocus: focus });
    };

    const setValueAndValidate = (name, value) => {
        setValue(name, value);
        validateField(name);
    };

    const formMemo = useMemo<IFormContext<IEventForm>>(
        () => ({
            formFields: formValues,
            errors,
            register,
            setValue: setValueAndValidate,
            validate: validateField
        }),
        [formValues, errors]
    );

    const validateDate = () => {
        // this method is used for converting the date/time<Date> to other format<string> used in the API=)
        const validatedEvent = {
            Name: formValues.Name,
            Description: formValues.Description,
            DistrictCode: formValues.DistrictCode
        } as ICreateEventPayload;

        const startDate = new Date(formValues.StartDate).toISOString();

        validatedEvent.StartDate = startDate.substring(0, 10) + 'T' + startDate.substring(11, 19);
        if (validatedEvent.EndDate === '') {
            validatedEvent.EndDate = validatedEvent.StartDate;
        } else {
            const endDate = new Date(formValues.EndDate).toISOString();
            validatedEvent.EndDate = endDate.substring(0, 10) + 'T' + endDate.substring(11, 19);
        }
        return validatedEvent;
    };

    const updateFormValues = (formData) => {
        setFormValues(formData);
    };

    const createEvent = async () => {
        const { error } = await create(validateDate());
        if (!error) {
            setFormValues({ IsActive: '1', Name: '', Description: '', StartDate: '', EndDate: '', DistrictCode: [] });
            navigate(getRouteByView(viewsPerRoute.ALL_EVENTS));
            setShowToast({ toastMessage: 'Event created successfully !!!' });
        } else {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something went wrong. Please try again !!!' });
        }
    };

    return (
        <DrcContainer heading={getTextTranslated('Calendar', NAMESPACE.EVENTS)}>
            <div className={style.container}>
                <EventFormContext.Provider value={formMemo}>
                    <form onSubmit={handleSubmit(createEvent)}>
                        <Card>
                            <CardHeader
                                title={getTextTranslated('Event Creation', NAMESPACE.EVENTS)}
                                subheader={getTextTranslated(
                                    'Please enter title, description, start date & end date for the new event',
                                    NAMESPACE.EVENTS
                                )}
                            />
                            <CardContent className={style.content}>
                                <EventFieldsFormControl onChange={updateFormValues} memo={formMemo} formData={formValues} />
                            </CardContent>
                            <CardActions className={style.actions}>
                                <DriscollsLoadingButton
                                    variant={ButtonVariant.PRIMARY}
                                    className={style.create}
                                    type="submit"
                                    disabled={!isValid}
                                    onClick={handleSubmit(createEvent)}
                                    loading={saveEventLoading}
                                    label={getTextTranslated('Create', NAMESPACE.COMMON)}
                                />
                                <DriscollsLoadingButton
                                    variant={ButtonVariant.SECONDARY}
                                    onClick={() => {
                                        navigate(getRouteByView(viewsPerRoute.ALL_EVENTS));
                                    }}
                                    label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                                />
                            </CardActions>
                        </Card>
                    </form>
                </EventFormContext.Provider>
            </div>
        </DrcContainer>
    );
};

export default withOktaAuth(CreateEvent);
