import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Box, Chip, Container, Grid, ListItem, Paper, Typography, styled } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { ComponentName } from 'constants/ComponentEnum';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { forms } from 'data/config/inviteforms';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useUpdateLanguage from 'hooks/useUpdateLanguage';
import { NAMESPACE } from 'i18n';
import React, { useContext, useState } from 'react';
import DriscollsLegend from 'ui/DriscollsLegend/DriscollsLegend';
import { logEvent } from '../../analytics/AnalyticsHandler';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import DrcDialog from '../../components/shared/drc/DrcDialog';
import PersonalInformation from '../../components/usermanagement/PersonalInformation';
import { EventConstants } from '../../constants/EventConstants';
import ApplicationContext from '../../context/ApplicationContext';
import useLang, { Lang } from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';

const StyledPaper = styled(Paper)(({ theme }) => ({
    boxShadow: theme.shadows[2],
    borderRadius: '10px',
    padding: '10px',
    margin: '24px',
    maxWidth: '70vw'
}));

const MultiLineChip = styled(Chip)({
    height: 'auto',
    '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal'
    }
});

const UserProfile = (props) => {
    const { oktaAuth } = props;
    useCheckMasterData();
    const { getTextTranslated, changeLanguage } = useLang();
    const { userPersona, userPersonas, userProfile, setUserProfile, companies, ranches, districtOptions, setShowToast, districtBerries } =
        useContext(ApplicationContext);
    const currentUserPersona = ([...userPersonas] || []).filter((item) => item.PersonaId === userPersona);
    const districts = districtOptions;
    const [changedLanguage, setChangedLanguage] = useState('');
    const { updateLanguageCall, error: updateLangError, loading: updateLangLoading } = useUpdateLanguage(oktaAuth);
    const [languageChangeDialogOpen, setLanguageChangeDialogOpen] = useState(false);
    const { profile, id } = userProfile;
    const { firstName, lastName, mobilePhone, email, gpaLang } = profile;
    const personalInfo = { FirstName: firstName, LastName: lastName, Email: email, Phone: mobilePhone, Lang: gpaLang, UserOktaId: id };
    const currentEditUser = [personalInfo]; // storing it as currentEditUser to use the same code

    const getLanguage = (payload) => {
        setChangedLanguage(payload);
    };

    const changeLang = async () => {
        const { FirstName, LastName, Email, Phone, Lang } = personalInfo;
        const payload = {
            UpdatedBy: personalInfo.UserOktaId,
            AuthId: personalInfo.UserOktaId,
            FirstName,
            LastName,
            Email,
            Phone: Phone || null,
            Lang: changedLanguage || Lang,
            CreatedBy: userPersonas[0].PersonaName
        };
        const response = await updateLanguageCall(payload);
        if (response.status) {
            setShowToast({ toastMessage: 'Language updated successfully!', nameSpacing: NAMESPACE.USERS });
            setUserProfile({ ...userProfile, profile: { ...userProfile.profile, gpaLang: changedLanguage } });
            setLanguageChangeDialogOpen(true);
        } else {
            logEvent(EventConstants.USER_PROFILE_ERROR, updateLangError);
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Could not change language. Please try again',
                nameSpacing: NAMESPACE.USERS
            });
        }
    };

    const updateLanguage = () => {
        logEvent(EventConstants.CHANGE_LANGUAGE, changedLanguage);
        sessionStorage.setItem('GEP_UserLang', changedLanguage);
        setLanguageChangeDialogOpen(false);
        setChangedLanguage('');
        changeLanguage(changedLanguage as Lang);
    };

    const getAssignmentType = () => {
        const currentPersonaType = forms.find((form: IInviteForms) => form.persona === userPersona);
        return currentPersonaType.assignmentType;
    };

    return (
        <DrcContainer heading={getTextTranslated('My Profile', NAMESPACE.USERS)}>
            <Container>
                <PersonalInformation
                    currentEditUser={currentEditUser}
                    persona={currentUserPersona[0].PersonaName}
                    getLanguage={getLanguage}
                    parentComponent={ComponentName.UserProfile}
                />
                <Grid xs={12} sm={12} md={12} display="flex" justifyContent="right" alignItems="center">
                    <DriscollsLoadingButton
                        variant={ButtonVariant.PRIMARY}
                        label={getTextTranslated('Update Language', NAMESPACE.USERS)}
                        loading={updateLangLoading}
                        disabled={!changedLanguage}
                        onClick={changeLang}
                    />
                </Grid>

                <DriscollsLegend
                    title={<TypographyTranslated nameSpacing={NAMESPACE.COMMON}>Assignments</TypographyTranslated>}
                    show={!!getAssignmentType()}
                >
                    {getAssignmentType() === AssignmentTypes.DISTRICTS && (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }}>
                            {(districts || []).map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                        p: 0.5,
                                        m: 0
                                    }}
                                >
                                    <ListItem>
                                        <Chip label={`${item.DistrictName} - ${item.DistrictCode}`} />
                                    </ListItem>
                                </Box>
                            ))}
                        </Grid>
                    )}

                    {getAssignmentType() === AssignmentTypes.DISTRICT_COMPANY && (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }}>
                            {(companies || []).map((item) => (
                                <StyledPaper key={item.DistrictName}>
                                    <Typography sx={{ textAlign: 'center' }} variant="h6">{`${item.DistrictName} - ${item.DistrictCode}`}</Typography>
                                    {item?.Companies.map((company) => (
                                        <ListItem key={company.Code}>
                                            <MultiLineChip label={`${company.Name} - ${company.Code}`} />
                                        </ListItem>
                                    ))}
                                </StyledPaper>
                            ))}
                        </Grid>
                    )}

                    {getAssignmentType() === AssignmentTypes.COMPANY_RANCH && (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }}>
                            {(ranches || []).map((item) => (
                                <StyledPaper key={item.CompanyNumber}>
                                    <Typography variant="h6">{`${item.CompanyName ?? item.GrowerName} - ${
                                        item.CompanyNumber ?? item.GrowerNbr
                                    }`}</Typography>
                                    {item?.Ranches.map((ranch) => (
                                        <ListItem key={ranch.code}>
                                            <MultiLineChip label={`${ranch.Name ?? ranch.RanchName} - ${ranch.Code ?? ranch.RanchNbr}`} />
                                        </ListItem>
                                    ))}
                                </StyledPaper>
                            ))}
                        </Grid>
                    )}

                    {getAssignmentType() === 'districtBerries' && (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }}>
                            {(districtBerries || []).map((item) => (
                                <StyledPaper key={item.DistrictName}>
                                    <Typography sx={{ textAlign: 'center' }} variant="h6">{`${item.DistrictName} - ${item.DistrictCode}`}</Typography>
                                    {item?.BerryTypes.map((berry) => (
                                        <ListItem key={berry.value}>
                                            <MultiLineChip label={getTextTranslated(berry.value, NAMESPACE.MAIN)} />
                                        </ListItem>
                                    ))}
                                </StyledPaper>
                            ))}
                        </Grid>
                    )}
                </DriscollsLegend>
            </Container>
            <DrcDialog
                title={getTextTranslated('Language Updated!', NAMESPACE.USERS)}
                open={languageChangeDialogOpen}
                buttons={
                    <>
                        <TranslatedDrcButton
                            nameSpacing={NAMESPACE.COMMON}
                            onClick={() => {
                                setLanguageChangeDialogOpen(false);
                                setChangedLanguage('');
                            }}
                        >
                            {'No'}
                        </TranslatedDrcButton>
                        <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isPrimary onClick={updateLanguage}>
                            {'Yes'}
                        </TranslatedDrcButton>
                    </>
                }
            >
                <div>
                    <TypographyTranslated nameSpacing={NAMESPACE.USERS}>
                        {'Your changes are saved, do you want to update your current App language too?'}
                    </TypographyTranslated>
                </div>
            </DrcDialog>
        </DrcContainer>
    );
};

export default withOktaAuth(UserProfile);
