import { Fade, Tooltip, tooltipClasses } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React, { PropsWithChildren } from 'react';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import styledTooltip from './DriscollsToolTip.module.scss';

const placementTypes = [
    'bottom',
    'left',
    'right',
    'top',
    'bottom-end',
    'bottom-start',
    'left-end',
    'left-start',
    'right-end',
    'right-start',
    'top-end',
    'top-start'
] as const;
type Nullable<T> = T | null;

type htmlToolTipProps = {
    className: Nullable<string>;
    children: any;
    title: any;
    placement: (typeof placementTypes)[number];
    TransitionComponent: any;
    arrow: boolean;
    TransitionProps: any;
};

type DrcToolTipProps = {
    className?: Nullable<string>;
    headingTitle?: string;
    description?: string;
    arrow?: Nullable<boolean>;
    placement?: Nullable<(typeof placementTypes)[number]>;
};

const HtmlTooltip = (props: PropsWithChildren<htmlToolTipProps>) => (
    <Tooltip
        TransitionProps={props.TransitionProps}
        title={props.title}
        placement={props.placement}
        className={` ${props?.className} ${tooltipClasses.tooltip}`}
        classes={{ popper: styledTooltip.tooltip }}
    >
        {props.children}
    </Tooltip>
);

const DriscollsToolTip = (props: PropsWithChildren<DrcToolTipProps>) => {

    return (
        <HtmlTooltip
            className={props.className}
            title={
                <React.Fragment>
                    <TypographyTranslated
                        nameSpacing={NAMESPACE.COMMON}
                        color="inherit"
                        sx={{ textTransform: 'capitalize', color: 'primary.main', fontWeight: 'bold' }}
                    >
                        {props.headingTitle}
                    </TypographyTranslated>
                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{props.description}</TypographyTranslated>
                </React.Fragment>
            }
            placement={props.placement || 'bottom'}
            TransitionComponent={Fade}
            arrow={props.arrow || false}
            TransitionProps={{ timeout: 600 }}
        >
            <div>{props.children}</div>
        </HtmlTooltip>
    );
};

export default DriscollsToolTip;
