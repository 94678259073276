import React from 'react';

import { Button, Divider, ListItem, ListItemText, Stack, Typography } from '@mui/material';

const ReportListItemRenderer = ({ row, downloadReport }) => {
    return (
        <>
            <ListItem component={Button} onClick={() => downloadReport(row.FileKey)} alignItems="flex-start">
                <ListItemText
                    primary={row.FileName}
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        letterSpacing: 0
                    }}
                    secondary={
                        <Stack spacing={1}>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {row.UpdatedAt}
                            </Typography>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {row.ReportType}
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};
export default ReportListItemRenderer;
