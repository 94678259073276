import React from 'react';

export const setKioskMode = () => {
    localStorage.setItem('kioskMode', 'true');
    window.location.href = '/';
};
export const getKioskMode = () => {
    if (localStorage.getItem('kioskMode')) {
        return localStorage.getItem('kioskMode') === 'true' && localStorage.getItem('authenticationProvider') === 'cognito';
    }
    return false;
};

const kioskMode = () => {
    setKioskMode();
    return <>Kioskmode set</>;
};

export default kioskMode;
