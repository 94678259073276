class FilterStruct {
    query;
    fieldName;
    displayName;
    hasOptions;
    options;
    optionLabel;
    optionValue;
    selected;
    value;
    isDate;
    isInt;
    isFloat;
    isNumeric;
    isBool;
    isAlphanumeric;
    isString;
    allowedLength;
    isSelected;
    isBerry;
    isAsync;
    isMulti;
    validations;
    hasError;
    customProps;
    isPhoneNumber;

    constructor(filter) {
        this.query = filter.QueryName || '';
        this.fieldName = filter.FieldName || '';
        this.displayName = filter.DisplayName || '';
        this.hasOptions = filter.hasOptions || false;
        this.options = filter.Options || [];
        this.optionLabel = filter.OptionLabel || 'label';
        this.optionValue = filter.OptionValue || 'value';
        this.selected = filter.selected || [];
        this.value = filter.value || '';
        this.isDate = filter.isDate || false;
        this.isInt = filter.isInt || false;
        this.isFloat = filter.isFloat || false;
        this.isNumeric = filter.isNumeric || false;
        this.isBool = filter.isBool || false;
        this.isAlphanumeric = filter.isAlphanumeric || false;
        this.isString = filter.isString || false;
        this.allowedLength = filter.allowedLength || null;
        this.isSelected = filter.isSelected || false;
        this.isBerry = filter.IsBerry || false;
        this.isAsync = filter.IsAsync || false;
        this.isMulti = filter.isMulti || false;
        this.validations = filter.validations || [];
        this.hasError = filter.hasError || false;
        this.customProps = filter.customProps || {};
        this.isCheckbox = filter.isCheckbox || false;
        this.isPhoneNumber = filter.isPhoneNumber || false;
    }
}

const createFilter = (field) => {
    switch (field.Datatype.toLowerCase()) {
        case 'int':
            field.isInt = true;
            break;
        case 'float':
            field.isFloat = true;
            break;
        case 'string':
            field.isString = true;
            break;
        case 'bool':
            field.isBool = true;
            break;
        case 'date':
            field.isDate = true;
            break;
        case 'alphanumeric':
            field.isAlphanumeric = true;
            break;
        case 'lookup':
        case 'attribute':
        case 'entity':
            field.hasOptions = true;
            break;

        default:
            break;
    }

    if (field.IsAsync) {
        field.hasOptions = false;
    }

    return new FilterStruct(field);
};

export default createFilter;
