import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useState } from 'react';
import { isOktaUser } from 'utils/personasUtils';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook creates file in bus and returns a callback function with filekey
 * {@link APIEndPoints.GET_SIGNED_UPLOAD_URL}
 *
 */

const useGetSignedUrl = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);

    const fetchSignedUrl = async (
        fileData: any,
        fileType: string,
        fileName: string,
        folderName: string,
        completeUpload: any,
        showProgress = true
    ) => {
        const { token } = await getTokenAndId(oktaAuth);
        if (showProgress) {
            setLoading(true);
        }

        try {
            const data = await Call(
                {
                    name: 'GetSignedUrl',
                    url: APIEndPoints.GET_SIGNED_UPLOAD_URL(fileName, fileType, folderName, isOktaUser()),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display.url && data.display.url.indexOf(folderName) > -1) {
                APIEndPoints.uploadToS3(data.display.url, fileType, fileData, folderName, completeUpload);
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
        } catch (error) {
            setError('error');
        } finally {
            if (showProgress) {
                setLoading(false);
            }
        }
    };

    return { fetchSignedUrl, error, loading };
};
export default useGetSignedUrl;
