import RichTextRenderer from 'components/shared/cellrenderers/RichTextRenderer';
import { NAMESPACE } from 'i18n';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import GenericRenderer from '../components/shared/cellrenderers/GenericRenderer';
import UserManagementCompany from '../components/shared/cellrenderers/UserManagementCompany';
import UserManagementDistricts from '../components/shared/cellrenderers/UserManagementDistricts';
import UserManagementRanches from '../components/shared/cellrenderers/UserManagementRanches';
import { getTxtTranslated } from '../hooks/useLang';

export const growerManageUserColumns = (actionColumn) => {
    return [
        actionColumn,
        { field: 'Persona', headerName: 'Persona', width: 150 },
        { field: 'FirstName', headerName: 'First Name', width: 200 },
        { field: 'LastName', headerName: 'Last Name', width: 200 },
        {
            field: 'Email',
            headerName: 'Email',
            width: 250,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'email'
            }
        },
        { field: 'Phone', headerName: 'Phone', width: 200 },
        {
            field: 'Lang',
            headerName: 'Language',
            width: 120,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'lang'
            }
        },
        { field: 'Districts', headerName: 'Districts', width: 250, minWidth: 250, cellRenderer: UserManagementDistricts },
        { field: 'Companies', headerName: 'Companies', width: 250, minWidth: 250, cellRenderer: UserManagementCompany },
        { field: 'Ranches', headerName: 'Ranches', width: 250, minWidth: 250, cellRenderer: UserManagementRanches },
        {
            field: 'UpdatedAt',
            headerName: 'Modified Date',
            width: 150,
            minWidth: 150
        },
        {
            field: 'UpdatedBy',
            headerName: 'Modified By',
            width: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'email'
            }
        },
        {
            field: 'CreatedAt',
            headerName: 'Created Date',
            width: 150,
            minWidth: 150
        }
    ].filter((item) => {
        return item !== null;
    });
};

export const announcementColumns = (deleteColumn, subjectColumn, bodyColumn) => {
    const columns = [
        deleteColumn ? deleteColumn : { suppressToolPanel: true, hide: true },
        { field: 'TargetCriteria', headerName: 'Recipients', width: 150, minWidth: 150 },
        subjectColumn,
        bodyColumn
    ];
    if (showAttachmentFeature() === true) {
        columns.push({
            field: deleteColumn ? 'AttachementURL' : 'AttachmentURL',
            headerName: 'Attachments',
            width: 120,
            minWidth: 120,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'attachments'
            }
        });
    }
    columns.push({
        field: 'ExpirationDate',
        headerName: 'Expiration Date',
        width: 180,
        minWIdth: 180
    });
    return columns;
};
export const supportRegionalColumns = (replyColumn) => {
    const columns = [
        replyColumn,
        {
            field: 'RequesterUserName',
            headerName: 'User Name',
            width: 160,
            minWidth: 140,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'subject'
            }
        },
        {
            field: 'RequesterPersona',
            headerName: 'Persona',
            width: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'subject'
            }
        },
        {
            field: 'RequestId',
            headerName: 'Incident Number',
            width: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'incident'
            }
        },
        {
            field: 'RequestHeader',
            headerName: 'Subject',
            width: 160,
            cellRenderer: GenericRenderer,
            tooltipField: 'RequestHeader',
            cellRendererParams: {
                type: 'subject'
            }
        },
        {
            field: 'RequestBody',
            headerName: 'Request',
            cellRenderer: RichTextRenderer,
            width: 280,
            tooltipField: 'RequestBody'
        },
        {
            field: 'Priority',
            headerName: 'Priority',
            width: 120,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'priority'
            }
        },
        {
            field: 'ResponseBody',
            headerName: 'Response',
            width: 300,
            minWidth: 300,
            tooltipField: 'Resolution'
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 100,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'status'
            }
        },
        {
            field: 'RequestCreatedBy',
            headerName: getTxtTranslated('Raised By', 'support'),
            width: 180
        },
        {
            field: 'RequestCreatedAt',
            headerName: getTxtTranslated('Raised On', 'support'),
            width: 140,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        },
        {
            field: 'RequestUpdatedBy',
            headerName: 'Request Modified By',
            width: 180
        },
        {
            field: 'RequestUpdatedAt',
            headerName: 'Modified Date',
            width: 140,
            minWidth: 140,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        }
    ];
    if (showAttachmentFeature() === true) {
        columns.push({
            field: 'RequestAttachmentURL',
            headerName: 'Request Attachments',
            width: 180,
            minWidth: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'attachments'
            }
        });
        columns.push({
            field: 'ResponseAttachmentURL',
            headerName: 'Response Attachments',
            width: 180,
            minWidth: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'attachments'
            }
        });
    }
    return columns;
};
export const supportGrowerColumns = (viewColumn) => {
    const columns = [
        viewColumn,
        {
            field: 'RequestId',
            headerName: 'Incident Number',
            width: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'incident'
            }
        },
        {
            field: 'RequestHeader',
            headerName: 'Subject',
            width: 160,
            cellRenderer: GenericRenderer,
            tooltipField: 'RequestHeader',
            cellRendererParams: {
                type: 'subject'
            }
        },
        {
            field: 'RequestBody',
            headerName: 'Query',
            width: 280,
            cellRenderer: RichTextRenderer,
            tooltipField: 'RequestBody'
        },
        {
            field: 'Priority',
            headerName: 'Priority',
            width: 120,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'priority'
            }
        },
        {
            field: 'ResponseBody',
            headerName: 'Response',
            width: 300,
            minWidth: 300
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 100,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'status'
            }
        },
        {
            field: 'RequestCreatedAt',
            headerName: 'Raised On',
            width: 140,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        },
        {
            field: 'ResponseUpdatedAt',
            headerName: 'Resolved On',
            width: 140,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        }
    ];
    if (showAttachmentFeature() === true) {
        columns.push({
            field: 'RequestAttachmentURL',
            headerName: 'Request Attachments',
            width: 180,
            minWidth: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'attachments'
            }
        });
        columns.push({
            field: 'ResponseAttachmentURL',
            headerName: 'Response Attachments',
            width: 180,
            minWidth: 180,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'attachments'
            }
        });
    }

    return columns;
};

export const libraryColumns = (folderColumn, excludedColumns = []) => {
    return [
        folderColumn,
        {
            field: 'createdBy',
            headerName: getTxtTranslated('Created By', NAMESPACE.COMMON),
            width: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'libraryemail'
            }
        },
        {
            field: 'createdDateTime',
            headerName: 'Created At',
            width: 200,
            minWidth: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        },
        {
            field: 'lastModifiedBy',
            headerName: getTxtTranslated('Modified By', NAMESPACE.COMMON),
            width: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'libraryemail'
            }
        },
        {
            field: 'lastModifiedDateTime',
            headerName: 'Modified At',
            width: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'datetime'
            }
        }
    ].filter((item) => {
        return !excludedColumns.includes(item.field);
    });
};
export const reportColumns = (downloadColumn) => {
    return [
        downloadColumn,
        {
            field: 'FileName',
            headerName: 'File Name',
            width: 300,
            minWidth: 350,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'report'
            }
        },
        {
            field: 'ReportType',
            headerName: 'Report Type',
            width: 200
        },
        {
            field: 'ReportDate',
            headerName: 'Report Date',
            width: 200,
            minWidth: 200,
            cellRenderer: GenericRenderer,
            cellRendererParams: {
                type: 'date'
            }
        }
    ];
};
