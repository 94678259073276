import { Call } from '@driscollsinc/driscolls-display-rules';
import { logEvent } from 'analytics/AnalyticsHandler';
import { IReportObject } from 'components/reports/ReportTypes';
import { EventConstants } from 'constants/EventConstants';
import { ROUTES } from 'constants/routes';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

const DAILY_WEEKLY_REPORT_ID = 30;
const GROWER_SETTLEMENT_REPORT_ID = 31;

/**
 * This hook fetches the list of contacts for an user
 * {@link APIEndPoints.GET_ASSIGNED_REPORTS}
 *
 */
const useFetchReports = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);

    const getAssignedReports = async (userLang: string, userOktaId?: string) => {
        const { token } = await getTokenAndId(oktaAuth);

        try {
            const data = await Call(
                {
                    name: 'Reports',
                    url: APIEndPoints.GET_ASSIGNED_REPORTS(userOktaId, userLang),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const result = (data?.display || []).map((report: IReportObject) => {
                if (report.ReportId === GROWER_SETTLEMENT_REPORT_ID) {
                    return {
                        ...report,
                        label: report.ReportName,
                        Description: ROUTES.VIEW_GROWER_SETTLEMENT_REPORT
                    };
                } else if (report.ReportId === DAILY_WEEKLY_REPORT_ID) {
                    return {
                        ...report,
                        label: report.ReportName,
                        Description: ROUTES.VIEW_WEEKLY_REPORT
                    };
                } else
                    return {
                        ...report,
                        label: report.ReportName
                    };
            });
            return result;
        } catch (error) {
            console.log(error);
            setError(error);
            logEvent(EventConstants.GET_REPORTS, error);
        }
    };

    return { error, getAssignedReports };
};
export default useFetchReports;
