import { FaIcons } from '@driscollsinc/style-guide-web2.0';
import { RichUtils } from 'draft-js';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';

const RichTextToolbar = ({ editorState, setEditorState }) => {
    const { getTextTranslated } = useLang();
    const tools = [
        {
            label: getTextTranslated('bold', NAMESPACE.COMMON),
            style: 'BOLD',
            icon: <FaIcons.FaBold />,
            method: 'inline'
        },
        {
            label: getTextTranslated('italic', NAMESPACE.COMMON),
            style: 'ITALIC',
            icon: <FaIcons.FaItalic />,
            method: 'inline'
        },
        {
            label: getTextTranslated('underline', NAMESPACE.COMMON),
            style: 'UNDERLINE',
            icon: <FaIcons.FaUnderline />,
            method: 'inline'
        },
        {
            label: getTextTranslated('highlight', NAMESPACE.COMMON),
            style: 'HIGHLIGHT',
            icon: <FaIcons.FaHighlighter />,
            method: 'inline'
        },
        {
            label: getTextTranslated('strike-through', NAMESPACE.COMMON),
            style: 'STRIKETHROUGH',
            icon: <FaIcons.FaStrikethrough />,
            method: 'inline'
        },
        {
            label: getTextTranslated('Superscript', NAMESPACE.COMMON),
            style: 'SUPERSCRIPT',
            icon: <FaIcons.FaSuperscript />,
            method: 'inline'
        },
        {
            label: getTextTranslated('Subscript', NAMESPACE.COMMON),
            style: 'SUBSCRIPT',
            icon: <FaIcons.FaSubscript />,
            method: 'inline'
        },
        {
            label: getTextTranslated('Monospace', NAMESPACE.COMMON),
            style: 'CODE',
            icon: <FaIcons.FaTextWidth />,
            method: 'inline'
        },
        {
            label: getTextTranslated('Blockquote', NAMESPACE.COMMON),
            style: 'blockQuote',
            icon: <FaIcons.FaQuoteRight />,
            method: 'block'
        },
        {
            label: getTextTranslated('Unordered-List', NAMESPACE.COMMON),
            style: 'unordered-list-item',
            method: 'block',
            icon: <FaIcons.FaListUl />
        },
        {
            label: getTextTranslated('Ordered-List', NAMESPACE.COMMON),
            style: 'ordered-list-item',
            method: 'block',
            icon: <FaIcons.FaListOl />
        },
        {
            label: getTextTranslated('Code Block', NAMESPACE.COMMON),
            style: 'CODEBLOCK',
            icon: <FaIcons.FaCode />,
            method: 'inline'
        },
        {
            label: getTextTranslated('Uppercase', NAMESPACE.COMMON),
            style: 'UPPERCASE',
            icon: <FaIcons.FaChevronUp />,
            method: 'inline'
        },
        {
            label: getTextTranslated('lowercase', NAMESPACE.COMMON),
            style: 'LOWERCASE',
            icon: <FaIcons.FaChevronDown />,
            method: 'inline'
        },
        { label: 'H1', style: 'header-one', method: 'block' },
        { label: 'H2', style: 'header-two', method: 'block' },
        { label: 'H3', style: 'header-three', method: 'block' },
        { label: 'H4', style: 'header-four', method: 'block' },
        { label: 'H5', style: 'header-five', method: 'block' },
        { label: 'H6', style: 'header-six', method: 'block' }
    ];

    const applyStyle = (e, style, method) => {
        e.preventDefault();
        method === 'block'
            ? setEditorState(RichUtils.toggleBlockType(editorState, style))
            : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const isActive = (style, method) => {
        if (method === 'block') {
            const selection = editorState.getSelection();
            const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
            return blockType === style;
        } else {
            const currentStyle = editorState.getCurrentInlineStyle();
            return currentStyle.has(style);
        }
    };

    return (
        <div className="toolbar-grid">
            {tools.map((item, idx) => (
                <button
                    style={{
                        color: isActive(item.style, item.method) ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.3)'
                    }}
                    key={`${item.label}-${idx}`}
                    title={item.label}
                    onClick={(e) => applyStyle(e, item.style, item.method)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {item.icon || item.label}
                </button>
            ))}
        </div>
    );
};

export default RichTextToolbar;
