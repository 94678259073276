import { styled } from '@mui/material/styles';
import React from 'react';
import { isJsonString } from 'utils/helper';

const StyledSpan = styled('span')(({ messageRead }) => ({
    fontWeight: messageRead === 0 ? 'bold' : 'normal',
    color: '#000',
    cursor: 'pointer'
}));

const SubjectBodyColumnRenderer = (props) => {
    if (props.type === 'subject') {
        return (
            props.value && (
                <div onClick={() => props?.openAnnouncement(props.data)}>
                    <StyledSpan messageRead={props.data.MessageRead}>{props.value}</StyledSpan>;
                </div>
            )
        );
    }
    if (props.type === 'body') {
        const value = isJsonString(props?.value)
            ? JSON.parse(props.value)
                  .blocks.map((itm) => itm.text)
                  .join(' ')
            : props.value;

        return (
            props.value && (
                <div onClick={() => props?.openAnnouncement(props.data)}>
                    <StyledSpan messageRead={props.data.MessageRead}>{value}</StyledSpan>
                </div>
            )
        );
    }
};

export default SubjectBodyColumnRenderer;
