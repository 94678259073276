import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { logError } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of assignments available for this user
 * {@link APIEndPoints.POST_ASSIGN_ENTITY_TO_USERS}
 * {@param {Object} oktaAuth Okta authentication object}
 * {@param {number} personaId is the numeric persona id .See PersonaID for more}
 * {@param {string} filterEntity is the entity needed to be in the list.[Companies | Districts | Ranches]}
 *
 *
 */

const useAssignEntityToUsers = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const navigate = useNavigate();
    const [assignedUsers, setAssignedUsers] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);

    const getEntityName = (entity) => {
        switch (entity) {
            case 'ranch':
                return 'Ranches';
            case 'district':
                return 'Districts';
            case 'company':
                return 'Companies';
        }
    };

    const assignEntityToUsers = async (selectedUsers, entityName, selectedEntity) => {
        try {
            setLoading(true);
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            Object.assign(selectedEntity, { AuthId: selectedUsers, Status: 1, CreatedBy: userOktaId, UpdatedBy: userOktaId });
            const response = await Call(
                {
                    url: APIEndPoints.POST_ASSIGN_ENTITY_TO_USERS(selectedUsers, getEntityName(entityName), selectedEntity),
                    method: 'POST',
                    options: {},
                    data: selectedEntity
                },
                token
            );
            const formattedData = response.display;
            if (!formattedData.ErrorMessage) {
                setShowToast({ toastMessage: 'Entity successfully assigned to selected users', nameSpacing: NAMESPACE.USERS });
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Entity assignment failed', nameSpacing: NAMESPACE.USERS });
                logError(EventConstants.GET_ENTITY_ASSIGNMENT_ERROR);
            }
        } catch (err) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Error getting entity assignments', nameSpacing: NAMESPACE.USERS });
            logError(EventConstants.GET_ENTITY_ASSIGNMENT_ERROR);
            setError(err);
        } finally {
            navigate(`/${ROUTES.USER_MANAGEMENT}`);
            setLoading(false);
        }
    };

    return { assignedUsers, setAssignedUsers, error, assignEntityToUsers, loading };
};
export default useAssignEntityToUsers;
