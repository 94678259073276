import { Info } from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import styles from './GenericRenderer.module.css';

const viewColumnRenderer = (props) => {
    return (
        <ButtonBase className={`${styles.actionButton} ${styles.primary}`} size="small" onClick={() => props?.viewRow(props.data)}>
            <Info />
        </ButtonBase>
    );
};

export default viewColumnRenderer;
