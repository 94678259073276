import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook creates an announcements
 * {@link APIEndPoints.POST_ANNOUNCEMENTS}
 */

interface AnnouncementPayload {
    Districts: string;
    Companies: string;
    Ranches: string;
    RecipientType: string;
    TextHeader: string;
    TextBody: string;
    AttachementLink: string;
    ExpirationDate: string;
    FileType: string;
    FileName: string;
    FileKey: string;
}

const useSendAnnouncement = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setShowToast } = useContext(ApplicationContext);

    const fetch = async (payload: AnnouncementPayload) => {
        setLoading(true);
        const { token, userOktaId, userEmail } = await getTokenAndId(oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'Announcement',
                    url: APIEndPoints.POST_ANNOUNCEMENTS(),
                    method: 'POST',
                    options: {},
                    data: {
                        SenderAuthId: userOktaId,
                        CreatedBy: userEmail,
                        UpdatedBy: userEmail,
                        ...payload
                    }
                },
                token
            );
            if (data.errors?.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            } else {
                setShowToast({ toastMessage: 'Announcement sent successfully!', nameSpacing: NAMESPACE.ANNOUNCEMENT });
                navigate(`/${ROUTES.ANNOUNCEMENT}`);
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            setError('Error');
        } finally {
            setLoading(false);
        }
    };

    const sendAnnouncementHandler = (payload: AnnouncementPayload) => {
        fetch(payload);
    };

    return { error, sendAnnouncementHandler, loading };
};
export default useSendAnnouncement;
