import { Button } from '@mui/material';
import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import { animated, useSpring } from '@react-spring/web';
import { NAMESPACE } from 'i18n';
import React from 'react';
import charImage from '../../../Images/No data-bro.svg';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import CustomGridOverlay from '../gridoverlays/CustomGridOverlay';
import Iconify from '../iconify/Iconify';
import styles from './DrcDataGrid.module.scss';

export const SearchResultTime = ({ page, total, executionTime }) => {
    if (total > 0)
        return (
            <p style={{ margin: '3px 10px 4px 0px', fontFamily: 'Rubik', fontSize: '12px' }}>
                {page > 1 ? `Page ${page} of ` : ''}
                {total.toLocaleString()} <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'results'}</TypographyTranslated> {executionTime}
                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'seconds'}</TypographyTranslated>
            </p>
        );
};

export default function DrcMobileList({
    rows,
    getRenderer,
    loading,
    pageCount,
    page,
    handlePageChange,
    totalUsers,
    executionTime,
    allowExport,
    exportExcelHandler,
    loadingMessage
}) {
    const springs = useSpring({
        from: { x: -100 },
        to: { x: 0 }
    });
    return (
        <div>
            {loading ? <CustomGridOverlay loadingMessage={'Loading data'} /> : null}
            {rows.length === 0 && !loading ? (
                <div
                    style={{
                        borderRadius: '8px',
                        transform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <animated.div
                        style={{
                            width: 400,
                            borderRadius: 8,
                            ...springs
                        }}
                    >
                        <img className="MuiBox-root css-1z13ce1" src={charImage} />
                    </animated.div>
                    <span>
                        <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Sorry, No results were found for the search.'}</TypographyTranslated>
                    </span>
                </div>
            ) : (
                <>
                    {allowExport && (
                        <div className={styles.toolbarContainer}>
                            <Button onClick={exportExcelHandler} endIcon={<Iconify icon="material-symbols:export-notes-outline" />}>
                                Export
                            </Button>
                        </div>
                    )}
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {rows.map((rowItem, index) => {
                            return getRenderer(rowItem, index);
                        })}
                    </List>
                </>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'right',
                    paddingTop: '24px',
                    height: '100px',
                    alignItems: 'center'
                }}
            >
                {executionTime && <SearchResultTime page={page} total={totalUsers} executionTime={executionTime} />}
                {rows.length > 0 ? (
                    <Pagination size="small" sx={{ width: 430, ml: 10 }} count={pageCount} page={page} onChange={handlePageChange} />
                ) : null}
            </div>
        </div>
    );
}
