import { Email, Phone } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useState } from 'react';
import CognitoLogin from './CognitoLogin';
import styles from './Login.module.scss';
import LoginForm from './LoginForm';

const Login = () => {
    const [tabValue, setTabValue] = useState('email');
    const { getTextTranslated } = useLang();
    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="secondary" centered>
                <Tab key={'email'} label={<Email />} value={'email'} />
                <Tab key={'phone'} label={<Phone />} value={'phone'} />
            </Tabs>
            <Box sx={{ mt: 5 }}>{tabValue === 'email' ? <LoginForm /> : <CognitoLogin />}</Box>
            <p className={styles.appVersion}>{`${getTextTranslated('App Version', NAMESPACE.COMMON)}[${process.env.REACT_APP_VERSION}]`}</p>
        </Box>
    );
};

export default Login;
