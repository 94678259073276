import { withOktaAuth } from '@okta/okta-react';
import { animated, useSpring } from '@react-spring/web';
import CategorizedReport from 'components/reports/CategorizedReport';
import CustomGridOverlay from 'components/shared/gridoverlays/CustomGridOverlay';
import FeatureProtect from 'components/shared/protected/FeatureProtected';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import FeaturesName from 'constants/featuresEnum';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetReports from 'hooks/useGetReports';
import { NAMESPACE } from 'i18n';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import charImage from '../../Images/No data-bro.svg';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import ApplicationContext from '../../context/ApplicationContext';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import style from './Reports.module.scss';

function Reports(props) {
    const { oktaAuth } = props;
    const { userPersona } = useContext(ApplicationContext);
    const navigate = useNavigate();
    useCheckMasterData();
    const springs = useSpring({
        from: { x: -100 },
        to: { x: 0 }
    });
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const userLang = getCurrentLanguageSelected();
    const { data: reports, getReports, loading } = useGetReports(oktaAuth, userPersona, userLang);

    useCallback(() => {
        getReports();
    }, [userLang]);

    return (
        <DrcContainer heading={getTextTranslated('Reports', NAMESPACE.REPORT)}>
            <div className={style.mainContainer}>
                {loading ? (
                    <CustomGridOverlay loadingMessage={getTextTranslated('Fetching Reports', NAMESPACE.REPORT)} />
                ) : reports?.length > 0 ? (
                    <>
                        <PersonaBaseProtect personasAllowed={[Persona.PORTAL_USER]}>
                            <FeatureProtect featureName={FeaturesName.UPLOAD_REPORT}>
                                <div className={style.headerContainer}>
                                    <TranslatedDrcButton
                                        onClick={() => navigate('upload/supplementary')}
                                        nameSpacing={NAMESPACE.REPORT}
                                        isPrimary
                                        noStyle
                                    >
                                        {'Upload Report'}
                                    </TranslatedDrcButton>
                                </div>
                            </FeatureProtect>
                        </PersonaBaseProtect>
                        <CategorizedReport userPersona={userPersona} reports={reports} />
                    </>
                ) : (
                    <div className={style.errorBoundaryContainer}>
                        <animated.div
                            style={{
                                width: 400,
                                borderRadius: 8,
                                ...springs
                            }}
                        >
                            <img alt="report image" className="MuiBox-root css-1z13ce1" src={charImage} />
                        </animated.div>
                        <span>
                            <TypographyTranslated nameSpacing={NAMESPACE.REPORT}>{'No Reports Assigned'}</TypographyTranslated>
                        </span>
                    </div>
                )}
            </div>
        </DrcContainer>
    );
}

export default withOktaAuth(Reports);
