import { Checkbox, FormControlLabel, FormGroup, FormGroupProps } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import styles from './FormCheckbox.module.scss';

interface IFormCheckboxProps extends FormGroupProps {
    label: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
}

const FormCheckbox = (props: IFormCheckboxProps) => {
    return (
        <FormGroup className={styles.checkboxContainer} {...props}>
            <FormControlLabel
                control={<Checkbox defaultChecked={props.defaultChecked} checked={props.value} />}
                label={<TypographyTranslated nameSpacing={NAMESPACE.USERS}>{props.label}</TypographyTranslated>}
                onChange={props.handleChange}
            />
        </FormGroup>
    );
};

export default FormCheckbox;
