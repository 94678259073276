import {
    DraftEditorCommand,
    DraftHandleValue,
    DraftStyleMap,
    Editor,
    EditorProps,
    EditorState,
    RawDraftContentState,
    RichUtils,
    convertFromRaw
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { NAMESPACE } from 'i18n';
import { jsonrepair } from 'jsonrepair';
import React, { useRef, useState } from 'react';
import useLang from '../../../../hooks/useLang';
import styles from './DrcRichTextEditor.module.scss';
import RichTextToolbar from './RichTextToolbar';

const styleMap: DraftStyleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
    },
    HIGHLIGHT: {
        backgroundColor: '#F7A5F7'
    },
    UPPERCASE: {
        textTransform: 'uppercase'
    },
    LOWERCASE: {
        textTransform: 'lowercase'
    },
    CODEBLOCK: {
        fontFamily: '"fira-code", "monospace"',
        fontSize: 'inherit',
        background: '#ffeff0',
        fontStyle: 'italic',
        lineHeight: 1.5,
        padding: '0.3rem 0.5rem',
        borderRadius: ' 0.2rem'
    },
    SUPERSCRIPT: {
        verticalAlign: 'super',
        fontSize: '80%'
    },
    SUBSCRIPT: {
        verticalAlign: 'sub',
        fontSize: '80%'
    }
};

const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
        case 'blockQuote':
            return 'superFancyBlockquote';
        case 'leftAlign':
            return 'leftAlign';
        case 'rightAlign':
            return 'rightAlign';
        case 'centerAlign':
            return 'centerAlign';
        case 'justifyAlign':
            return 'justifyAlign';
        default:
            break;
    }
};

interface IDrcRichTextEditorProps extends EditorProps {
    defaultValue?: RawDraftContentState | string;
    label?: string;
    onEditorChange?: (value) => void;
    error?: string;
}

const DrcRichTextEditor = (props: IDrcRichTextEditorProps) => {
    const { getTextTranslated } = useLang();
    const editor = useRef(null);
    const repaired = props.defaultValue ? jsonrepair(props.defaultValue as string) : null;
    const [editorState, setEditorState] = useState(() =>
        props.defaultValue ? EditorState.createWithContent(convertFromRaw(JSON.parse(repaired))) : EditorState.createEmpty()
    );
    const [focused, setFocused] = useState(false);
    const placeholder = !props.readOnly ? props.label || `${getTextTranslated('Start typing', NAMESPACE.EVENTS)}` : '';

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const focusEditor = () => {
        editor.current.focus();
    };

    const handleKeyCommand = (command: DraftEditorCommand | string): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return newState;
        }
        return null;
    };

    return (
        <>
            <div
                className={`${focused ? styles.editorWrapperActive : styles.editorWrapper} ${props.readOnly ? styles.noBorder : ''} ${
                    props.error ? styles.error : ''
                }`}
                onClick={focusEditor}
            >
                {!props.readOnly ? <RichTextToolbar editorState={editorState} setEditorState={setEditorState} /> : null}
                <Editor
                    ref={editor}
                    customStyleMap={styleMap}
                    blockStyleFn={myBlockStyleFn}
                    handleKeyCommand={handleKeyCommand}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    editorState={editorState}
                    placeholder={placeholder}
                    onChange={(editorState) => {
                        props.onEditorChange(editorState);
                        setEditorState(editorState);
                    }}
                    readOnly={props.readOnly ?? false}
                />
            </div>
            {props.error ? <p className={styles.errorMessage}>{props.error}</p> : null}
        </>
    );
};

export default DrcRichTextEditor;
