import React, { useEffect, useState } from 'react';

import { Avatar, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

export default function FormSelectField(props) {
    const [error, setError] = useState(props.error);
    const [selectedValue, setSelectedValue] = useState(props.selectedValue || '');
    const { getTextTranslated } = useLang();

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setSelectedValue(props.selectedValue);
    }, [props.selectedValue]);

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedValue(value);
        props.handleChange && props.handleChange(value);
    };

    return (
        <Box
            component="div"
            sx={{
                '& .MuiTextField-root': { m: 1 }
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {props.hideLegacyLabel && (
                    <TypographyTranslated variant="h7" gutterBottom>
                        {props.label}
                    </TypographyTranslated>
                )}
                <FormControl sx={{ m: 1, width: '90%', maxWidth: 350, ...props.sx }}>
                    <InputLabel id="demo-simple-select-label">
                        {!props.hideLegacyLabel ? <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.label}</TypographyTranslated> : ''}
                    </InputLabel>
                    <Select
                        label={
                            !props.hideLegacyLabel ? <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.label}</TypographyTranslated> : ''
                        }
                        value={selectedValue}
                        defaultValue={props.selectedValue}
                        onChange={handleChange}
                        sx={{ mb: 2, color: 'text.black' }}
                        MenuProps={{
                            classes: {
                                paper: {
                                    menuPaper: {
                                        maxHeight: 200
                                    }
                                }
                            }
                        }}
                        error={error}
                        inputProps={props.inputProps}
                        disabled={props.disabled}
                    >
                        {(props.values || []).map((value, i) => (
                            <MenuItem key={i} value={value[props.valueField] || value.label} sx={{ display: 'flex', gap: 2 }}>
                                {props.icon && <Avatar sx={{ width: 24, height: 24 }}>{props.icon}</Avatar>}
                                {getTextTranslated(value.label, NAMESPACE.EVENTS)}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{props.helperText}</FormHelperText>
                </FormControl>
            </div>
        </Box>
    );
}
