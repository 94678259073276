import FormSelectField from 'components/shared/formcontrols/FormSelectField';
import { queryStatus } from 'constants/options/options';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../../hooks/useLang';
interface IStatusDropDownProps {
    status: string;
    disabled: boolean;
    onChange: (value: string) => void;
}

const StatusDropDown = (props: IStatusDropDownProps) => {
    const { getTextTranslated } = useLang();
    return (
        <FormSelectField
            label={getTextTranslated('Query Status', NAMESPACE.COMMON)}
            sx={{ width: 240 }}
            values={queryStatus}
            valueField={'value'}
            selectedValue={props.status || ''}
            handleChange={props.onChange}
            disabled={props.disabled}
        />
    );
};

export default StatusDropDown;
