import validator from 'validator';

const lettersOnly = (value) => {
    if (value) {
        const val = value.match(/^[A-Za-z]+$/);
        if (!val) {
            // components['password'][0].value !== components['confirm'][0].value
            // 'confirm' - name of input
            // components['confirm'] - array of same-name components because of checkboxes and radios
            return 'This field must only consist of letters.';
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Email validator
 */
const email = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} is not a valid email.`;
    }
};

const isValidEmail = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} is not a valid email.`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Email validation for driscollsEmail
 */
const driscollsEmail = (value) => {
    const val = value.match(/^.*@driscolls\.com/);
    if (!val) {
        return 'Email must be Driscolls.';
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Anything other than driscolls
 */
const nonDriscollsEmail = (value) => {
    const val = value.match(/^.*@driscolls\.com/);
    if (val) {
        return 'Email cannot be Driscolls.';
    }
};

function isValidPhoneNumber(phoneNumber) {
    if (!validator.isMobilePhone(phoneNumber, 'any')) {
        return 'Please Enter a valid phone number.';
    }
}

function isUSPhoneNumber(phoneNumber) {
    return validator.isMobilePhone(phoneNumber, 'en-US');
}

/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check no other symbols
 */
const numeric = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9]+$/);
        if (!val) {
            return 'Only numbers are allowed in this field.';
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check with decimal or negative value no other symbols
 */
const integer = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9.-]+$/);
        if (!val) {
            return 'Only integers are allowed in this field.';
        }
    }
};

export { driscollsEmail, email, integer, isUSPhoneNumber, isValidEmail, isValidPhoneNumber, lettersOnly, nonDriscollsEmail, numeric };
