import { DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import { IDistrict } from 'components/appinitializer/IDistricts';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import FormAutoComplete from '../shared/formcontrols/FormAutoComplete';
import styles from './AssignmentsContainer.module.scss';

interface IDistrictAssignmentProps {
    districts: Array<IDistrict>;
    onChangeHandler: (value: IDistrict) => void;
    districtLabel: string;
    addAssignments: () => void;
    value: { value: string; label: string };
}

export default function DistrictAssignment(props: IDistrictAssignmentProps) {
    const {
        addAssignments,
        districts,
        onChangeHandler,
        districtLabel,
        value: { label }
    } = props;
    const { getTextTranslated } = useLang();

    const isValid = () => {
        return props.districts;
    };

    return (
        <DriscollsHContainer wrap>
            <div className={styles.col}>
                <FormAutoComplete
                    style={{ maxWidth: '260px', minWidth: '210px' }}
                    width={'100%'}
                    labelField="label"
                    values={districts}
                    onChangeHandler={onChangeHandler}
                    label={getTextTranslated(districtLabel, NAMESPACE.EVENTS)}
                    value={label || ''}
                />
            </div>
            <div className={styles.button}>
                <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} disabled={!isValid()} onClick={addAssignments}>
                    {'ADD'}
                </TranslatedDrcButton>
            </div>
        </DriscollsHContainer>
    );
}
