import { Call } from '@driscollsinc/driscolls-display-rules';
import { IDocumentListingType } from 'components/documentlibrary/IDocumentListingType';
import { listingTypes } from 'components/documentlibrary/documentLibraryEnum';
import { useState } from 'react';
import APIEndPoints from 'services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This fetches the document library listing - wip
 * @param oktaAuth Okta auth object
 * @id has to be the identifier for the folder
 */

const useGetDocumentListing = (oktaAuth: any, id: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [documentList, setDocumentList] = useState<Array<IDocumentListingType> | []>([]);
    const [skipToken, setSkipToken] = useState<string | undefined>('');
    const [error, setError] = useState(null);
    const [executionTime, setExecutionTime] = useState(0);
    const DocumentListKey = 'documentList';

    const downloadDocument = async (itemId: string, listId: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: APIEndPoints.GET_SHAREPOINT_DOWNLOAD_URL(itemId?.toUpperCase(), listId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            window.open(response.display?.DownloadUrl, '_blank');
        } catch (err) {
            setError(err);
        }
    };

    const getLatestItems = async () => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const startExecution = Date.now();
            const response = await Call(
                {
                    url: APIEndPoints.GET_LATEST_ITEMS(),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const endExecution = Date.now();
            setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);
            setDocumentList(response.display);
        } catch (err) {
            setError(err);
        }
    };

    const search = async (query: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const startExecution = Date.now();
            const response = await Call(
                {
                    url: APIEndPoints.GET_SHAREPOINT_SEARCH(query),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const endExecution = Date.now();
            setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);
            setDocumentList(response.display);
        } catch (err) {
            setError(err);
        }
    };

    const fetchListing = async (listId: string, perPage: number, skipToken: string, loadMore: boolean) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const startExecution = Date.now();
            const response = await Call(
                {
                    url: listId === listingTypes.SITES ? APIEndPoints.GET_SITE_LISTING() : APIEndPoints.GET_SITE_ITEMS(listId, perPage, skipToken),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const endExecution = Date.now();
            setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);
            !loadMore ? localStorage.removeItem(DocumentListKey) : '';

            if (listId !== listingTypes.SITES) {
                const listFromLocalStorage = JSON.parse(localStorage.getItem(DocumentListKey));
                const combinedList = listFromLocalStorage === null ? response.display.Items : [...listFromLocalStorage, ...response.display.Items];
                setDocumentList(combinedList);
                localStorage.setItem(DocumentListKey, JSON.stringify(combinedList));
                setSkipToken(response.display.Skiptoken);
            } else {
                setDocumentList(response.display.value);
            }
        } catch (err) {
            setError(err);
        }
    };

    return { downloadDocument, search, fetchListing, documentList, error, executionTime, getLatestItems, skipToken };
};
export default useGetDocumentListing;
