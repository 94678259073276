import { Call } from '@driscollsinc/driscolls-display-rules';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { logError, logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook changes default persona
 * {@link APIEndPoints.POST.MAKE_DEFAULT_PERSONA}
 */
const useMakeDefaultPersona = (oktaAuth: IOktaContext) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const makeDefaultPersona = async (persona: number, oktaId?: string) => {
        try {
            const { token, userEmail, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'personaId',
                    url: APIEndPoints.MAKE_DEFAULT_PERSONA(),
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: oktaId || userOktaId,
                        DefaultPersonaId: persona,
                        UpdatedBy: userEmail,
                        Status: '1'
                    }
                },
                token
            );
            if (data?.display?.Message) {
                logEvent(EventConstants.CHANGE_PERSONA, { persona: persona, oktaId: oktaId });
                return data?.display?.Message;
            }
        } catch (error) {
            logError(EventConstants.CHANGE_PERSONA_ERROR, error);
            setError('ERROR');
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, makeDefaultPersona };
};
export default useMakeDefaultPersona;
