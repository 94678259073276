import DuExcelUtilities from 'components/shared/Utilities/DuExcelUtilities';
import { PERSONA_NAME } from 'data/config/persona';
import { AdjustTimezoneTime } from 'utils/TimezoneUtil';
import { persona } from '../data/config/datasetup';

export const getAppliedFilters = (filters = []) => {
    return filters.reduce((query, filter) => {
        if (filter.isSelected) {
            if (filter.hasOptions && !filter.isCheckbox) {
                if (filter.isMulti) {
                    query += `&${filter.query}=` + encodeURIComponent(filter.selected.map((itm) => itm.value).join(','));
                } else {
                    query += `&${filter.query}=` + encodeURIComponent(filter.selected[0]);
                }
            } else if (filter.hasOptions && filter.isCheckbox) {
                query += `&${filter.query}=` + encodeURIComponent(filter.selected.join(','));
            } else {
                query += `&${filter.query}=` + encodeURIComponent(filter.value);
            }
        }
        return query;
    }, '');
};

export const getAppliedFiltersPost = (filters = [], valueExtractor = 'OptionValue') => {
    let filterPayload = {};

    filters
        .filter((e) => e.isSelected)

        .map((filter) => {
            if (filter.hasOptions || filter.isAsync) {
                // if (filter.isBerry) {
                //     filterPayload[filter.fieldName] = filter.selected.map((e) => berryMap[e[filter.optionValue]])[0];
                // } else
                // filterPayload[filter.query] = filter.selected.map((e) => (filter.isAsync ? e[valueExtractor] : e[filter.optionLabel]));
                filterPayload[filter.query] = filter.selected[0]; // for single select
            } else {
                if (filter.isBool) {
                    filterPayload[filter.query] = +filter.value;
                } else if (filter.istypeAheadInputField) {
                    filterPayload[filter.query] = (filter.selected || []).map((e) => {
                        return e.OptionLabel;
                    });
                } else {
                    filterPayload[filter.query] = filter.value;
                }
            }
            return filter;
        });

    return filterPayload;
};

export const getPersonaIdFromPersona = (p) => {
    const personaName = p === PERSONA_NAME.RANCHADMIN ? PERSONA_NAME.GROWERADMIN : p;
    const personaDetail = persona.find((item) => personaName.indexOf(item.Name) > -1);
    return personaDetail.Id;
};

export const getPersonaFromPersonaId = (p) => {
    const personaDetail = persona.find((item) => parseInt(p) === parseInt(item.Id));
    return personaDetail.Name;
};

export const makeOptions = (options, label, value) => {
    // here label and value are the key names that needto be used as label and value
    return options.map((e) => {
        e.label = e[label];
        e.value = e[value];
        return e;
    });
};

export const areObjectsEqual = function (obj1, obj2) {
    // function to check if two objects are equal or not
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;
    if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
            // eslint-disable-next-line no-prototype-builtins
            (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
        );
    }
    return false;
};

export const cloneArray = (sourceArray) => JSON.parse(JSON.stringify(sourceArray));

export const convertDateToIso = (value) => {
    // function to convert date in YYYY-MM-DD format with correct time zone
    const date = new Date(value);
    const offset = date.getTimezoneOffset();
    const yourDate = new Date(date.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split('T')[0];
};

export const getDownloadColumns = (column, excludedColumns = []) => {
    const colsToExclude = [...excludedColumns, 'getPreviousData'];
    const cols = Object.values(column)
        .filter((col) => !colsToExclude.includes(col))
        .map((key) => {
            if (key) return { name: key.headerName, key: key.field };
        });
    return cols;
};

export const downloadFile = (fileName, data, columnMap, excludedColumns = []) => {
    const colsToExclude = [...excludedColumns, 'getPreviousData'];
    const columnNames = columnMap
        ? getDownloadColumns(columnMap, excludedColumns)
        : Object.keys(data[0])
              .filter((col) => !colsToExclude.includes(col))
              .map((key) => {
                  if (key) return { name: key.headerName, key: key.field };
              });

    DuExcelUtilities.Write(fileName, columnNames, data);
};

export const convertArrayToCommaSeparatedString = (data, key) => {
    if (!Array.isArray(data)) {
        throw new Error('The data must be an array.');
    }
    return data.map((obj) => obj[key]).join(', ');
};

export const convertEventsListToCalendarFormat = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('The data must be an array.');
    } else {
        let result = data.map((event) => {
            return {
                id: event.EventId,
                title: event.Name,
                allDay: false,
                start: AdjustTimezoneTime(event.StartDate),
                end: AdjustTimezoneTime(event.EndDate),
                description: event.Description
            };
        });
        return result;
    }
};

export const getFileNameFromS3URL = (str) => {
    const filenameRegex = /filename%3D%22(.*?)%22/;
    const match = str.match(filenameRegex);
    if (match && match.length >= 2) {
        const encodedFilename = match[1];
        const decodedFilename = decodeURIComponent(encodedFilename);
        return decodedFilename;
    } else return null;
};

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
