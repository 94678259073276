import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DLink from '../Link';
import style from './RecentReport.module.scss';

type RecentReportProps = {
    href: string;
    text: string;
    image: React.ReactNode;
};

const RecentReport = ({ href, text, image }: RecentReportProps) => {
    return (
        <Card className={style.card}>
            <CardContent className={style.cardContent}>
                <article className={style.content}>
                    <DLink href={href}>{text}</DLink>
                    {image}
                </article>
            </CardContent>
            <CardActions className={style.actions}>
                <DLink href={href}>
                    <TypographyTranslated nameSpacing={NAMESPACE.DASHBOARD}>{'Quick View'}</TypographyTranslated>
                </DLink>
            </CardActions>
        </Card>
    );
};

export default RecentReport;
