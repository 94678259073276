import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import blocked from '../../Images/Warning-rafiki.svg';

import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import DrcInformationBox from 'components/shared/drc/DrcInformationBox';
import style from './AppInitializer.module.scss';
import { NAMESPACE } from 'i18n';

const InactiveUserComponent = () => {
    const [logout, setLogout] = useState<boolean>(false);
    return (
        <>
            {logout && <Navigate to={'/Logout'} />}
            <section className={style.flexColumnCenter}>
                <Box component="img" src={blocked} sx={{ width: 400 }} />
                <DrcInformationBox hasError={true}>
                    <TypographyTranslated align="center" nameSpacing={NAMESPACE.COMMON}>
                        {'UserNotAssigned'}
                    </TypographyTranslated>
                </DrcInformationBox>
            </section>
            <section className={`row ${style.flexBoxCenter}`}>
                <div className="col-xs-1">
                    <TranslatedDrcButton isPrimary fullWidth onClick={() => setLogout(true)}>
                        {'Logout'}
                    </TranslatedDrcButton>
                </div>
            </section>
        </>
    );
};

export default InactiveUserComponent;
