import { CardActions, List, Pagination } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Loader from 'components/shared/Loader/Loader';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import usePagination from 'hooks/usePagination';
import React, { useState } from 'react';
import DashboardSection from '../Section';
import SectionHeader from '../Section/SectionHeader';
import ContactComponent from './ContactComponent';
import { IContactDisplay } from './ContactTypes';
import style from './Contacts.module.scss';
import { NAMESPACE } from 'i18n';

interface ContactsProps {
    loading: boolean;
    error?: string;
    contacts: Array<IContactDisplay> | [];
    contactsType: 'super admin' | 'regional admin';
    classNameContainer?: string;
}

const Contacts = ({ contactsType, contacts, loading, error, classNameContainer }: ContactsProps) => {
    const [page, setPage] = useState(1);
    const PER_PAGE = 4;
    const count = Math.ceil(contacts.length / PER_PAGE);
    const paginatedData = usePagination(contacts, PER_PAGE);
    return (
        <DashboardSection classNameContainer={`${style.contacts} ${classNameContainer}`}>
            <SectionHeader title={'contacts'} capitalize />
            <Card className={`${style.card}`}>
                <CardContent>
                    {!loading && !error && (
                        <article className={style.contactsList}>
                            <List>
                                {contacts &&
                                    contacts.length > 0 &&
                                    paginatedData.currentData().map((contact, index) => {
                                        return (
                                            <div key={index}>
                                                <ContactComponent key={contact.AuthId} contact={contact} />
                                                {index < contacts.length - 1 && <Divider />}
                                            </div>
                                        );
                                    })}
                            </List>
                        </article>
                    )}
                    {loading && <Loader circular />}
                    {(contacts.length === 0 || error) && !loading && (
                        <TypographyTranslated nameSpacing={NAMESPACE.DASHBOARD}>Sorry we can not load the Contacts</TypographyTranslated>
                    )}
                </CardContent>
                {!loading && !error && (
                    <CardActions>
                        <section className={style.actions}>
                            <Pagination
                                count={count}
                                size="large"
                                page={page}
                                variant="outlined"
                                shape="rounded"
                                onChange={(event, page) => {
                                    setPage(page);
                                    paginatedData.jump(page);
                                }}
                            />
                        </section>
                    </CardActions>
                )}
            </Card>
        </DashboardSection>
    );
};

export default Contacts;
