import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './DriscollsLegend.module.scss';

interface IDriscollsLegend {
    title: React.ReactComponentElement<typeof TypographyTranslated>;
    children: ReactNode;
    show: boolean;
}

const DriscollsLegend = (props: PropsWithChildren<IDriscollsLegend>) => {
    const { title, container, showLegend, hideLegend } = styles;

    return (
        <fieldset className={`${container} ${!props.show ? hideLegend : showLegend} `}>
            <legend className={title}>{props.title}</legend>
            {props.children}
        </fieldset>
    );
};

export default DriscollsLegend;
