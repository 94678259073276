import { Call } from '@driscollsinc/driscolls-display-rules';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

interface IUserFetchPayload {
    UserOktaId?: string;
    RequestorPersonaId: number;
    AuthIdFilter?: string;
    PersonaId?: string;
    Limit?: number;
    Offset?: number;
    GenericFilter?: string;
}

const useFetchUsers = (oktaAuth: IOktaContext) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchUsers = async (payload: IUserFetchPayload) => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        setLoading(true);
        try {
            const data = await Call(
                {
                    name: 'ManageUser',
                    url: APIEndPoints.MANAGE_USER_GROWER(),
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: userOktaId,
                        ...payload
                    }
                },
                token
            );
            return data;
        } catch (error) {
            setError('ERROR');
            console.log(error);
            logEvent(EventConstants.USER_PROFILE_ERROR, error);
        } finally {
            setLoading(false);
        }
    };

    return { fetchUsers, loading, error };
};

export default useFetchUsers;
