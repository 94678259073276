import DeleteColumnRenderer from 'components/shared/cellrenderers/DeleteColumnRenderer';
import { PERSONAID } from 'data/config/persona';
import { NAMESPACE } from 'i18n';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import SubjectBodyColumnRenderer from '../../components/shared/cellrenderers/SubjectBodyColumnRenderer';
import ApplicationContext from '../../context/ApplicationContext';
import { announcementColumns } from '../../data/GridColumnMap';
import useGetLocale from '../../hooks/useGetLocale';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import { nonConventionalDateFormatter } from '../../utils/dateFormatterHelper';
import DrcDataGrid from '../shared/drc/DrcDataGrid';
import DrcMobileList from '../shared/drc/DrcMobileList';
import styles from './Announcement.module.scss';
import AnnouncementListItemRenderer from './AnnouncementListItemRenderer';

const AnnouncementGridContainer = (props) => {
    const { announcementList, loading, pageCount, page, handlePageChange, totalUsers, removeAnnouncement } = props;
    const { userPersona } = useContext(ApplicationContext);
    const { getTextTranslated } = useLang();
    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'md');
    const { locale } = useGetLocale();

    const expirationDateFormatter = (list) => {
        return list.map((item) => ({
            ...item,
            ExpirationDate: nonConventionalDateFormatter(item.ExpirationDate, locale)
        }));
    };

    const subjectBodyColumn = (headerName, field) => {
        return {
            field,
            headerName,
            width: 200,
            minWidth: 200,
            cellRenderer: SubjectBodyColumnRenderer,
            resizable: false,
            sortable: false,
            suppressMovable: false,
            filter: false,
            cellRendererParams: {
                openAnnouncement: (rowData) => {
                    const obj = JSON.stringify(rowData);
                    navigate(`view/${rowData.AnnouncementId}`, {
                        state: { data: obj }
                    });
                },
                type: field === 'TextBody' ? 'body' : 'subject'
            }
        };
    };

    const deleteColumn = () => {
        if ([PERSONAID.REGIONALADMIN, PERSONAID.SUPERADMIN].includes(userPersona)) {
            return {
                field: 'Remove',
                headerName: '',
                minWidth: 60,
                maxWidth: 60,
                pinned: 'left',
                cellRenderer: DeleteColumnRenderer,
                resizable: false,
                sortable: false,
                suppressMovable: false,
                filter: false,
                cellRendererParams: {
                    deleteRow: (rowData) => {
                        removeAnnouncement(rowData.AnnouncementId);
                    }
                }
            };
        }
    };

    return (
        <div className={styles.container}>
            {isDesktop ? (
                <DrcDataGrid
                    sizeColumnsToFit={true}
                    rows={expirationDateFormatter(announcementList)}
                    columns={announcementColumns(deleteColumn(), subjectBodyColumn('Subject', 'TextHeader'), subjectBodyColumn('Body', 'TextBody'))}
                    page={page}
                    totalUsers={totalUsers}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    loading={loading}
                    loadingMessage={getTextTranslated('Loading', NAMESPACE.COMMON)}
                />
            ) : (
                <DrcMobileList
                    page={page}
                    totalUsers={totalUsers}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    getRenderer={(row) => <AnnouncementListItemRenderer row={row} removeAnnouncement={removeAnnouncement} navigate={navigate} />}
                    rows={announcementList}
                    loading={loading}
                    loadingMessage={getTextTranslated('Loading', NAMESPACE.COMMON)}
                />
            )}
        </div>
    );
};

export default AnnouncementGridContainer;
