import { Box, Grid } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React, { useState } from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import Filter from '../../ui/DrcFilters/DrcFilter';
import Iconify from '../shared/iconify/Iconify';
import GenericSearchBox from '../shared/search/GenericSearchBox';
import styles from './Report.module.css';

const ReportDesktop = ({ searchInput, setSearchInput, doGenericSearch, applyFilter }) => {
    const [advancedSearchOn, setAdvancedSearchOn] = useState(false);

    const resetFilters = (event) => {
        setAdvancedSearchOn(false);
    };

    const advancedSearchHandler = () => {
        setAdvancedSearchOn(!advancedSearchOn);
    };

    return (
        <>
            <Box className={styles.parentDiv}>
                <TranslatedDrcButton
                    noStyle
                    disableRipple
                    nameSpacing={NAMESPACE.COMMON}
                    color="inherit"
                    endIcon={<Iconify icon={advancedSearchOn ? 'mdi:toggle-switch' : 'mdi:toggle-switch-off'} />}
                    onClick={advancedSearchHandler}
                >
                    {'Advanced Search'}
                </TranslatedDrcButton>
                {!advancedSearchOn && (
                    <div className={styles.searchBox}>
                        <GenericSearchBox
                            value={searchInput}
                            setValue={setSearchInput}
                            onEnterPress={() => {
                                doGenericSearch();
                            }}
                        />
                    </div>
                )}
            </Box>
            {advancedSearchOn && (
                <Grid container gap={2} sx={{ m: 2 }}>
                    <Filter applyFilter={applyFilter} showFilter={advancedSearchOn} resetFilter={resetFilters} filterNames={['Report Type']} />
                </Grid>
            )}
        </>
    );
};

export default ReportDesktop;
