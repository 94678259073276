import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ManageUser',
        [
            {
                name: 'UserOktaId',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'DefaultPersona',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Persona',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LastName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FirstName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'UpdatedAt',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'CreatedAt',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'UpdatedBy',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Email',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Lang',
                type: FIELD_UNITS.STRING
            }
        ],
        {}, //options
        (data) => {
            console.log('Manage User REDUCER-- ', data);
        }
    );
}

const ManageUser = {
    configureSetupCall
};

export default ManageUser;
