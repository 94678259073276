import React from 'react';
import { Box } from '@mui/material';
import sorryImage from '../../../Images/Feeling sorry-bro.svg';
import { logError } from '../../../analytics/AnalyticsHandler';
import { EventConstants } from '../../../constants/EventConstants';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        logError(EventConstants.GLOBAL_ERROR, {
            error: error,
            errorInfo: errorInfo
        });
    }
    componentDidUpdate = (revProps) => {
        if (revProps.path !== this.props.path)
            this.setState({
                error: null,
                errorInfo: null
            });
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.error) {
            return (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Box component="img" src={sorryImage} sx={{ width: 400 }} />
                    <aside
                        style={{
                            backgroundColor: 'rgba(255, 249, 235, 0.5)',
                            width: '80%',
                            borderColor: 'rgba(209, 142, 0, 0.3)',
                            padding: '10px',
                            borderRadius: '10px'
                        }}
                    >
                        <p style={{ textAlign: 'center' }}>{this.state.error.message}</p>
                    </aside>
                </div>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
