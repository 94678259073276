import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import useLang from 'hooks/useLang';
import React from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import styles from './AssignmentLlist.module.scss';
import GetBerryIcon from './GetBerryIcon';

export default function AssignMentList(props) {
    const { getTextTranslated } = useLang();
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    maxWidth: 350
                }
            }}
        >
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    titleTypographyProps={{
                        variant: 'h6',
                        align: 'center'
                    }}
                    title={props.data.label || props.data.CompanyName || props.data.DistrictName || props.data.RanchNbr || props.data.CompanyNbr}
                ></CardHeader>
                <Divider />
                {(props.data.Companies || props.data.Ranches || props.data.BerryTypes) && (
                    <div style={{ maxHeight: 260, overflow: 'auto' }}>
                        <List sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}>
                            {(props.data.Companies || props.data.Ranches || props.data.BerryTypes).map((item, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            props.removeItem && (
                                                <IconButton
                                                    onClick={() => props.removeItem(item, props.data.CompanyNbr || props.data.DistrictId)}
                                                    edge="end"
                                                    aria-label="delete"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar className={styles.avatar}>
                                                {props.data.BerryTypes ? (
                                                    <GetBerryIcon berry={item.value} />
                                                ) : (
                                                    <AgricultureIcon className={styles.icon} />
                                                )}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getTextTranslated(
                                                typeof item === 'string'
                                                    ? item
                                                    : item?.label || (item.RanchNbr || item.RanchNumber || item.CompanyCode) + ' - ' + item.Name,
                                                'main'
                                            )}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                )}
                <Divider />
                <div style={{ display: props.removeAllItems ? 'flex' : 'none', justifyContent: 'center' }}>
                    <TranslatedDrcButton onClick={() => props.removeAllItems(props.data)} customBtnStyles={{ width: '80px' }}>
                        {'Remove'}
                    </TranslatedDrcButton>
                </div>
            </Card>
        </Box>
    );
}
