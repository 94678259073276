import { Delete } from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import styles from './GenericRenderer.module.css';

interface IDeleteColumnRendererProps {
    data: IAnnouncementRow;
    deleteRow: (data: IAnnouncementRow) => void;
}

interface IAnnouncementRow {
    AnnouncementId: number;
    [key: string]: string | number | (() => void);
}

const DeleteColumnRenderer = (props: IDeleteColumnRendererProps) => {
    return (
        <ButtonBase className={`${styles.actionButton} ${styles.negative}`} onClick={() => props?.deleteRow(props.data)}>
            <Delete />
        </ButtonBase>
    );
};

export default DeleteColumnRenderer;
