import { DriscollsSpinner, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { withOktaAuth } from '@okta/okta-react';
import DownloadColumnRenderer from 'components/shared/cellrenderers/DownloadColumnRenderer';
import ApplicationContext from 'context/ApplicationContext';
import useDownloadFile from 'hooks/useDownloadFile';
import { NAMESPACE } from 'i18n';
import { ReportType } from 'pages/report/ReportTypes';
import React, { useContext } from 'react';
import { reportColumns } from '../../data/GridColumnMap';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import DrcDataGrid from '../shared/drc/DrcDataGrid';
import DrcMobileList from '../shared/drc/DrcMobileList';
import styles from '../support/Support.module.css';
import ReportListItemRenderer from './ReportListItemRenderer';

const ReportGridContainer = (props) => {
    const { reportList, loading, pageCount, page, handlePageChange, totalUsers, executionTime, oktaAuth, reportType } = props;
    const { setShowToast } = useContext(ApplicationContext);
    const { getTextTranslated } = useLang();
    const isDesktop = useResponsive('up', 'md');
    const { downloadFile, error, isFileLoading: downloadLoading } = useDownloadFile(oktaAuth);

    const downloadReport = async (fileKey) => {
        const fileURL = await downloadFile(fileKey);
        if (!fileURL || error) {
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Could not download the file, please try again later!',
                nameSpacing: NAMESPACE.REPORT
            });
        } else {
            const anchor = document.createElement('a');
            anchor.href = fileURL;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    };

    const downloadColumn = {
        field: 'Download',
        headerName: '',
        minWidth: 60,
        maxWidth: 60,
        pinned: 'left',
        cellRenderer: DownloadColumnRenderer,
        resizable: false,
        sortable: false,
        suppressMovable: false,
        filter: false,
        cellRendererParams: {
            downloadReport: downloadReport
        }
    };

    const reportListFormatted =
        reportType === ReportType.GrowerSettlement ? reportList?.map(({ ...item }) => ({ ...item, FileName: item?.FileKey })) : reportList;

    const reportColumnsPopulated = reportColumns(downloadColumn);

    return (
        <div className={styles.mainContainer} style={{ padding: isDesktop ? '20px' : '0px' }}>
            {isDesktop ? (
                <DrcDataGrid
                    sizeColumnsToFit={true}
                    loadingMessage={getTextTranslated('Loading Reports', NAMESPACE.REPORT)}
                    loading={loading}
                    rows={reportListFormatted}
                    columns={reportColumnsPopulated}
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                />
            ) : (
                <DrcMobileList
                    loadingMessage={getTextTranslated('Loading Reports', NAMESPACE.COMMON)}
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    loading={loading}
                    getRenderer={(row) => <ReportListItemRenderer row={row} downloadReport={downloadReport} />}
                    rows={reportListFormatted}
                />
            )}
            {downloadLoading && <DriscollsSpinner text={getTextTranslated('Downloading document', NAMESPACE.REPORT)} />}
        </div>
    );
};

export default withOktaAuth(ReportGridContainer);
