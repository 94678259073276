import { Locale } from 'date-fns';
import { enUS, es, fr } from 'date-fns/locale';
import useLang, { Lang } from 'hooks/useLang';
import { useEffect, useState } from 'react';

const useGetLocale = () => {
    const [locale, setLocale] = useState<Locale>();
    const { getCurrentLanguageSelected } = useLang();

    useEffect(() => {
        const currentLanguage = getCurrentLanguageSelected();
        switch (currentLanguage) {
            case Lang.ENGLISH:
                setLocale(enUS);
                break;
            case Lang.SPANISH:
                setLocale(es);
                break;
            case Lang.FRENCH:
                setLocale(fr);
                break;
            default:
                setLocale(enUS);
        }
    }, [getCurrentLanguageSelected]);

    return { locale };
};

export default useGetLocale;
