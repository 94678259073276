import { IAnalyticsResponse } from 'types/analytics';

interface IAnalyticsDataTableColumn {
    name: string;
    label: string;
}
interface IAnalyticsDataTableRow {
    values: Array<string>;
}
export interface IAnalyticsDataTable {
    columns: Array<IAnalyticsDataTableColumn>;
    rows: Array<IAnalyticsDataTableRow>;
}

const getDimensionValue = (row, type: 'dimensionValues' | 'metricValues') => {
    const _row: Array<string> = [];
    row[type].forEach((element) => {
        _row.push(element.value);
    });
    return { values: _row };
};
const createHeader = (data: IAnalyticsResponse, type: 'dimensionHeaders' | 'metricHeaders') => {
    const columns: Array<IAnalyticsDataTableColumn> = [];
    if (data) {
        data[type].forEach((element) => {
            columns.push({ name: element.name, label: element.name });
        });
    }
    return columns;
};

const createRows = (data: IAnalyticsResponse, type: 'dimensionValues' | 'metricValues') => {
    const rows: Array<IAnalyticsDataTableRow> = [];
    if (data) {
        data.rows.forEach((row) => {
            rows.push(getDimensionValue(row, type));
        });
    }
    return rows;
};

export const createDimensionsTableData = (data: IAnalyticsResponse): IAnalyticsDataTable => {
    const ret: IAnalyticsDataTable = {
        columns: createHeader(data, 'dimensionHeaders'),
        rows: createRows(data, 'dimensionValues')
    };
    return ret;
};

export const createMetricsTableData = (data: IAnalyticsResponse): IAnalyticsDataTable => {
    const ret: IAnalyticsDataTable = {
        columns: createHeader(data, 'metricHeaders'),
        rows: createRows(data, 'metricValues')
    };
    return ret;
};
