import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Analytics from './Analytics';

const AnalyticsRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN]}>
                            <Analytics />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AnalyticsRoutes;
