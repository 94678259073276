import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'constants/routes';
import React, { useState } from 'react';
import { getPersonaIdFromPersona } from 'utils/helper';

interface IUserListItemRendererProps {
    row?: any;
    navigate?: (path: string, data: any) => void;
    isReadOnlyPersona?: boolean;
}

const UserListItemRenderer = (props: IUserListItemRendererProps) => {
    const { row, navigate, isReadOnlyPersona } = props;
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const open = Boolean(openSubMenu);
    const obj = JSON.stringify(row);
    let selectedPersonaId = getPersonaIdFromPersona(row.Persona);

    // react-router-dom is not allowing a complex object to pass so converting to string first
    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={(e) => setOpenSubMenu(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Avatar alt={row.email} />
                </ListItemAvatar>
                <ListItemText
                    primary={(row.FirstName || '') + ' ' + (row.LastName || '')}
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        letterSpacing: 0
                    }}
                    secondary={
                        <Stack spacing={1}>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {row.Email}
                            </Typography>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {row.Persona}
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <Menu
                id="basic-menu"
                anchorEl={openSubMenu}
                open={open}
                onClose={() => setOpenSubMenu(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={() => navigate(`${ROUTES.VIEWUSER}${row.UserOktaId}/${selectedPersonaId}`, { state: { data: obj } })}>
                    View
                </MenuItem>
                {!isReadOnlyPersona && (
                    <MenuItem onClick={() => navigate(`${ROUTES.EDITUSER}${row.UserOktaId}/${selectedPersonaId}`, { state: { data: obj } })}>
                        Edit
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default UserListItemRenderer;
