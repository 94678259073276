import { Checkbox, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import ApplicationContext from 'context/ApplicationContext';
import { getInvitablePersonas } from 'data/config/datasetup';
import { NAMESPACE } from 'i18n';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import useLang from '../../hooks/useLang';
import DrcDialog from '../shared/drc/DrcDialog';
import styles from './AddPersona.module.scss';

const AddPersona = (props) => {
    const { userPersona } = useContext(ApplicationContext);
    const { getTextTranslated } = useLang();
    const [personaSelectionOpenState, setPersonaSelectionOpenState] = useState(false);
    const [checked, setChecked] = React.useState([]);
    const [possiblePersonas, sePossiblePersonas] = useState([]);
    const [invitablePersona, setInvitablePersona] = useState([]);
    const [noAssignablePersonas, setNoAssignablePersonas] = useState(false);

    const getData = () => {
        const personas = getInvitablePersonas(userPersona);
        setInvitablePersona(personas);
        const selectedItem = personas.find((item) => item.id === parseInt(props.personaId));
        setChecked([selectedItem.title]);
        const sameGroupPersonas = personas.filter((item) => item.group === selectedItem.group).filter((item2) => item2.title !== selectedItem.title);
        sePossiblePersonas(sameGroupPersonas);
        setNoAssignablePersonas(sameGroupPersonas.length === 0);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleClose = () => {
        setPersonaSelectionOpenState(false);
    };

    const handleChangePersona = (event) => {
        const {
            target: { value }
        } = event;
        props.setAvailablePersona([...invitablePersona.filter((itm) => checked.includes(itm.title))]);
        props.setAddPersonaFromDropdownClick(true);
        setPersonaSelectionOpenState(false);
        props.setInitialButtonDisabled(false);
    };

    const handleToggleSelection = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <Fragment>
            <Grid item sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
                <TranslatedDrcButton
                    nameSpacing={NAMESPACE.USERS}
                    onClick={() => {
                        setPersonaSelectionOpenState(true);
                    }}
                >
                    {'Add Persona'}
                </TranslatedDrcButton>
            </Grid>

            <DrcDialog
                title={`${getTextTranslated('Assign Persona', NAMESPACE.USERS)}`}
                open={personaSelectionOpenState}
                buttons={
                    noAssignablePersonas ? (
                        <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={handleClose}>
                            {'Close'}
                        </TranslatedDrcButton>
                    ) : (
                        <>
                            <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={handleClose}>
                                {'Cancel'}
                            </TranslatedDrcButton>
                            <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isPrimary onClick={handleChangePersona}>
                                {'Submit'}
                            </TranslatedDrcButton>
                        </>
                    )
                }
            >
                <article>
                    {noAssignablePersonas ? (
                        <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="subtitle2">
                            {'Cannot add Persona with a different group'}
                        </TypographyTranslated>
                    ) : (
                        <List className={styles.userslist}>
                            {possiblePersonas &&
                                (possiblePersonas || []).map((user, index) => {
                                    return (
                                        <Fragment key={user?.title + index}>
                                            <ListItemButton role={undefined} key={index} onClick={(event) => handleToggleSelection(user?.title)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(user?.title) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={user.label} />
                                            </ListItemButton>
                                            <Divider />
                                        </Fragment>
                                    );
                                })}
                        </List>
                    )}
                </article>
            </DrcDialog>
        </Fragment>
    );
};

export default AddPersona;
