import { ButtonVariant, DriscollsLoadingButton } from '@driscollsinc/style-guide-web2.0';
import ActionColumnRenderer from 'components/shared/cellrenderers/ActionColumnRenderer';
import DrcDataGrid from 'components/shared/drc/DrcDataGrid';
import DrcDialog from 'components/shared/drc/DrcDialog';
import DrcInformationBox from 'components/shared/drc/DrcInformationBox';
import DrcMobileList from 'components/shared/drc/DrcMobileList';
import { EventConstants } from 'constants/EventConstants';
import { ROUTES } from 'constants/routes';
import { growerManageUserColumns } from 'data/GridColumnMap';
import { PERSONAID, PERSONA_NAME } from 'data/config/persona';
import useFetchUsers from 'hooks/useFetchUsers';
import useResponsive from 'hooks/useResponsive';
import { NAMESPACE } from 'i18n';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertArrayToCommaSeparatedString, downloadFile, getPersonaIdFromPersona } from 'utils/helper';
import { logEvent } from '../../analytics/AnalyticsHandler';
import ApplicationContext from '../../context/ApplicationContext';
import useLang from '../../hooks/useLang';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import UserListItemRenderer from './UserListItemRenderer';
import classes from './usermanagement.module.scss';

const UserGridContainer = ({
    userPersona,
    userList,
    loading,
    filterPayload,
    pageCount,
    page,
    handlePageChange,
    totalUsers,
    executionTime,
    isReadOnlyPersona,
    oktaAuth
}) => {
    const { companies, ranches, districtOptions } = useContext(ApplicationContext);
    const districts = districtOptions;
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [fileDownloadLoading, setFileDownloadLoading] = useState(false);
    const { fetchUsers } = useFetchUsers(oktaAuth);
    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'md');

    const getDistrictData = (arrDistricts) => {
        return (arrDistricts || []).map((item) => {
            const district = (districts || []).find((diItem) => {
                return (
                    diItem.GrowingDistrictName?.trim().toUpperCase() === item.DistrictId.trim().toUpperCase() ||
                    diItem.DistrictName?.trim().toUpperCase() === item.DistrictId.trim().toUpperCase()
                );
            });
            if (!district && !item?.DistrictName) {
                item.DistrictName = item.DistrictId;
            }
            return { ...item, ...district };
        });
    };

    const getCompanyData = (arrCompanies) => {
        return (arrCompanies || []).map((item) => {
            const company = (companies || []).find((diItem) => {
                return diItem?.GrowerNbr?.toUpperCase() === item.CompanyNbr.toUpperCase();
            });
            return { ...item, ...company };
        });
    };

    const getRanchData = (arrRanches) => {
        return (arrRanches || []).map((item) => {
            const ranch = (ranches || []).find((diItem) => {
                return diItem?.RanchNbr === item.RanchNbr.trim();
            });
            return { ...item, ...ranch };
        });
    };

    const getPersonaName = (persona: string) => {
        const { RANCHADMIN, QAUSER, RANCHPLANNER } = PERSONA_NAME;
        switch (persona) {
            case RANCHADMIN:
                return 'Grower Admin / Ranch Admin';
            case RANCHPLANNER:
                return QAUSER;
            default:
                return persona;
        }
    };

    const formatPayload = () => {
        return (userList || []).map((item) => {
            return {
                ...item,
                Persona: getPersonaName(item.Persona),
                Districts: getDistrictData(item.Districts),
                Companies: item.Companies ? getCompanyData(item.Companies) : [],
                Ranches: item.Ranches ? getRanchData(item.Ranches) : []
            };
        });
    };

    const actionColumn = () => {
        return {
            field: 'Action',
            headerName: '',
            minWidth: 60,
            maxWidth: 60,
            pinned: 'left',
            cellRenderer: ActionColumnRenderer,
            resizable: false,
            sortable: false,
            suppressMovable: false,
            filter: false,
            cellRendererParams: {
                editRow: (rowData) => {
                    const obj = JSON.stringify(rowData);
                    const selectedPersonaId = getPersonaIdFromPersona(rowData.Persona);
                    logEvent(EventConstants.EDIT_USER, { oktaId: rowData?.UserOktaId, personaId: selectedPersonaId });
                    // react-router-dom is not allowing a complex object to pass so converting to string first
                    navigate(`${isReadOnlyPersona ? ROUTES.VIEWUSER : ROUTES.EDITUSER}${rowData.UserOktaId}/${selectedPersonaId}`, {
                        state: { data: obj }
                    });
                },
                isRemovable: (rowData) => {
                    return rowData?.data?.Email?.indexOf('@') > -1;
                },
                isEditable: (rowData) => {
                    return rowData?.data?.Email?.indexOf('@') > -1 || rowData?.data?.Phone?.indexOf('Phone') === -1;
                },
                isReadOnly: isReadOnlyPersona
            }
        };
    };

    const exportExcelHandler = () => {
        setExportDialogOpen(true);
    };

    const downloadCurrentPage = (users) => {
        const data = (users || []).map((item) => ({
            ...item,
            Persona: item.Persona === 'Ranch Admin' ? 'Grower Admin / Ranch Admin' : item.Persona,
            Districts: item.Districts ? convertArrayToCommaSeparatedString(item.Districts, 'DistrictId') : '',
            Companies: item.Companies ? convertArrayToCommaSeparatedString(item.Companies, 'CompanyNbr') : '',
            Ranches: item.Ranches ? convertArrayToCommaSeparatedString(item.Ranches, 'RanchNbr') : '',
            UpdatedBy: item.UpdatedBy ? item.UpdatedBy.substring(item.UpdatedBy.indexOf('<') + 1, item.UpdatedBy.lastIndexOf('>')) : ''
        }));
        downloadFile(
            'GEP - Users.xlsx',
            data,
            growerManageUserColumns().filter((itm) => !!itm)
        );
        setExportDialogOpen(false);
    };

    const downloadAllRecords = async () => {
        setFileDownloadLoading(true);
        const fetchUserPayload = {
            RequestorPersonaId: userPersona,
            Offset: 0,
            Limit: totalUsers,
            ...filterPayload
        };
        const data = await fetchUsers(fetchUserPayload);
        if (data.display) {
            downloadCurrentPage(data.display.Response);
        }
        setExportDialogOpen(false);
    };

    const { getTextTranslated } = useLang();
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                padding: isDesktop ? '20px' : '0px',
                borderRadius: '20px'
            }}
        >
            {isDesktop ? (
                <DrcDataGrid
                    loadingMessage={getTextTranslated('Loading Users', NAMESPACE.COMMON)}
                    loading={loading}
                    rows={formatPayload()}
                    columns={growerManageUserColumns(actionColumn())}
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    allowExport={[PERSONAID.REGIONALADMIN, PERSONAID.SUPERADMIN, PERSONAID.GROWERADMIN].includes(userPersona)}
                    exportExcelHandler={exportExcelHandler}
                />
            ) : (
                <DrcMobileList
                    loadingMessage={getTextTranslated('Loading Users', NAMESPACE.COMMON)}
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    loading={loading}
                    getRenderer={(row) => <UserListItemRenderer row={row} navigate={navigate} isReadOnlyPersona={isReadOnlyPersona} />}
                    rows={formatPayload()}
                    allowExport={userPersona === PERSONAID.REGIONALADMIN}
                    exportExcelHandler={exportExcelHandler}
                />
            )}
            <DrcDialog
                title={getTextTranslated('Export Users List', NAMESPACE.USERS)}
                open={exportDialogOpen}
                isConfirmation={true}
                buttons={
                    <div className={classes.btn}>
                        <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={() => setExportDialogOpen(false)}>
                            {'Cancel'}
                        </TranslatedDrcButton>
                        <DriscollsLoadingButton
                            loading={fileDownloadLoading}
                            variant={ButtonVariant.SECONDARY}
                            label={getTextTranslated('Export All', NAMESPACE.COMMON)}
                            onClick={downloadAllRecords}
                            disabled={totalUsers <= 50}
                        />
                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => downloadCurrentPage(userList)}
                            label={getTextTranslated('Export Current Page', NAMESPACE.COMMON)}
                        />
                    </div>
                }
            >
                <DrcInformationBox>
                    <span className={classes.btnTitle}>{getTextTranslated('Export All', NAMESPACE.COMMON)}</span>
                    {' - '}
                    {getTextTranslated('Export All Records with current filters.', NAMESPACE.USERS)}
                    <br />
                    <span className={classes.btnTitle}>{getTextTranslated('Export Current Page', NAMESPACE.COMMON)}</span>
                    {' - '}
                    {getTextTranslated('Export Records which are present on the table.', NAMESPACE.USERS)}
                </DrcInformationBox>
            </DrcDialog>
        </div>
    );
};

export default UserGridContainer;
