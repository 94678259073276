import Link, { LinkProps } from '@mui/material/Link';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface DLinkProps extends LinkProps {
    target?: '_blank';
    className?: string;
}

const DLink = (props: DLinkProps) => {
    const navigate = useNavigate();
    return (
        <div className={props.className || ''}>
            <Link
                underline={props.underline || 'none'}
                component="button"
                variant={props.variant || 'body2'}
                onClick={() => {
                    if (!props.target) {
                        navigate(props.href);
                    } else if (props.target === '_blank') {
                        window.open(props.href, '_blank');
                    }
                }}
            >
                {props.children}
            </Link>
        </div>
    );
};

export default DLink;
