import { IReportObject } from 'components/reports/ReportTypes';
import Loader from 'components/shared/Loader/Loader';
import { ROUTES } from 'constants/routes';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { useNavigate } from 'react-router';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import useLang from '../../../hooks/useLang';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DashboardSection from '../Section';
import SectionHeader from '../Section/SectionHeader';
import style from './QueryBuilderCreationComponent.module.scss';

const TABLEAU_REPORT_BUILDER = 'Tableau Report Builder';
interface QueryBuilderCreationComponentProps {
    loadingReports: boolean;
    errorReports?: string;
    reports: IReportObject[];
}
const QueryBuilderCreationComponent = (props: QueryBuilderCreationComponentProps) => {
    const { getTextTranslated } = useLang();
    const { reports, loadingReports, errorReports } = props;
    const navigate = useNavigate();
    // const { data: reports, loading: loadingReports, error: errorReports } = useGetReports(oktaAuth, 1, 'en', null, true);
    const reportUrl = (reports || []).find((rep) => rep.ReportName === TABLEAU_REPORT_BUILDER)?.ReportURL;

    return (
        <DashboardSection classNameContainer={`${style.reportContainer} `}>
            <SectionHeader title={getTextTranslated('Reports', NAMESPACE.COMMON)} />
            {loadingReports && <Loader circular />}
            {errorReports && <TypographyTranslated nameSpacing={NAMESPACE.DASHBOARD}>{'Reports Error Msg'}</TypographyTranslated>}

            {!loadingReports && !errorReports && (
                <TranslatedDrcButton
                    nameSpacing={NAMESPACE.COMMON}
                    customBtnStyles={{ marginLeft: '1rem' }}
                    isPrimary
                    onClick={() => {
                        return navigate(`/${ROUTES.REPORTS}/${ROUTES.AUTHORING_REPORT}`, {
                            state: { data: reportUrl }
                        });
                    }}
                >
                    {'Build Report'}
                </TranslatedDrcButton>
            )}
        </DashboardSection>
    );
};

export default QueryBuilderCreationComponent;
