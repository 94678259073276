import React, { useEffect, useState } from 'react';
import InactiveMessage from './inactiveMessage';
import { getKioskMode } from './kioskMode';

interface InactivityTrackerProps {
    inActivityEvent: () => void;
    inactivePeriod: number;
}

const InactivityTracker = (props: InactivityTrackerProps) => {
    const { inactivePeriod, inActivityEvent } = props;

    const handleInactivityEvent = () => {
        setState({ ...state, activeUserTrackingEnabled: true, inactiveUserDetected: true });
    };

    const checkKiosk = () => {
        return getKioskMode();
    };

    const [state, setState] = useState({
        inactiveUserDetected: false,
        activeUserTrackingEnabled: checkKiosk()
    });

    const allowUserActivityTracking = () => {
        setState({ ...state, inactiveUserDetected: false, activeUserTrackingEnabled: true });
    };

    useEffect(() => {
        if (state.activeUserTrackingEnabled) {
            checkInactivityFunction(state.activeUserTrackingEnabled, state.inactiveUserDetected);
        }
    }, [state.activeUserTrackingEnabled, state.inactiveUserDetected]);

    //const classes = useStyles();
    const checkInactivityFunction = (enabledTracking, inactiveUserDetected) => {
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer; // catches touchscreen presses as well
        window.ontouchstart = resetTimer; // catches touchscreen swipes as well
        window.ontouchmove = resetTimer; // required by some devices
        window.onclick = resetTimer; // catches touchpad clicks as well
        window.onkeydown = resetTimer;
        window.addEventListener('scroll', resetTimer, true);

        function resetTimer() {
            clearTimeout(timeOut);
            startTracking();
        }
        function timerEnd() {
            stopTracking();
            handleInactivityEvent();
        }
        function stopTracking() {
            window.onload = null;
            window.onmousemove = null;
            window.onmousedown = null; // catches touchscreen presses as well
            window.ontouchstart = null; // catches touchscreen swipes as well
            window.ontouchmove = null; // required by some devices
            window.onclick = null; // catches touchpad clicks as well
            window.onkeydown = null;
            window.addEventListener('scroll', null, true);
            clearTimeout(timeOut);
            timeOut = '';
        }

        let timeOut;
        function startTracking() {
            if (enabledTracking && !inactiveUserDetected) {
                timeOut = setTimeout(timerEnd, Number(inactivePeriod));
            } else {
                stopTracking();
            }
        }
        startTracking();
    };

    return (
        <InactiveMessage
            timeOut={60000} //ms 60000 = 1 minute until timeOutEven
            cancelEvent={allowUserActivityTracking}
            timeOutEvent={() => inActivityEvent()}
            open={state.inactiveUserDetected}
        />
    );
};

export default InactivityTracker;
