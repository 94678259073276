import { Call } from '@driscollsinc/driscolls-display-rules';
import { logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

const useGetOktaUsers = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);

    const getOktaUsers = async (filter, callback) => {
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'OktaUsers',
                    url: APIEndPoints.GET_OKTA_USERS(filter.input),
                    method: 'GET',
                    options: {}
                },
                token
            );
            callback(data?.display);
        } catch (error) {
            console.log(error);
            setError(error);
            logEvent(EventConstants.GET_OKTA_USERS, error);
        }
    };

    return { getOktaUsers, error };
};

export default useGetOktaUsers;
