import React, { useEffect } from 'react';
import { Box, Drawer } from '@mui/material';
import { MOBILE_NAV_WIDTH, NAV_WIDTH } from './constants';
import useResponsive from '../../hooks/useResponsive';
import { useLocation } from 'react-router-dom';
import NavigationMobileContent from './NavigationMobileContent';
import NavigationBarSection from './NavigationBarSection';
import { NavigationBarData } from './navigationBarData';

import style from './NavigationBar.module.scss'


const NavigationBar = ({ openNav, onCloseNav }) => {
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'md');


    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    }, [pathname]);

    return (
        <>
            <Box component='nav' className={style.navigationBarBox}>
                {isDesktop ? (
                    <Drawer
                        open
                        variant='permanent'
                        PaperProps={{
                            sx: {
                                width: NAV_WIDTH,
                                bgcolor: 'primary.main',
                                left: '16px',
                                top: '140px',
                                borderRadius: '50px',
                                height: '70vh',
                                position: 'fixed',
                                alignContent: 'center',
                                '&::-webkit-scrollbar': {
                                    display: 'none'
                                },
                                '::-webkit-scrollbar-thumb': {
                                    background: '#babfc7',
                                    borderRadius: 10
                                },
                                '::-webkit-scrollbar-thumb:hover': {
                                    background: '#ffffff'
                                },
                                '::-webkit-scrollbar-track': {
                                    background: 'none',
                                    borderRadius: 10
                                }
                            }
                        }}
                    >
                        <NavigationBarSection navBarData={NavigationBarData} />
                    </Drawer>
                ) : (
                    <Drawer
                        open={openNav}
                        onClose={onCloseNav}
                        ModalProps={{
                            keepMounted: true
                        }}
                        PaperProps={{
                            sx: { width: MOBILE_NAV_WIDTH }
                        }}
                    >
                        <NavigationMobileContent />
                    </Drawer>
                )}
            </Box>
        </>
    );
};

export default NavigationBar;
