import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity, dateFns } from '@driscollsinc/style-guide-web2.0';
import { IWeatherFormData, ReportTypesEnum } from '@driscollsinc/weather-module-ui';
import { IWeatherPayload } from 'components/weather/utils';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useState } from 'react';
import APIEndPoints from 'services/api';
import useGetApiTokens from './useGetApiTokens';

const useWeatherService = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);

    const createWeatherPayload = (weatherFormValues: IWeatherFormData): IWeatherPayload => {
        const weatherPayload: IWeatherPayload = {
            LatLong: {
                Latitude: weatherFormValues?.locationInfo.latitude,
                Longitude: weatherFormValues?.locationInfo.longitude
            },
            UserEmail: weatherFormValues?.userEmail,
            LanguageCode: 'en',
            DetailsIndicator: true,
            CostCenter: ''
        };
        switch (weatherFormValues.reportType) {
            case ReportTypesEnum.DAILY:
                weatherPayload['Frequency'] = { Daily: { NumberDays: '10' } };
                break;
            case ReportTypesEnum.HOURLY:
                weatherPayload['Frequency'] = { Hourly: { NumberHours: '24' } };
                break;
            case ReportTypesEnum.HISTORICAL:
                weatherPayload['DateRange'] = {
                    StartDate: dateFns.format(weatherFormValues?.startDate, 'yyyy-MM-dd'),
                    EndDate: dateFns.format(weatherFormValues?.endDate, 'yyyy-MM-dd')
                };
        }
        return weatherPayload;
    };

    const generateWeatherReport = async (weatherData: IWeatherFormData) => {
        setLoading(true);
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'GenerateWeatherReport',
                    url: APIEndPoints.GET_WEATHER_REPORT(weatherData.reportType ?? ReportTypesEnum.DAILY),
                    method: 'POST',
                    options: {},
                    data: createWeatherPayload(weatherData)
                },
                token
            );

            const {
                raw: {
                    data: { Message }
                }
            } = data;
            setShowToast({ toastMessage: Message, severity: DriscollsToastSeverity.SUCCESS });
            setLoading(false);
        } catch (error) {
            setShowToast({ toastMessage: 'There is an error processing your request', severity: DriscollsToastSeverity.ERROR });
            setLoading(false);
        }
    };

    return {
        generateWeatherReport,
        loading
    };
};
export default useWeatherService;
