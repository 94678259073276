import React from 'react';
import { styled } from '@mui/material';
import CustomGridOverlay from '../gridoverlays/CustomGridOverlay';

/**
 * @ignore
 * @memberof DrcBackdrop
 */

const RootDiv = styled('div')(({ theme, show, isLoading }) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.palette.primary.light,
    zIndex: isLoading ? 1301 : 1100,
    display: show ? 'block' : 'none',
    ['@media (prefers-color-scheme: dark)']: {
        color: theme.palette.primary.dark
    }
}));

const LoadingDiv = styled('div')(({ theme }) => ({
    left: 'calc(50vw - 194px)',
    top: 'calc(50vh - 153px)',
    position: 'fixed',
    padding: '20px',
    background: 'none',
    borderRadius: '10px',
    textAlign: 'center'
}));

/**
 * @description DrcBackdrop is a background that appears when the the app is loading something.
 * @class DrcBackdrop
 * @property {array} children list of children.
 * @property {string} className className for the backdrop.
 * @property {boolean} isLoading shows or hides the loading message.
 * @property {string} loadingMessage loading message.
 * @property {boolean} show shows or hide the backdrop.
 * @property {function} onClick event when backdrop is clicked.
 * @property {object} classes customized style for backdrop.
 * @example <DrcBackdrop show={true} isLoading={true} onClick={() => {}} loadingMessage='loading example' />
 * @extends {React.Component}
 * @category Loading
 * @tags loading backdrop blocking
 */
class DrcBackdrop extends React.Component {

    /**
     * @description render method for the DrcBackdrop, part of the react component lifecyle.
     * @return {*} the view for this component.
     * @memberof DrcBackdrop
     */
    render() {
        
        const { children, isLoading, loadingMessage, show, onClick, classes } = this.props;

        return (
            <RootDiv show={show} isLoading={isLoading} onClick={(event) => {
                if(onClick){
                    onClick(event)
                }
            }}>
                {children}
                {isLoading ? (
                    <LoadingDiv>
                        <CustomGridOverlay loadingMessage={loadingMessage ? loadingMessage : 'Loading'} />
                    </LoadingDiv>
                ) : null}
            </RootDiv>
        );
    }
}

export default DrcBackdrop;
