import ApplicationContext from 'context/ApplicationContext';
import { getInvitablePersonas } from 'data/config/datasetup';
import { PERSONA_NAME } from 'data/config/persona';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect } from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import CreateFilter from '../../utils/filter';
import style from './DrcFilter.module.scss';
import FilterItem from './FilterItem';
import { filterFields } from './drcFilterFields';

const Filter = (props): JSX.Element => {
    const { showFilter, filterNames, resetFilter, applyFilter } = props;
    const { ranches, districtOptions, companies, filters, setFilters, userPersona } = useContext(ApplicationContext);

    const getAllRanches = () => {
        const ranchesList = ranches
            ?.flatMap((ranch) => ranch?.Ranches)
            .map((item) => ({
                ...item,
                label: `${item?.RanchNbr || item?.Code} - ${item?.Name}`,
                value: item?.RanchNbr || item?.Code
            }));

        return ranchesList || [];
    };

    const filterOptions = {
        Ranch: getAllRanches(),
        'Ranch Number': getAllRanches(),
        Company: ranches,
        'Grower Number': ranches,
        District: districtOptions,
        Target: [...districtOptions, ...companies, ...ranches, ...getAllRanches()],
        Persona: getInvitablePersonas(userPersona).map((p) => ({
            ...p,
            value: p.label === PERSONA_NAME.GROWERADMIN ? PERSONA_NAME.RANCHADMIN : p.label
        }))
    };

    const generateFilters = (filters) =>
        filterNames
            .reduce((acc, name) => {
                const foundFilter = filters.find((filter) => filter.FieldName === name);
                if (foundFilter && foundFilter.IsSearchable && filterNames.includes(foundFilter.FieldName)) {
                    const optionsAvailable = filterOptions[foundFilter.FieldName];
                    if (optionsAvailable) {
                        foundFilter.Options = optionsAvailable.map((option) => ({
                            label: option.label || option.Name,
                            value: option.value || option.Code,
                            ...option
                        }));
                    }
                    acc.push(foundFilter);
                }
                return acc;
            }, [])
            .map(CreateFilter);

    const clearFiltersHandler = () => {
        const clearedFilters = clearFilters();
        setFilters(clearedFilters);
        resetFilter();
    };

    const clearFilters = () => {
        return filters?.map((filter) => {
            if (filter.isSelected) {
                if (filter.hasOptions || filter.isAsync) {
                    filter.isSelected = false;
                    filter.value = '';
                    filter.selected = [];
                } else {
                    filter.isSelected = false;
                    filter.value = '';
                }
            }
            return filter;
        });
    };

    const filterSelectHandler = (fieldName: string, value: string, bool: boolean) => {
        const updatedFilters = filters.map((filter) => {
            if (filter.fieldName === fieldName) {
                filter.isSelected = true;

                if (filter.hasOptions || filter.isAsync) {
                    if (!filter.isCheckbox) {
                        filter.selected = value ? (Array.isArray(value) ? value : [value]) : filter.isBerry ? [value] : [...filter.selected, value];
                    } else {
                        filter.selected = bool ? [...filter.selected, value] : filter.selected.filter((val) => val !== value);
                    }
                } else {
                    filter.value = value;
                    // filter.value = filter.isBool ? value : filter.value;
                }

                if (filter.hasOptions && !filter.selected.length) {
                    filter.isSelected = false;
                }
            }
            return { ...filter };
        });

        const hasFilters = updatedFilters.filter((filter) => filter.isSelected).length;
        if (!hasFilters) {
            resetFilter();
        } else {
            setFilters(updatedFilters);
        }
    };

    const validationDetectedHandler = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        const updatedFilters = filters.map((filter) => {
            if (filter.fieldName === fieldName) {
                filter.hasError = validate;
            }
            return { ...filter };
        });
        setFilters(updatedFilters);
    };

    const generatedFilters = generateFilters(filterFields);

    useEffect(() => {
        setFilters(generatedFilters);
    }, []);

    return (
        showFilter && (
            <div className={style.filterContainer}>
                <div className={style.filterWrapper}>
                    {filters
                        ?.map((filter, index) => {
                            const { fieldName } = filter;
                            return (
                                fieldName !== 'BerryType' && (
                                    <FilterItem
                                        key={index}
                                        filterSelect={filterSelectHandler}
                                        filter={filter}
                                        handleValidationDetected={validationDetectedHandler}
                                        applyFilter={applyFilter}
                                    />
                                )
                            );
                        })
                        .filter((e) => e)}
                </div>
                <div className={style.filterButtonsContainer}>
                    <TranslatedDrcButton
                        nameSpacing={NAMESPACE.COMMON}
                        isPrimary
                        onClick={applyFilter}
                        //disabled={!!filters.find((filter) => filter?.hasError)}
                    >
                        {'Apply'}
                    </TranslatedDrcButton>
                    <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isSecondary onClick={clearFiltersHandler}>
                        {'Reset'}
                    </TranslatedDrcButton>
                </div>
            </div>
        )
    );
};

export default Filter;
