import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Box, Chip, Container, Divider, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { IReportObject } from 'components/reports/ReportTypes';
import ApplicationContext from 'context/ApplicationContext';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { PERSONAID, PERSONA_NAME } from 'data/config/persona';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useFetchUsers from 'hooks/useFetchUsers';
import useFormatPayload from 'hooks/useFormatPayload';
import useGetReports from 'hooks/useGetReports';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import AssignMentList from '../../components/shared/generic/AssignmentList';
import PersonalInformation from '../../components/usermanagement/PersonalInformation';
import { ROUTES } from '../../constants/routes';
import { forms } from '../../data/config/inviteforms';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import { getPersonaFromPersonaId, getPersonaIdFromPersona } from '../../utils/helper';
import classes from './usermanagement.module.scss';

const ViewUser = (props) => {
    useCheckMasterData();
    useMapSuperAdminData();
    const { oktaId: UserOktaId, selectedpersonaid } = useParams();
    const selectedPersonaId = Number(selectedpersonaid);
    let selectedPersona = getPersonaFromPersonaId(selectedPersonaId);
    const [currentForm, setCurrentForm] = useState<IInviteForms>();
    const [currentUser, setCurrentUser] = useState({});
    const [assignments, setAssignments] = useState([]);
    const [reports, setReports] = useState<Array<IReportObject>>([]);
    const { fetchUsers, loading: fetchUsersLoading } = useFetchUsers(props.oktaAuth);
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const userLang = getCurrentLanguageSelected();
    const { userPersona } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const { data: reportsData } = useGetReports(props.oktaAuth, selectedPersonaId, userLang, UserOktaId);

    useEffect(() => {
        selectedPersona !== PERSONA_NAME.SUPERADMIN &&
            setCurrentForm(forms.find((item) => item.persona === getPersonaIdFromPersona(selectedPersona)));
    }, []);

    const { formattingDistrictPayload, formattingCompanyRanchPayload, formattingDistrictCompanyPayload } = useFormatPayload(
        false,
        assignments,
        setAssignments,
        currentForm
    );

    useEffect(() => {
        currentForm?.reports && setReports(reportsData);
    }, [currentForm, reportsData]);

    useEffect(() => {
        currentForm && getUserProfile();
    }, [currentForm]);

    const getUserProfile = async () => {
        const fetchUserPayload = {
            RequestorPersonaId: userPersona,
            AuthIdFilter: UserOktaId,
            PersonaId: selectedPersona === PERSONA_NAME.GROWERADMIN ? PERSONA_NAME.RANCHADMIN : selectedPersona
        };
        const data = await fetchUsers(fetchUserPayload);
        if (!data.display.errorCode) {
            selectedPersona = data.display?.Response[0].Persona;
            setCurrentUser(data.display.Response);
            if (currentForm.assignmentType === AssignmentTypes.DISTRICTS) formattingDistrictPayload(data.raw.data.Response[0].Districts);
            if (currentForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
                if (data.raw.data.Response[0].Ranches && data.raw.data.Response[0].Companies)
                    formattingCompanyRanchPayload([...data.raw.data.Response[0].Ranches, ...data.raw.data.Response[0].Companies]);
            }
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY)
                formattingDistrictCompanyPayload([...data.raw.data.Response[0].Districts, ...data.raw.data.Response[0].Companies]);
        }
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.USER_MANAGEMENT}`);
    };

    return (
        <DrcContainer heading={getTextTranslated('View User', NAMESPACE.USERS)} goBackHandler={goBackHandler}>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <>
                        <Grid container spacing={2}>
                            <Grid item>
                                <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="subtitle2" className={classes.contentHeading}>
                                    {'Persona Type'}
                                </TypographyTranslated>
                            </Grid>
                            <Grid item>
                                <TypographyTranslated variant="subtitle2">{selectedPersona}</TypographyTranslated>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        {/* Personal Information Section */}
                        <PersonalInformation persona={selectedPersona} currentEditUser={currentUser} currentForm={currentForm} />
                        <Divider className={classes.divider} />
                        {/* Assignment Section */}
                        <Grid item>
                            <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="subtitle2" className={classes.contentBottom}>
                                {'Assignments'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                            {selectedPersonaId !== PERSONAID.SUPERADMIN && selectedPersonaId !== PERSONAID.PORTALUSER ? (
                                (assignments || []).map((item, index) => (
                                    <Grid item key={index} xs={12} sm={4} md={4}>
                                        <AssignMentList data={item} />
                                    </Grid>
                                ))
                            ) : (
                                <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="h5" sx={{ ml: 5 }}>
                                    {selectedPersonaId === PERSONAID.SUPERADMIN ? 'User is a Super Admin' : 'User is a Portal User'}
                                </TypographyTranslated>
                            )}
                            <Divider className={classes.divider} />
                            {currentForm?.reports && (
                                <>
                                    <Grid item>
                                        <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="subtitle2" className={classes.contentBottom}>
                                            {'Reports'}
                                        </TypographyTranslated>
                                    </Grid>
                                    <Box className={classes.reportsContainer}>
                                        {(reports || []).map((item, index) => (
                                            <Chip key={index} label={item.label || item.Description} />
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </>
                </Box>
                {fetchUsersLoading && <DriscollsSpinner text={getTextTranslated('Fetching User Info', NAMESPACE.USERS)} />}
            </Container>
        </DrcContainer>
    );
};

export default withOktaAuth(ViewUser);
