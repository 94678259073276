import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import DrcPhoneInput from 'components/shared/drc/DrcPhoneInput';
import FormCheckbox from 'components/shared/formcontrols/FormCheckbox';
import FormDriscollsUsers from 'components/shared/formcontrols/FormDriscollsUsers';
import FormSelectField from 'components/shared/formcontrols/FormSelectField';
import FormTextField from 'components/shared/formcontrols/FormTextField';
import { usernameOptions } from 'constants/options/options';
import { IPersonaInfoObj } from 'data/config/DataSetupTypes';
import useGetOktaUsers from 'hooks/useGetOktaUsers';
import React, { Fragment } from 'react';
import { ICountryObject } from './UserManagementTypes';

interface IRenderFormFieldProps {
    item: IPersonaInfoObj;
    addPayloadValue: (field: string, value: any) => void;
    availableCountries: Array<ICountryObject>;
    memo: any;
}

interface IFormFieldProps extends IRenderFormFieldProps, IOktaContext {}

const RenderFormField = (props: IFormFieldProps) => {
    const { getOktaUsers } = useGetOktaUsers(props.oktaAuth);
    const { memo } = props;

    return (
        <Fragment>
            {props.item.type === 'textinput' && (
                <FormTextField
                    label={props.item.label}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                    value={memo.formFields[props.item.field]}
                    inputProps={{ ...memo.register(props.item.field) }}
                    helperText={memo.errors[props.item.field] ? memo.errors[props.item.field]?.message : ''}
                    error={!!memo.errors[props.item.field]}
                />
            )}
            {props.item.type === 'select' && (
                <FormSelectField
                    label={props.item.label}
                    values={props.item.field === 'country' ? props.availableCountries : props.item.data}
                    valueField={props.item.valueField}
                    selectedValue={memo.formFields[props.item.field]}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                    inputProps={{ ...memo.register(props.item.field) }}
                    helperText={memo.errors[props.item.field] ? memo.errors[props.item.field]?.message : ''}
                    error={!!memo.errors[props.item.field]}
                />
            )}
            {props.item.type === 'email' && (
                <FormTextField
                    label={props.item.label}
                    value={memo.formFields[props.item.field]}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                    inputProps={{ ...memo.register(props.item.field) }}
                    helperText={memo.errors[props.item.field] ? memo.errors[props.item.field]?.message : ''}
                    error={!!memo.errors[props.item.field]}
                />
            )}
            {props.item.type === 'phone' && (
                <DrcPhoneInput
                    style={{ margin: '12px 0 0 18px', width: '90%' }}
                    label={props.item.label}
                    value={memo.formFields[props.item.field]}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                    helperText={memo.errors[props.item.field] ? memo.errors[props.item.field]?.message : ''}
                    error={!!memo.errors[props.item.field]}
                />
            )}

            {props.item.type === 'driscollsemail' && (
                <FormDriscollsUsers
                    service={getOktaUsers}
                    label={props.item.label}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                    inputProps={{ ...memo.register(props.item.field) }}
                    helperText={memo.errors[props.item.field] ? memo.errors[props.item.field]?.message : ''}
                    error={!!memo.errors[props.item.field]}
                />
            )}
            {props.item.type === 'usernamechoice' && (
                <FormSelectField
                    label={props.item.label}
                    values={usernameOptions}
                    selectedValue={memo.formFields[props.item.field] ?? 'phone'}
                    valueField={props.item.valueField}
                    handleChange={(value) => props.addPayloadValue(props.item.field, value)}
                />
            )}
            {props.item.type === 'checkbox' && (
                <FormCheckbox
                    label={props.item.label}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) => props.addPayloadValue(props.item.field, event.target.checked)}
                />
            )}
        </Fragment>
    );
};

export default withOktaAuth(RenderFormField);
