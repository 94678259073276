import viewColumnRenderer from 'components/shared/cellrenderers/viewColumnRenderer';
import { NAMESPACE } from 'i18n';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import { supportGrowerColumns, supportRegionalColumns } from '../../data/GridColumnMap';
import { PERSONAID } from '../../data/config/persona';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import ReplyColumnRenderer from '../shared/cellrenderers/ReplyColumnRenderer';
import DrcDataGrid from '../shared/drc/DrcDataGrid';
import DrcMobileList from '../shared/drc/DrcMobileList';
import styles from './Support.module.css';
import SupportListItemRenderer from './SupportListItemRenderer';
const SupportGridContainer = ({ supportList, loading, pageCount, page, handlePageChange, totalUsers, executionTime }) => {
    const navigate = useNavigate();
    const { userPersona } = useContext(ApplicationContext);
    const isDesktop = useResponsive('up', 'md');
    const { getTextTranslated } = useLang();
    const formatPayload = () => {
        return (supportList || []).map((item) => {
            return {
                ...item,
                Priority: (item.Priority === 1 && 'High') || (item.Priority === 2 && 'Medium') || (item.Priority === 3 && 'Low')
            };
        });
    };

    const replyColumn = () => {
        return {
            field: 'Reply',
            headerName: '',
            minWidth: 60,
            maxWidth: 60,
            pinned: 'left',
            cellRenderer: ReplyColumnRenderer,
            resizable: false,
            sortable: false,
            suppressMovable: false,
            filter: false,
            cellRendererParams: {
                replyRow: (rowData) => {
                    const obj = JSON.stringify(rowData);
                    navigate(`${ROUTES.RESOLUTION}/${rowData.RequestId}/${rowData.ResponseId ? rowData.ResponseId : 0}`, {
                        state: { data: obj }
                    });
                },
                isEditable: () => {
                    return true;
                }
            }
        };
    };

    const viewColumn = () => {
        return {
            field: 'View',
            headerName: '',
            minWidth: 60,
            maxWidth: 60,
            pinned: 'left',
            cellRenderer: viewColumnRenderer,
            resizable: false,
            sortable: false,
            suppressMovable: false,
            filter: false,
            cellRendererParams: {
                viewRow: (rowData) => {
                    const obj = JSON.stringify(rowData);
                    navigate(`${ROUTES.VIEW_RESPONSE}/${rowData.RequestId}/${rowData.ResponseId ? rowData.ResponseId : 0}`, {
                        state: { data: obj }
                    });
                }
            }
        };
    };

    return (
        <div className={styles.mainContainer} style={{ padding: isDesktop ? '20px' : '0px' }}>
            {isDesktop ? (
                <DrcDataGrid
                    loadingMessage={getTextTranslated('Loading', NAMESPACE.COMMON)}
                    loading={loading}
                    rows={formatPayload()}
                    columns={
                        [PERSONAID.SUPERADMIN, PERSONAID.REGIONALADMIN].includes(userPersona)
                            ? supportRegionalColumns(replyColumn())
                            : supportGrowerColumns(viewColumn())
                    }
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                />
            ) : (
                <DrcMobileList
                    loadingMessage={getTextTranslated('Loading', NAMESPACE.COMMON)}
                    page={page}
                    totalUsers={totalUsers}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    loading={loading}
                    getRenderer={(row, i) => <SupportListItemRenderer row={row} navigate={navigate} />}
                    rows={formatPayload()}
                />
            )}
        </div>
    );
};

export default SupportGridContainer;
