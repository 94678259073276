import { DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import { IDistrict } from 'components/appinitializer/IDistricts';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../../hooks/useLang';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import FormAutoComplete from '../shared/formcontrols/FormAutoComplete';
import FormMultiSelect from '../shared/formcontrols/FormMultiSelect';
import styles from './AssignmentsContainer.module.scss';
import { ICompanyObject, IRanchObject } from './UserManagementTypes';

interface IRanchAssignmentProps {
    districts: Array<ICompanyObject>;
    onChangeHandler: (value: IDistrict) => void;
    districtLabel: string;
    companyLabel: string;
    districtCompanies: Array<ICompanyObject>;
    onAddRanchHandler: (berry: Array<IRanchObject>) => void;
    addAssignments: () => void;
    value: { value: string; label: string };
}

export default function RanchAssignments(props: IRanchAssignmentProps) {
    const { getTextTranslated } = useLang();
    return (
        <DriscollsHContainer wrap>
            <div className={styles.col}>
                <FormAutoComplete
                    labelField="label"
                    values={props.districts}
                    onChangeHandler={props.onChangeHandler}
                    label={props.districtLabel}
                    value={props?.value?.label || ''}
                />
            </div>
            <div className={styles.col}>
                <FormMultiSelect
                    headerText={getTextTranslated('Select ranches to add to the list', NAMESPACE.EVENTS)}
                    values={props.districtCompanies}
                    onChangeHandler={props.onAddRanchHandler}
                    label={props.companyLabel}
                />
            </div>
            <div className={styles.button}>
                <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={props.addAssignments}>
                    {'ADD'}
                </TranslatedDrcButton>
            </div>
        </DriscollsHContainer>
    );
}
