export const queryStatus = [
    { label: 'OPEN', value: 'OPEN' },
    { label: 'IN PROGRESS', value: 'IN PROGRESS' },
    { label: 'RESOLVED', value: 'RESOLVED' },
    { label: 'CLOSED', value: 'CLOSED' }
];

export const usernameOptions = [
    { label: 'Phone', value: 'phone' },
    { label: 'Email', value: 'email' }
];