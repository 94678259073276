import { Box, Card, Typography } from '@mui/material';
import DrcProgress from 'components/shared/drc/DrcProgress';
import React from 'react';
import style from './AppInitializer.module.scss';
const MasterDataLoader = ({ percentComplete, message }) => {
    return (
        <section className={style.container}>
            <Card className={style.card}>
                <Box className={style.box} dir="ltr">
                    <div className={style.div}>
                        <Typography sx={{ textTransform: 'uppercase' }} variant="h6" align="center" component="h2">
                            {message}
                        </Typography>
                        <DrcProgress value={percentComplete || 0} />
                    </div>
                </Box>
            </Card>
        </section>
    );
};

export default MasterDataLoader;
