import { Typography } from '@mui/material';
import React from 'react';
import { isJsonString } from 'utils/helper';

const DrcToolTip = (props) => {
    const value = isJsonString(props?.value)
        ? JSON.parse(props.value)
              .blocks.map((itm) => itm.text)
              .join(' ')
        : props.value;
    return (
        <div
            className="custom-tooltip"
            style={{
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: '0px 4px 8px gray',
                border: '1px solid #dadde9',
                borderRadius: '5px',
                padding: '10px',
                fontFamily: 'Rubik'
            }}
        >
            <Typography component={'span'}>{value}</Typography>
        </div>
    );
};
export default DrcToolTip;
