import { COMMODITY_CODE } from 'constants/CommodityEnum';
import React from 'react';
import BLACK from '../Images/Black_Small.png';
import BLUE from '../Images/Blue_Small.png';
import RASP from '../Images/Rasp_Small.png';
import STRAW from '../Images/Straw_Small.png';

const GetBerryIcon = ({ berry }: { berry: COMMODITY_CODE }) => {
    let icon: string;
    switch (berry) {
        case COMMODITY_CODE.BLACKBERRY:
            icon = BLACK;
            break;
        case COMMODITY_CODE.BLUEBERRY:
            icon = BLUE;
            break;
        case COMMODITY_CODE.STRAWBERRY:
            icon = STRAW;
            break;
        case COMMODITY_CODE.RASPBERRY:
            icon = RASP;
            break;
    }
    return <img src={icon} />;
};

export default GetBerryIcon;
