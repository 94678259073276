import { Card, CardContent, CardHeader } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import EventDetails from 'components/events/EventDetails';
import Loader from 'components/shared/Loader/Loader';
import useFetchEvent from 'hooks/useFetchEvent';
import React from 'react';
import { useParams } from 'react-router-dom';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import style from './ViewEventDetails.module.scss';

const ViewEventDetails = (props) => {
    const { oktaAuth } = props;
    const { id } = useParams();
    const { data: event, loading: loadingEvent, error: errorEvent } = useFetchEvent(oktaAuth, id);

    return (
        <DrcContainer heading="Event Details">
            {loadingEvent && <Loader />}
            {errorEvent && <p>Can not load the event</p>}
            {!loadingEvent && !errorEvent && (
                <Card className={style.container}>
                    <CardHeader title={event?.Name} />
                    <CardContent>
                        <EventDetails event={event} />
                    </CardContent>
                </Card>
            )}
        </DrcContainer>
    );
};

export default withOktaAuth(ViewEventDetails);
