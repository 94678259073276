import React, { useEffect } from 'react';
import DrcInput from '../drc/DrcInput';

const GEPFormInput = ({ validationDetected = (p) => null, ...props }) => {
    const { error, isChanged, isUsed } = props;

    useEffect(() => {
        validationDetected((isUsed || isChanged) && !!error);
    }, [error, isChanged, isUsed, validationDetected]);

    const renderLabel = () => {
        if (props.required) {
            return (
                <span>
                    {props.label} <span style={{ color: 'red' }}>*</span>
                </span>
            );
        } else {
            return props.label;
        }
    };

    return (
        <DrcInput
            error={(isUsed || isChanged) && !!error}
            helperText={isUsed || isChanged ? error ? <span style={{ fontSize: 10 }}>{error} </span> : '' : ''}
            required={false}
            label={renderLabel()}
            {...props}
        />
    );
};

export default GEPFormInput;
