import DuAuthenticationUtilities from 'components/shared/Utilities/DuAuthenticationUtilities';
const useGetApiTokens = () => {
    const getTokenAndId = async (oktaAuth) => {
        const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(oktaAuth);
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        const userEmail = DuAuthenticationUtilities.GetUserId(token);
        return { token, userOktaId, userEmail };
    };

    return { getTokenAndId: getTokenAndId };
};

export default useGetApiTokens;
