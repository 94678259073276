import { Call } from '@driscollsinc/driscolls-display-rules';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { logEvent } from 'analytics/AnalyticsHandler';
import { IReportObject } from 'components/reports/ReportTypes';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { EventConstants } from 'constants/EventConstants';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { isPersonaIsOKTA } from 'data/config/datasetup';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

const DAILY_WEEKLY_REPORT_ID = 30;
export const GROWER_SETTLEMENT_REPORT_ID = 31;
export const SUPPLEMENTAL_STATEMENT_REPORT_ID = 33;
export const CANADA_MANUAL_TAX_REPORT_ID = 34;

/**
 * This hook fetches the list of contacts for an user
 * {@link APIEndPoints.GET_REPORTS_LIST}
 * {@link APIEndPoints.GET_ASSIGNED_REPORTS}
 *
 */
const useGetReports = (oktaAuth: IOktaContext, userPersona: number, userLang: string, editUserOktaId?: string, boolReportByCategory?: boolean) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<Array<IReportObject> | []>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const excludedOktaUsers = [Persona.PORTAL_USER];
    const { setReportsList } = useContext(ApplicationContext);

    const getReports = async () => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const canViewReports = isPersonaIsOKTA(userPersona) && !excludedOktaUsers.includes(userPersona);

        try {
            const data = await Call(
                {
                    name: 'Reports',
                    url: canViewReports
                        ? APIEndPoints.GET_REPORTS_LIST(userLang)
                        : APIEndPoints.GET_ASSIGNED_REPORTS(editUserOktaId || userOktaId, userLang),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const result = (data?.display || []).map((report: IReportObject) => {
                // this mapping is needed because we are navigating these reports based on their description
                switch (report.ReportId) {
                    case GROWER_SETTLEMENT_REPORT_ID:
                        return {
                            ...report,
                            label: report.ReportName,
                            Description: ROUTES.VIEW_GROWER_SETTLEMENT_REPORT
                        };
                    case SUPPLEMENTAL_STATEMENT_REPORT_ID:
                        return {
                            ...report,
                            label: report.ReportName,
                            Description: ROUTES.VIEW_SUPPLEMENTAL_STATEMENT_REPORT
                        };
                    case CANADA_MANUAL_TAX_REPORT_ID:
                        return {
                            ...report,
                            label: report.ReportName,
                            Description: ROUTES.VIEW_CANADA_MANUAL_TAX_REPORT
                        };
                    case DAILY_WEEKLY_REPORT_ID:
                        return {
                            ...report,
                            label: report.ReportName,
                            Description: ROUTES.VIEW_WEEKLY_REPORT
                        };
                    default:
                        return {
                            ...report,
                            label: report.ReportName
                        };
                }
            });
            setReportsList(result);
            setData(result);
        } catch (error) {
            console.log(error);
            setError(error);
            logEvent(EventConstants.GET_REPORTS, error);
        } finally {
            setLoading(false);
        }
    };

    const getReportsByCategory = async () => {
        const { token } = await getTokenAndId(oktaAuth);

        try {
            const data = await Call(
                {
                    name: 'Reports',
                    url: APIEndPoints.GET_REPORTS_BY_CATEGORY(),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setData(data?.display || []);
        } catch (error) {
            console.log(error);
            setError(error);
            logEvent(EventConstants.GET_REPORTS, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (boolReportByCategory) {
            getReportsByCategory();
        } else {
            getReports();
        }
    }, []);

    return { data, loading, error, getReports, getReportsByCategory };
};
export default useGetReports;
