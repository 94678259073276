import { PERSONAID } from 'data/config/persona';
import { persona } from '../data/config/datasetup';
const getAdminsId = () => {
    const ids = {};
    persona.forEach((p) => {
        if (p.type === 'ADMIN') {
            ids[p.Id] = p.Name;
        }
    });
    return ids;
};

export const getAllPersonas = () => {
    const all = Object.values(PERSONAID);
    return [...all];
};

export const isSuperAdmin = (persona: number): boolean => persona === PERSONAID.SUPERADMIN;

const isAdmin = (persona: number) => {
    const adminIds = getAdminsId();
    return !!adminIds[persona];
};

export const isPersonaMatches = (source: number, target: number) => {
    return source === target;
};

/**
 * TODO: Change logic to get more accurate type
 */
export const isOktaUser = (): boolean => {
    const authenticationProvider: string = localStorage.getItem('authenticationProvider');
    return 'okta' === authenticationProvider;
};

export default isAdmin;
