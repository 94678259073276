import { Box } from '@mui/material';
import React, { useState } from 'react';
import GenericSearchBox from '../../components/shared/search/GenericSearchBox';
import styles from './DocumentLibrary.module.scss';

interface Props {
    setSearchInput?: any;
    searchInput?: string;
    handlePageChange?: any;
    onEnterPress?: any;
    isDesktop?: any;
}

const LibraryDesktop = (props: Props) => {
    const [advancedSearchOn, setAdvancedSearchOn] = useState(false);
    const resetFilters = (event) => {
        setAdvancedSearchOn(false);
        props.handlePageChange(event, 1);
    };
    return (
        <Box className={styles.searchContainer}>
            <GenericSearchBox
                style={{ width: props.isDesktop ? '35vw' : '100%' }}
                value={props.searchInput}
                setValue={props.setSearchInput}
                onEnterPress={props.onEnterPress}
            />
        </Box>
    );
};

export default LibraryDesktop;
