import EmailIcon from '@mui/icons-material/Email';
import React from 'react';
import style from './EmailItem.module.scss';

const EmailItem = ({ email, showIcon }: { email: string; showIcon?: boolean }) => {
    return (
        <div className={style.container}>
            {showIcon && <EmailIcon />}
            <span>{email}</span>
        </div>
    );
};

export default EmailItem;
