import { PERSONAID, PERSONA_NAME } from 'data/config/persona';

const usePersona = (userPersona: number) => {
    const getPersonaSrcImage = () => {
        switch (userPersona) {
            case PERSONAID.GROWERADMIN:
                return '/icons/personas/growerAdmin.svg';
            case PERSONAID.REGIONALADMIN:
                return '/icons/personas/regionalAdmin.svg';
            case PERSONAID.HARVESTPLANNER:
                return '/icons/personas/growerUser.svg';
            case PERSONAID.REPORTBUILDER:
                return '/icons/personas/reportBuilder.svg';
            default:
                return '/icons/personas/growerUser.svg';
        }
    };
    const getPersonaCaption = () => {
        switch (userPersona) {
            case PERSONAID.SUPERADMIN:
                return PERSONA_NAME.SUPERADMIN;
            case PERSONAID.GROWERADMIN:
                return PERSONA_NAME.GROWERADMIN;
            case PERSONAID.REGIONALADMIN:
                return PERSONA_NAME.REGIONALADMIN;
            case PERSONAID.HARVESTPLANNER:
                return PERSONA_NAME.HARVESTPLANNER;
            case PERSONAID.QAADMIN:
                return PERSONA_NAME.QAADMIN;
            case PERSONAID.RANCHPLANNER:
            case PERSONAID.QAUSER:
                return PERSONA_NAME.RANCHPLANNER;
            case PERSONAID.PALLETBUILDER:
                return PERSONA_NAME.PALLETBUILDER;
            case PERSONAID.PTIAPP:
                return PERSONA_NAME.PTIAPP;
            case PERSONAID.GROWERUSER:
                return PERSONA_NAME.GROWERUSER;
            case PERSONAID.QUALITYVIEWER:
                return PERSONA_NAME.QUALITYVIEWER;
            case PERSONAID.PORTALUSER:
                return PERSONA_NAME.PORTALUSER;
            case PERSONAID.REPORTBUILDER:
                return PERSONA_NAME.REPORTBUILDER;
            default:
                return 'unknown';
        }
    };

    const getPersonaWelcomeMessage = () => {
        switch (userPersona) {
            case PERSONAID.SUPERADMIN:
                return 'superAdminWelcomeMessage';
            case PERSONAID.REGIONALADMIN:
                return 'regionalAdminWelcomeMessage';
            case PERSONAID.HARVESTPLANNER:
                return 'harvestPlannerWelcomeMessage';
            case PERSONAID.GROWERADMIN:
                return 'growerAdminWelcomeMessage';
            case PERSONAID.QAADMIN:
                return 'qaAdminWelcomeMessage';
            case PERSONAID.RANCHPLANNER:
            case PERSONAID.QAUSER:
                return 'ranchPlannerWelcomeMessage';
            case PERSONAID.PALLETBUILDER:
                return 'palletBuilderWelcomeMessage';
            case PERSONAID.PTIAPP:
            case PERSONAID.PTIUSER:
                return 'ptiAppWelcomeMessage';
            case PERSONAID.QUALITYVIEWER:
                return 'qualityViewerWelcomeMessage';
            case PERSONAID.GROWERUSER:
                return 'growerUserWelcomeMessage';
            case PERSONAID.PORTALUSER:
                return 'portalUserWelcomeMessage';
            case PERSONAID.REPORTBUILDER:
                return 'reportBuilderWelcomeMessage';
            default:
                return 'defaultWelcomeMessage';
        }
    };
    return { getPersonaSrcImage, getPersonaWelcomeMessage, getPersonaCaption };
};

export default usePersona;
