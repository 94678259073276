import { DriscollsSpinner, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { withOktaAuth } from '@okta/okta-react';
import FileUpload from 'components/report/FileUpLoad';
import { FOLDER_NAMES } from 'constants/foldernames';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import useGetApiTokens from 'hooks/useGetApiTokens';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useGetSupplementaryReports from 'hooks/useGetSupplementaryReports';
import { NAMESPACE } from 'i18n';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import DuDateUtilities from '../../utils/DuDateUtilities';
import styles from './Reports.module.scss';

const UploadReport = (props) => {
    const navigate = useNavigate();
    const fileUploader = useRef(null);
    const currentFile = useRef(0);
    const fileProgress = useRef([]);
    const { fetchSignedUrl, loading: fetchFileLoading } = useGetSignedUrl(props.oktaAuth);
    const { validateFiles, validateFileName, validateGrowers, loading, loadingMessage, updateSupplementaryReport } = useGetSupplementaryReports(
        props.oktaAuth
    );
    const [fileUploadStatus, setFileUploadStatus] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(true);
    const [viewReportDisabled, setViewReportDisabled] = useState(true);
    const { getTokenAndId } = useGetApiTokens();
    const { setShowToast } = useContext(ApplicationContext);
    const payload = [];

    const addFileStatus = (fileName: string, status: string) => {
        let fileItem = fileProgress.current.find((item) => {
            return item.fileName === fileName;
        });
        if (fileItem) {
            fileItem.status = status;
        } else {
            fileProgress.current.push({ fileName, status });
        }
        setFileUploadStatus([...fileProgress.current]);
    };

    const uploadFiles = async (checkGrowers = true) => {
        const growerNumbers = {
            GrowerNbr: fileUploadStatus.map((status) => {
                return status.growerNumber;
            })
        };
        //Validating the growers found from the attached file names
        const growerStatus = checkGrowers
            ? await validateGrowers(growerNumbers, getTextTranslated('Validating Grower Number', NAMESPACE.REPORT))
            : [];
        if (growerStatus.length === 0) {
            setUploadDisabled(true);
            const fileReader = new FileReader();
            const file = fileUploader.current[currentFile.current];
            fileReader.readAsDataURL(file.binary);
            fileReader.onload = (e) => {
                addFileStatus(file.name, 'uploading');
                fetchSignedUrl(file.binary, file.binary.type, file.name, FOLDER_NAMES.supplementary, completeUpload, false);
            };
        } else {
            //There are incorrect grower numbers
            const statusItem = fileUploadStatus.find((item) => {
                return growerStatus.includes(item.growerNumber);
            });
            statusItem.errorMessage = getTextTranslated('invalid grower number', NAMESPACE.REPORT);
            setFileUploadStatus([...fileUploadStatus]);
        }
    };

    const add = (listFiles) => {
        currentFile.current = 0;
        fileUploader.current = [...listFiles];
        const fileValidationStatus = validateFiles(fileUploader.current);
        setFileUploadStatus(fileValidationStatus);
        setViewReportDisabled(true);
        setUploadDisabled(
            listFiles.length === 0 ||
                fileValidationStatus.filter((item) => {
                    return item.errorMessage;
                }).length > 0
        );
    };

    const completeUpload = async (uploadId, file) => {
        addFileStatus(file.name, 'success');
        const { userEmail } = await getTokenAndId(props.oktaAuth);
        const FileName = file.name;
        const arrTypes = FileName.split('_');
        const ReportName = arrTypes[1];
        const GrowerNumber = arrTypes[0];
        const ReportDate = DuDateUtilities.getDateFrom8Digits(new Date());
        const FileId = uploadId;
        const CreatedBy = userEmail;
        payload.push({ FileName, ReportName, GrowerNumber, ReportDate, FileId, CreatedBy });
        if (currentFile.current < fileUploader.current.length - 1) {
            currentFile.current++;
            uploadFiles(false);
        } else {
            //Publishing the report to Grower DB
            const success = await updateSupplementaryReport(payload, getTextTranslated('Publishing Reports', NAMESPACE.REPORT));
            if (success) {
                setViewReportDisabled(false);
                setShowToast({ toastMessage: 'UploadSuccessful', nameSpacing: NAMESPACE.REPORT });
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'UploadFailed', nameSpacing: NAMESPACE.REPORT });
            }
        }
    };

    const { getTextTranslated } = useLang();
    if (loading && loadingMessage) {
        return <DriscollsSpinner text={loadingMessage} />;
    }

    const goBackHandler = () => {
        navigate(`/${ROUTES.REPORTS}`);
    };

    return (
        <DrcContainer heading={getTextTranslated('Upload Report', NAMESPACE.REPORT)} goBackHandler={goBackHandler}>
            <div className={styles.uploadContainer}>
                <section className={styles.uploadInnerContainer}>
                    <FileUpload
                        info={getTextTranslated('Upload Info', NAMESPACE.REPORT)}
                        filelistlabel={getTextTranslated('Upload Label', NAMESPACE.REPORT)}
                        validateFileName={validateFileName}
                        fileUploadStatus={fileUploadStatus}
                        accept=".pdf"
                        label={getTextTranslated('Browse For File', NAMESPACE.REPORT)}
                        multiple
                        updateFilesCb={(listFiles) => {
                            add(listFiles);
                        }}
                    />
                </section>
                <section>
                    <TranslatedDrcButton disabled={uploadDisabled} nameSpacing={NAMESPACE.REPORT} isPrimary onClick={() => uploadFiles()}>
                        {'Upload Report'}
                    </TranslatedDrcButton>
                    <TranslatedDrcButton
                        disabled={viewReportDisabled}
                        nameSpacing={NAMESPACE.REPORT}
                        isPrimary
                        onClick={() => navigate(`/${ROUTES.REPORTS}`)}
                    >
                        {'View Reports'}
                    </TranslatedDrcButton>
                </section>
            </div>
        </DrcContainer>
    );
};

export default withOktaAuth(UploadReport);
