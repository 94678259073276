import { AssignmentTypes, IInviteForms } from './DataSetupTypes';
import { PERSONAID } from './persona';
import { cognitoPersonaInfo, defaultContactFormField, driscollsPersonalInfo } from './personalinfo';

const {
    SUPERADMIN,
    REGIONALADMIN,
    HARVESTPLANNER,
    GROWERADMIN,
    RANCHPLANNER,
    QAADMIN,
    PALLETBUILDER,
    PTIAPP,
    GROWERUSER,
    PORTALUSER,
    SETTLEMENTUSER,
    QUALITYVIEWER,
    GEOWEATHER,
    REPORTBUILDER,
    PTIUSER,
    CLAMSCANNER
} = PERSONAID;

export const forms: IInviteForms[] = [
    {
        persona: SUPERADMIN,
        personalinformation: [...driscollsPersonalInfo, ...defaultContactFormField],
        assignments: [],
        reports: false,
        assignmentType: null
    },
    {
        persona: REGIONALADMIN,
        personalinformation: [...driscollsPersonalInfo, ...defaultContactFormField],
        assignments: [{ field: 'DistrictsAssigned', label: 'Districts', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.DISTRICTS
    },
    {
        persona: HARVESTPLANNER,
        personalinformation: driscollsPersonalInfo,
        assignments: [{ field: 'DistrictsAssigned', label: 'Districts', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.DISTRICTS
    },
    {
        persona: GROWERADMIN,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'DistrictsAssigned', label: 'Districts', type: 'multiselect' }],
        reports: true,
        assignmentType: AssignmentTypes.DISTRICT_COMPANY
    },
    {
        persona: RANCHPLANNER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: QAADMIN,
        personalinformation: driscollsPersonalInfo,
        assignments: [],
        reports: false,
        assignmentType: null
    },
    {
        persona: PALLETBUILDER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: PTIAPP,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: GROWERUSER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: true,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: PORTALUSER,
        personalinformation: driscollsPersonalInfo,
        assignments: [],
        reports: true,
        assignmentType: null
    },
    {
        persona: SETTLEMENTUSER,
        personalinformation: driscollsPersonalInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Companies', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: QUALITYVIEWER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: true,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    },
    {
        persona: GEOWEATHER,
        personalinformation: driscollsPersonalInfo,
        assignments: [],
        reports: false,
        assignmentType: null
    },
    {
        persona: REPORTBUILDER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'DistrictsAssigned', label: 'Districts', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.DISTRICT_COMPANY
    },
    {
        persona: PTIUSER,
        personalinformation: driscollsPersonalInfo,
        assignments: [{ field: 'DistrictsAssigned', label: 'Districts', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.DISTRICT_BERRIES
    },
    {
        persona: CLAMSCANNER,
        personalinformation: cognitoPersonaInfo,
        assignments: [{ field: 'CompaniesAssigned', label: 'Ranches', type: 'multiselect' }],
        reports: false,
        assignmentType: AssignmentTypes.COMPANY_RANCH
    }
];
