import { ArrowBack } from '@mui/icons-material';
import { Box, Container, Grid, IconButton } from '@mui/material';
import { NAMESPACE } from 'i18n';
import LabelInfo from 'pages/ptic/LabelInfo';
import DrcPrinterSetup from 'pages/ptic/PrinterSetup';
import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import ErrorBoundary from '../../components/shared/generic/ErrorBoundary';
import ApplicationContext from '../../context/ApplicationContext';
import { hideUrlKey } from '../../data/config/datasetup';
import useResponsive from '../../hooks/useResponsive';

function DrcContainer(props) {
    const { userPersona, userPersonas } = useContext(ApplicationContext);
    const currentUserPersona = (userPersonas || []).filter((item) => item.PersonaId === userPersona);
    const { pathname } = useLocation();
    const path = pathname.split('/');
    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'md');

    const goBack = () => {
        if (!props.goBackHandler) {
            navigate(-1);
        } else {
            props.goBackHandler();
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>GEP : {props.heading}</title>
            </Helmet>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    overflow: 'visible',
                    width: isDesktop ? 'calc(100% - 40px)' : '100%',
                    marginLeft: { md: '40px', sm: 0 },
                    background: 'white',
                    borderRadius: '20px',
                    minHeight: '80vh',
                    padding: isDesktop ? '24px 0 8px 56px' : '24px 0 8px 36px',
                    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                    ...props.style
                }}
            >
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: props.logo ? 'center' : 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {props.logo ? (
                                <img className={`${props.logoClassName || ''}`} src={props.logoImage} />
                            ) : (
                                <>
                                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="h4">
                                        {props.heading}
                                    </TypographyTranslated>
                                    {props.showHeaderIconButton && <LabelInfo />}
                                </>
                            )}
                        </Box>

                        <Box sx={{ display: props.hidePersona ? 'none' : 'flex', flexDirection: 'column' }}>
                            {currentUserPersona && currentUserPersona.length > 0 && (
                                <TypographyTranslated
                                    nameSpacing={NAMESPACE.COMMON}
                                    variant="h5"
                                    sx={{ color: 'secondary.main', mr: 3, mt: -2, opacity: 0.5, userSelect: 'none' }}
                                >
                                    {currentUserPersona[0]?.PersonaName}
                                </TypographyTranslated>
                            )}

                            {userPersonas && userPersonas.length > 1 && (
                                <TypographyTranslated
                                    nameSpacing={NAMESPACE.COMMON}
                                    variant="p"
                                    sx={{ fontSize: '8px', color: 'secondary.main', mr: 3, mt: -0.8, opacity: 0.5, userSelect: 'none' }}
                                >
                                    {'Multiple Personas Available'}
                                </TypographyTranslated>
                            )}
                        </Box>
                    </Box>
                    {path[1] === 'pti' ? (
                        <DrcPrinterSetup />
                    ) : (
                        !pathname.startsWith('/dashboard') && (
                            <Box sx={{ mb: 5, textTransform: 'capitalize', display: props.hideRoute ? 'none' : 'flex', alignItems: 'center' }}>
                                {path.length > 2 && (
                                    <IconButton onClick={() => goBack()}>
                                        <ArrowBack fontSize="small" />
                                    </IconButton>
                                )}
                                <TypographyTranslated noWrap>
                                    {[
                                        'edituser',
                                        'resolution',
                                        'view',
                                        'documents',
                                        'create-announcement',
                                        'createEvent',
                                        'invite',
                                        'search'
                                    ].includes(path[2])
                                        ? hideUrlKey({ path, startIndex: 1, endIndex: 2 })
                                        : hideUrlKey({ path, startIndex: 1 })}
                                </TypographyTranslated>
                            </Box>
                        )
                    )}
                </Box>
                <Grid container spacing={3} sx={{ padding: 2, ...props.gridStyle }}>
                    <ErrorBoundary>{props.children}</ErrorBoundary>
                    {props.footer ? (
                        <section className={`${props.footerStyle || ''}`}>
                            <img className={`${props.footerImageStyle || ''}`} src={props.footerImage} />{' '}
                        </section>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Container>
        </Fragment>
    );
}

export default DrcContainer;
