import { isValidPhoneNumber, nonDriscollsEmail } from 'utils/validations';
import { IPersonaInfoObj } from './DataSetupTypes';
import { language, titles } from './datasetup';

export const defaultContactFormField: IPersonaInfoObj[] = [
    {
        field: 'defaultContact',
        label: 'Default Contact',
        type: 'checkbox'
    }
];

export const driscollsPersonalInfo: Array<IPersonaInfoObj> = [
    {
        field: 'Login',
        label: 'Email',
        pattern: '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(driscolls.com|driscolls.eu)',
        type: 'driscollsemail',
        helpMessage: 'Should be a valid driscolls email'
    }
];

export const cognitoPersonaInfo: Array<IPersonaInfoObj> = [
    {
        field: 'firstName',
        label: 'First Name',
        type: 'textinput',
        pattern: '^^[a-zA-Zs]*$',
        helpMessage: 'Should not be empty and should contain only alphabets'
    },
    {
        field: 'lastName',
        label: 'Last Name',
        type: 'textinput',
        pattern: '^^[a-zA-Zs]*$',
        helpMessage: 'Should not be empty and should contain only alphabets'
    },
    {
        field: 'email',
        label: 'Email',
        type: 'email',
        validator: nonDriscollsEmail,
        pattern: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$',
        helpMessage: 'Should be a valid email and should not be empty'
    },
    {
        field: 'phone',
        label: 'Phone',
        type: 'phone',
        validator: isValidPhoneNumber,
        pattern: '^(?!\\s*$).+',
        helpMessage: 'Should not be empty'
    },
    { field: 'country', label: 'Country', type: 'select', data: [], pattern: '^(?!\\s*$).+', helpMessage: 'Should not be empty' },
    {
        field: 'gpaLang',
        label: 'Preferred Language',
        type: 'select',
        data: language,
        valueField: 'value',
        pattern: '^(?!\\s*$).+',
        helpMessage: 'Should not be empty'
    },
    { field: 'role', label: 'Role', type: 'select', data: titles, pattern: '^(?!\\s*$).+', helpMessage: 'Should not be empty' },
    { field: 'usernamefield', label: 'Default user', valueField: 'value', type: 'usernamechoice' }
];
