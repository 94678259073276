import { Box, Divider, Typography } from '@mui/material';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import React from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';
import charImage from '../../Images/Oh no-bro.svg';
import style from './AppInitializer.module.scss';

interface IFailedApiComponentProps {
    errorCount: number;
    totalCount: number;
    forceLoadApp: () => void;
}

const FailedApiComponent = (props: IFailedApiComponentProps) => {
    const { errorCount, totalCount, forceLoadApp } = props;
    return (
        <section className={style.failedApiBox}>
            <section className={style.insideBox}>
                <Divider />
                <Box component="img" src={charImage} className={style.image} />
                <TypographyTranslated align="center">{'ApiFailed'}</TypographyTranslated>
                <Typography align="center">{errorCount}</Typography>
                <Divider />
                <section className={`row ${style.flexBoxCenter}`}>
                    <section className="col-xs-8">
                        <TranslatedDrcButton
                            isPrimary
                            fullWidth
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            {'TryAgain'}
                        </TranslatedDrcButton>
                    </section>
                    <section className="col-xs-8">
                        <TranslatedDrcButton
                            isSecondary
                            fullWidth
                            onClick={() => {
                                forceLoadApp();
                            }}
                        >
                            {'ProceedToWebsite'}
                        </TranslatedDrcButton>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default FailedApiComponent;
