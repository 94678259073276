import React from 'react';

const DrcInformationBox = (props) => {
    return (
        <aside
            style={{
                border: props.hasError ? '1px solid #e906066b' : '1px solid #4A773C',
                borderRadius: '10px',
                padding: '10px',
                backgroundColor: props.hasError ? '#ed2c0826' : '#FBF6EA',
                ...props.style
            }}
        >
            {props.children}
        </aside>
    );
};

export default DrcInformationBox;
