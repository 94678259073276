import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import * as yup from 'yup';

function getEventFieldsValidationSchema(required) {
    const { getTextTranslated } = useLang();
    return yup.object({
        Name: yup.string().required(getTextTranslated(required, NAMESPACE.COMMON)),
        Description: yup.string(),
        StartDate: yup.date().required(getTextTranslated(required, NAMESPACE.COMMON)),
        EndDate: yup
            .date()
            .required(getTextTranslated(required, NAMESPACE.COMMON))
            .min(yup.ref('StartDate'), "End Date Time' should be after Start Date Time"),
        DistrictCode: yup.array().required(getTextTranslated(required, NAMESPACE.COMMON))
    });
}

export default getEventFieldsValidationSchema;
