import Avatar from '@mui/material/Avatar';
import React from 'react';
import style from './index.module.scss';

export interface IUser {
    firstName: string;
    lastName: string;
    avatarUrl?: string;
}

interface AvatarComponentProps {
    user: IUser;
    size?: 'small' | 'medium' | 'large';
}
/**
 *
 * The component takes care of the two options:
 * No src provide for the avatar
 * src provided for the avatar
 * In case we don't provide the avatar we show initials of firstName and lastName
 *
 */
const AvatarComponent = (props: AvatarComponentProps) => {
    const { user, size } = props;

    if (!user.avatarUrl || user?.avatarUrl !== '') {
        return (
            <Avatar className={`${size ? style[size] : style.medium}`}>
                <span>{`${user?.firstName?.charAt(0) ?? ''}${user?.lastName?.charAt(0) ?? ''}`}</span>
            </Avatar>
        );
    } else {
        return <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user?.avatarUrl} />;
    }
};

export default AvatarComponent;
