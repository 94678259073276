import { createContext } from 'react';

export interface IFormContext<T> {
    formFields:  T[] | object;
    errors: object;
    register: (name: string) => void ;
    validate: (name: string, focus?: boolean) => void;
    setValue: (name: string, value: any) => void;
}

export function createFormContext<T>() { 
    return createContext<IFormContext<T>>({} as IFormContext<T>);
}

const defaultFormContext = createFormContext<string[]>();
export default defaultFormContext;
