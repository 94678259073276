export const reportCategories = {
    Forecast: 'Forecast',
    Order: 'Order',
    Packaging: 'Packaging',
    ReceiptsAdjustedReceipt: 'ReceiptsAdjusted Receipt',
    Settlement: 'Settlement',
    Others: 'Others',
    Financials: 'Financials',
    QAReport: 'QAInspection'
};
