import { Upload } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import DrcDialog from 'components/shared/drc/DrcDialog';
import DrcInformationBox from 'components/shared/drc/DrcInformationBox';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useRef, useState } from 'react';
import DriscollsToolTip from 'ui/DriscollsToolTip/DriscollsToolTip';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import FilePreviewList from './FilePreviewList';
import styles from './Report.module.css';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const Icon = ({ name }) => <ImageComponent size={'small'} src={`/icons/sharepoint/${name}.svg`} brokenImage={`/icons/sharepoint/genericfile.svg`} />;

const FileUpload = ({
    info,
    filelistlabel,
    label,
    fileUploadStatus,
    updateFilesCb,
    validateFileName,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState([]);
    const [fileUnderEdit, setFileUnderEdit] = useState(null);
    const [errors, setErrors] = useState([]);
    const { getTextTranslated } = useLang();
    const handleCapture = ({ target }) => {
        const customFiles = [];
        for (const file of target.files) {
            customFiles.push({ name: file.name, binary: file });
        }
        setFiles([...customFiles]);
        updateFilesCb([...customFiles]);
    };

    const deleteItem = (fileName) => {
        const filteredFiles = files.filter((file) => {
            return file.name !== fileName;
        });
        setFiles([...filteredFiles]);
        updateFilesCb([...filteredFiles]);
    };

    const editItem = (fileName) => {
        setFileUnderEdit(fileName);
    };

    const updateFilename = () => {
        const { errorMessage } = validateFileName(fileUnderEdit);
        setErrors(errorMessage);
        const editingFile = files.find((file) => {
            return file.name !== fileUnderEdit;
        });
        if (!errorMessage) {
            editingFile.name = fileUnderEdit;
            setFiles([...files]);
            updateFilesCb([...files]);
        }
        setFileUnderEdit(null);
    };

    const dropHandler = (ev) => {
        ev.preventDefault();
        if (ev.dataTransfer.files) {
            handleCapture({ target: ev.dataTransfer });
        }
    };

    const dragOverHandler = (ev) => {
        ev.preventDefault();
    };

    return (
        <section className="styles.row">
            <section className={styles.dragArea} id="drop_zone" onDrop={(event) => dropHandler(event)} onDragOver={(event) => dragOverHandler(event)}>
                <DrcInformationBox>
                    <TypographyTranslated nameSpacing={NAMESPACE.REPORT}>{info}</TypographyTranslated>
                </DrcInformationBox>
                <div className="icon">
                    <Upload />
                </div>
                <header>{getTextTranslated('Drag and Upload File', NAMESPACE.REPORT)}</header>
                <TypographyTranslated nameSpacing={NAMESPACE.REPORT}>{'OR'}</TypographyTranslated>
                <Button className={styles.UploadButton} component="label">
                    <DriscollsToolTip
                        headingTitle="Accepted File Formats"
                        description="*.pdf"
                        arrow={false}
                        placement="bottom"
                        className={null}
                    ></DriscollsToolTip>
                    {label}
                    <input hidden onChange={handleCapture} type="file" ref={fileInputField} title="" value="" {...otherProps} />
                </Button>
            </section>
            <section className="styles.row">
                <FilePreviewList
                    filelistlabel={filelistlabel}
                    files={files}
                    fileUploadStatus={fileUploadStatus}
                    deleteItem={deleteItem}
                    editItem={editItem}
                />
            </section>
            <DrcDialog
                title={getTextTranslated('Edit File Name', NAMESPACE.REPORT)}
                open={fileUnderEdit !== null}
                isConfirmation={true}
                buttons={
                    <>
                        <TranslatedDrcButton onClick={() => setFileUnderEdit(null)} nameSpacing={NAMESPACE.COMMON}>
                            {'Cancel'}
                        </TranslatedDrcButton>
                        <TranslatedDrcButton onClick={() => updateFilename()} isPrimary nameSpacing={NAMESPACE.COMMON}>
                            {'Update'}
                        </TranslatedDrcButton>
                    </>
                }
            >
                <TextField
                    onChange={(e) => setFileUnderEdit(e.target.value)}
                    className={styles.editTextField}
                    value={fileUnderEdit}
                    label={getTextTranslated('Persona Type', NAMESPACE.USERS)}
                    variant="standard"
                />
                {errors?.length > 0 && <DrcInformationBox hasError>{errors}</DrcInformationBox>}
            </DrcDialog>
        </section>
    );
};

export default FileUpload;
