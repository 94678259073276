import DrcInformationBox from 'components/shared/drc/DrcInformationBox';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';

const AnnouncementValidation = ({ announcementHeader, expirationDate, recipients, richText }) => {
    return (
        <DrcInformationBox hasError>
            {!announcementHeader && (
                <p>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Announcement Header cannot be empty'}</TypographyTranslated>
                </p>
            )}
            {!expirationDate && (
                <p>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Expiration Date cannot be empty'}</TypographyTranslated>
                </p>
            )}
            {recipients && (
                <p>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Recipients cannot be empty'}</TypographyTranslated>
                </p>
            )}
            {!richText && (
                <p>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Content cannot be empty'}</TypographyTranslated>
                </p>
            )}
        </DrcInformationBox>
    );
};

export default AnnouncementValidation;
