import React, { useContext, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import ApplicationContext from 'context/ApplicationContext';
import useGetSupplementaryReports from 'hooks/useGetSupplementaryReports';
import { NAMESPACE } from 'i18n';
import { getAppliedFilters } from 'utils/helper';
import ReportGridContainer from '../../components/report/ReportGridContainer';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import GenericFilterSideBar from '../../layouts/dashboard/filters/GenericFilterSideBar';
import Filter from '../../ui/DrcFilters/DrcFilter';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { ReportType } from './ReportTypes';

const SupplementaryReports = (props) => {
    const { oktaAuth } = props;
    const { reportsList, pageCount, totalReports, fetchWithFilters, loading, fetch } = useGetSupplementaryReports(oktaAuth);
    const { filters } = useContext(ApplicationContext);
    const [page, setPage] = useState(1);
    const isDesktop = useResponsive('up', 'md');
    const [openFilter, setOpenFilter] = useState(false);

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchWithFilters(getAppliedFilters(filters), value);
    };

    const resetFilters = (event) => {
        fetchWithFilters('', 1);
        setOpenFilter(false);
        handlePageChange(event, 1);
    };

    const applyFilter = () => {
        fetchWithFilters(getAppliedFilters(filters), page);
    };

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const openFilterHandler = () => {
        setOpenFilter(!openFilter);
    };

    const { getTextTranslated } = useLang();

    useEffect(() => {
        fetch();
    }, []);

    return (
        <DrcContainer heading={getTextTranslated('Supplemental Statement Report', NAMESPACE.REPORT)}>
            <TranslatedDrcButton
                customBtnStyles={{ width: '100%', maxWidth: 'max-content', padding: '1rem' }}
                nameSpacing={NAMESPACE.COMMON}
                disableRipple
                color="inherit"
                endIcon={<Icon icon={openFilter ? 'mdi:toggle-switch' : 'mdi:toggle-switch-off'} />}
                onClick={openFilterHandler}
            >
                {'Advanced Search'}
            </TranslatedDrcButton>
            {isDesktop ? (
                <Grid container gap={2} sx={{ m: 2 }}>
                    <Filter
                        showFilter={openFilter}
                        applyFilter={applyFilter}
                        disabled={!getAppliedFilters(filters)}
                        resetFilter={resetFilters}
                        filterNames={['Grower Number']}
                    />
                </Grid>
            ) : (
                <>
                    <Box sx={{ display: 'flex', mb: 2, px: 1, width: '100%' }}>
                        <GenericFilterSideBar
                            openFilter={openFilter}
                            getData={applyFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                            filters={filters}
                            filterNames={['Grower Number']}
                            resetFilters={resetFilters}
                        />
                    </Box>
                </>
            )}
            <ReportGridContainer
                handlePageChange={handlePageChange}
                page={page}
                totalUsers={totalReports}
                loading={loading}
                reportList={reportsList}
                executionTime={undefined}
                pageCount={pageCount}
                reportType={ReportType.SupplementalStatementReport}
            />
        </DrcContainer>
    );
};

export default withOktaAuth(SupplementaryReports);
