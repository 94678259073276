import { Call } from '@driscollsinc/driscolls-display-rules';
import { IBerryType, IDistrictBerries } from 'components/appinitializer/IDistricts';
import { COMMODITY_LABEL, commodityObj } from 'constants/CommodityEnum';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the assignments for District Berries combination
 * {@link APIEndPoints.GET_DISTRICTS}
 *
 */

const useGetDistrictBerry = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<Array<IDistrictBerries> | []>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchDistrictAssignment = async (persona: number, userOktaId: string) => {
        setLoading(true);
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    url: APIEndPoints.GET_DISTRICTS(persona, userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const result = (data?.display || []).map((item) => {
                return {
                    DistrictName: item.DistrictName,
                    DistrictCode: item.DistrictCode,
                    DistrictId: item.DistrictId,
                    label: item.DistrictName + ' - ' + item.DistrictCode,
                    value: item.GrowingDistrictCode || item.DistrictCode,
                    BerryTypes: item.BerriesAssigned.map((berry: IBerryType) => ({
                        label: berry.Name,
                        value: commodityObj[berry.Name as COMMODITY_LABEL].code,
                        ...berry
                    }))
                };
            });
            setData(result ?? []);
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading(false);
        }
    };

    return { fetchDistrictAssignment, data, loading, error };
};
export default useGetDistrictBerry;
