import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Button, CardActions, CardContent, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import { withOktaAuth } from '@okta/okta-react';
import DeleteEventWarningModal from 'components/events/DeleteEventWarningModal';
import EventFieldsFormControl from 'components/events/EventFieldsFormControl';
import Loader from 'components/shared/Loader/Loader';
import ApplicationContext from 'context/ApplicationContext';
import { IFormContext } from 'context/FormContext';
import useDeleteEvent from 'hooks/useDeleteEvent';
import useFetchEvent from 'hooks/useFetchEvent';
import useLang from 'hooks/useLang';
import useUpdateEvent, { IUpdateEventPayload } from 'hooks/useUpdateEvent';
import { NAMESPACE } from 'i18n';
import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { viewsPerRoute } from 'routes/routesEnum';
import { getRouteByView } from 'routes/routesUtils';
import { AdjustTimeZonesFetchEvent } from 'utils/TimezoneUtil';
import { default as EventFormContext, IEventForm } from '../../context/EventFormContext';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import getValidationResolver from '../../utils/ValidationResolver';
import style from './CreateEvent.module.scss';
import getEventFieldsValidationSchema from './EventFieldsValidationSchema';

const EditEvent = (props) => {
    const { getTextTranslated } = useLang();
    const { oktaAuth } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        data: event,
        loading: loadingEvent,
        error: errorEvent
    } = AdjustTimeZonesFetchEvent(useFetchEvent(oktaAuth, id), ['StartDate', 'EndDate']);
    const [warningDialog, setWarningDialog] = useState(false);
    const { update } = useUpdateEvent(oktaAuth);
    const [loading, setLoading] = useState(false);
    const { del, loading: deleteLoading } = useDeleteEvent(oktaAuth);
    const REQUIRED_ERROR_MESSAGE = 'This field is required';
    const [formValues, setFormValues] = useState<IEventForm>({
        IsActive: '1',
        Name: '',
        Description: '',
        StartDate: '',
        EndDate: '',
        DistrictCode: []
    });
    const [refreshKey, setRefreshKey] = useState(0);
    const { setShowToast } = useContext(ApplicationContext);

    const validationSchema = getEventFieldsValidationSchema(REQUIRED_ERROR_MESSAGE);
    const resolver = getValidationResolver(validationSchema);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm({ resolver, mode: 'onBlur' });

    const validateField = (name, focus = false) => {
        trigger(name, { shouldFocus: focus });
    };

    const setValueAndValidate = (name, value) => {
        setValue(name, value);
        validateField(name);
    };

    const formMemo = useMemo<IFormContext<IEventForm>>(
        () => ({
            formFields: formValues,
            errors,
            register,
            setValue: setValueAndValidate,
            validate: validateField
        }),
        [formValues, errors]
    );

    const updateFormValues = (formData) => {
        setFormValues(formData);
    };

    const saveEvent = async () => {
        setLoading(true);
        const startDate = new Date(formValues.StartDate).toISOString();
        const endDate = new Date(formValues.EndDate).toISOString();
        const validatedEvent = {
            EventId: id,
            Name: formValues.Name,
            Description: formValues.Description,
            DistrictCode: formValues.DistrictCode,
            StartDate: startDate.substring(0, 10) + 'T' + startDate.substring(11, 19),
            EndDate: endDate.substring(0, 10) + 'T' + endDate.substring(11, 19)
        } as IUpdateEventPayload;

        const { error } = await update(validatedEvent);
        setLoading(false);
        if (!error) {
            navigate(getRouteByView(viewsPerRoute.ALL_EVENTS));
            setRefreshKey(refreshKey + 1);
            setShowToast({ toastMessage: 'Event edited successfully !!!' });
        } else {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something went wrong. Please try again !!!' });
        }
    };
    const deleteTheEvent = async () => {
        const { error } = await del({ EventId: id });
        setWarningDialog(false);
        if (!error) {
            navigate(getRouteByView(viewsPerRoute.ALL_EVENTS));
            setRefreshKey(refreshKey + 1);
            setShowToast({ toastMessage: 'Event deleted successfully !!!' });
        } else {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something went wrong. Please try again !!!' });
        }
    };
    return (
        <DrcContainer heading="Calendar">
            {loadingEvent && <Loader />}
            {!loadingEvent && (
                <div className={style.container}>
                    <EventFormContext.Provider value={formMemo}>
                        <form onSubmit={handleSubmit(saveEvent)}>
                            <Card>
                                <CardHeader
                                    title="Edit Event"
                                    subheader="Edit event name or description and save it"
                                    action={
                                        <Button
                                            title="Delete this event"
                                            color="error"
                                            onClick={() => {
                                                setWarningDialog(true);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    }
                                />
                                <CardContent className={style.content}>
                                    <EventFieldsFormControl
                                        key={refreshKey}
                                        onChange={updateFormValues}
                                        formData={formValues}
                                        initData={event}
                                        memo={formMemo}
                                    />
                                </CardContent>
                                <CardActions className={style.actions}>
                                    <DriscollsLoadingButton
                                        loading={loading}
                                        className={style.create}
                                        variant={ButtonVariant.PRIMARY}
                                        type="submit"
                                        onClick={handleSubmit(saveEvent)}
                                        disabled={!isValid}
                                        label={getTextTranslated('Save', NAMESPACE.COMMON)}
                                    />
                                    <DriscollsLoadingButton
                                        variant="outlined"
                                        label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                                        onClick={() => {
                                            navigate(getRouteByView(viewsPerRoute.ALL_EVENTS));
                                        }}
                                    />
                                </CardActions>
                            </Card>
                        </form>
                    </EventFormContext.Provider>
                </div>
            )}
            <DeleteEventWarningModal
                open={warningDialog}
                handleSubmit={deleteTheEvent}
                loading={deleteLoading}
                handleClose={() => {
                    setWarningDialog(false);
                }}
            />
        </DrcContainer>
    );
};

export default withOktaAuth(EditEvent);
