import { queryStatus } from '../../constants/options/options';
import { email, isValidPhoneNumber, lettersOnly, numeric } from '../../utils/validations';
import { IFilterField, priorityLabels, reportTypeLabels } from './DrcFilters.types';

const priority: priorityLabels = [
    { label: 'High', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'Low', value: 3 }
];

const reportType: reportTypeLabels = [
    { label: 'All', value: '' },
    { label: 'Daily Reports', value: 'DailyReport' },
    { label: 'Weekly Reports', value: 'WeeklyReport' }
];

export const filterFields: IFilterField[] = [
    {
        FieldName: 'FirstName',
        DisplayName: 'First Name',
        Datatype: 'string',
        QueryName: 'FirstName',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        validations: [lettersOnly],
        Options: []
    },
    {
        FieldName: 'LastName',
        DisplayName: 'Last Name',
        Datatype: 'string',
        QueryName: 'LastName',
        IsSearchable: true,
        hasOptions: false,
        validations: [lettersOnly],
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Email',
        DisplayName: 'Email',
        Datatype: 'string',
        QueryName: 'Email',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        validations: [email],
        Options: []
    },
    {
        FieldName: 'Persona',
        DisplayName: 'Persona',
        Datatype: 'string',
        QueryName: 'PersonaId',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Phone',
        DisplayName: 'Phone',
        Datatype: 'string',
        QueryName: 'Phone',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        isPhoneNumber: true,
        validations: [isValidPhoneNumber],
        Options: []
    },
    {
        FieldName: 'Lang',
        DisplayName: 'Lang',
        Datatype: 'string',
        QueryName: 'Lang',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'District',
        DisplayName: 'District',
        Datatype: 'string',
        QueryName: 'Districts',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Company',
        DisplayName: 'Company',
        Datatype: 'string',
        QueryName: 'Companies',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Ranch',
        DisplayName: 'Ranch',
        Datatype: 'string',
        QueryName: 'Ranch',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },

    {
        FieldName: 'IncidentNumber',
        DisplayName: 'Incident Number',
        Datatype: 'string',
        QueryName: 'RequestIdFilter',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Priority',
        DisplayName: 'Priority',
        Datatype: 'string',
        QueryName: 'PriorityFilter',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: priority
    },
    {
        FieldName: 'FileName',
        DisplayName: 'File Name',
        Datatype: 'string',
        QueryName: 'FileName',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Status',
        DisplayName: 'Status',
        Datatype: 'string',
        QueryName: 'StatusFilter',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: queryStatus
    },
    {
        FieldName: 'Subject',
        DisplayName: 'Subject',
        Datatype: 'string',
        QueryName: 'RequestHeaderFilter',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Report Type',
        DisplayName: 'Report Type',
        Datatype: 'string',
        QueryName: 'reporttype',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: reportType
    },
    {
        FieldName: 'AnnouncementId',
        DisplayName: 'Announcement Id',
        Datatype: 'integer',
        QueryName: 'AnnouncementId',
        IsSearchable: true,
        hasOptions: false,
        isMulti: false,
        validations: [numeric],
        Options: []
    },
    {
        FieldName: 'Target',
        DisplayName: 'Target',
        Datatype: 'string',
        QueryName: 'Target',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Grower Number',
        DisplayName: 'Grower Number',
        Datatype: 'string',
        QueryName: 'growernbr',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'Ranch Number',
        DisplayName: 'Ranch Number',
        Datatype: 'string',
        QueryName: 'ranchnbr',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    }
];
