import { Card } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React from 'react';
import BLACK from '../Images/Black_Medium.png';
import BLUE from '../Images/Blue_Medium.png';
import RASP from '../Images/Rasp_Medium.png';
import STRAW from '../Images/Straw_Medium.png';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

const CustomGridOverlay = ({ title, subheader, list, loadingMessage }) => {
    return (
        <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '300px', height: '200px', display: 'flex', flexDirection: 'column' }}>
                <div className="balls-loader">
                    <div className="balls-loader-container">
                        <div className="ball ball0">
                            <div>
                                <img src={BLACK} />
                            </div>
                        </div>
                        <div className="ball ball1">
                            <div>
                                <img src={BLUE} />
                            </div>
                        </div>
                        <div className="ball ball2">
                            <div>
                                <img src={RASP} />
                            </div>
                        </div>
                        <div className="ball ball3">
                            <div>
                                <img src={STRAW} />
                            </div>
                        </div>
                    </div>
                </div>

                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h5" gutterBottom>
                    {loadingMessage}
                </TypographyTranslated>
            </div>
        </Card>
    );
};

export default CustomGridOverlay;
