/* eslint-disable react-hooks/exhaustive-deps */
import { IHarvestOrderList, Manual, PrintOrderProvider, Reprint, SearchByRanch, syncTranslationPti } from '@driscollsinc/pti-module-ui';
import { DriscollsTabs } from '@driscollsinc/style-guide-web2.0';
import { Container } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { isPersonaIsOKTA } from 'data/config/datasetup';
import useGetAllRanches from 'hooks/useGetAllRanches';
import usePTIService from 'hooks/usePTIService';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PERSONAID } from 'data/config/persona';
import useLang, { getTxtTranslated } from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import PrintTabProtection from './PrintTabProtection';

function Print() {
    const { oktaAuth } = useOktaAuth();
    const { userPersona, userProfile } = useContext(ApplicationContext);
    const isOktaUser = isPersonaIsOKTA(userPersona);
    const allRanches = useGetAllRanches();
    const {
        getProducingAreas,
        producingAreas,
        getWarehouses,
        warehouses,
        getBerryType,
        berryTypes,
        getGrowers,
        growers,
        ranches,
        getRanches,
        getHarvestOrders,
        harvestOrders,
        setHarvestOrders,
        manualItems,
        getManualItems,
        ranchDetails,
        getRanchDetailsByRanch,
        printManualHarvestOrder,
        printHarvestOrder,
        ptiAuditDetails,
        getPrintAuditDetails,
        loading,
        formLoading,
        error
    } = usePTIService(oktaAuth);
    const navigate = useNavigate();
    const ptiParam = useParams();
    const ptiSeparatorPath = `${ROUTES.SEPARATOR}${ROUTES.PTI}${ROUTES.SEPARATOR}`;
    const { getCurrentLanguageSelected } = useLang();

    useEffect(() => {
        syncTranslationPti(getCurrentLanguageSelected());
    }, [getCurrentLanguageSelected]);

    const userInfo = {
        personaId: userPersona,
        userOktaId: userProfile.id,
        username: userProfile.profile.email,
        isOktaUser: isOktaUser
    };

    const ptiTabs = [
        {
            label: getTxtTranslated('Search by ranch', NAMESPACE.PTI),
            content: (
                <PrintTabProtection
                    personasAllowed={[Persona.SUPER_ADMIN, Persona.PTI_APP, Persona.PTI_USER]}
                    component={
                        <PrintOrderProvider>
                            <SearchByRanch
                                personalId={userPersona}
                                getProducingAreas={getProducingAreas}
                                getBerryType={getBerryType}
                                getGrowers={getGrowers}
                                getRanches={getRanches}
                                getWarehouses={getWarehouses}
                                getHarvestOrders={getHarvestOrders}
                                printHarvestOrder={printHarvestOrder}
                                producingAreas={producingAreas}
                                berryTypes={berryTypes}
                                growers={growers}
                                ranches={isOktaUser ? ranches : allRanches}
                                warehouses={warehouses}
                                harvestOrders={harvestOrders}
                                userInfo={userInfo}
                                loading={loading}
                                formLoading={formLoading}
                            ></SearchByRanch>
                        </PrintOrderProvider>
                    }
                />
            )
        },
        {
            label: getTxtTranslated('Reprint', NAMESPACE.PTI),
            content: (
                <PrintTabProtection
                    personasAllowed={[Persona.SUPER_ADMIN, Persona.PTI_APP, Persona.PTI_USER]}
                    component={
                        <PrintOrderProvider>
                            <Reprint
                                printHarvestOrder={printHarvestOrder}
                                ptiAuditDetails={ptiAuditDetails}
                                personaId={userPersona}
                                useroktaid={userProfile.id}
                                cognitoUser={!isOktaUser}
                                getPrintAuditDetails={getPrintAuditDetails}
                                userInfo={userInfo}
                                loading={loading}
                            />
                        </PrintOrderProvider>
                    }
                />
            )
        },
        {
            label: getTxtTranslated('Manual', NAMESPACE.PTI),
            hideTab: !isOktaUser,
            content: (
                <PrintTabProtection
                    personasAllowed={[Persona.SUPER_ADMIN, Persona.PTI_USER]}
                    component={
                        <PrintOrderProvider>
                            <Manual
                                userInfo={userInfo}
                                manualItems={manualItems}
                                getManualItems={getManualItems}
                                ranchDetails={ranchDetails}
                                ranches={userPersona === PERSONAID.SUPERADMIN ? allRanches : ranches}
                                getRanches={getRanches}
                                getRanchDetailsByRanch={getRanchDetailsByRanch}
                                warehouses={warehouses}
                                getWarehouses={getWarehouses}
                                printManualHarvestOrder={printManualHarvestOrder}
                                loading={loading}
                            />
                        </PrintOrderProvider>
                    }
                />
            )
        }
    ];

    useEffect(() => {
        handleTabChange(getPtiIndex());
    }, []);

    const getPtiIndex = () => {
        const { ptiTab } = ptiParam;
        switch (ptiTab) {
            case ROUTES.SEARCHBYRANCH:
                return 0;
            case ROUTES.REPRINT:
                return 1;
            case ROUTES.MANUAL:
                return 2;
            default:
                return 0;
        }
    };

    const handleTabChange = (index: number) => {
        setHarvestOrders({} as IHarvestOrderList); // reset list when tab changes
        switch (index) {
            case 0:
                navigate(`${ptiSeparatorPath}${ROUTES.SEARCHBYRANCH}`);
                break;
            case 1:
                navigate(`${ptiSeparatorPath}${ROUTES.REPRINT}`);
                break;
            case 2:
                navigate(`${ptiSeparatorPath}${ROUTES.MANUAL}`);
                break;
            default:
                0;
        }
    };

    return (
        <DrcContainer heading="Print Labels" showHeaderIconButton>
            <Container>
                <DriscollsTabs tabs={ptiTabs} activeTab={getPtiIndex()} onTabChange={handleTabChange} />
            </Container>
        </DrcContainer>
    );
}

export default Print;
