import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useResponsive from '../../../hooks/useResponsive';

const GenericSearchBox = (props) => {
    const { value, setValue, onEnterPress, style, className } = props;
    const isDesktop = useResponsive('up', 'md');
    const { getTextTranslated } = useLang();

    return (
        <TextField
            className={className}
            sx={{
                '& .MuiInputBase-root': {
                    height: '36px',
                    ...style
                }
            }}
            fullWidth={!isDesktop}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                )
            }}
            placeholder={getTextTranslated('Search', NAMESPACE.COMMON)}
            name="searchInput"
            value={value}
            onKeyUp={(e) => {
                if (e.key === 'Enter') onEnterPress();
            }}
            onChange={(e) => setValue(e.target.value)}
        />
    );
};

export default GenericSearchBox;
