import { ROUTES } from 'constants/routes';
import { viewsPerRoute } from './routesEnum';

const getRoutePath = (route: string, param?: string, queryParam?: string) => {
    switch (route) {
        case ROUTES.USER_MANAGEMENT:
            return `${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`;
        case ROUTES.REPORTS:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}`;
        case ROUTES.VIEW_WEEKLY_REPORT:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.ROOT}${ROUTES.VIEW_WEEKLY_REPORT}`;
        case ROUTES.VIEW_GROWER_SETTLEMENT_REPORT:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.ROOT}${ROUTES.VIEW_GROWER_SETTLEMENT_REPORT}`;
        case ROUTES.DOCUMENT_LIBRARY:
            return `${ROUTES.ROOT}${ROUTES.DOCUMENT_LIBRARY}${ROUTES.SEPARATOR}${ROUTES.LATEST}`;
        case ROUTES.SUPPORT:
            return `${ROUTES.ROOT}${ROUTES.SUPPORT}`;
        case ROUTES.ANNOUNCEMENT:
            return `${ROUTES.ROOT}${ROUTES.ANNOUNCEMENT}`;
        case ROUTES.EVENTSVIEWDETAILS:
            return `${ROUTES.ROOT}${ROUTES.EVENTSVIEWDETAILS}${param}`;
        case ROUTES.EVENTSVIEWALL:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}`;
        case ROUTES.EVENTSCREATE:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}${ROUTES.SEPARATOR}${ROUTES.EVENTSCREATE}`;
        case ROUTES.EVENT_DETAILS:
            return `${ROUTES.SEPARATOR}:id${ROUTES.SEPARATOR}${ROUTES.EVENT_DETAILS}`;
        case ROUTES.EVENT_EDIT:
            return `${ROUTES.SEPARATOR}:id${ROUTES.SEPARATOR}${ROUTES.EVENT_EDIT}`;
        case ROUTES.USER_ASSIGNMENT:
            return `${ROUTES.ROOT}${ROUTES.ASSIGNMENT_ENTITY}${param}`;
        case ROUTES.WEATHER:
            return `${ROUTES.ROOT}${ROUTES.WEATHER}`;
        default:
            return ROUTES.ROOT;
    }
};

export const getReportPath = (report: string) =>
    [
        ROUTES.VIEW_GROWER_SETTLEMENT_REPORT,
        ROUTES.VIEW_WEEKLY_REPORT,
        ROUTES.VIEW_SUPPLEMENTAL_STATEMENT_REPORT,
        ROUTES.VIEW_CANADA_MANUAL_TAX_REPORT
    ].includes(report)
        ? `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${report}`
        : `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${report}`;

export const getCompiledRoute = (route: string, id: string) => {
    switch (route) {
        case ROUTES.EVENT_DETAILS:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}${ROUTES.SEPARATOR}${id}${ROUTES.SEPARATOR}${ROUTES.EVENT_DETAILS}`;
        case ROUTES.DOWNLOAD_REPORT_FILE:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW_WEEKLY_REPORT}${ROUTES.SEPARATOR}${id}`;
        default:
            return `${ROUTES.ROOT}${ROUTES.DASHBOARD}`;
    }
};
export const getRouteByView = (viewName: viewsPerRoute, id?: string) => {
    switch (viewName) {
        case viewsPerRoute.EVENT_CREATE:
        case ROUTES.EVENTSCREATE:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}${ROUTES.SEPARATOR}${ROUTES.EVENTSCREATE}`;
        case viewsPerRoute.EVENT_EDIT:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}${ROUTES.SEPARATOR}${id}${ROUTES.SEPARATOR}${ROUTES.EVENT_EDIT}`;
        case viewsPerRoute.EVENT_DETAILS:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}${ROUTES.SEPARATOR}${id}${ROUTES.SEPARATOR}${ROUTES.EVENT_DETAILS}`;
        case viewsPerRoute.ALL_EVENTS:
            return `${ROUTES.ROOT}${ROUTES.EVENTS}`;
        case viewsPerRoute.NON_MARKETABLE_REPORT:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${id}`;
        case viewsPerRoute.PACKAGING_ISSUANCE:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${id}`;
        case viewsPerRoute.SWEET_SPOT_DAILY:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${id}`;
        case viewsPerRoute.SWEET_SPOT_WEEKLY:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${id}`;
        case viewsPerRoute.RECEIPTS:
            return `${ROUTES.ROOT}${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.VIEW}${ROUTES.SEPARATOR}${id}`;
        default:
            return `${ROUTES.ROOT}${ROUTES.DASHBOARD}`;
    }
};

export default getRoutePath;
