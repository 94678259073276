import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { isPersonaAllowedInTheApp } from 'data/config/datasetup';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import TranslatedDrcButton from 'ui/translatedButton/TranslatedDrcButton';

const PersonaListItem = ({ personaId, personaName, allowed, makeDefaultPersona }) => {
    const { getTextTranslated } = useLang();
    return (
        <>
            <ListItem
                key={personaId}
                secondaryAction={
                    isPersonaAllowedInTheApp(personaId) ? (
                        <TranslatedDrcButton
                            onClick={() => {
                                makeDefaultPersona(personaId);
                            }}
                        >
                            {'MakeDefault'}
                        </TranslatedDrcButton>
                    ) : null
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <AccountCircleIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={personaName}
                    secondary={allowed ? getTextTranslated('Supported', NAMESPACE.MAIN) : getTextTranslated('Not Supported', NAMESPACE.MAIN)}
                />
            </ListItem>
        </>
    );
};

export default PersonaListItem;
