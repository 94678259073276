import BerryConnectProvider from 'context/BerryConnectProvider';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BerryConnect from './BerryConnect';

const BerryConnectRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <BerryConnectProvider>
                        <BerryConnect />
                    </BerryConnectProvider>
                }
            />
        </Routes>
    );
};

export default BerryConnectRoutes;
