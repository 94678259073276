import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import React, { ReactNode } from 'react';

interface IPrintTabProtection {
    personasAllowed: Array<Persona>;
    component: ReactNode;
}

const PrintTabProtection = (props: IPrintTabProtection) => {
    return (
        <PersonaBaseProtect personasAllowed={props.personasAllowed} forbiddenComponent={<DrcPageNotFound />}>
            {props.component}
        </PersonaBaseProtect>
    );
};

export default PrintTabProtection;
