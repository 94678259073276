import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of grower settlement reports
 * {@link APIEndPoints.GET_GROWER_SETTLEMENT_REPORTS}
 *
 */

const useGetGrowerSettlementReport = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [reportsList, setReportList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);
    const ITEMS_PER_PAGE = 50;

    const fetch = async (filters = '', page = 1) => {
        setReportList([]);
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const currentPage = page === 1 ? 0 : (page - 1) * ITEMS_PER_PAGE + 1;
        try {
            const data = await Call(
                {
                    name: 'GrowerSettlementReport',
                    url: APIEndPoints.GET_GROWER_SETTLEMENT_REPORTS(userOktaId, currentPage, ITEMS_PER_PAGE, filters),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
                return console.log(data.errors[0]);
            }
            const results = data.display;
            const totalResults = results.TotalCount;
            setPageCount(Math.round(totalResults / ITEMS_PER_PAGE));
            setTotalReports(totalResults);
            if (results.Data) {
                setReportList(results.Data);
            } else {
                setReportList([]);
            }
        } catch (err) {
            setError(err);
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'An error occurred while fetching reports. Please try again',
                nameSpacing: NAMESPACE.REPORT
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetchWithFilters = (filters: string, page: number) => {
        fetch(filters, page);
    };

    return { loading, reportsList, pageCount, totalReports, error, fetchWithFilters };
};
export default useGetGrowerSettlementReport;
