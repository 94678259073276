import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { DriscollsButton, DriscollsSpinner, DriscollsToast, DriscollsToastPosition, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Icon } from '@iconify/react';
import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { NAMESPACE } from 'i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import charImage from '../../../Images/No data-bro.svg';
import useLang from '../../../hooks/useLang';
import TranslatedDrcButton from '../../../ui/translatedButton/TranslatedDrcButton';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import styles from './DrcDataGrid.module.scss';
import DrcToolTip from './DrcToolTip';
import CustomHeader from './datagridheader/CustomHeader';
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([InfiniteRowModelModule]);

/**
 *
 *
 * @description Custom data grid based on ag grid community edition.
 * @class DrcDataGrid
 * @extends {Component}
 * @property {*} rows list of data items
 * @property {*} columns column definition
 * @example ''
 */

export const SearchResultTime = ({ page, total, executionTime }) => {
    if (total > 0)
        return (
            <div className={styles.resultTime}>
                {page > 1 ? `Page ${page} of ` : ''}
                {total.toLocaleString()} <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'results'}</TypographyTranslated> {executionTime}
                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'seconds'}</TypographyTranslated>
            </div>
        );
};

interface IDrcDataGridProps extends AgGridReactProps {
    rows?: Array<any>;
    columns?: Array<any>;
    loading?: boolean;
    pageCount?: number;
    handlePageChange?: () => void;
    page?: number;
    loadingMessage?: string;
    totalUsers?: number;
    executionTime?: number | string;
    sizeColumnsToFit?: any;
    paginationPageSize?: any;
    allowExport?: boolean;
    exportExcelHandler?: any;
    gridReady?: any;
    isPaginated?: boolean;
    skipToken?: string | undefined;
    handleLoadMore?: () => void;
    disableLoadMore?: boolean;
}

const DrcDataGrid = (props: IDrcDataGridProps) => {
    const {
        rows,
        columns,
        loading,
        pageCount,
        handlePageChange,
        page,
        loadingMessage,
        totalUsers,
        executionTime,
        sizeColumnsToFit,
        paginationPageSize,
        allowExport,
        exportExcelHandler,
        gridReady,
        isPaginated,
        skipToken,
        handleLoadMore,
        disableLoadMore
    } = props;
    const gridRef = useRef<any | null>();
    const theme = useTheme();
    const gridStyle = useMemo(
        () => ({
            height: '500px',
            width: '100%',
            '--ag-alpine-active-color': theme?.palette?.primary?.light,
            '--ag-borders': 'none',
            '--ag-border-color': '#fff',
            '--ag-header-height': 48,
            '--ag-header-foreground-color': '#000000',
            '--ag-header-background-color': '#f4f6f8',
            '--ag-header-cell-hover-background-color': '#80a077',
            '--ag-header-cell-moving-background-color': theme?.palette?.grey[600],
            '--ag-row-height': 48,
            '--ag-selected-row-background-color': theme?.palette?.grey[200],
            '--ag-row-hover-color': theme?.palette?.grey[200],
            '--ag-borders-row': 'dashed 1px',
            '--ag-row-border-color': theme?.palette?.grey[300],
            '--ag-range-selection-border-color': theme?.palette?.primary.light,
            '--ag-range-selection-background-color': theme?.palette?.grey[300]
        }),
        []
    );

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState(columns);
    const { getTextTranslated } = useLang();
    const [showToast, setShowToast] = useState<boolean>(false);

    useEffect(() => {
        setRowData(rows);
    }, [rows]);

    useEffect(() => {
        gridRef?.current?.api?.setColumnDefs(columns);
        if (sizeColumnsToFit) {
            gridRef.current.api?.sizeColumnsToFit();
        }
    }, [columns]);

    useEffect(() => {
        if (loading) {
            gridRef.current.api?.showLoadingOverlay();
        } else {
            if (rows.length > 0) {
                gridRef.current.api?.hideOverlay();
            } else {
                gridRef.current.api?.showNoRowsOverlay();
            }
        }
    }, [loading]);

    const onFirstDataRendered = useCallback((params) => {
        if (sizeColumnsToFit) {
            gridRef.current.api.sizeColumnsToFit();
        }
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            // filter: true,
            resizable: true,
            tooltipComponent: DrcToolTip,
            suppressMovable: true,
            headerComponentParams: { menuIcon: 'ag-icon-menu' }
        };
    }, []);

    const loadingOverlayComponent = useMemo(() => {
        return DriscollsSpinner;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            text: loadingMessage || `${getTextTranslated('Loading Data', NAMESPACE.EVENTS)}`
        };
    }, []);

    const noRowsOverLayTemplate = useMemo(() => {
        return (
            '<div style="width: 200px; border-radius: 8px; transform: none;"><img alt="no result image" class="MuiBox-root css-1z13ce1" src="' +
            charImage +
            '">' +
            `${getTextTranslated('Sorry, No results were found for the search.', NAMESPACE.EVENTS)}` +
            '</div>'
        );
    }, []);

    const onGridReady = useCallback((params) => {
        gridReady && gridReady();
        return;
    }, []);

    const onPaginationChanged = useCallback(() => {
        return;
    }, []);

    const onLoadMore = () => {
        skipToken === '' || skipToken === null ? setShowToast(true) : handleLoadMore();
    };

    return (
        <div>
            <div className="ag-theme-alpine" style={gridStyle}>
                {allowExport && (
                    <div className={styles.toolbarContainer}>
                        <TranslatedDrcButton
                            nameSpacing={NAMESPACE.COMMON}
                            onClick={exportExcelHandler}
                            endIcon={<Icon icon="material-symbols:export-notes-outline" />}
                        >
                            {'Export'}
                        </TranslatedDrcButton>
                    </div>
                )}
                <AgGridReact
                    onPaginationChanged={onPaginationChanged}
                    onGridReady={onGridReady}
                    suppressMenuHide={true}
                    frameworkComponents={{ agColumnHeader: CustomHeader }}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    rowHeight={48}
                    ref={gridRef}
                    rowData={rowData}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onFirstDataRendered}
                    loadingOverlayComponent={loadingOverlayComponent}
                    overlayNoRowsTemplate={noRowsOverLayTemplate}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                    rowBuffer={20}
                    pagination={isPaginated || true}
                    paginationPageSize={paginationPageSize || 50}
                    suppressPaginationPanel={true}
                    suppressScrollOnNewData={true}
                    tooltipShowDelay={1000}
                    tooltipMouseTrack={true}
                ></AgGridReact>
            </div>
            <div className={`${styles.footerContainer} ${allowExport ? styles.allowExport : ''} `}>
                {showToast && (
                    <DriscollsToast
                        severity={DriscollsToastSeverity.INFO}
                        message={getTextTranslated('No more records', NAMESPACE.COMMON)}
                        position={DriscollsToastPosition.BOTTOMRIGHT}
                        open={true}
                        autoHideDuration={6000}
                        onClose={() => {
                            setShowToast(false);
                        }}
                    />
                )}
                {useLocation().pathname.includes('/documents') && rowData.length !== 0 ? (
                    <DriscollsButton disabled={disableLoadMore} className={styles.loadMoreButton} onClick={onLoadMore}>
                        {getTextTranslated('Load More', NAMESPACE.COMMON)}
                    </DriscollsButton>
                ) : (
                    ''
                )}
                {executionTime && <SearchResultTime page={page} total={totalUsers} executionTime={executionTime} />}
                {pageCount > 1 ? (
                    <Pagination sx={{ width: 430 }} count={pageCount} page={page} onChange={handlePageChange} showFirstButton showLastButton />
                ) : null}
            </div>
        </div>
    );
};

export default DrcDataGrid;
