import { ButtonVariant, DriscollsLoadingButton } from '@driscollsinc/style-guide-web2.0';
import { AttachFile } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress } from '@mui/material';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import classes from '../../pages/announcement/announcement.module.scss';
import DriscollsToolTip from '../../ui/DriscollsToolTip/DriscollsToolTip';
import ExpiryDatePicker from './ExpiryDatePicker';

const AnnouncementCreateFooter = ({
    expirationDate,
    setExpirationDate,
    fileDetails,
    deleteAttachment,
    deleteAttachmentLoading,
    handleCapture,
    sendAnnouncement,
    loading,
    fetchFileLoading,
    disabledSendAnnouncement
}) => {
    const { getTextTranslated } = useLang();

    return (
        <Box className={classes.footerContainer}>
            <ExpiryDatePicker onChange={(newValue: Date) => setExpirationDate(newValue)} value={expirationDate} />
            <Box className={classes.composeFooter}>
                <DriscollsLoadingButton
                    variant={ButtonVariant.PRIMARY}
                    loading={loading}
                    onClick={sendAnnouncement}
                    disabled={!disabledSendAnnouncement}
                    label={getTextTranslated('Send', NAMESPACE.COMMON)}
                />

                <Box>
                    {deleteAttachmentLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        fileDetails.length > 0 &&
                        fileDetails.map((file) => (
                            <Chip
                                key={file.attachmentId}
                                variant="outlined"
                                label={file.fileName}
                                onDelete={() => deleteAttachment(file.attachmentId)}
                            />
                        ))
                    )}
                    {showAttachmentFeature() === true && fetchFileLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Button component="label">
                            <DriscollsToolTip
                                headingTitle="Accepted File Formats"
                                description="*.jpg, *.png, *.pdf, *.mp4"
                                arrow={false}
                                placement="bottom"
                                className={null}
                            >
                                <AttachFile />
                            </DriscollsToolTip>
                            <input hidden onChange={handleCapture} type="file" accept="image/png, application/pdf, image/jpg, video/mp4" />
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AnnouncementCreateFooter;
