import { Box } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import charImage from '../../../Images/Under construction-amico.svg';
function UnderConstruction() {
    const springs = useSpring({
        from: { x: -100 },
        to: { x: 0 }
    });
    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <animated.div
                style={{
                    width: 400,
                    borderRadius: 8,
                    ...springs
                }}
            >
                <Box component="img" src={charImage} sx={{ width: 400 }} />
            </animated.div>
        </div>
    );
}

export default UnderConstruction;
