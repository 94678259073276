import { Typography } from '@mui/material';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import React, { useContext } from 'react';
import usePersona from 'services/personas/usePersona';
import ImageComponent from '../../shared/ImageComponent/ImageComponent';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DashboardSection from '../Section';
import style from './WelcomeComponent.module.scss';

interface WelcomeComponentProps {
    message: React.ReactNode;
    persona: number;
    classNameContainer?: string;
}

const WelcomeComponent = ({ message, persona, classNameContainer }: WelcomeComponentProps) => {
    const {
        userProfile: {
            profile: { displayName }
        }
    } = useContext(ApplicationContext);

    const { getPersonaSrcImage, getPersonaCaption } = usePersona(persona);

    return (
        <DashboardSection classNameContainer={classNameContainer}>
            <div style={{ display: 'flex' }}>
                <TypographyTranslated variant="h5" nameSpacing={NAMESPACE.DASHBOARD}>
                    {'Welcome'}
                </TypographyTranslated>
                &nbsp;
                <Typography variant="h5">{displayName}</Typography>
            </div>
            <div className={style.infoContainer}>
                <ImageComponent caption={getPersonaCaption()} src={getPersonaSrcImage()} size="large" />
                <TypographyTranslated variant="body1" nameSpacing={NAMESPACE.DASHBOARD}>
                    {message}
                </TypographyTranslated>
            </div>
        </DashboardSection>
    );
};

export default WelcomeComponent;
