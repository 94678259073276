import FeaturesName from 'constants/featuresEnum';
import { FeatureType } from 'types/application';

export const isFeatureActive = (featuresInContext: Array<FeatureType>, featureName: FeaturesName) => {
    let active = false;
    featuresInContext.forEach((feature) => {
        if (feature.FeatureName === (featureName as string) && feature.IsActive) {
            active = true;
            return;
        }
    });
    return active;
};

export const canWriteInFeature = (featuresInContext: Array<FeatureType>, featureName: FeaturesName) => {
    return false;
};
