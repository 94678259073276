import React from 'react';

import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from '@mui/material';

import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import Form from 'react-validation/build/form';
import { AnyObject } from 'yup';
import DrcPhoneInput from '../../components/shared/drc/DrcPhoneInput';
import GEPFormInput from '../../components/shared/generic/GEPFormInput';
import { IFilterItemProps } from './DrcFilters.types';
import style from './FilterItem.module.scss';

const FilterItem = (props: IFilterItemProps): JSX.Element => {
    const { filterSelect, filter, handleValidationDetected, applyFilter } = props;
    const {
        displayName,
        fieldName,
        hasOptions,
        isAsync,
        isDate,
        isBerry,
        isBool,
        isPhoneNumber,
        value,
        validations,
        isCheckbox,
        optionValue,
        options,
        optionLabel
    } = filter;

    const { getTextTranslated } = useLang();
    const formLabel = getTextTranslated(`${displayName || fieldName}`, NAMESPACE.EVENTS);

    const inputHandler = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
        filterSelect(name, typeof event === 'string' ? '+' + event : event.target.value);

    const selectHandler = (name: string) => (_event: React.ChangeEvent<AnyObject>, value: AnyObject) => {
        if (value) {
            filterSelect(name, value.value);
        } else {
            filterSelect(name, null);
        }
    };

    const generateOptionsHandler = (options: AnyObject[], label: string, value: string) => {
        return options.map((e) => ({
            ...e,
            label: e[label],
            value: e[value]
        }));
    };

    const checkboxHandler = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        filterSelect(name, event.target.value, event.target.checked);
    };

    const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            applyFilter();
        }
    };

    return (
        <div style={{ display: 'flex' }} id="filterItem">
            <div className={style.filterItemContainer}>
                {!hasOptions && !isAsync && !isDate && !isBool && !isPhoneNumber && (
                    <Form style={{ width: '100%' }}>
                        <GEPFormInput
                            label={formLabel}
                            style={{ marginTop: '11px' }}
                            onChange={inputHandler(fieldName)}
                            onKeyPress={keyPressHandler}
                            value={value}
                            {...filter.customProps}
                            validations={validations}
                            validationDetected={(validate) => handleValidationDetected(validate, fieldName)}
                        />
                    </Form>
                )}
                {hasOptions && !isBerry && !isCheckbox && !isPhoneNumber && (
                    <FormControl sx={{ width: 223 }}>
                        <Autocomplete
                            onChange={selectHandler(fieldName)}
                            id="form-auto-complete"
                            options={generateOptionsHandler(options, optionLabel, optionValue).map((e) => ({
                                ...e,
                                label: getTextTranslated(e.label, NAMESPACE.COMMON)
                            }))}
                            sx={{ width: '90%', maxWidth: 350, alignSelf: 'center', mt: '11px', color: 'text.black' }}
                            renderInput={(params) => <TextField {...params} label={formLabel} />}
                        />
                    </FormControl>
                )}
                {hasOptions && isCheckbox && isPhoneNumber && (
                    <FormControl component="fieldset" margin="dense" fullWidth>
                        <FormLabel component="legend">{formLabel}</FormLabel>
                        <FormGroup aria-label="position" row>
                            {options.map((option: AnyObject, i: number) => (
                                <FormControlLabel
                                    key={i}
                                    value={option.value}
                                    control={<Checkbox color="primary" checked={filter.selected.includes(option.value)} />}
                                    label={option.label}
                                    onChange={checkboxHandler(fieldName)}
                                    labelPlacement="start"
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                )}
                {!hasOptions && !isAsync && !isDate && !isBool && isPhoneNumber && (
                    <DrcPhoneInput
                        handleChange={inputHandler(fieldName)}
                        style={{ width: '223px', marginTop: '10px' }}
                        id="form-auto-complete"
                        label={formLabel}
                        sx={{ width: '90%', maxWidth: 350, alignSelf: 'center', mt: '11px', color: 'text.black' }}
                    />
                )}
            </div>
        </div>
    );
};

export default FilterItem;
