import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { PERSONAID } from 'data/config/persona';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of announcements
 * {@link APIEndPoints.GET_ANNOUNCEMENTS}
 * {@link APIEndPoints.GET_SENT_ANNOUNCEMENTS}
 *
 */

interface AnnouncementPayload {
    AnnouncementId: string;
    RecipientAuthId: string;
}

const useGetAnnouncements = (oktaAuth: any, userPersona: number, payload?: AnnouncementPayload, announcementId?: number) => {
    const { getTokenAndId } = useGetApiTokens();
    const [announcementList, setAnnouncementList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalAnnouncements, setTotalAnnouncements] = useState(0);
    const [error, setError] = useState(null);
    const isSent = userPersona === PERSONAID.SUPERADMIN || userPersona === PERSONAID.REGIONALADMIN;
    const { setShowToast } = useContext(ApplicationContext);

    const fetchAnnouncements = async () => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'Announcement',
                    url: isSent ? APIEndPoints.GET_SENT_ANNOUNCEMENTS(userOktaId, announcementId) : APIEndPoints.GET_ANNOUNCEMENTS(),
                    method: isSent ? 'GET' : 'POST',
                    options: {},
                    ...(!isSent && {
                        data: {
                            RecipientAuthId: userOktaId,
                            ...payload
                        }
                    })
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
                return console.log(data.errors[0]);
            }
            const result = isSent ? data.display : data.display.resultSet1;
            setPageCount(Math.round(result.length / 50));
            setTotalAnnouncements(result.length);
            setAnnouncementList(result || []);
        } catch (error) {
            setError('error');
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'An error occurred while fetching announcements. Please try again',
                nameSpacing: NAMESPACE.ANNOUNCEMENT
            });
        }
    };

    const fetchWithFilters = () => {
        !isSent && fetchAnnouncements();
    };

    return { announcementList, pageCount, totalAnnouncements, error, fetchWithFilters, fetchAnnouncements };
};
export default useGetAnnouncements;
