import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { NAMESPACE } from 'i18n';
import React, { useEffect, useState } from 'react';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

export default function FormAutoComplete(props) {
    const [value, setValue] = useState(props.value);
    const handleChange = (event, value) => {
        setValue(value);
        props.onChangeHandler && props.onChangeHandler(value);
    };

    useEffect(() => {
        if (value && props.value !== value.label) setValue(props.value);
    }, [props.value]);

    return (
        <Box component="div" sx={{ '& .MuiTextField-root': { m: 1 }, width: props.width }} noValidate autoComplete="off">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {props.hideLegacyLabel && (
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h7" gutterBottom>
                        {props.label}
                    </TypographyTranslated>
                )}
                <Autocomplete
                    getOptionLabel={(option) => option[props.labelField] || ''}
                    className={props?.className || ''}
                    onChange={handleChange}
                    id="form-auto-complete"
                    options={props.values || []}
                    sx={{ width: '90%', maxWidth: 350, minWidth: 180, ...props.style }}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                !props.hideLegacyLabel ? (
                                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.label}</TypographyTranslated>
                                ) : (
                                    ''
                                )
                            }
                        />
                    )}
                />
            </div>
        </Box>
    );
}
