import { Checkbox, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import AvatarComponent from 'components/shared/AvatarComponent';
import useResponsive from 'hooks/useResponsive';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import styles from './SelectPersonaInviteUser.module.scss';
import { AvailablePersonaObject } from './UserManagementTypes';

interface SelectPersonaInviteUserProps {
    personaSelectionOpenState: boolean;
    handleChangePersona: (event: any) => void;
    handleClose: () => void;
    handleOpen: () => void;
    availablePersona: Array<AvailablePersonaObject> | [];
    selectedPersona: Array<string>;
    selectedPersonaIds: Array<number>;
}

const SelectPersonaInviteUser = (props: SelectPersonaInviteUserProps) => {
    const isDesktop = useResponsive('up', 'md');

    return (
        <Stack direction={isDesktop ? 'row' : 'column'} alignItems="center" justifyContent="center" mb={5} spacing={isDesktop ? 10 : 0}>
            <Typography variant="h6" gutterBottom>
                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Select Persona Type'}</TypographyTranslated>
            </Typography>
            <FormControl sx={{ m: 1, width: '100%', maxWidth: '350px' }}>
                <Select
                    open={props.personaSelectionOpenState}
                    value={props.selectedPersona}
                    onChange={props.handleChangePersona}
                    onClose={props.handleClose}
                    onOpen={props.handleOpen}
                    multiple
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected || (selected && selected.length === 0)) {
                            return (
                                <div className={styles.selectAvailablePersona}>
                                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Available Personas'}</TypographyTranslated>
                                    {props.availablePersona?.length}
                                </div>
                            );
                        }
                        return (selected || []).map((persona) => (
                            <TypographyTranslated nameSpacing={NAMESPACE.COMMON} key={persona}>
                                {persona}
                            </TypographyTranslated>
                        ));
                    }}
                    labelId="gep-simple-select-label"
                    sx={{ mb: 2, color: 'text.black', width: '100%', maxWidth: '350px' }}
                >
                    {(props.availablePersona || []).map((itm: AvailablePersonaObject) => (
                        <MenuItem key={itm.id} value={itm.label} sx={{ display: 'flex', gap: 2 }}>
                            <Checkbox checked={props.selectedPersonaIds && props.selectedPersonaIds.indexOf(itm.id) > -1} />
                            <AvatarComponent size="small" user={{ firstName: itm.label.split(' ')[0], lastName: itm.label.split(' ')[1] }} />
                            <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{itm.label}</TypographyTranslated>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};

export default SelectPersonaInviteUser;
