import { CircularProgress } from '@mui/material';
import React from 'react';
import BLACK from '../Images/Black_Medium.png';
import BLUE from '../Images/Blue_Medium.png';
import RASP from '../Images/Rasp_Medium.png';
import STRAW from '../Images/Straw_Medium.png';
const Loader = ({ circular = false }) => {
    return (
        <>
            {circular ? (
                <div className="circular-loader">
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <div className="balls-loader">
                    <div className="balls-loader-container">
                        <div className="ball ball0">
                            <div>
                                <img src={BLACK} />
                            </div>
                        </div>
                        <div className="ball ball1">
                            <div>
                                <img src={BLUE} />
                            </div>
                        </div>
                        <div className="ball ball2">
                            <div>
                                <img src={RASP} />
                            </div>
                        </div>
                        <div className="ball ball3">
                            <div>
                                <img src={STRAW} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Loader;
