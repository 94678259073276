import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { IBerryType } from 'components/appinitializer/IDistricts';
import { IAssignmentPayload, ICompanyObject, IDistrictObject, IRanchObject } from 'components/usermanagement/UserManagementTypes';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneArray } from 'utils/helper';
import ApplicationContext from '../context/ApplicationContext';
import useLang from './useLang';

const useHandleAssignments = (
    currentForm: IInviteForms | Record<string, never>,
    assignments: Array<IAssignmentPayload>,
    setAssignments: (value: Array<IAssignmentPayload>) => void,
    setInitialButtonDisabled?: (value: boolean) => void
) => {
    const { ranches, companies, setShowToast, districtBerries: masterDistrictBerries } = useContext(ApplicationContext);
    const [selectedCompany, setSelectedCompany] = useState({} as ICompanyObject);
    const [selectedDistrict, setSelectedDistrict] = useState({} as IDistrictObject);
    const [companyRanches, setCompanyRanches] = useState([] as Array<IRanchObject | null>);
    const [districtBerries, setDistrictBerries] = useState([]);
    const [districtCompanies, setDistrictCompanies] = useState([] as Array<ICompanyObject | null>);
    const [currentAssignedEntity, setCurrentAssignedEntity] = useState({} as IAssignmentPayload);
    const dispatch = useDispatch();
    const { getTextTranslated } = useLang();

    const onChangeDistrictHandler = (value: IDistrictObject) => {
        if (value) {
            setSelectedDistrict(value);
            if (currentForm.assignmentType === AssignmentTypes.DISTRICTS) {
                let currentAssignedEntity = assignments.find((item) => item.DistrictId === value.DistrictId);
                if (!currentAssignedEntity) {
                    currentAssignedEntity = { ...value };
                    setCurrentAssignedEntity(currentAssignedEntity);
                } else {
                    setShowToast({
                        severity: DriscollsToastSeverity.ERROR,
                        toastMessage: 'Assignment already added.',
                        nameSpacing: NAMESPACE.USERS
                    });
                    setSelectedDistrict({} as IDistrictObject);
                }
            }
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY) {
                const districtTemp = companies.find(
                    (item: IDistrictObject) =>
                        item?.DistrictCode?.toUpperCase() === value?.DistrictCode?.toUpperCase() ||
                        item?.GrowingDistrictCode?.toUpperCase() === value?.DistrictCode?.toUpperCase()
                );
                const currentDistrict = assignments.find((itm) => itm.DistrictId === value.DistrictId);
                const disCompanies = currentDistrict
                    ? (districtTemp.Companies || []).filter(
                          (obj1: ICompanyObject) =>
                              !(currentDistrict.Companies || []).some(
                                  (obj2: ICompanyObject) => (obj1.Code || obj1.CompanyCode) === (obj2.Code || obj2.CompanyCode)
                              )
                      )
                    : districtTemp.Companies;
                setDistrictCompanies(
                    disCompanies.map((item: ICompanyObject) => {
                        return { ...item, label: item.label ? item.label : item.Name + ' - ' + item.Code };
                    })
                );
                if (disCompanies.length === 0) {
                    setShowToast({
                        severity: DriscollsToastSeverity.ERROR,
                        toastMessage: 'All Companies Assigned or No Active Company!',
                        nameSpacing: NAMESPACE.USERS
                    });
                    setSelectedDistrict({} as IDistrictObject);
                }
            }
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES) {
                const districtTemp = masterDistrictBerries.find(
                    (item: IDistrictObject) => item?.DistrictCode?.toUpperCase() === value?.DistrictCode?.toUpperCase()
                );
                const currentDistrict = assignments.find((itm) => itm.DistrictId === value.DistrictId);
                const disBerries = currentDistrict
                    ? (districtTemp.BerryTypes || []).filter(
                          (obj1: IBerryType) => !(currentDistrict.BerryTypes || []).some((obj2: IBerryType) => obj1.Name === obj2.Name)
                      )
                    : districtTemp.BerryTypes;
                setDistrictBerries(disBerries);
                if (disBerries.length === 0) {
                    setShowToast({
                        severity: DriscollsToastSeverity.ERROR,
                        toastMessage: 'All Berries Assigned!',
                        nameSpacing: NAMESPACE.USERS
                    });
                    setSelectedDistrict({} as IDistrictObject);
                }
            }
        } else {
            setDistrictCompanies([]);
            setDistrictBerries([]);
        }
    };

    const onChangeCompanyHandler = (value: ICompanyObject) => {
        if (value) {
            setSelectedCompany(value);
            if (currentForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
                const companyTemp = ranches.find(
                    (item: ICompanyObject) => parseInt(item?.CompanyNumber || item?.GrowerNbr) === parseInt(value?.CompanyNumber || value?.GrowerNbr)
                );
                const currentCompany = assignments.find((itm) => (itm.CompanyNumber || itm.GrowerNbr) === (value.CompanyNumber || value.GrowerNbr));
                const comRanches = currentCompany
                    ? (companyTemp.Ranches || []).filter(
                          (obj1: IRanchObject) =>
                              !(currentCompany.Ranches || []).some(
                                  (obj2: IRanchObject) => (obj1.Code || obj1.RanchNumber) === (obj2.Code || obj2.RanchNumber)
                              )
                      )
                    : companyTemp.Ranches;
                setCompanyRanches(
                    (comRanches || []).map((item: IRanchObject) => {
                        return { ...item, label: item.label ? item.label : item.Name + ' - ' + item.Code };
                    })
                );
                if (!comRanches || comRanches?.length === 0) {
                    setShowToast({
                        severity: DriscollsToastSeverity.ERROR,
                        toastMessage: 'All Ranches Assigned or No Active Ranch!',
                        nameSpacing: NAMESPACE.USERS
                    });
                    setSelectedCompany({} as ICompanyObject);
                }
            }
        } else setCompanyRanches([]);
    };

    const onAddBerriesHandler = (value) => {
        const assignmentForUser = cloneArray(assignments);
        let currentAssignedEntity = assignmentForUser.find((item) => item.DistrictId === selectedDistrict.DistrictId);
        if (!currentAssignedEntity) {
            currentAssignedEntity = { ...selectedDistrict };
            delete currentAssignedEntity.BerryTypes;
            currentAssignedEntity.BerryTypes = [];
        }
        currentAssignedEntity.BerryTypes.push(...value);
        setCurrentAssignedEntity(currentAssignedEntity);
    };

    const onAddCompaniesHandler = (value) => {
        const assignmentForUser = cloneArray(assignments);
        let currentAssignedEntity = assignmentForUser.find((item) => item.DistrictId === selectedDistrict.DistrictId);
        if (!currentAssignedEntity) {
            currentAssignedEntity = { ...selectedDistrict };
            delete currentAssignedEntity.Companies;
            currentAssignedEntity.Companies = [];
        }
        currentAssignedEntity.Companies.push(...value);
        setCurrentAssignedEntity(currentAssignedEntity);
    };

    const onAddRanchHandler = (value) => {
        const assignmentForUser = cloneArray(assignments);
        let currentAssignedEntity = assignmentForUser.find(
            (item) => (item.CompanyNumber || item.GrowerNbr) === (selectedCompany.CompanyNumber || selectedCompany.GrowerNbr)
        );
        if (!currentAssignedEntity) {
            currentAssignedEntity = { ...selectedCompany };
            delete currentAssignedEntity.Ranches;
            currentAssignedEntity.Ranches = [];
        }
        currentAssignedEntity.Ranches.push(...value);
        setCurrentAssignedEntity(currentAssignedEntity);
    };

    const addAssignments = () => {
        setInitialButtonDisabled && setInitialButtonDisabled(false);
        let assignmentForUser = cloneArray(assignments);
        if (currentAssignedEntity && (currentAssignedEntity.DistrictName || currentAssignedEntity.CompanyName)) {
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY && currentAssignedEntity.Companies.length === 0) {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: 'Please add companies before adding',
                    nameSpacing: NAMESPACE.USERS
                });
            } else if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES && currentAssignedEntity.BerryTypes.length === 0) {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: 'Please add berries before adding',
                    nameSpacing: NAMESPACE.USERS
                });
            } else {
                const districtExist = assignmentForUser.find((item) => currentAssignedEntity.DistrictName === item.DistrictName);
                if (districtExist) {
                    assignmentForUser = assignmentForUser.filter((item) => item.DistrictId !== districtExist.DistrictId);
                }
                assignmentForUser.push(currentAssignedEntity);
                setAssignments(assignmentForUser);
                setSelectedDistrict({} as IDistrictObject);
                setDistrictCompanies([]);
                setDistrictBerries([]);
                setCurrentAssignedEntity({});
            }
        } else {
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Please make an assignment before adding',
                nameSpacing: NAMESPACE.USERS
            });
        }
    };

    const addRanchAssignments = () => {
        setInitialButtonDisabled && setInitialButtonDisabled(false);
        let assignmentForUser = cloneArray(assignments);
        if (currentAssignedEntity && currentAssignedEntity.CompanyName) {
            if (!currentAssignedEntity.Ranches[currentAssignedEntity?.Ranches?.length - 1]?.label) {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: 'Please add ranches before adding',
                    nameSpacing: NAMESPACE.USERS
                });
            } else {
                const companyExist = assignmentForUser.find(
                    (item) => (currentAssignedEntity.CompanyNumber || currentAssignedEntity.GrowerNbr) === (item.CompanyNumber || item.GrowerNbr)
                );
                if (companyExist) {
                    assignmentForUser = assignmentForUser.filter(
                        (item) => (item.CompanyNumber || item.GrowerNbr) !== (companyExist.CompanyNumber || companyExist.GrowerNbr)
                    );
                }
                assignmentForUser.push(currentAssignedEntity);
                setAssignments(assignmentForUser);
                setCurrentAssignedEntity({});
                setSelectedCompany({});
                setCompanyRanches([]);
            }
        } else {
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Please make an assignment before adding',
                nameSpacing: NAMESPACE.USERS
            });
        }
    };

    const removeAllItems = (data) => {
        setInitialButtonDisabled && setInitialButtonDisabled(false);
        setAssignments([...assignments.filter((item) => item.value !== data.value)]);
        if (data.DistrictId) setAssignments([...assignments.filter((item) => item.DistrictId !== data.DistrictId)]);
        else if (data.CompanyNbr || data.CompanyNumber)
            setAssignments([...assignments.filter((item) => (item.CompanyNbr || item.CompanyNumber) !== (data.CompanyNbr || data.CompanyNumber))]);
        else if (data.RanchNbr) setAssignments([...assignments.filter((item) => item.RanchNbr !== data.RanchNbr)]);
    };

    const removeItem = (data, assignmentNbr) => {
        setInitialButtonDisabled && setInitialButtonDisabled(false);
        const array = cloneArray(assignments);
        if (currentForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
            const objectIndex = array.findIndex((obj) => parseInt(obj.CompanyNbr || obj.CompanyNumber || obj.value) === parseInt(assignmentNbr));
            const ranchIndex = array[objectIndex].Ranches.findIndex(
                (itm) => parseInt(itm.RanchNumber || itm.Code) === parseInt(data.RanchNumber || data.Code)
            );
            if (array[objectIndex].Ranches.length === 1) {
                array.splice(objectIndex, 1);
            } else array[objectIndex].Ranches.splice(ranchIndex, 1);
        }
        if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY) {
            const objectIndex = array.findIndex((obj) => obj.value === assignmentNbr);
            const companyIndex = array[objectIndex].Companies.findIndex(
                (itm) => parseInt(itm.CompanyCode || itm.CompanyNbr || itm.Code) === parseInt(data.CompanyCode || data.CompanyNbr || data.Code)
            );
            if (array[objectIndex].Companies.length === 1) {
                array.splice(objectIndex, 1);
            } else array[objectIndex].Companies.splice(companyIndex, 1);
        }
        if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES) {
            const objectIndex = array.findIndex((obj) => (obj.value || obj.DistrictCode) === assignmentNbr);
            const berryIndex = array[objectIndex].BerryTypes.findIndex((itm) => itm.value === data.value);
            if (array[objectIndex].BerryTypes.length === 1) {
                array.splice(objectIndex, 1);
            } else array[objectIndex].BerryTypes.splice(berryIndex, 1);
        }
        setAssignments(array);
    };

    return {
        selectedCompany,
        companyRanches,
        selectedDistrict,
        districtCompanies,
        districtBerries,
        onChangeDistrictHandler,
        onChangeCompanyHandler,
        onAddBerriesHandler,
        onAddCompaniesHandler,
        onAddRanchHandler,
        addAssignments,
        addRanchAssignments,
        removeItem,
        removeAllItems
    };
};

export default useHandleAssignments;
