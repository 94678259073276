import { IBerryType } from 'components/appinitializer/IDistricts';
import { IAssignmentPayload, IAssignmentsRaw, ICompanyObject, IDistrictObject, IRanchObject } from 'components/usermanagement/UserManagementTypes';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { useContext } from 'react';
import { cloneArray } from 'utils/helper';
import ApplicationContext from '../context/ApplicationContext';

const useFormatPayload = (
    isEditUser: boolean,
    assignments: Array<IAssignmentsRaw> | Array<IDistrictObject> | Array<ICompanyObject>,
    setAssignments: (value: Array<IAssignmentPayload>) => void,
    currentForm: IInviteForms | Record<string, never>,
    setAssignmentsNotMatchingWithMasterData?: (value: boolean) => void
) => {
    const { companies, ranches, districtOptions } = useContext(ApplicationContext);
    const formatPayload = () => {
        if (currentForm.assignmentType === AssignmentTypes.DISTRICTS) {
            return formattingDistrictPayload(assignments);
        }
        if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES) {
            return assignments.map((item) => {
                item.BerryTypes = (item.BerryTypes || []).map((berry: IBerryType) => berry.value);
                return item;
            });
        }
        if (currentForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
            if (isEditUser) return formattingCompanyRanchPayload(assignments);
            else {
                return assignments.map((item: ICompanyObject) => {
                    item.Ranches = item.Ranches.map((item2: IRanchObject) => {
                        return {
                            Name: item2.Name,
                            RanchNumber: item2.Code || item2.RanchNbr,
                            Code: item2.Code || item2.RanchNbr
                        };
                    });
                    return item;
                });
            }
        } else if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY) {
            if (isEditUser) return formattingDistrictCompanyPayload(assignments);
            else {
                return assignments.map((item) => {
                    item.Companies = item.Companies.map((item2: ICompanyObject) => {
                        return {
                            Name: item2.Name || item2.GrowerName,
                            CompanyCode: item2.Code || item2.GrowerNbr || item2.CompanyCode
                        };
                    });
                    return item;
                });
            }
        }
    };

    const formattingDistrictPayload = (assignments: Array<IAssignmentsRaw> | Array<IDistrictObject>) => {
        const assignmentPayload: IAssignmentPayload[] = [];
        assignments.map((item) => {
            if (Object.keys(item).length > 1) {
                assignmentPayload.push({ DistrictCode: item.DistrictCode, DistrictId: item.DistrictId, DistrictName: item.DistrictName });
            } else {
                const district = districtOptions.find((diItem: IDistrictObject) => {
                    const tempDistrict = diItem.DistrictName.toUpperCase().trim() === item.DistrictId.toUpperCase().trim();
                    if (tempDistrict) return tempDistrict;
                    else return diItem.DistrictCode.toUpperCase().trim() === item.DistrictId.toUpperCase().trim();
                });
                if (district)
                    assignmentPayload.push({
                        DistrictCode: district.DistrictCode,
                        DistrictId: district.DistrictId,
                        DistrictName: district.DistrictName,
                        label: district.DistrictName,
                        value: district.DistrictCode
                    });
            }
        });
        setAssignments(assignmentPayload);
        return assignmentPayload;
    };

    const formattingCompanyRanchPayload = (assignments: Array<IAssignmentsRaw> | Array<ICompanyObject>) => {
        const assignmentPayload: IAssignmentPayload[] = [];
        assignments.map((item: ICompanyObject) => {
            if (Object.keys(item).length > 1) {
                item.Ranches = item.Ranches.map((item2: IRanchObject) => {
                    return {
                        Name: item2.Name,
                        RanchNumber: item2.Code || item2.RanchNbr,
                        Code: item2.Code || item2.RanchNbr
                    };
                });
                assignmentPayload.push(item);
            } else {
                const assignedCompany = cloneArray(ranches).find(
                    (com: ICompanyObject) => parseInt(com.CompanyNumber || com.GrowerNbr) === parseInt(item.CompanyNbr)
                );
                if (assignedCompany?.Ranches) {
                    const comRanches = assignments.map((item: IRanchObject) => {
                        return assignedCompany.Ranches.find((ran: IRanchObject) => parseInt(item.RanchNbr) === parseInt(ran.Code));
                    });
                    delete assignedCompany.Ranches;
                    const assignedRanches = (comRanches || []).filter((hasRanch) => !!hasRanch);
                    assignedCompany.Ranches = assignedRanches.map((ranch) => {
                        return {
                            Name: ranch.Name,
                            RanchNumber: ranch.Code || ranch.RanchNbr,
                            Code: ranch.Code || ranch.RanchNbr,
                            label: `${ranch.Name} - ${ranch.Code || ranch.RanchNbr}`,
                            value: ranch.Code || ranch.RanchNbr
                        };
                    });

                    assignmentPayload.push(assignedCompany);
                } else if (item.CompanyNbr) {
                    isEditUser && setAssignmentsNotMatchingWithMasterData(true);
                }
            }
        });
        setAssignments(assignmentPayload);
        return assignmentPayload;
    };

    const formattingDistrictCompanyPayload = (assignments: Array<IAssignmentsRaw> | Array<IDistrictObject>) => {
        const assignmentPayload: IAssignmentPayload[] = [];
        assignments.map((item) => {
            if (Object.keys(item).length > 1) {
                item.Companies = item?.Companies.map((item2: ICompanyObject) => {
                    return {
                        Name: item2.Name || item2.GrowerName,
                        CompanyCode: item2.Code || item2.GrowerNbr || item2.CompanyCode
                    };
                });
                assignmentPayload.push(item);
            } else if (item.DistrictId) {
                //  mapping logic...
                let disCompanies: ICompanyObject[] = [];
                let assignedDisCompanies: ICompanyObject[] = [];
                const masterDistricts = cloneArray(companies);
                const assignedDistrict = masterDistricts.find((diItem: IDistrictObject) => {
                    const districtTemp =
                        (diItem.DistrictName?.toUpperCase().trim() === item.DistrictId?.toUpperCase().trim() ||
                            diItem.DistrictCode?.toUpperCase().trim() === item.DistrictId?.toUpperCase().trim()) &&
                        diItem;
                    disCompanies = districtTemp.Companies;

                    assignedDisCompanies = assignments.map((itm: ICompanyObject) => {
                        return disCompanies && disCompanies.find((com) => parseInt(com.Code) === parseInt(itm.CompanyNbr));
                    });
                    return districtTemp;
                });
                if (assignedDistrict) {
                    assignedDistrict.Companies = (assignedDisCompanies || []).filter((hasCompany) => !!hasCompany);
                    assignedDistrict.Companies = assignedDistrict.Companies.map((item2: ICompanyObject) => {
                        return {
                            Name: item2.Name || item2.GrowerName,
                            CompanyCode: item2.Code || item2.GrowerNbr,
                            label: `${item2.Name || item2.GrowerName} - ${item2.Code || item2.GrowerNbr}`,
                            value: item2.Code || item2.GrowerNbr
                        };
                    });
                    assignmentPayload.push(assignedDistrict);
                } else {
                    isEditUser && setAssignmentsNotMatchingWithMasterData(true);
                }
            }
        });
        setAssignments(assignmentPayload);
        return assignmentPayload;
    };

    return {
        formatPayload,
        formattingDistrictPayload,
        formattingDistrictCompanyPayload,
        formattingCompanyRanchPayload
    };
};

export default useFormatPayload;
