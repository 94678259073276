import { Call } from '@driscollsinc/driscolls-display-rules';
import { ICalendarEvent } from 'components/dashboard/Calendar/CalendarTypes';
import { useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of event
 * {@link APIEndPoints.GET_}
 */
const useFetchEvent = (oktaAuth, eventId: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<ICalendarEvent | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetch = async () => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'EventDetails',
                    url: APIEndPoints.GET_EVENT_DETAILS(eventId, userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.length > 0) {
                setData(data.display[0]);
            }
            setLoading(false);
        } catch (error) {
            setError('ERROR');
        }
    };
    useEffect(() => {
        fetch();
    }, []);
    return { data, loading, error };
};
export default useFetchEvent;
