import { Card, CardContent } from '@mui/material';
import Loader from 'components/shared/Loader/Loader';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { getReportPath } from 'routes/routesUtils';
import useLang from '../../../hooks/useLang';
import { IReportObject } from '../../reports/ReportTypes';
import ImageComponent from '../../shared/ImageComponent/ImageComponent';
import DashboardSection from '../Section';
import SectionHeader from '../Section/SectionHeader';
import RecentReport from './RecentReport';
import style from './index.module.scss';

interface RecentReportsProps {
    classNameContainer?: string;
    reports: IReportObject[] | null;
    loading: boolean;
    error: string | null;
}
const RecentReports = ({ classNameContainer, reports, loading, error }: RecentReportsProps) => {
    const { getTextTranslated } = useLang();
    return (
        <DashboardSection classNameContainer={`${style.recentReports} ${classNameContainer || ''}`}>
            <SectionHeader title={'Reports'} className={style.title} />
            <Card className={style.card} title="Reports">
                <CardContent className={style.content}>
                    {loading && <Loader circular />}
                    {error && <p>{`${getTextTranslated('Reports Error Msg', NAMESPACE.DASHBOARD)}`}</p>}
                    {!loading && !error && !reports && <p>{`${getTextTranslated('No Assigned Reports', NAMESPACE.DASHBOARD)}`}</p>}
                    {!loading &&
                        !error &&
                        reports &&
                        reports.slice(0, 4).map((report) => {
                            return (
                                <RecentReport
                                    key={report.ReportId}
                                    image={
                                        <ImageComponent
                                            size="medium"
                                            src={`/icons/reports/${report.CategoryCode}.svg`}
                                            brokenImage={'/icons/reports/Others.svg'}
                                        />
                                    }
                                    href={getReportPath(report.Description)}
                                    text={report.ReportName}
                                />
                            );
                        })}
                </CardContent>
            </Card>
        </DashboardSection>
    );
};

export default RecentReports;
