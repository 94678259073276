import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import { IAnalyticsResponse } from 'types/analytics';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

export interface IDateRangeFilter {
    startDate: string;
    endDate: string;
}

interface IDimensionsFilter {
    dimensions: string[];
}

/**
 * This hook fetches the google analytics
 * {@link APIEndPoints.}
 * {@param {Object} oktaAuth Okta authentication object}
 */
const useGoogleAnalytics = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<IAnalyticsResponse>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const prepareDimensionsFilter = (dimensionsFilter: Array<string> | []) => {
        const filters = [];
        dimensionsFilter.forEach((dimension) => {
            filters.push({ name: dimension });
        });
        if (filters.length === 0) {
            filters.push({ name: 'city' });
        }
        return filters;
    };
    const prepareMetricFilters = (metricFilters: Array<string> | []) => {
        const filters = [];
        metricFilters.forEach((metric) => {
            filters.push({ name: metric });
        });
        if (filters.length === 0) {
            filters.push({ name: 'activeUsers' });
        }
        return filters;
    };

    const fetch = async (dateRange: IDateRangeFilter, dimensionsFilter: Array<string> | [], metricFilters: Array<string> | []) => {
        setLoading(true);
        const property = `properties/${window.config.ga.propertyId}`;
        // send the request
        const payload = {
            property: property,
            dateRanges: [dateRange],
            dimensions: prepareDimensionsFilter(dimensionsFilter),
            metrics: prepareMetricFilters(metricFilters)
        };
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: APIEndPoints.GET_REPORT(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            setData(response.display);
        } catch (err) {
            debugger;
            setError('Error loading google Analytics');
        } finally {
            setLoading(false);
        }
    };

    const fetchReport = async (dateRange: IDateRangeFilter, dimensionsFilter: Array<string> | [], metricFilters: Array<string> | []) => {
        fetch(dateRange, dimensionsFilter, metricFilters);
    };

    return { data, loading, error, fetchReport };
};

export default useGoogleAnalytics;
