import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Feedback from './Feedback';

const FeedbackRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Feedback />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default FeedbackRoutes;
