import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DLink from 'components/dashboard/Link';
import DrcRichTextEditor from 'components/shared/drc/richtexteditor/DrcRichTextEditor';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import React from 'react';
import { viewsPerRoute } from 'routes/routesEnum';
import { getRouteByView } from 'routes/routesUtils';
import style from './PreviewEventModal.module.scss';

export interface ICalendarEventPreview {
    Id: string;
    Name: string;
    Description: string;
}

const PreviewEventModal = ({ event, open, onClose }: { event?: ICalendarEventPreview; open: boolean; onClose: any }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>
                <div className={style.dialogTitle}>
                    {event?.Name}
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <DrcRichTextEditor defaultValue={event?.Description} readOnly={true} onChange={() => null} editorState={null} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className={style.actions}>
                    <DLink variant="subtitle2" href={getRouteByView(viewsPerRoute.EVENT_DETAILS, '' + event?.Id)} underline="none">
                        View
                    </DLink>
                    <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}>
                        <DLink variant="subtitle2" href={getRouteByView(viewsPerRoute.EVENT_EDIT, '' + event?.Id)} underline="none">
                            Edit
                        </DLink>
                    </PersonaBaseProtect>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewEventModal;
