import React from 'react';

import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
const DrcPhoneInput = (props) => {
    const { value, handleChange, handleBlur, label, onEnterKeyPress, style, error, helperText } = props;
    const { getTextTranslated } = useLang();
    const translatedLabel = getTextTranslated(label, NAMESPACE.EVENTS);
    return (
        <>
            <PhoneInput
                placeholder={translatedLabel}
                specialLabel={translatedLabel}
                country={'us'}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onEnterKeyPress={onEnterKeyPress}
                containerStyle={{
                    height: '56px',
                    width: '100%',
                    border: error ? '1px solid red' : '1px solid #ccc',
                    borderRadius: '18px',
                    padding: '5px',
                    margin: '6px 14px !important',
                    ...style
                }}
                inputStyle={{
                    height: '40px',
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%'
                }}
            />
            {error && (
                <p
                    style={{ color: 'red', margin: '0 25px', fontSize: '12px' }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense"
                >
                    {helperText}
                </p>
            )}
        </>
    );
};

export default DrcPhoneInput;
