import React from 'react';

interface DrcVideoPlayerTypes {
    url: URL | string;
}

const DrcVideoPlayer = ({ url }: { url: DrcVideoPlayerTypes }) => {
    return <video src={url.toString()} height="auto" width="500px" controls={true} autoPlay={true} />;
};

export default DrcVideoPlayer;
