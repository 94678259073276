import React from 'react';

import { Box, Divider, Drawer, IconButton, Stack } from '@mui/material';
import { TypographyTranslated } from '../../../components/shared/Typography/TypographyTranslated';
import Iconify from '../../../components/shared/iconify';
import Scrollbar from '../../../components/shared/scrollbar';
import Filter from '../../../ui/DrcFilters/DrcFilter';
import TranslatedDrcButton from '../../../ui/translatedButton/TranslatedDrcButton';
import { getAppliedFilters, getAppliedFiltersPost } from '../../../utils/helper';
import { NAMESPACE } from 'i18n';

export default function GenericFilterSideBar({
    openFilter,
    onOpenFilter,
    onCloseFilter,
    getData,
    filters,
    paramFilter = false,
    filterNames = ['FirstName', 'LastName', 'Persona', 'Email', 'Phone', 'Lang', 'District', 'Company', 'Ranch'],
    resetFilters
}) {
    return (
        <>
            <TranslatedDrcButton
                nameSpacing={NAMESPACE.COMMON}
                disableRipple
                color="inherit"
                endIcon={<Iconify icon="ic:round-filter-list" />}
                onClick={onOpenFilter}
            >
                {'Filters'} &nbsp;
            </TranslatedDrcButton>

            <Drawer
                anchor="right"
                open={openFilter}
                onClose={onCloseFilter}
                PaperProps={{
                    sx: { width: 280, border: 'none', overflow: 'hidden' }
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="subtitle1" sx={{ ml: 1 }}>
                        {'Filters'}
                    </TypographyTranslated>
                    <IconButton onClick={onCloseFilter}>
                        <Iconify icon="eva:close-fill" />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar sx={{ p: 2 }}>
                    <Filter
                        buttonContainerStyle={{ position: 'absolute', right: '52px', bottom: '5px' }}
                        showFilter={true}
                        applyFilter={() => {
                            paramFilter ? getData(1, getAppliedFilters(filters)) : getData(1, getAppliedFiltersPost(filters));
                            onCloseFilter();
                        }}
                        resetFilter={() => {
                            resetFilters();
                            onCloseFilter();
                        }}
                        filterNames={filterNames}
                    />
                </Scrollbar>
                <Divider />
                <Box sx={{ p: 4 }}> </Box>
            </Drawer>
        </>
    );
}
