import { MenuItem, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import { IDistrictObject } from 'components/usermanagement/UserManagementTypes';
import React, { Fragment } from 'react';
import useLang from '../../../hooks/useLang';
import { NAMESPACE } from 'i18n';
interface IUserManageDistrictsProps {
    value: Array<IDistrictObject>;
}

const UserManagementDistricts = (props: IUserManageDistrictsProps) => {
    const cellValue = props.value;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { getTextTranslated } = useLang();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const maxItems = 2;

    return (
        <div>
            {cellValue && cellValue.length > 0 ? (
                <>
                    <Stack direction="row" spacing={0.75}>
                        {cellValue.map((option, index) => (
                            <Fragment key={option?.DistrictId || index}>
                                {index < maxItems ? (
                                    <Link>{option?.DistrictName}</Link>
                                ) : (
                                    <>
                                        <Link
                                            component="button"
                                            title={getTextTranslated('Click to view more items', NAMESPACE.EVENTS)}
                                            variant="body2"
                                            onClick={(event) => handleClick(event)}
                                        >
                                            ...More
                                        </Link>
                                        <Popover
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                            sx={{ height: '25%' }}
                                        >
                                            <Stack spacing={0.75} sx={{ width: 230 }}>
                                                {cellValue.slice(maxItems).map((option) => (
                                                    <MenuItem key={option?.DistrictId}>{option?.DistrictName}</MenuItem>
                                                ))}
                                            </Stack>
                                        </Popover>
                                    </>
                                )}
                                {cellValue.length > 1 && index !== cellValue.length - 1 && index < maxItems ? <span>,</span> : ''}
                            </Fragment>
                        ))}
                    </Stack>
                </>
            ) : (
                'N.A'
            )}
        </div>
    );
};

export default UserManagementDistricts;
