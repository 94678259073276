import { IUserManagementForm } from 'context/UserManageFormContext';
import { IInviteForms } from 'data/config/DataSetupTypes';
import { NAMESPACE } from 'i18n';
import validator from 'validator';
import * as yup from 'yup';
import useLang from '../../hooks/useLang';
import { IInvitePayload } from './UserManagementTypes';

function getUserManageFieldsValidationSchema(personaForm?: IInviteForms | Record<string, never>, payload?: IInvitePayload) {
    const form: IUserManagementForm = (personaForm.personalinformation || []).reduce(
        (obj, item) => Object.assign(obj, { [item.field]: item.pattern }),
        {}
    );
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { getTextTranslated } = useLang();
    const userNameField: string = payload?.usernamefield;
    return yup.object({
        ...(form?.firstName && { firstName: yup.string().required(getTextTranslated('First Name is Required!', NAMESPACE.USERS)) }),
        ...(form?.lastName && { lastName: yup.string().required(getTextTranslated('Last Name is Required!', NAMESPACE.USERS)) }),
        ...(userNameField === 'phone' && form?.phone && {
            phone: yup
                .string()
                .required(getTextTranslated('Phone is Required!', NAMESPACE.USERS))
                .test('phone-test', getTextTranslated('Invalid Phone Number!', NAMESPACE.USERS), (phone) =>
                    validator.isMobilePhone('+' + phone, 'any')
                )
        }
        ),
        ...(userNameField === 'phone' && (payload?.email ?? '') && {
            email: yup
                .string()
                .email(getTextTranslated('Please Enter the Correct Email!', NAMESPACE.USERS))
                .matches(emailRegex,getTextTranslated('Please Enter the Correct Email!', NAMESPACE.USERS))
                .test(
                    'non-driscolls-test',
                    getTextTranslated('Cannot use driscolls email!', NAMESPACE.USERS),
                    (email) => !email.match(/^.*@driscolls/)
                )
        }
        ),
        ...(userNameField === 'email' &&
            form?.email && {
            email: yup
                .string()
                .required(getTextTranslated('Email is Required!', NAMESPACE.USERS))
                .email(getTextTranslated('Please Enter the Correct Email!', NAMESPACE.USERS))
                .matches(emailRegex,getTextTranslated('Please Enter the Correct Email!', NAMESPACE.USERS))
                .test(
                    'non-driscolls-test',
                    getTextTranslated('Cannot use driscolls email!', NAMESPACE.USERS),
                    (email) => !email.match(/^.*@driscolls/)
                )
        }),
        ...(form?.Login && {
            Login: yup
                .string()
                .required(getTextTranslated('Email is Required!', NAMESPACE.USERS))
                .matches(form.Login, getTextTranslated('Please Enter Correct Driscolls Email!', NAMESPACE.USERS))
        }),
        ...(form?.gpaLang && { gpaLang: yup.string().required(getTextTranslated('Language is Required!', NAMESPACE.USERS)) }),
        ...(form?.country && { country: yup.string().required(getTextTranslated('Country is Required!', NAMESPACE.USERS)) }),
        ...(form?.usernamefield && { usernamefield: yup.string().required(getTextTranslated('Username Field is Required!', NAMESPACE.USERS)) }),
        ...(form?.role && { role: yup.string().required(getTextTranslated('Role is Required!', NAMESPACE.USERS)) })
    });
}

export default getUserManageFieldsValidationSchema;
