import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook deletes the announcement
 * {@link APIEndPoints.DELETE_ANNOUNCEMENT}
 *
 */

const useRemoveAnnouncement = (oktaAuth: any, announcementIdToRemove: number) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const invokeRemoveAnnouncement = async () => {
        setLoading(true);
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'Announcement',
                    url: APIEndPoints.DELETE_ANNOUNCEMENT(),
                    method: 'POST',
                    options: {},
                    data: { AnnouncementId: announcementIdToRemove }
                },
                token
            );
            if (data.errors.length && !data.raw) setError('error');
            else setError(null);
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading(false);
        }
    };

    return { invokeRemoveAnnouncement, error, loading };
};
export default useRemoveAnnouncement;
