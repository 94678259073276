import { withOktaAuth } from '@okta/okta-react';
import React, { useContext } from 'react';

import WelcomeComponent from '../../../components/dashboard/Welcome/WelcomeComponent';
import ApplicationContext from '../../../context/ApplicationContext';
import DrcContainer from '../../../layouts/dashboard/DrcContainer';

import QueryBuilderCreationComponent from 'components/dashboard/QueryBuilder/QueryBuilderCreationComponent';
import useGetReports from 'hooks/useGetReports';
import { NAMESPACE } from 'i18n';
import usePersona from 'services/personas/usePersona';
import useLang from '../../../hooks/useLang';
import style from './ReportBuilderDashboard.module.scss';

const ReportBuilderDashboard = (props) => {
    const { oktaAuth } = props;
    const { userPersona } = useContext(ApplicationContext);
    const { getPersonaWelcomeMessage } = usePersona(userPersona);
    const { getTextTranslated } = useLang();
    const { data: reports, loading: loadingReports, error: errorReports } = useGetReports(oktaAuth, 1, 'en', null, true);

    return (
        <DrcContainer heading={getTextTranslated('Report Builder Dashboard', NAMESPACE.COMMON)}>
            <article className={style.mainContainer}>
                <div className={style.row}>
                    <WelcomeComponent message={getPersonaWelcomeMessage()} persona={userPersona} />
                </div>
                <QueryBuilderCreationComponent reports={reports} loadingReports={loadingReports} errorReports={errorReports} />
            </article>
        </DrcContainer>
    );
};

export default withOktaAuth(ReportBuilderDashboard);
