import { ButtonVariant, DriscollsLoadingButton } from '@driscollsinc/style-guide-web2.0';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import style from './PreviewEventModal.module.scss';

const DeleteEventWarningModal = ({
    open,
    loading,
    handleSubmit,
    handleClose
}: {
    open: boolean;
    loading: boolean;
    handleSubmit: () => void;
    handleClose: () => void;
}) => {
    const { getTextTranslated } = useLang();
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>
                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Delete Event'}</TypographyTranslated>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={style.modalContent}>
                        <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Do you want to delete the event?'}</TypographyTranslated>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className={style.actions}>
                    <DriscollsLoadingButton
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleSubmit}
                        loading={loading}
                        label={getTextTranslated('Delete', NAMESPACE.COMMON)}
                    />
                    <DriscollsLoadingButton variant={ButtonVariant.TEXT} onClick={handleClose} label={getTextTranslated('No', NAMESPACE.COMMON)} />
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteEventWarningModal;
