import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import AvatarComponent from '../../shared/AvatarComponent';
import style from './ContactComponent.module.scss';
import { IContactDisplay } from './ContactTypes';
import EmailItem from './EmailItem';
import PhoneItem from './PhoneItem';

const ContactComponent = ({ contact }: { contact: IContactDisplay }) => {
    return (
        <div className={style.contactItem}>
            <ListItem className={style.item}>
                <article className={style.avatarContainer}>
                    <ListItemAvatar>
                        <AvatarComponent
                            size="small"
                            user={{ firstName: contact.FirstName, lastName: contact.LastName, avatarUrl: contact.avatarUrl }}
                        />
                    </ListItemAvatar>
                </article>
                <article className={style.infoContainer}>
                    <ListItemText
                        primary={
                            <div className={style.phoneContainer}>
                                {contact.FirstName} {contact.LastName} <PhoneItem phoneNumber={contact.PhoneNumber} showIcon />
                            </div>
                        }
                    />
                    <ListItemText
                        primary={
                            <div className={style.emailContainer}>
                                <EmailItem email={contact.Email} showIcon />
                            </div>
                        }
                    />
                </article>
            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    );
};

export default ContactComponent;
