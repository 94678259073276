import { styled } from '@mui/system';
import React from 'react';

const styles = (theme) => ({
    handHeld: {
        padding: '0 !important',
        backgroundColor: 'transparent'
    },
    transparent: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
});

const StyledMain = styled('main')({
    margin: '48px auto 0',
    padding: '24px',
    borderRadius: '0 0 8px 8px',
    backgroundColor: 'primary.main',
    boxShadow: '0px 4px 8px gray'
});

/**
 * @class DrcMain
 * @description DRC Main component wrapper
 * @property {number} maxWidth Sets the maximum width of component
 * @property {boolean} handHeld To be set as true if the app is used on a handheld device
 * @property {boolean} transparent Makes Main transparent
 * @example
 * <DrcMain transparent>Hello world!</DrcMain>
 * @donotprint true
 * @category Layout
 * @tags layout main html
 * @extends {Component}
 */
const DrcMain = (props) => {
    /**
     * @return {*}
     * @memberof DrcMain
     */

    const { style, children, maxWidth } = props;

    return (
        <StyledMain
            sx={{
                maxWidth: maxWidth || '1280px',
                ...style
            }}
        >
            {children}
        </StyledMain>
    );
};

export default DrcMain;
