import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/shared/iconify';
import { ROUTES } from '../../constants/routes';
import { driscollsEmail, email } from '../../utils/validations';
import styles from './Login.module.scss';

// ----------------------------------------------------------------------
function LoginForm() {
    const navigate = useNavigate();
    let intervalId;
    const [isDriscollsEmail, setIsDriscollsEmail] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [driscollsUserName, setDriscollsUserName] = useState('');
    const [driscollsPassword, setDriscollsPassword] = useState('');
    const [loginDisabled, setLoginDisabled] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [cognitoOTP, setCognitoOTP] = useState('');
    const [cognitoUser, setCognitoUser] = useState(null);
    const { setShowToast } = useContext(ApplicationContext);

    useEffect(() => {
        const event = { target: { value: driscollsUserName } };
        handleBlur(event);
    }, [driscollsUserName]);

    const handleClick = () => {
        //navigate('/dashboard', { replace: true });
        //Hackish - TODO we have to have an API based login to replace this okta one
        //As of now I am sending all the emails to okta and on failure and non -driscolls case
        //Sending to cognito
        if (isDriscollsEmail) {
            setLoginDisabled(true);
            setButtonLoading(true);
            document.getElementById('okta-signin-username').value = driscollsUserName;
            const event = new Event('input', { bubbles: true });
            document.getElementById('okta-signin-username').dispatchEvent(event);
            document.getElementById('okta-signin-password').value = driscollsPassword;
            const event2 = new Event('input', { bubbles: true });
            document.getElementById('okta-signin-password').dispatchEvent(event2);
            document.getElementById('okta-signin-submit').click();
            intervalId = setInterval(checkLoginStatus, 2000);
        } else if (isValidEmail) {
            doCognitoLOgin();
        }
    };

    const checkLoginStatus = () => {
        if (document.getElementsByClassName('infobox-error')[0]?.textContent) {
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: document.getElementsByClassName('infobox-error')[0].textContent
            });
            clearInterval(intervalId);
            setButtonLoading(false);
            setLoginDisabled(false);
        } else setButtonLoading(false);
    };

    const doCognitoLOgin = () => {
        setButtonLoading(true);
        Auth.signIn(driscollsUserName)
            .then((user) => {
                console.log(user);
                setCognitoUser(user);
                setIsOTPSent(true);
            })
            .catch((err) => {
                console.error(err);
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: err.message });
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    const handleChangeUserName = (event) => {
        const tempEmail =
            event.target.value?.indexOf('@') === -1 && event.target.value?.indexOf('.') > -1
                ? event.target.value + '@driscolls.com'
                : event.target.value;
        setDriscollsUserName(tempEmail);
    };

    const handleChangeUserPassword = (event) => {
        setDriscollsPassword(event.target.value);
        if (isValidEmail) {
            setLoginDisabled(false);
        }
    };

    const handleChangeOTP = (event) => {
        setCognitoOTP(event.target.value);
    };

    const handleBlur = (event) => {
        const tempEmail =
            event.target.value?.indexOf('@') === -1 && event.target.value?.indexOf('.') > -1
                ? event.target.value + '@driscolls.com'
                : event.target.value;
        const isNotDriscollsEmail = driscollsEmail(tempEmail);
        setIsDriscollsEmail(!isNotDriscollsEmail);
        const isNotEmail = email(tempEmail);
        setIsValidEmail(!isNotEmail);
    };

    const verifyOTP = () => {
        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
            setButtonLoading(true);
            Auth.sendCustomChallengeAnswer(cognitoUser, cognitoOTP)
                .then((res) => {
                    localStorage.clear();
                    localStorage.setItem('cognitouser', JSON.stringify(res));
                    localStorage.setItem('authenticationProvider', 'cognito');
                    navigate(ROUTES.INITIALIZE_APP);
                })
                .catch((err) => {
                    console.error('otp error ', err);
                    setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: err.message });
                    setIsOTPSent(false);
                })
                .finally(() => {
                    setButtonLoading(false);
                });
        }
    };
    const handleKeypressOnPassword = (e) => {
        if (e.keyCode === 13) {
            if (!loginDisabled && isValidEmail) {
                handleClick();
            }
            return false;
        }
    };

    const { getTextTranslated } = useLang();
    return (
        <>
            <Stack spacing={3}>
                <TextField
                    name="email"
                    type="email"
                    label={getTextTranslated('Email', NAMESPACE.COMMON)}
                    onChange={handleChangeUserName}
                    onBlur={handleBlur}
                />
                {isDriscollsEmail ? (
                    <TextField
                        disabled={isOTPSent}
                        name="password"
                        label={getTextTranslated('Password', NAMESPACE.COMMON)}
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChangeUserPassword}
                        onInput={handleChangeUserPassword}
                        onKeyUp={handleKeypressOnPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                ) : null}
                {isOTPSent ? <TextField name="otp" label="OTP" onChange={handleChangeOTP} onKeyUp={handleKeypressOnPassword} /> : null}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
            {!isOTPSent && (
                <DriscollsLoadingButton
                    disabled={loginDisabled && !isValidEmail}
                    className={styles.fullWidth}
                    loading={buttonLoading}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleClick}
                    label={getTextTranslated('Login', NAMESPACE.COMMON)}
                ></DriscollsLoadingButton>
            )}
            {isOTPSent ? (
                <DriscollsLoadingButton
                    loading={buttonLoading}
                    className={styles.fullWidth}
                    disabled={cognitoOTP.length < 1}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    onClick={verifyOTP}
                    label={getTextTranslated('Verify OTP', NAMESPACE.COMMON)}
                ></DriscollsLoadingButton>
            ) : null}
        </>
    );
}
export default withLocalize(LoginForm);
