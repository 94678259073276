export const ROUTES = {
    ROOT: '/',
    SEPARATOR: '/',
    DASHBOARD: 'dashboard',
    SUPER_ADMIN: 'superadmin',
    ADMIN: 'admin',
    GROWER: 'grower',
    REPORT_BUILDER: 'reportbuilder',
    MAIN_DASHBOARD: 'main',
    LOGIN: '/login',
    INITIALIZE_APP: '/InitializeApplication/',
    OKTA_CALLBACK: '/implicit/callback',
    LOGOUT: 'LogOut',
    WILDCARD: '*',
    GROWER_DASHBOARD: 'dashboard/grower',
    USER_MANAGEMENT: 'user',
    USER_INVITE: 'invite',
    USER_EDIT: 'edituser/:oktaId/:selectedpersonaid',
    EDITUSER: 'edituser/',
    USER_VIEW: 'view/:oktaId/:selectedpersonaid',
    VIEWUSER: 'view/',
    REPORTS: 'reports',
    VIEW_REPORT: 'view/:reportName/',
    AUTHORING_REPORT: 'authoring',
    DOWNLOAD_REPORT_FILE: 'view/:reportName/:fileid', // DO NOT REMOVE
    // DOWNLOAD_REPORT_FILE not using in Web App. This Route is needed for One Driscoll's Mobile App!
    UPLOAD_REPORT: 'upload/:reportType/',
    UPLOAD_REPORT_REVIEW: 'upload/review/',
    VIEW: 'view',
    ANNOUNCEMENT: 'announcement',
    WEATHER: 'weather',
    CREATE_ANNOUNCEMENT: 'create-announcement',
    VIEW_ANNOUNCEMENT: 'view/:announcementId',
    DOCUMENT_LIBRARY: 'library',
    FEEDBACK: 'feedback',
    SUPPORT: 'support',
    SUPPORT_QUERY: 'query',
    PROFILE: 'profile',
    PTI: 'pti',
    PTIPARAMS: ':ptiTab',
    SEARCHBYRANCH: 'searchbyranch',
    REPRINT: 'reprint',
    MANUAL: 'manual',
    DOCUMENTSPARAM: 'documents/:Filename',
    DOCUMENTS: 'documents',
    SEARCH: 'search',
    DOCUMENTS_LATEST: 'latest',
    DOCUMENTS_SEARCH: 'search/:query',
    RESOLUTIONPARAMS: 'resolution/:RequestId/:ResponseId',
    RESOLUTION: 'resolution',
    VIEW_RESPONSE_PARAMS: 'view/:RequestId/:ResponseId',
    VIEW_RESPONSE: 'view',
    VIEW_WEEKLY_REPORT: 'view/DailyWeeklyReport',
    VIEW_GROWER_SETTLEMENT_REPORT: 'view/GrowerSettlementReport',
    VIEW_SUPPLEMENTAL_STATEMENT_REPORT: 'view/SupplementalStatement',
    VIEW_CANADA_MANUAL_TAX_REPORT: 'view/CanadaManualTax',
    USER_ASSIGNMENT: 'assignment/entity/:EntityName',
    ANALYTICS: 'analytics',
    BERRY_CONNECT: 'berryconnect',
    //ROUTES for API

    RESPONSE_PATH: 'support/response',
    ASSIGNMENT_ENTITY: 'user/assignment/entity/',
    EVENTS: 'events',
    EVENTSCREATE: 'createEvent',
    EVENTSVIEWALL: 'eventsList',
    EVENT_DETAILS: 'details',
    EVENT_EDIT: 'edit',
    LATEST: 'latest',
    SETKIOSK: 'setKioskMode'
};
