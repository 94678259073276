import { Call } from '@driscollsinc/driscolls-display-rules';
import {
    ButtonVariant,
    DriscollsAssignmentCard,
    DriscollsHContainer,
    DriscollsLoadingButton,
    DriscollsToastSeverity
} from '@driscollsinc/style-guide-web2.0';
import { Box, Chip, Container, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import AddPersona from 'components/usermanagement/AddPersona';
import RenderAssignments from 'components/usermanagement/RenderAssignments';
import { ComponentName } from 'constants/ComponentEnum';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { PERSONAID, PERSONA_NAME } from 'data/config/persona';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useCreateAssignments from 'hooks/useCreateAssignment';
import useFetchUsers from 'hooks/useFetchUsers';
import useFormatPayload from 'hooks/useFormatPayload';
import useGetDistrictBerry from 'hooks/useGetDistrictBerry';
import useGetReports from 'hooks/useGetReports';
import useHandleAssignments from 'hooks/useHandleAssignments';
import useMakeDefaultPersona from 'hooks/useMakeDefaultPersona';
import useManageReports from 'hooks/useManageReports';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import useUpdateCognitoUser from 'hooks/useUpdateCognitoUser';
import { NAMESPACE } from 'i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DriscollsLegend from 'ui/DriscollsLegend/DriscollsLegend';
import { logEvent } from '../../analytics/AnalyticsHandler';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import DrcDialog from '../../components/shared/drc/DrcDialog';
import DrcInformationBox from '../../components/shared/drc/DrcInformationBox';
import ChangePersona from '../../components/usermanagement/ChangePersona';
import PersonalInformation from '../../components/usermanagement/PersonalInformation';
import ReportsAssignment from '../../components/usermanagement/ReportsAssignment';
import UserLifeCycle from '../../components/usermanagement/UserLifeCycle';
import { EventConstants } from '../../constants/EventConstants';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import { isPersonaIsOKTA, persona } from '../../data/config/datasetup';
import { forms } from '../../data/config/inviteforms';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import APIEndPoints from '../../services/api';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { areObjectsEqual, getPersonaFromPersonaId, getPersonaIdFromPersona } from '../../utils/helper';
import classes from './usermanagement.module.scss';

const EditUser = (props) => {
    const { oktaAuth } = props;
    useCheckMasterData();
    useMapSuperAdminData();
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const { oktaId, selectedpersonaid } = useParams();
    const location = useLocation();
    const rowData = location?.state?.data ? JSON.parse(location.state.data) : {};
    rowData.UserOktaId = rowData.UserOktaId ? rowData.UserOktaId : oktaId;
    rowData.selectedPersonaId = selectedpersonaid;
    const selectedPersonaId = rowData.selectedPersonaId;
    rowData.Persona = getPersonaFromPersonaId(selectedPersonaId);
    const selectedPersona = rowData.Persona;
    const [currentForm, setCurrentForm] = useState<IInviteForms>();
    const [currentEditUser, setCurrentEditUser] = useState({});
    const [assignments, setAssignments] = useState([]);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [migrationSectionOpen, setMigrationSectionOpen] = useState(false);
    const [cognitoPayload, setCognitoPayload] = useState<any>({});
    const [defaultPersona, setDefaultPersona] = useState('');
    const [addPersonaFromDropdownClick, setAddPersonaFromDropdownClick] = useState(false);
    const [availablePersona, setAvailablePersona] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [assignedReports, setAssignedReports] = useState([]);
    const [oldReports, setOldReports] = useState([]);
    const [deletedReports, setDeletedReports] = useState([]);
    const [initialButtonDisabled, setInitialButtonDisabled] = useState(true);
    const userLang = getCurrentLanguageSelected();
    const [assignmentsNotMatchingWithMasterData, setAssignmentsNotMatchingWithMasterData] = useState(false);
    const { assignReports, deleteReports, loading: reportLoading } = useManageReports(oktaAuth);
    const { userPersona, setShowToast } = useContext(ApplicationContext);
    const { data: reportsData, getReports } = useGetReports(oktaAuth, selectedPersona, userLang, rowData.UserOktaId);
    const { data: reportsList } = useGetReports(oktaAuth, userPersona, userLang);
    const { updateCognitoUser, loading: updateCognitoLoading } = useUpdateCognitoUser(oktaAuth);
    const { makeDefaultPersona, error: changeDefaultPersonaError } = useMakeDefaultPersona(oktaAuth);
    const { fetchDistrictAssignment, data: assignedDistrictBerries } = useGetDistrictBerry(oktaAuth);
    const { fetchUsers, loading: fetchUsersLoading } = useFetchUsers(oktaAuth);
    const { createAssignments, loading: createAssignmentsLoading } = useCreateAssignments(oktaAuth);

    const {
        selectedCompany,
        companyRanches,
        selectedDistrict,
        districtBerries,
        districtCompanies,
        onChangeDistrictHandler,
        onChangeCompanyHandler,
        onAddBerriesHandler,
        onAddCompaniesHandler,
        onAddRanchHandler,
        addAssignments,
        addRanchAssignments,
        removeItem,
        removeAllItems
    } = useHandleAssignments(currentForm, assignments, setAssignments, setInitialButtonDisabled);
    const isOktaUser = isPersonaIsOKTA(selectedPersonaId);

    useEffect(() => {
        setCurrentForm(forms.find((item) => item.persona === getPersonaIdFromPersona(selectedPersona)));
    }, [selectedPersona]);

    useEffect(() => {
        if (currentForm) {
            getUserProfile();
            getUserPersonas();
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES) {
                fetchDistrictAssignment(selectedPersonaId, rowData.UserOktaId);
            }
        }
    }, [currentForm]);

    const navigate = useNavigate();

    useCallback(() => {
        getReports();
    }, [userLang]);

    const { formatPayload, formattingDistrictPayload, formattingCompanyRanchPayload, formattingDistrictCompanyPayload } = useFormatPayload(
        true,
        assignments,
        setAssignments,
        currentForm,
        setAssignmentsNotMatchingWithMasterData
    );

    useEffect(() => {
        if (currentForm) {
            if (currentForm.assignmentType === AssignmentTypes.DISTRICT_BERRIES) setAssignments(assignedDistrictBerries);
        }
    }, [assignedDistrictBerries]);

    useEffect(() => {
        currentForm?.reports && setOldReports(reportsData);
    }, [currentForm, reportsData]);

    const getUserProfile = async () => {
        const fetchUserPayload = {
            RequestorPersonaId: userPersona,
            AuthIdFilter: rowData.UserOktaId,
            PersonaId: selectedPersona === PERSONA_NAME.GROWERADMIN ? PERSONA_NAME.RANCHADMIN : selectedPersona
        };
        const data = await fetchUsers(fetchUserPayload);
        if (!data.display.errorCode) {
            if (data.raw.data.Response.length > 0) {
                rowData.Persona = data.display?.Response[0].Persona;
                rowData.DefaultPersona = data.display?.Response[0].DefaultPersona;
                setDefaultPersona(data.display?.Response[0].DefaultPersona);
                setCurrentEditUser(data.display.Response);
                const userInfo = data.display.Response[0];
                // initializing cognito payload (used when a user only changes assignments) otherwise payload was becoming undefined
                setCognitoPayload({
                    firstName: userInfo.FirstName,
                    lastName: userInfo.LastName,
                    email: userInfo.Email,
                    phone: userInfo.Phone,
                    gpaLang: userInfo.Lang,
                    defaultContact: isOktaUser ? data.display.Response[0].IsContact : '0' // cognito users should not be part of contact
                });
                if (currentForm.assignmentType === AssignmentTypes.DISTRICTS) formattingDistrictPayload(data.raw.data.Response[0].Districts);
                if (currentForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
                    if (data.raw.data.Response[0].Ranches && data.raw.data.Response[0].Companies)
                        formattingCompanyRanchPayload([...data.raw.data.Response[0].Ranches, ...data.raw.data.Response[0].Companies]);
                }
                if (currentForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY) {
                    if (data.raw.data.Response[0].Districts && data.raw.data.Response[0].Companies)
                        formattingDistrictCompanyPayload([...data.raw.data.Response[0].Districts, ...data.raw.data.Response[0].Companies]);
                }
            } else setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
        }
    };

    const getUserPersonas = async () => {
        const token = oktaAuth.getAccessToken();
        try {
            const data = await Call(
                {
                    name: 'personaId',
                    url: APIEndPoints.GET_PERSONA_ID(rowData.UserOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setAvailablePersona(data.display.UserDetails);
        } catch (error) {
            console.log(error);
            logEvent(EventConstants.GET_USER_PERSONAS, error);
        }
    };

    const changePersona = async (changedValue) => {
        setDefaultPersona(changedValue);
        const response = await makeDefaultPersona(getPersonaIdFromPersona(changedValue), currentEditUser[0].UserOktaId);
        if (changeDefaultPersonaError)
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Could not change the default persona. Please try again',
                nameSpacing: NAMESPACE.USERS
            });
        else if (response === 'Request Processed Successfully')
            setShowToast({ toastMessage: 'Default persona changed successfully', nameSpacing: NAMESPACE.USERS });
    };

    const getCognitoPayload = (payload) => {
        if (!areObjectsEqual(payload, cognitoPayload)) {
            setInitialButtonDisabled(false);
            setCognitoPayload(payload);
        }
    };

    const createAssignmentsHandler = async () => {
        const isOktaUser = isPersonaIsOKTA(selectedPersonaId);
        const payload = {
            PersonaDetails: {
                UserOktaId: currentEditUser[0].UserOktaId,
                FirstName: isOktaUser ? currentEditUser[0].FirstName : cognitoPayload.firstName,
                LastName: isOktaUser ? currentEditUser[0].LastName : cognitoPayload.lastName,
                UserPhone: isOktaUser ? currentEditUser[0].Phone || '' : cognitoPayload.phone,
                UserEmail: isOktaUser ? currentEditUser[0].Email : cognitoPayload.email,
                UserLanguage: cognitoPayload.gpaLang || currentEditUser[0].Lang || 'en',
                defaultContact: isOktaUser && cognitoPayload.defaultContact ? cognitoPayload.defaultContact + '' : '0'
            }
        };
        if (currentForm && currentForm.assignments && currentForm.assignments.length > 0) {
            const field = currentForm.assignments[0].field;
            payload[field] = formatPayload();
        }

        if (currentForm.reports) {
            await assignReportsHandler(currentEditUser[0].UserOktaId);
            await deleteReportsHandler(currentEditUser[0].UserOktaId);
        }
        !isOktaUser && (await updateCognitoUserFn(currentEditUser[0].UserOktaId));

        const cognitoPersonas = addPersonaFromDropdownClick
            ? Array.from(new Array(1), () =>
                  availablePersona.map((item) => {
                      return item.id || item.PersonaId;
                  })
              )
            : Array.from(new Array(1), () => selectedpersonaid);

        await createAssignments(
            isOktaUser ? Array.from(new Array(1), () => selectedpersonaid) : cognitoPersonas,
            availablePersona,
            payload,
            { id: currentEditUser[0].UserOktaId, UserSub: currentEditUser[0].UserOktaId },
            currentForm,
            getPersonaIdFromPersona(defaultPersona)
        );
        goBackHandler();
    };

    const updateCognitoUserFn = async (userId) => {
        const groups = availablePersona.map((item) => {
            const objP = persona.find((item2) => item2.Id === item.PersonaId || item2.Id === item.id);
            return objP.title;
        });
        const { firstName, lastName, gpaLang, isWhatsAppUser } = cognitoPayload;
        const error = await updateCognitoUser({ username: userId, firstName, lastName, locale: gpaLang, groups, isWhatsAppUser });
        if (error)
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'Something Went Wrong while updating User Details in One Driscolls!',
                nameSpacing: NAMESPACE.USERS
            });
    };

    const ReportItem = (itm) => {
        return { ReportId: itm.ReportId, ReportURL: itm.ReportURL };
    };

    const assignReportsHandler = async (userId) => {
        const reportUrls = [
            ...assignedReports.map((itm) => {
                return ReportItem(itm);
            }),
            ...oldReports.map((itm) => {
                return ReportItem(itm);
            })
        ];
        await assignReports(userId, reportUrls);
    };

    const deleteReportsHandler = async (userId) => {
        const reportUrls = [
            ...deletedReports.map((itm) => {
                return ReportItem(itm);
            })
        ];
        if (reportUrls && reportUrls.length > 0) {
            await deleteReports(userId, reportUrls);
        }
    };

    const addReport = (allReports, currentReport) => {
        setInitialButtonDisabled(false);
        if (currentReport && [...oldReports].find((itm) => itm.ReportURL === currentReport.ReportURL)) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Report Already Added', nameSpacing: NAMESPACE.USERS });
        } else {
            setAssignedReports(allReports);
        }
    };

    const removeReport = (data) => {
        setInitialButtonDisabled(false);
        if (data.ReportURL) {
            setOldReports(oldReports.filter((itm) => itm.ReportURL !== data.ReportURL));
            setDeletedReports([...deletedReports, data]);
        }
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.USER_MANAGEMENT}`);
    };

    return (
        <DrcContainer heading={getTextTranslated('Edit User', NAMESPACE.USERS)} goBackHandler={goBackHandler}>
            <Container>
                <>
                    <DriscollsLegend title={<TypographyTranslated nameSpacing={NAMESPACE.USERS}>User Actions</TypographyTranslated>} show>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                            <ChangePersona
                                defaultPersona={defaultPersona}
                                persona={rowData.Persona}
                                personaId={selectedPersonaId}
                                setDefaultPersona={changePersona}
                                availablePersona={availablePersona}
                                setAvailablePersona={setAvailablePersona}
                                selectedUserOktaId={rowData.UserOktaId}
                                parentComponent={ComponentName.EditUser}
                            />
                            {!isOktaUser && (
                                <AddPersona
                                    defaultPersona={defaultPersona}
                                    availablePersona={availablePersona}
                                    personaId={selectedPersonaId}
                                    setAvailablePersona={setAvailablePersona}
                                    setInitialButtonDisabled={setInitialButtonDisabled}
                                    setAddPersonaFromDropdownClick={setAddPersonaFromDropdownClick}
                                />
                            )}
                            <UserLifeCycle
                                migrationSectionOpen={migrationSectionOpen}
                                selectedPersona={selectedPersona}
                                setMigrationSectionOpen={setMigrationSectionOpen}
                                selectedUserOktaId={rowData.UserOktaId}
                                personaId={selectedPersonaId}
                                oktaAuth={oktaAuth}
                            />
                        </Grid>
                    </DriscollsLegend>

                    {/* Migration Section Disabled
                     */}

                    {/* Personal Information Section */}
                    <PersonalInformation
                        persona={selectedPersona}
                        currentEditUser={currentEditUser}
                        currentForm={currentForm}
                        getCognitoPayload={getCognitoPayload}
                        getInvalidFields={setInvalidFields}
                        parentComponent={ComponentName.EditUser}
                        loading={fetchUsersLoading}
                    />

                    {/* Assignment Section */}
                    <DriscollsLegend
                        title={<TypographyTranslated nameSpacing={NAMESPACE.USERS}>Assignments</TypographyTranslated>}
                        show={currentForm?.assignments.length > 0}
                    >
                        {selectedPersonaId !== PERSONAID.SUPERADMIN && currentForm && currentForm.assignments && (
                            <RenderAssignments
                                assignmentType={currentForm.assignmentType}
                                districtCompanies={districtCompanies}
                                onChangeDistrictHandler={onChangeDistrictHandler}
                                addAssignments={addAssignments}
                                selectedDistrict={selectedDistrict}
                                selectedCompany={selectedCompany}
                                addRanchAssignments={addRanchAssignments}
                                onAddRanchHandler={onAddRanchHandler}
                                onChangeCompanyHandler={onChangeCompanyHandler}
                                companyRanches={companyRanches}
                                onAddCompaniesHandler={onAddCompaniesHandler}
                                districtBerries={districtBerries}
                                onAddBerriesHandler={onAddBerriesHandler}
                            />
                        )}

                        {selectedPersonaId !== PERSONAID.SUPERADMIN && selectedPersonaId !== PERSONAID.PORTALUSER ? (
                            <DriscollsHContainer wrap className={classes.assignments}>
                                {currentForm &&
                                    (assignments || []).map((item, index) => (
                                        <DriscollsAssignmentCard
                                            key={index}
                                            data={item}
                                            assignmentType={currentForm.assignmentType}
                                            deleteItemHandler={removeItem}
                                            removeAllHandler={removeAllItems}
                                        />
                                    ))}
                            </DriscollsHContainer>
                        ) : (
                            <TypographyTranslated nameSpacing={NAMESPACE.USERS} variant="h5" sx={{ ml: 5 }}>
                                {selectedPersonaId === PERSONAID.SUPERADMIN ? 'User is a Super Admin' : 'User is a Portal User'}
                            </TypographyTranslated>
                        )}
                    </DriscollsLegend>
                    <DriscollsLegend
                        title={<TypographyTranslated nameSpacing={NAMESPACE.REPORT}>Reports</TypographyTranslated>}
                        show={currentForm?.reports}
                    >
                        {currentForm?.reports && (
                            <>
                                <ReportsAssignment
                                    values={reportsList}
                                    onChangeHandler={(e) => addReport(e, e[e.length - 1])}
                                    label={getTextTranslated('Reports', NAMESPACE.REPORT)}
                                />
                            </>
                        )}
                        {currentForm?.reports && (
                            <Box className={classes.reportsContainer}>
                                {([...assignedReports, ...oldReports] || []).map((item, index) => (
                                    <Chip
                                        key={index}
                                        label={item.label || item.Description}
                                        onDelete={item.ReportURL ? () => removeReport(item) : null}
                                    />
                                ))}
                            </Box>
                        )}
                    </DriscollsLegend>
                </>
                {assignmentsNotMatchingWithMasterData && (
                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 5 }}>
                        <DrcInformationBox hasError={true}>
                            <b>
                                <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'ALERT'}</TypographyTranslated>
                            </b>
                            <br />
                            <TypographyTranslated nameSpacing={NAMESPACE.USERS}>{'Assignments not matching'}</TypographyTranslated>
                        </DrcInformationBox>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} sx={{ marginY: 5 }}>
                    <DrcInformationBox
                        hasError={true}
                        style={{
                            display: (invalidFields || []).length > 0 ? 'block' : 'none'
                        }}
                    >
                        <ol>
                            {invalidFields &&
                                invalidFields.map((key) => {
                                    return <li key={key}>{key}</li>;
                                })}
                        </ol>
                    </DrcInformationBox>
                </Grid>
                {
                    <Grid item xs={12} sm={12} md={12} gap={1} display="flex" justifyContent="right" alignItems="center">
                        <DriscollsLoadingButton
                            variant={ButtonVariant.SECONDARY}
                            label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                            onClick={() => setOpenCancelDialog(true)}
                        />

                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            onClick={createAssignmentsHandler}
                            label={getTextTranslated('Submit', NAMESPACE.COMMON)}
                            loading={reportLoading || updateCognitoLoading || createAssignmentsLoading}
                            disabled={
                                initialButtonDisabled ||
                                invalidFields.length > 0 ||
                                (currentForm.assignments?.length > 0 && assignments?.length === 0)
                            }
                        />
                    </Grid>
                }
                <DrcDialog
                    title={getTextTranslated('Are you sure?', NAMESPACE.COMMON)}
                    open={openCancelDialog}
                    buttons={
                        <>
                            <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={() => setOpenCancelDialog(false)}>
                                {'Close'}
                            </TranslatedDrcButton>
                            <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isPrimary onClick={() => navigate(-1)}>
                                {'Ok'}
                            </TranslatedDrcButton>
                        </>
                    }
                >
                    <div>
                        <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Do you want to cancel user update?'}</TypographyTranslated>
                    </div>
                </DrcDialog>
            </Container>
        </DrcContainer>
    );
};

export default withOktaAuth(EditUser);
