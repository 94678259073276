/* eslint-disable indent */
import { Call } from '@driscollsinc/driscolls-display-rules';
import { Middleware } from '@driscollsinc/one-ring';
import { logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { getPersonaByTitle, isPersonaIsOKTA } from 'data/config/datasetup';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

const useCheckUserEmail = (oktaAuth: any) => {
    const TITLE_ATTRIBUTE = 'custom:title';
    const GIVEN_NAME_ATTRIBUTE = 'given_name';
    const FAMILY_NAME_ATTRIBUTE = 'family_name';
    const LOCALE_ATTRIBUTE = 'locale';
    const PHONE_NUMBER = 'phone_number';
    const EMAIL = 'email';
    const LOGIN_ID_FIELD = 'custom:loginIdField';
    const IS_WHATSAPP_USER = 'custom:isWhatsAppUser';
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { getTokenAndId } = useGetApiTokens();

    const checkUserEmail = async (email: string, phone: string, persona: number, loginField: 'phone' | 'email' | null) => {
        const isOktaUser = isPersonaIsOKTA(persona);
        setLoading(true);
        if (email && email.trim() !== '' && isOktaUser) {
            const { token } = await getTokenAndId(oktaAuth);
            try {
                const data = await Call(
                    {
                        name: 'ManageUser',
                        url: APIEndPoints.GET_USER_PROFILE(email),
                        method: 'GET',
                        options: {}
                    },
                    token
                );
                if (!data.display.errorCode) {
                    return data.display;
                }
            } catch (error) {
                console.log(error);
                setError(error);
                logEvent(EventConstants.CHECK_USER_EMAIL, error);
            } finally {
                setLoading(false);
            }
        } else if (!isOktaUser) {
            try {
                const { token } = await getTokenAndId(oktaAuth);
                const payload = loginField
                    ? {
                          email: loginField === 'email' ? email : '',
                          userPoolId: window.config.aws_user_pools_id,
                          phone: loginField === 'phone' ? phone : ''
                      }
                    : { email, phone, userPoolId: window.config.aws_user_pools_id };
                const res = await Middleware.Send('ManageUser', token, APIEndPoints.SEARCH_COGNITO_USER, 'POST', payload, {
                    overrideRequestMapping: true,
                    overrideResponseMapping: true
                });
                if (res?.message && res?.message === 'Success') {
                    const data = res?.Data?.Users?.map((user) => {
                        //check if title value is an array
                        const arrTitles = user?.Attributes?.find((attribute) => attribute.Name === TITLE_ATTRIBUTE).Value.split(',');
                        const firstName = user?.Attributes?.find((attribute) => attribute.Name === GIVEN_NAME_ATTRIBUTE)?.Value || '';
                        const lastName = user?.Attributes?.find((attribute) => attribute.Name === FAMILY_NAME_ATTRIBUTE)?.Value || '';
                        const locale = user?.Attributes?.find((attribute) => attribute.Name === LOCALE_ATTRIBUTE)?.Value || '';
                        const phone = user?.Attributes?.find((attribute) => attribute.Name === PHONE_NUMBER)?.Value || '';
                        const email = user?.Attributes?.find((attribute) => attribute.Name === EMAIL)?.Value || '';
                        const loginIdField = user?.Attributes?.find((attribute) => attribute.Name === LOGIN_ID_FIELD)?.Value || '';
                        const isWhatsAppUser = user?.Attributes?.find((attribute) => attribute.Name === IS_WHATSAPP_USER)?.Value || '';
                        //loop through the titles

                        return arrTitles.map((title) => {
                            const { Name, Id } = getPersonaByTitle(title);
                            return {
                                UserOktaId: user.Username,
                                PersonaName: Name,
                                DefaultPersonaId: Id,
                                PersonaId: Id,
                                phone: phone,
                                email: email,
                                Username: user?.Username,
                                loginIdField: loginIdField,
                                isWhatsAppUser: isWhatsAppUser,
                                firstName: firstName,
                                lastName: lastName,
                                gpaLang: locale,
                                locale: locale
                            };
                        });
                    });
                    return {
                        UserDetails: data,
                        cognitoEdit: true
                    };
                }
            } catch (error) {
                console.log(error);
                setError(error);
                logEvent(EventConstants.CHECK_USER_EMAIL, error);
            } finally {
                setLoading(false);
            }
        }
    };

    return { error, checkUserEmail, loading };
};

export default useCheckUserEmail;
