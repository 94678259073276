import { localizeReducer } from 'react-localize-redux';
import { combineReducers } from 'redux';
import masterReducer from './MasterReducer';

import { SERVICE_WORKER_UPDATE, SERVICE_WORKER_UPDATED } from '../actions/actions';

const initialState = {
    serviceWorkerRegistration: null,
    serviceWorkerUpdated: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };

        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer
});
