import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { IDistrict, IDistrictBerries } from 'components/appinitializer/IDistricts';
import { IUserDetails } from 'components/appinitializer/IUserDetails';
import { IPersona } from 'components/appinitializer/IUserPersona';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import React, { useMemo, useState } from 'react';
import { FeatureType, IShowToast } from 'types/application';
import { IFilterField } from 'ui/DrcFilters/DrcFilters.types';
import ApplicationContext from './ApplicationContext';

const AppProvider = (props: any) => {
    const [filters, setFilters] = useState<IFilterField[]>([]);
    const [userPersona, setUserPersona] = useState<Persona>(null);
    const [isMasterDataInitialized, setMasterDataInitialized] = useState<boolean>(false);
    const [userPersonas, setUserPersonas] = useState<IPersona[]>([]);
    const [userStatus, setUserStatus] = useState<boolean>(false);
    const [districtOptions, setDistrictOptions] = useState<IDistrict[]>([]);
    const [districtBerries, setDistrictBerries] = useState<IDistrictBerries[]>([]);
    const [basicInformation, setBasicInformation] = useState<any>(null);
    const [companies, setCompanies] = useState<any>([]);
    const [ranches, setRanches] = useState<any>([]);
    const [userProfile, setUserProfile] = useState<IUserDetails>(null);
    const [userLang, setUserLang] = useState<string>(null);
    const [pageTitle, setPageTitle] = useState<string>(null);
    const [redirectUrl, setInitializeRedirectUrl] = useState<string>(null);
    const [reportsList, setReportsList] = useState<any>([]);
    const [landingPage, setLandingPage] = useState<string>(null);
    const [userFeatures, setUserFeatures] = useState<Array<FeatureType>>([]);
    const [showToast, setShowToast] = useState<IShowToast>({ toastMessage: '', severity: DriscollsToastSeverity.SUCCESS });

    const value = useMemo(
        () => ({
            filters,
            setFilters,
            userPersona,
            setUserPersona,
            isMasterDataInitialized,
            setMasterDataInitialized,
            userPersonas,
            setUserPersonas,
            userStatus,
            setUserStatus,
            districtOptions,
            setDistrictOptions,
            basicInformation,
            setBasicInformation,
            companies,
            setCompanies,
            ranches,
            setRanches,
            userProfile,
            setUserProfile,
            userLang,
            setUserLang,
            pageTitle,
            setPageTitle,
            redirectUrl,
            setInitializeRedirectUrl,
            reportsList,
            setReportsList,
            landingPage,
            setLandingPage,
            userFeatures,
            setUserFeatures,
            showToast,
            setShowToast,
            districtBerries,
            setDistrictBerries
        }),
        [
            basicInformation,
            companies,
            districtOptions,
            districtBerries,
            isMasterDataInitialized,
            landingPage,
            pageTitle,
            ranches,
            redirectUrl,
            reportsList,
            userLang,
            userPersona,
            userPersonas,
            userProfile,
            userStatus,
            filters,
            userFeatures,
            showToast
        ]
    );
    return <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>;
};

export default AppProvider;
