import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'MMM-dd-yyyy';
const DATE_TIME_FORMAT = 'MMM-dd-yyyy hh:mm a';
const DATE_TIME_SECONDS_FORMAT = 'MMM-dd-yyyy hh:mm:ss a';
const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const NULL_TIME = 'T00:00:00';
const BEFORE_MIDNIGHT_TIME = 'T23:59:59';
const DATE_TIME_FULL_FORMAT = 'YYYY-MM-DD hh:mm:ss';

/* To explain the terms in the formula, the Javascript timestamp has milliseconds (*1000) 
which accounts for the 1000. There are 60*60*24 seconds in one day = 86400. Finally, 
Excel dates start on Jan 1 1900, and Javascript starts on Jan 1 1970. 
There are 25567 days between Jan 1 1900 and Jan 1 1970. */
const EXCEL_DAYS_DIFFERENCE = 25568;
const MIN_DATE = new Date(0);

const getDateFrom8Digits = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const getWeekNumber = (date, today) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const toString = (date, showTime = false) => {
    if (date !== null) {
        try {
            return format(date, showTime ? DATE_TIME_FORMAT : DATE_FORMAT);
        } catch (error) {
            return date;
        }
    } else {
        return null;
    }
};

const formatDateFromIso = (date, failPlaceholder = 'N/A') => {
    if (!!date && (date || '').length > 0) {
        try {
            let dateFixed = date.toString().trim();

            //Fixing date to have a T instead of a space that are correct in some ISO 8601
            if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
                dateFixed = dateFixed.replace(' ', 'T');
            }

            //All dates should be coming in as UTC (Zulu)
            //   except for those that have the null time which allows us to see dates only irregardless of time zone.
            if (!dateFixed.includes(NULL_TIME) && !dateFixed.includes('Z') && dateFixed.includes('T')) {
                dateFixed += 'Z';
            }

            const parsedIso = parseISO(dateFixed);

            return format(parsedIso, DATE_FORMAT);
        } catch (error) {
            console.error('Got an incorrect date format');

            return failPlaceholder;
        }
    } else {
        return failPlaceholder;
    }
};

const formatDateTimeFromIso = (date, showSeconds = false, failPlaceholder = 'N/A') => {
    if (!!date && (date || '').length > 0) {
        try {
            let dateFixed = date.toString().trim();

            //Fixing date to have a T instead of a space that are correct in some ISO 8601
            if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
                dateFixed = dateFixed.replace(' ', 'T');
            }

            //All dates should be coming in as UTC (Zulu)
            if (!dateFixed.includes('Z') && dateFixed.includes('T')) {
                dateFixed += 'Z';
            }

            const parsedIso = parseISO(dateFixed);
            return format(parsedIso, showSeconds ? DATE_TIME_SECONDS_FORMAT : DATE_TIME_FORMAT);
        } catch (error) {
            console.error('Got an incorrect date format');

            return failPlaceholder;
        }
    } else {
        return failPlaceholder;
    }
};

const FormatDateTimeFromSeconds = (seconds, format = ISO_DATE_FORMAT) => {
    try {
        let secondsToDate = new Date(seconds);
        let result;
        const clientSideDateRef = new Date();
        const offset = clientSideDateRef.getTimezoneOffset();
        secondsToDate = new Date(secondsToDate.getTime() - offset * 60 * 1000);
        switch (format) {
            case DATE_TIME_FULL_FORMAT:
                result = secondsToDate
                    .toISOString()
                    .replace(/T|Z|\.\d{3}/g, ' ')
                    .trim();
                break;
            default:
                result = secondsToDate.toISOString().split[0];
        }
        return result;
    } catch (error) {
        console.error(error);
        return '';
    }
};

const returnDate = (dateObj, convertTime, convertToPretty) => {
    if (convertToPretty) {
        return toString(dateObj, convertTime);
    }

    return dateObj;
};

const convert = (dateObj, options) => {
    options = { ...{ convertTime: false, convertToPretty: false, fromExcel: false, defaultMinDate: true }, ...(options || {}) };
    const excelDateTimeValue = '';
    const defaultMinDate = null;
    //Check to see if already date
    if (dateObj instanceof Date) {
        return returnDate(dateObj, options.convertTime, options.convertToPretty);
    }

    if (!isNaN(dateObj)) {
        if (options.fromExcel) {
            //Check to see if Excel
            const date = new Date(Math.round((excelDateTimeValue - EXCEL_DAYS_DIFFERENCE) * 86400 * 1000));
            return returnDate(date, options.convertTime, options.convertToPretty);
        } else {
            //Check to see if Epoch
            const date = new Date(0);
            date.setUTCSeconds(dateObj);
            return returnDate(date, options.convertTime, options.convertToPretty);
        }
    }

    if (typeof dateObj === 'string') {
        //Check to see ISO (date time)
        if (dateObj.includes(' ') || dateObj.includes('T')) {
            let isoDate = dateObj;
            if (!options.convertTime) {
                isoDate = isoDate.substring(0, Math.max(isoDate.indexOf(' '), isoDate.indexOf('T')));
            }

            const date = parseISO(isoDate);
            return returnDate(date, options.convertTime, options.convertToPretty);
        }

        //Attempt to convert string to date
        const date = new Date(dateObj);
        return returnDate(date, options.convertTime, options.convertToPretty);
    }

    return defaultMinDate ? MIN_DATE : null;
};

const roundOffTime = (minutes, date) => {
    const coeff = 1000 * 60 * minutes;
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
};

const getFirstAndLastDayOfMonth = (date) => {
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDate: format(firstDay, ISO_DATE_FORMAT), lastDate: format(lastDay, ISO_DATE_FORMAT) };
};

const getStartAndEndDatesFromRange = (range) => {
    if (range.length) {
        return range.length > 1
            ? { startDate: DuDateUtilities.ToIsoDate(range[0]), endDate: DuDateUtilities.ToIsoDate(range[range.length - 1]) }
            : { startDate: DuDateUtilities.ToIsoDate(range[0]), endDate: DuDateUtilities.ToIsoDate(range[0], false, 'N/A', true) };
    }
    return { startDate: DuDateUtilities.ToIsoDate(range.start), endDate: DuDateUtilities.ToIsoDate(range.end) };
};

const DuDateUtilities = {
    getStartAndEndDatesFromRange: getStartAndEndDatesFromRange,
    getFirstAndLastDayOfMonth: getFirstAndLastDayOfMonth,
    ToString: toString,
    ToPrettyDate: (value) => {
        if (!value) {
            return null;
        }

        if (value instanceof Date) {
            return toString(value);
        }

        if (typeof value === 'string') {
            if (value.indexOf('-') === 3) {
                //Pretty Format
                if (value.indexOf(' ')) {
                    //Remove time component
                    return value.split(' ')[0];
                }
                return value;
            }

            if (value.indexOf('-') === 4) {
                //ISO Format
                return formatDateFromIso(value);
            }
        }

        return null;
    },
    ToPrettyDateTime: (value) => {
        if (!value) {
            return null;
        }

        if (value instanceof Date) {
            return toString(value, true);
        }

        if (typeof value === 'string') {
            if (value.indexOf('-') === 3) {
                //Pretty Format
                return value;
            }

            if (value.indexOf('-') === 4) {
                //ISO Format
                return formatDateTimeFromIso(value);
            }
        }

        return null;
    },
    FormatDateFromIso: formatDateFromIso,
    FormatDateTimeFromIso: formatDateTimeFromIso,
    ToIsoDate: function (date, addNullTime = true, failPlaceholder = 'N/A', addBeforeMidnight = false) {
        try {
            const isoDate = format(date, ISO_DATE_FORMAT);
            return isoDate + (addNullTime ? NULL_TIME : addBeforeMidnight ? BEFORE_MIDNIGHT_TIME : '');
        } catch (error) {
            console.error('Got an incorrect date to be able to ISO format');

            return failPlaceholder;
        }
    },
    GetWeekNumber: getWeekNumber,
    GetPoolWeek: function (date) {
        return date.getFullYear().toString().substring(2) + ('0' + getWeekNumber(date).toString()).slice(-2);
    },
    TimeSpanToString: function (start, end) {
        let difference = end - start;

        if (difference > 1000) {
            difference /= 1000;

            if (difference > 60) {
                difference /= 60;

                if (difference > 60) {
                    return `${(difference / 60).toFixed(2)} h`;
                }

                return `${difference.toFixed(2)} min`;
            }

            return `${difference.toFixed(2)} s`;
        }

        return `${difference} ms`;
    },
    getDateFromExcelTimeStamp: function (excelDateTimeValue) {
        if (!excelDateTimeValue) {
            return '';
        }

        const jsDateTimeStamp = new Date(Math.round((excelDateTimeValue - EXCEL_DAYS_DIFFERENCE) * 86400 * 1000));
        return this.ToIsoDate(jsDateTimeStamp, '').split('T')[0];
    },
    Convert: convert,
    EXCEL_DAYS_DIFFERENCE,
    MIN_DATE,
    BEFORE_MIDNIGHT_TIME,
    DATE_TIME_FULL_FORMAT,
    FormatDateTimeFromSeconds: FormatDateTimeFromSeconds,
    RoundOffTime: roundOffTime,
    getDateFrom8Digits: getDateFrom8Digits
};

export default DuDateUtilities;
