import { createFormContext } from 'context/FormContext';

export interface IEventForm {
    IsActive: string;
    Name: string;
    Description: string;
    StartDate: Date | string;
    EndDate: Date | string;
    DistrictCode: string[];
}

const EventFormContext = createFormContext<IEventForm>();

export default EventFormContext;


