import { NAMESPACE } from 'i18n';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { libraryColumns } from '../../data/GridColumnMap';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import FileColumnRenderer from '../shared/cellrenderers/FileColumnRenderer';
import DrcDataGrid from '../shared/drc/DrcDataGrid';
import DrcMobileList from '../shared/drc/DrcMobileList';
import { IDocumentListingType } from './IDocumentListingType';
import ListItemRenderer from './ListItemRenderer';
const LibraryGridContainer = ({
    documentList,
    skipToken,
    handleLoadMore,
    loading,
    pageCount,
    page,
    handlePageChange,
    totalRecords,
    executionTime,
    getListing,
    type,
    downloadDocument,
    disableLoadMore
}: {
    title: string;
    documentList: Array<IDocumentListingType>;
    skipToken: string | undefined;
    handleLoadMore: () => void;
    loading: boolean;
    pageCount: number;
    page: number;
    handlePageChange: any;
    totalRecords: number;
    executionTime: number;
    getListing: any;
    type: string;
    downloadDocument: any;
    disableLoadMore: boolean;
}) => {
    const navigate = useNavigate();
    const { getTextTranslated } = useLang();
    const isDesktop = useResponsive('up', 'md');
    const excludedColumns = type === 'sites' ? ['createdBy', 'lastModifiedBy'] : [];

    const sortedDocumentList = (): IDocumentListingType[] =>
        documentList?.sort((a, b) => {
            const dateA = new Date(a.createdDateTime);
            const dateB = new Date(b.createdDateTime);
            return dateB.getTime() - dateA.getTime();
        });

    const folderColumn = () => {
        return {
            field: 'Filename',
            headerName: 'File Name',
            width: 300,
            pinned: 'left',
            cellRenderer: FileColumnRenderer,
            resizable: false,
            sortable: false,
            suppressMovable: false,
            filter: false,
            cellRendererParams: {
                downloadDocument: (itemId, listId, fileOpenURL) => {
                    downloadDocument(itemId, listId, fileOpenURL);
                },
                openFolder: (rowData): void => {
                    getListing(rowData);
                },
                type: 'folder'
            }
        };
    };
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                padding: isDesktop ? '20px' : '0px',
                borderRadius: '20px'
            }}
        >
            {isDesktop ? (
                <DrcDataGrid
                    sizeColumnsToFit={true}
                    loading={loading}
                    rows={useLocation().pathname.includes('/documents') ? documentList : sortedDocumentList()}
                    loadingMessage={getTextTranslated('Loading Documents', NAMESPACE.COMMON)}
                    columns={libraryColumns(folderColumn(), excludedColumns)}
                    page={page}
                    totalUsers={totalRecords}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    paginationPageSize={totalRecords}
                    allowExport={false}
                    exportExcelHandler={null}
                    skipToken={skipToken}
                    handleLoadMore={handleLoadMore}
                    disableLoadMore={disableLoadMore}
                />
            ) : (
                <DrcMobileList
                    loadingMessage={getTextTranslated('Loading Documents', NAMESPACE.COMMON)}
                    page={page}
                    totalUsers={totalRecords}
                    executionTime={executionTime}
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    loading={loading}
                    getRenderer={(row) => (
                        <ListItemRenderer
                            view
                            row={row}
                            navigate={navigate}
                            downloadDocument={(itemId, listId, fileOpenURL) => {
                                downloadDocument(itemId, listId, fileOpenURL);
                            }}
                            onSelectFolder={(rowData) => {
                                getListing(rowData);
                            }}
                        />
                    )}
                    rows={sortedDocumentList()}
                    allowExport={false}
                    exportExcelHandler={null}
                />
            )}
        </div>
    );
};

export default LibraryGridContainer;
