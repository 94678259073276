import { Container } from '@mui/material';
import OktaSignIn from '@okta/okta-signin-widget';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import Login from '../../login/index';

/**
 * @property {object} styles style for the DrcOktaWidget
 */

class DrcOktaWidget extends Component {
    componentDidUpdate() {
        try {
            if (this.widget) {
                this.widget.remove();
            }

            this.widget = new OktaSignIn({
                baseUrl: this.props.baseUrl,
                language: this.props.activeLanguage ? this.props.activeLanguage.code : 'en', //Default to English
                ...this.props.config
            });

            this.widget.renderEl({ el: this.node }, this.props.onSuccess, this.props.onError);
        } catch (error) {
            console.log('Error');
        }
    }

    componentWillUnmount() {
        try {
            this.widget?.remove();
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: 'none' }} ref={(node) => (this.node = node)} />
                <Container maxWidth="sm">
                    <Login />
                </Container>
            </div>
        );
    }
}

export default withLocalize(DrcOktaWidget);
