import { NAMESPACE } from 'i18n';
import { useTranslation } from 'react-i18next';

export enum Lang {
    ENGLISH = 'en',
    SPANISH = 'es',
    FRENCH = 'fr'
}

const useLang = () => {
    const { t, i18n } = useTranslation([
        NAMESPACE.MAIN,
        NAMESPACE.MAIN,
        NAMESPACE.ANALYTICS,
        NAMESPACE.ANNOUNCEMENT,
        NAMESPACE.DASHBOARD,
        NAMESPACE.SUPPORT,
        NAMESPACE.REPORT,
        NAMESPACE.USERS,
        NAMESPACE.EVENTS,
        NAMESPACE.WEATHER
    ]);
    const changeLanguage = (lang: Lang) => {
        i18n.changeLanguage(lang);
    };

    const getCurrentLanguageSelected = () => {
        return <'en' | 'es' | 'fr'>i18n.language;
    };

    const getTextTranslated = (text, nameSpacing = NAMESPACE.MAIN) => {
        return t(text, { ns: [nameSpacing] });
    };

    return {
        changeLanguage,
        getCurrentLanguageSelected,
        getTextTranslated
    };
};

export default useLang;

export const getTxtTranslated = (text, nameSpacing = NAMESPACE.MAIN) => {
    const { t } = useTranslation([
        NAMESPACE.MAIN,
        NAMESPACE.MAIN,
        NAMESPACE.ANALYTICS,
        NAMESPACE.ANNOUNCEMENT,
        NAMESPACE.DASHBOARD,
        NAMESPACE.SUPPORT,
        NAMESPACE.REPORT,
        NAMESPACE.USERS,
        NAMESPACE.EVENTS,
        NAMESPACE.WEATHER
    ]);
    return t(text, { ns: [nameSpacing] });
};
