import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

export interface IUpdateLanguagePayload {
    UpdatedBy: string;
    AuthId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string | null;
    Lang: string;
    CreatedBy: string;
}

/**
 * This hook updates the user language
 * {@link APIEndPoints.POST_USER_PROFILE}
 */

const useUpdateLanguage = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    let error: null;

    const updateLanguageCall = async (payload: IUpdateLanguagePayload) => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            setLoading(true);
            const data = await Call(
                {
                    url: APIEndPoints.POST_USER_PROFILE(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors && data.errors.length > 0) {
                error = data.errors;
                return error;
            } else return data.display;
        } catch (err) {
            error = err;
        } finally {
            setLoading(false);
        }
    };
    return { updateLanguageCall, error, loading };
};
export default useUpdateLanguage;
