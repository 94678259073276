import { withOktaAuth } from '@okta/okta-react';
import ViewAttachments from 'components/announcement/ViewAttachments';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetAttachedFiles from 'hooks/useGetAttachedFiles';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
function DownloadQAReport(props) {
    useCheckMasterData();
    const location = useLocation();
    const navigate = useNavigate();
    const fileId = decodeURI(location.pathname.split('/')[4]);
    const { fileURL, isFileLoading } = useGetAttachedFiles(props.oktaAuth, fileId);

    const goBackHandler = (path) => {
        navigate(`/reports`);
    };

    const { getTextTranslated } = useLang();
    return (
        <DrcContainer heading={getTextTranslated('Download', NAMESPACE.COMMON)} goBackHandler={goBackHandler}>
            <ViewAttachments isFileLoading={isFileLoading} fileURL={fileURL} />
        </DrcContainer>
    );
}

export default withOktaAuth(DownloadQAReport);
