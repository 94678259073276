import { Call } from '@driscollsinc/driscolls-display-rules';
import DuAuthenticationUtilities from '../components/shared/Utilities/DuAuthenticationUtilities';

const API_VALID_TIMEOUT_MS = 3600000; // 1 hour - General timeout unless specified by the call to load.
const GENERIC_ERROR_MESSAGE = 'We\'re Having problems loading data';
const GENERIC_LOADING_MESSAGE = 'Loading Data';
const GENERIC_DONE_LOADING_MESSAGE = 'Data Load Complete, Processing Now';

const masterDataLoadedPerApi = {};

const getApisToLoad = (apis, currentTimeMs) => {
    return (
        apis.filter((api) => {
            if (masterDataLoadedPerApi[api.url] > 0) {
                const timeout = api.overrideTimeoutMs || API_VALID_TIMEOUT_MS;

                if (masterDataLoadedPerApi[api.url] + timeout > currentTimeMs) {
                    return false;
                }
            }

            return true;
        }) || []
    );
};

export const InitializeData = {
    CheckData: (apis) => {
        if (!Array.isArray(apis)) {
            return true;
        }

        //Look for what APIs are no longer valid
        const currentTimeMs = new Date().getTime();
        const apisToLoad = getApisToLoad(apis, currentTimeMs);

        return apisToLoad.length <= 0;
    },

    LoadApis: async (authState, apis, statusFunc, onSuccess) => {
        if (!Array.isArray(apis)) {
            return;
        }

        //Look for what APIs are no longer valid
        const currentTimeMs = new Date().getTime();
        const apisToLoad = getApisToLoad(apis, currentTimeMs);

        const totalApiCalls = apisToLoad.length;
        let apisFailed = 0;
        let apisSuccessful = 0;

        if (totalApiCalls <= 0) {
            return;
        }
        const cognitoToken = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(authState);
        if ((!authState?.isAuthenticated || !authState?.accessToken) && !authState && !cognitoToken) {
            statusFunc('Not Authorized to Load Data', totalApiCalls, totalApiCalls, true);
            return;
        }

        //Getting access token from authState if available as a precaution.
        const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(authState);

        //Setting Status at a good starting point
        statusFunc(GENERIC_LOADING_MESSAGE, 0, 0, totalApiCalls);
        const apiPromises = apisToLoad.map((api) => {
            //Loading each API async so that we can simultaneously load multiple apis at the same time
            return Call(api, token)
                .then((data) => {
                    if (!data || (data.errors.length && !data?.raw?.data)) {
                        apisFailed++;
                    } else {
                        api.returnFunction(data);
                        masterDataLoadedPerApi[api.url] = currentTimeMs;
                        apisSuccessful++;
                    }
                    statusFunc(
                        //message, successCount, errorCount, totalCount
                        apisFailed > 0 ? GENERIC_ERROR_MESSAGE : GENERIC_LOADING_MESSAGE,
                        apisSuccessful,
                        apisFailed,
                        totalApiCalls
                    );
                })
                .catch((error) => {
                    apisFailed++;
                    console.error(error);
                    statusFunc(
                        //message, successCount, errorCount, totalCount
                        apisFailed > 0 ? GENERIC_ERROR_MESSAGE : GENERIC_LOADING_MESSAGE,
                        apisSuccessful,
                        apisFailed,
                        totalApiCalls
                    );
                });
        });

        await Promise.all(apiPromises).then(() => {
            statusFunc(
                //message, successCount, errorCount, totalCount
                apisFailed > 0 ? GENERIC_ERROR_MESSAGE : GENERIC_DONE_LOADING_MESSAGE,
                apisSuccessful,
                apisFailed,
                totalApiCalls
            );
        });
        if (apisFailed === 0 && apisSuccessful === totalApiCalls) {
            onSuccess('Data loaded');
        }
    }
};
