import { IDistrict, IDistrictBerries } from 'components/appinitializer/IDistricts';
import { IUserDetails } from 'components/appinitializer/IUserDetails';
import { IPersona } from 'components/appinitializer/IUserPersona';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { createContext } from 'react';
import { FeatureType, IShowToast } from 'types/application';
import { IFilterField } from 'ui/DrcFilters/DrcFilters.types';

interface IAppContext {
    filters: IFilterField[] | [];
    setFilters: (filters: IFilterField[]) => void;
    userPersona: Persona | null;
    setUserPersona: (userPersona: Persona) => void;
    isMasterDataInitialized: boolean;
    setMasterDataInitialized: (isMasterDataInitialized: boolean) => void;
    userPersonas: Array<IPersona> | null;
    setUserPersonas: (personas: Array<IPersona>) => void;
    userStatus: boolean | any;
    setUserStatus: (status: boolean) => void;
    districtOptions: Array<IDistrict> | null;
    setDistrictOptions: (options: Array<IDistrict>) => void;
    districtBerries: Array<IDistrictBerries> | null;
    setDistrictBerries: (options: Array<IDistrictBerries>) => void;
    basicInformation: any;
    setBasicInformation: (data: any) => void;
    companies: any | [];
    setCompanies: (data: any) => void;
    ranches: any | [];
    setRanches: (data: any) => void;
    userProfile: IUserDetails | null;
    setUserProfile: (dat: IUserDetails) => void;
    userLang: any | null;
    setUserLang: (value: string) => void;
    pageTitle: string | null;
    setPageTitle: (lang: string) => void;
    redirectUrl: string | null;
    setInitializeRedirectUrl: (url: string) => void;
    reportsList: string | null;
    setReportsList: (list: any) => void;
    landingPage: string | null;
    setLandingPage: (url: string) => void;
    userFeatures: Array<FeatureType>;
    setUserFeatures: (features: Array<FeatureType>) => void;
    showToast: IShowToast;
    setShowToast: (toast: IShowToast) => void;
}

const ApplicationContext = createContext<IAppContext>({} as IAppContext);

export default ApplicationContext;
