import Button, { ButtonProps } from '@mui/material/Button';
import { NAMESPACE } from 'i18n';
import React, { CSSProperties, PropsWithChildren } from 'react';
import useLang from '../../hooks/useLang';
interface TranslatedDrcButtonProps extends ButtonProps {
    floatRight?: boolean;
    fullWidth?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    noStyle?: boolean;
    useUppercaseText?: boolean;
    nameSpacing?: NAMESPACE;
    customBtnStyles?: { [key: string]: string };
}

/**
 * @description Custom button for the GEP application
 * @property {string} children - the button label
 * @property {boolean} isPrimary - determines the variant of the MUI button as 'contained'
 * @property {boolean} isSecondary - determines the variant of the MUI button as 'outlined'
 * @property {boolean} noStyle - if true, accepts a JavaScript object with custom styles
 * @property {boolean} useUppercaseText - if true, makes the text in the label uppercase
 * @property {boolean} floatRight - if true, aligns the button to the right
 * @property {boolean} fullWidth - if true, sets the button width to 100%
 * @property {string} nameSpacing - property for the translations function to convert text, the default is main, but it can be added as a prop to the component
 *
 */
const TranslatedDrcButton = (props: PropsWithChildren<TranslatedDrcButtonProps>) => {
    const { children, isPrimary, isSecondary, noStyle, useUppercaseText, floatRight, fullWidth, nameSpacing = NAMESPACE.MAIN, ...rest } = props;
    const { getTextTranslated } = useLang();
    const buttonVariant = isPrimary ? 'contained' : isSecondary ? 'outlined' : 'text';
    const label = children as string;
    const styleObj: CSSProperties = !noStyle
        ? {
              textTransform: useUppercaseText ? 'uppercase' : 'none',
              borderRadius: '4px',
              width: fullWidth ? '100%' : 'inherit',
              float: floatRight ? 'right' : 'inherit',
              margin: '0.5rem',
              ...props.customBtnStyles
          }
        : {};

    return (
        <Button variant={buttonVariant} style={styleObj} className={props.className} {...rest}>
            {getTextTranslated(label, nameSpacing as NAMESPACE)}
        </Button>
    );
};

export default TranslatedDrcButton;
