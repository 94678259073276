import ApplicationContext from 'context/ApplicationContext';
import React, { useContext } from 'react';
import AppInitializer from './AppInitializer';
const InitData = () => {
    const {
        setUserPersona,
        setMasterDataInitialized,
        setUserPersonas,
        setDistrictOptions,
        setDistrictBerries,
        setBasicInformation,
        setCompanies,
        setRanches,
        setUserStatus,
        setUserProfile,
        setUserLang,
        setPageTitle,
        setInitializeRedirectUrl,
        setReportsList,
        setLandingPage,
        setFilters,
        setUserFeatures
    } = useContext(ApplicationContext);
    return (
        <AppInitializer
            setDistrictOptions={setDistrictOptions}
            setDistrictBerries={setDistrictBerries}
            setBasicInformation={setBasicInformation}
            setPersona={setUserPersona}
            setMasterDataInitialized={setMasterDataInitialized}
            setUserPersonas={setUserPersonas}
            setCompanies={setCompanies}
            setRanches={setRanches}
            setUserProfile={setUserProfile}
            setUserLang={setUserLang}
            setPageTitle={setPageTitle}
            setInitializeRedirectUrl={setInitializeRedirectUrl}
            setReportsList={setReportsList}
            setLandingPage={setLandingPage}
            setUserStatus={setUserStatus}
            setFilters={setFilters}
            setUserFeatures={setUserFeatures}
        />
    );
};

export default InitData;
