import { withOktaAuth } from '@okta/okta-react';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import { ROUTES } from 'constants/routes';
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import isAdmin, { isPersonaMatches, isSuperAdmin } from 'utils/personasUtils';
import ApplicationContext from '../../context/ApplicationContext';
import AdminDashboard from './admin/AdminDashboard';
import GrowerDashboard from './grower/GrowerDashboard';
import ReportBuilderDashboard from './reportbuilder/ReportBuilderDashboard';
import SuperAdminDashboard from './superAdmin/SuperAdminDashboard';
const DashboardRoutes = (props: any) => {
    const { userPersona } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        if (isSuperAdmin(userPersona)) {
            navigate(ROUTES.SUPER_ADMIN);
            return;
        }
        if (isAdmin(userPersona)) {
            navigate('admin');
        } else if (isPersonaMatches(userPersona, Persona.REPORT_BUILDER)) {
            navigate('reportbuilder');
        } else {
            navigate('grower');
        }
    }, [userPersona, pathname]);

    return (
        <Routes>
            <Route
                path={ROUTES.SUPER_ADMIN}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN]}>
                            <SuperAdminDashboard />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.ADMIN}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.RANCH_ADMIN, Persona.QA_ADMIN]}>
                            <AdminDashboard />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.GROWER}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[
                                Persona.HARVEST_PLANNER,
                                Persona.RANCH_PLANNER,
                                Persona.PALLET_BUILDER,
                                Persona.GROWER_USER,
                                Persona.PORTAL_USER,
                                Persona.SETTLEMENT_USER,
                                Persona.QUALITY_VIEWER
                            ]}
                        >
                            <GrowerDashboard />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.REPORT_BUILDER}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.REPORT_BUILDER]}>
                            <ReportBuilderDashboard />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default withOktaAuth(DashboardRoutes);
