import { Call } from '@driscollsinc/driscolls-display-rules';
import { INotification } from 'components/dashboard/Notifications/NotificationType';
import { useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of contacts for an user
 * {@link APIEndPoints.GET_NOTIFICATIONS}
 */
const useFetchNotifications = (oktaAuth, personaId) => {
    const [data, setData] = useState<Array<INotification> | []>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getTokenAndId } = useGetApiTokens();

    const fetch = async () => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    url: APIEndPoints.GET_NOTIFICATIONS(userOktaId, personaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data?.errors?.length > 0) setError('ERROR'); // we're getting an empty array of errors for a successful data load
            else setData(data.display);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    };
    useEffect(() => {
        fetch();
    }, []);
    return { data, loading, error };
};
export default useFetchNotifications;
