import { Divider, Link, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import { urls } from 'constants/urls';
import React from 'react';
import styles from './DocumentLibrary.module.scss';
import { listingTypes } from './documentLibraryEnum';

const ListItemRenderer = ({ row, navigate, view, onSelectFolder, downloadDocument }) => {
    const supportedTypes = ['docx', 'pptx', 'xlsx'];
    const fileName = decodeURI(row?.webUrl.split('/').pop());
    const fileType = fileName.split('.').pop();
    const isFolder = !row?.contentType || row?.contentType?.name === listingTypes.FOLDER;
    const eTag = row?.eTag ? row?.eTag?.split('"')[1].split(',')[0] : row?.id; //id is when item is a searched result item
    const webUrl = decodeURI(row?.webUrl);
    const fileOpenURL = supportedTypes.includes(fileType)
        ? webUrl.split(urls.SHARED_DOCUMENTS)[0] + `${urls.LAYOUT}{${eTag}}&file=${fileName}${urls.DEFAULT_ACTION}`
        : webUrl;
    const Icon = ({ name }) => (
        <ImageComponent size={'small'} src={`/icons/sharepoint/${name}.svg`} brokenImage={`/icons/sharepoint/genericfile.svg`} />
    );

    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>{isFolder ? <Icon name="folder" /> : <Icon name={fileType} />}</ListItemAvatar>
                <ListItemText
                    onClick={() => (isFolder ? onSelectFolder(row) : null)}
                    primary={
                        !isFolder ? (
                            <Link
                                onClick={() => {
                                    downloadDocument(eTag, row?.parentReference?.siteId, fileOpenURL);
                                }}
                                component="button"
                                className={styles.doclink}
                            >
                                {fileName}
                            </Link>
                        ) : (
                            row.displayName
                        )
                    }
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        letterSpacing: 0
                    }}
                    secondary={
                        <Stack spacing={1}>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {row?.createdBy?.user?.displayName}
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};
export default ListItemRenderer;
