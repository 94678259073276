import { styled } from '@mui/material/styles';
import MasterDataUtilities from 'data/MasterDataUtilities';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import NavigationBar from '../../components/navigationBar/NavigationBar';
import useCheckUserToken from '../../hooks/useCheckUserToken';
import Header from './header';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 74;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    backgroundColor: '#F6F5FA'
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    const isLoggedIn = useCheckUserToken();
    // hook is not working when we reload as cognito user -- have to check why (adding this here so that it won't be a showstopper)
    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    useMapSuperAdminData();

    return (
        <StyledRoot>
            {isLoggedIn ? (
                <Fragment>
                    <Header onOpenNav={() => setOpen(true)} />
                    <NavigationBar openNav={open} onCloseNav={() => setOpen(false)} />
                    <Main>
                        <Outlet />
                    </Main>
                </Fragment>
            ) : (
                <Outlet />
            )}
        </StyledRoot>
    );
}
