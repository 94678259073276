import FeaturesName from "constants/featuresEnum";
import ApplicationContext from "context/ApplicationContext";
import React, { PropsWithChildren, useContext } from "react";
import { isFeatureActive } from "utils/features.utils";

interface FeatureProtectProtectProps {
    featureName: FeaturesName
}

/**

 */
const FeatureProtect = (props: PropsWithChildren<FeatureProtectProtectProps>) => {
    const { featureName } = props;
    const { userFeatures } = useContext(ApplicationContext);
    if (isFeatureActive(userFeatures, featureName)) {
        return <>{props.children}</>;
    } else {
        return null;
    }
};

export default FeatureProtect;