import { Call } from '@driscollsinc/driscolls-display-rules';
import { logError, logEvent } from 'analytics/AnalyticsHandler';
import { IPersona } from 'components/appinitializer/IUserPersona';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { EventConstants } from 'constants/EventConstants';
import { ROUTES } from 'constants/routes';
import { getPersonaLandingPage, isPersonaAllowedInTheApp } from 'data/config/datasetup';
import { useEffect, useState } from 'react';
import APIEndPoints from 'services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This fetches the personas associates with a logged-in user
 * @param oktaAuth Okta auth object
 * @param redirectUrl url to redirect
 * @id has to be the identifier for the folder
 */
const useGetInitialAppData = (oktaAuth: unknown, redirectUrl: string) => {
    const { getTokenAndId } = useGetApiTokens();
    const [userPersonas, setUserPersonas] = useState<Array<IPersona> | []>([]);
    const [userPersona, setUserPersona] = useState<Persona>(null);
    const [invalidPersona, setInvalidPersona] = useState<Array<IPersona> | []>([]);
    const [defaultPersonaName, setDefaultPersonaName] = useState<string>(null);
    const [landingPage, setLandingPage] = useState<string>(null);
    const [inactiveUser, setInactiveUser] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [executionTime, setExecutionTime] = useState(0);
    const [initializeRedirectUrl, setInitializeRedirectUrl] = useState<string>(null);

    const showLoader = () => {
        setLoading(true);
    };

    const hideLoader = () => {
        setLoading(false);
    };

    const loadPersonas = async () => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        //
        setInvalidPersona([]);
        if (userOktaId) {
            try {
                showLoader();
                const response = await Call(
                    {
                        url: APIEndPoints.GET_PERSONA_ID(userOktaId),
                        method: 'GET',
                        options: {}
                    },
                    token
                );
                if (response?.display?.UserDetails) {
                    const personaSaved = parseInt(localStorage.getItem('defaultPersona'));
                    const defaultPersonaId = personaSaved ? personaSaved : response.display.UserDetails[0].DefaultPersonaId;
                    const defaultPersonaObj = response.display.UserDetails.find((item) => {
                        return item.PersonaId === defaultPersonaId;
                    });
                    if (defaultPersonaObj && isPersonaAllowedInTheApp(defaultPersonaId)) {
                        setUserPersonas(response.display.UserDetails);
                        setUserPersona(defaultPersonaId);
                        setLandingPage(getPersonaLandingPage(defaultPersonaId));
                        logEvent(EventConstants.USER_LOGGED_IN, { persona: defaultPersonaObj?.PersonaName });
                    } else {
                        setDefaultPersonaName(defaultPersonaObj?.PersonaName);
                        setInvalidPersona(response.display?.UserDetails);
                    }
                    if (!redirectUrl || redirectUrl === 'undefined') {
                        setInitializeRedirectUrl(getPersonaLandingPage(defaultPersonaId));
                    }
                } else if (response?.display?.Message) {
                    setInactiveUser(true);
                }
            } catch (err) {
                setError(err);
                logError(EventConstants.PERSONA_LOADING_ERROR, error?.message);
                window.location.replace(window.location.protocol + '//' + window.location.host + ROUTES.LOGIN);
            } finally {
                hideLoader();
            }
        }
    };

    useEffect(() => {
        loadPersonas();
    }, [userPersona]);

    return {
        inactiveUser,
        error,
        executionTime,
        loading,
        initializeRedirectUrl,
        userPersonas,
        userPersona,
        loadPersonas,
        landingPage,
        invalidPersona,
        defaultPersonaName
    };
};
export default useGetInitialAppData;
