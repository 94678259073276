import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook raises a request to Regional Admin
 * {@link APIEndPoints.POST_SUPPORT_REQUEST}
 */

const useRaiseRequest = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setShowToast } = useContext(ApplicationContext);

    const raiseRequest = async (payload: any) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'Resquest',
                    url: APIEndPoints.POST_SUPPORT_REQUEST(),
                    method: 'POST',
                    options: {},
                    data: {
                        RequesterAuthId: userOktaId,
                        UpdatedBy: userOktaId,
                        CreatedBy: userOktaId,
                        UpdatedAt: new Date().toISOString(),
                        ...payload
                    }
                },
                token
            );
            if (data.errors?.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            } else {
                setShowToast({ toastMessage: 'Request sent successfully!' });
                navigate(`${ROUTES.SEPARATOR}${ROUTES.SUPPORT}`);
            }
        } catch (error) {
            setError('Error');
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    const raiseRequestHandler = (payload: any) => {
        raiseRequest(payload);
    };

    return { error, raiseRequestHandler, loading };
};
export default useRaiseRequest;
