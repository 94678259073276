import ReplyIcon from '@mui/icons-material/Reply';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import styles from './GenericRenderer.module.css';

const ReplyColumnRenderer = (props) => {
    const isEditable = props.isEditable(props);
    return (
            <ButtonBase className={`${styles.actionButton} ${styles.primary}`} sx={{ opacity: !isEditable ? '0.3' : 1 }} size="small" onClick={() => props?.replyRow(props.data)} disabled={!isEditable}>
                <ReplyIcon />
            </ButtonBase>
    );
};

export default ReplyColumnRenderer;
