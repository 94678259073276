import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Card, CardContent, CardHeader, List, ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useState } from 'react';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import styles from './AssignEntityCard.module.scss';

const AssignmentEntityCard = ({ assignments, changeEvent, entity, loading }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { getTextTranslated } = useLang();

    const handleListItemClick = (selectedItem, index) => {
        setSelectedIndex(index);
        changeEvent(selectedItem);
    };

    const getLabel = (assignment, type) => {
        switch (entity) {
            case 'ranch':
                return type === 'primary' ? assignment.RanchNbr : assignment.RanchName;
            case 'district':
                return type === 'primary' ? assignment.DistrictCode : assignment.DistrictName;
            case 'company':
                return type === 'primary' ? assignment.CompanyNbr : assignment.CompanyName;
        }
    };

    return (
        <Card>
            <CardHeader title="Available Entities" />

            <CardContent className={styles.cardcontent}>
                <article>
                    <List className={styles.assignmentlist}>
                        {loading ? (
                            <DriscollsSpinner text={getTextTranslated('Loading Assignments', NAMESPACE.USERS)} />
                        ) : (
                            (assignments || []).map((assignment, index) => {
                                return (
                                    <>
                                        <ListItemButton
                                            key={index}
                                            onClick={(event) => handleListItemClick(assignment, index)}
                                            selected={selectedIndex === index}
                                        >
                                            <ListItemText primary={getLabel(assignment, 'primary')} secondary={getLabel(assignment, 'secondary')} />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                );
                            })
                        )}
                        {assignments?.length === 0 && (
                            <span>
                                <TypographyTranslated>{`Sorry, No entities available for mapping`}</TypographyTranslated>
                            </span>
                        )}
                    </List>
                </article>
            </CardContent>
        </Card>
    );
};

export default AssignmentEntityCard;
