import { PrinterSetup } from '@driscollsinc/pti-module-ui';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import { NAMESPACE } from 'i18n';
import React, { useState } from 'react';
import styles from './Print.module.scss';

const DrcPrinterSetup = () => {
    const [openPrinterSetup, setOpenPrinterSetup] = useState(false);

    return (
        <>
            <TypographyTranslated nameSpacing={NAMESPACE.PTI} className={styles.printerLabel} onClick={() => setOpenPrinterSetup(true)}>
                How to setup printer?
            </TypographyTranslated>
            <PrinterSetup open={openPrinterSetup} setOpen={setOpenPrinterSetup} />
        </>
    );
};

export default DrcPrinterSetup;
