import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EditUser from './EditUser';
import EntityAssignment from './EntityAssignment';
import InviteUser from './InviteUser';
import UserManagement from './UserManagement';
import ViewUser from './ViewUser';

const UserManagementRoutes = () => {
    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN]}
            forbiddenComponent={<DrcPageNotFound />}
        >
            <Routes>
                <Route path="" element={<UserManagement />} />
                <Route path={ROUTES.USER_INVITE} element={<InviteUser />} />
                <Route path={ROUTES.USER_EDIT} element={<EditUser />} />
                <Route path={ROUTES.USER_VIEW} element={<ViewUser />} />
                <Route
                    path={ROUTES.USER_ASSIGNMENT}
                    element={
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN]}>
                            <EntityAssignment />
                        </PersonaBaseProtect>
                    }
                />
            </Routes>
        </PersonaBaseProtect>
    );
};

export default UserManagementRoutes;
