import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

export interface IDeleteEventPayload {
    EventId: string;
}

/**
 * This deletes an event based on an ID
 * {@link APIEndPoints.POST_CREATEEVENT}
 */

const useDeleteEvent = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    let ret: null;
    let error: null;

    const del = async (payload: IDeleteEventPayload) => {
        setLoading(true);
        try {
            const { token, userEmail, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    url: APIEndPoints.POST_CREATEEVENT(),
                    method: 'POST',
                    options: {},
                    data: {
                        UpdatedBy: userOktaId,
                        EventId: payload.EventId,
                        IsActive: '0'
                    }
                },
                token
            );
            if (data.errors && data.errors.length > 0) {
                error = data.errors;
            } else {
                ret = data.display;
            }
        } catch (err) {
            error = err;
        } finally {
            setLoading(false);
        }
        return { ret, error };
    };

    return { del, loading };
};
export default useDeleteEvent;
