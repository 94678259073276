import React from 'react';
import List from '@mui/material/List';
import useResponsive from '../../hooks/useResponsive';
import { INavBarDataObj } from './NavigationBar.types';
import NavigationBarItem from './NavigationBarItem';

import style from './NavigationBarSection.module.scss';
const NavigationBarSection = (props) => {
    const { navBarData } = props;
    const isDesktop = useResponsive('up', 'md');
    return (
        <List disablePadding className={style.navigationBarList}>
            {navBarData?.map((dataObj: INavBarDataObj, index) => {
                const { isEnabled } = dataObj;
                if (isEnabled) {
                    return <NavigationBarItem item={dataObj} isDesktop={isDesktop} key={index} />;
                }
            })}
        </List>
    );
};

export default NavigationBarSection;
