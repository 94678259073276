/**
 * @description list of languages supported
 * @constant LANGUAGES
 */
const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: 'Français', code: 'fr' }
    // { name: '汉语', code: 'zh-CN' },
];

// [English, Spanish, Chinese, French, Arabic, Portuguese]
/**
 * @description translations for commonly used words for translatedButton/fields/role
 * @constant COMMON_TRANSLATIONS
 */
const COMMON_TRANSLATIONS = {
    buttons: {
        okay: ['Okay', 'Bueno', '好的', 'D\'accord'],
        yes: ['Yes', 'Si', '是的', 'Oui'],
        no: ['No', 'No', '不', 'Non'],
        cancel: ['Cancel', 'Cancelar', '取消', 'Annuler'],
        continue: ['Continue', 'Seguir', '继续', 'Continuer'],
        back: ['Back', 'Regresa', '后退', 'Arrière'],
        oops: ['Oops, Just Kidding', 'Vaya, es broma', '哎呀，开玩笑的', 'Oups, je plaisante'],
        submit: ['Submit', 'Enviar', '提交', 'Soumettre'],
        abort: ['Abort', 'Abortar', '中止', 'Avorter'],
        agree: ['Agree', 'Acordar', '同意', 'Se mettre d\'accord'],
        add: ['Add', 'Añadir', '添加', 'Ajouter'],
        delete: ['Delete', 'Eliminar', '删除', 'Effacer'],
        download: ['Download', 'Descargar', '下载', 'Télécharger'],
        save: ['Save', 'Salvar', '节省', 'Sauvegarder'],
        accept: ['Accept', 'Aceptar', '接受', 'J\'accepte']
    },
    fields: {
        requiredField: ['This field is required', 'Este campo es requerido', '此字段是必需的', 'Ce champ est obligatoire.']
    },
    roles: {
        admin: ['Admin', 'Admin', '行政', 'Administrateur'],
        user: ['User', 'Usuario', '用户', 'Utilisateur'],
        readOnly: ['Read Only', 'Solo Lectura', '只读', 'Lecture seulement']
    },
    devices: {
        android: ['Android', 'Android', 'Android', 'Android'],
        ios: ['iOS', 'iOS', '的iOS', 'iOS'],
        windows: ['Windows', 'Windows', 'Windows', 'Windows'],
        mac: ['Mac OS', 'Mac OS', 'Mac OS', 'Mac OS']
    },
    links: {
        go: ['Go', 'Ir', '去', 'Va'],
        fetchingRedirect: ['Fetching Redirect', 'Obteniendo redireccionamiento', '获取重定向', 'Récupération de la redirection'],
        redirectDoesNotExist: ['Redirect Does Not Exist', 'La redirección no existe', '重定向不存在', 'La redirection n\'existe pas'],
        redirectingNow: ['Redirecting You Now', 'Redirigiéndote ahora', '现在重定向您', 'Vous redirigez maintenant']
    },
    login: {
        checking: ['Checking For an Existing Session', 'Comprobación de una sesión existente', '检查现有会话', 'Recherche d\'une session existante'],
        reauthorizing: ['Attempting to Reauthenticate', 'Intentando volver a autenticarse', '尝试重新认证', 'Tentative de authentication'],
        authorizing: ['Authorizing You Now', 'Autorizándolo ahora', '立即授权您', 'Vous autorisez maintenant'],
        loggedIn: ['You Are Logged In', 'Estás conectado', '您已登录', 'Vous êtes connecté'],
        loggedOut: ['You Must Log In Again', 'Debes iniciar sesión de nuevo', '您必须重新登录', 'Vous devez vous reconnecter'],
        notAuthorized: ['Not Authorized', 'No Autorizado', '未经授权', 'Pas autorisé'],
        youNotAuthorized: ['You are not authorized!', '¡No estas autorizado!', '您没有权限!', 'Vous n\'êtes pas autorisé !'],
        pleaseSignInAgain: [
            'Please Sign-Out and try with better credentials.',
            'Cierre la sesión y pruebe con mejores credenciales.',
            '请注销并尝试使用更好的凭据。',
            'Veuillez vous déconnecter et essayer avec de meilleures informations d\'identification.'
        ],
        pleaseSignIn: [
            'Please go back to the Sign-In page and try again.',
            'Vuelva a la página de inicio de sesión y vuelva a intentarlo.',
            '请返回登录页面并重试。',
            'Veuillez revenir à la page de connexion et réessayer.'
        ],
        loadingUser: ['Loading User...', 'Cargando usuario...', '正在加载用户...', 'Chargement de l\'utilisateur...'],
        loadingUserInfo: [
            'Loading User Information...',
            'Cargando información de usuario...',
            '正在加载用户信息...',
            'Chargement des informations utilisateur...'
        ],
        description: [
            'Access is allowed only for Registered',
            'El acceso está permitido solo para registrados',
            '仅允许注册用户访问',
            'L\'accès est autorisé uniquement pour les inscrits'
        ],
        login: ['Login', 'Acceso', '登录', 'Connexion'],
        logout: ['Logout', 'Cerrar sesión', '登出', 'Se déconnecter']
    },
    general: {
        notFound: ['Not Found', 'No Encontrado', '未找到', 'Pas trouvé'],
        pageNotFound: ['404 Page not found', '404 Pagina no encontrada', '404页面不存在', '404 Page non trouvée']
    },
    HelpTexts: {
        addNewType: ['Add new ${type}', 'Añadir nuevo ${type}', '添加新${type}', 'Ajouter un nouveau ${type}'],
        editType: ['Edit ${type}', 'Editar ${type}', '编辑${type}', 'Modifier ${type}'],
        changeTypeConfirmTitle: [
            'Confirm Change of ${type}',
            'Confirmar cambio de ${type}',
            '确认${type}的更改',
            'Confirmer le changement de ${type}'
        ],
        addNewTypeConfirmTitle: [
            'Confirm Add New ${type}',
            'Confirmar añadir nuevo ${type}',
            '确认添加新${type}',
            'Confirmer l\'ajout d\'un nouveau ${type}'
        ],
        deleteTypeConfirmTitle: ['Confirm Delete of ${type}', 'Confirmar eliminar ${type}', '确认删除${type}', 'Confirmer la suppression de ${type}'],
        addTypeConfirmTitle: [
            'Confirm Add New ${type}',
            'Confirmar añadir nuevo ${type}',
            '确认添加新${type}',
            'Confirmer l\'ajout d\'un nouveau ${type}'
        ]
    }
};

/**
 *
 * @constant DuCommonTranslations
 */
const DuCommonTranslations = { COMMON_TRANSLATIONS, LANGUAGES };

export default DuCommonTranslations;
