import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import ApplicationContext from 'context/ApplicationContext';
import { isPersonaIsOKTA } from 'data/config/datasetup';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook creates nd assigns different entities to the user
 * {@link APIEndPoints.POST_OKTA_MAKE_ASSIGNMENT}
 *
 */

const useCreateAssignments = (oktaAuth) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getTokenAndId } = useGetApiTokens();
    const { setShowToast } = useContext(ApplicationContext);

    const createAssignments = async (
        selectedPersonaIds,
        availablePersona,
        assignments,
        userData,
        personaForm,
        defaultPersonaForEdit,
        defaultPersona,
        usernamefield
    ) => {
        const { token, userEmail, userOktaId } = await getTokenAndId(oktaAuth);
        const isOktaUser = isPersonaIsOKTA(selectedPersonaIds[0]);
        const selectedUserPersonas = selectedPersonaIds.map((item) => {
            const objP = availablePersona.find((item2) => (item2.Id || item2.id) === item);
            return objP?.Name || objP?.label;
        });
        const assignmentPayload = {
            PersonaDetails: {
                Status: 1,
                PersonaId: selectedPersonaIds.toString(),
                DefaultPersonaId: defaultPersonaForEdit ? defaultPersonaForEdit : defaultPersona ? defaultPersona : selectedPersonaIds[0],
                ...(!defaultPersonaForEdit && { UserPersona: selectedUserPersonas.map((persona) => persona).join(', ') }),
                ...(!defaultPersonaForEdit && { UserPersonaId: selectedPersonaIds[0] + '' }),
                ...(!defaultPersonaForEdit && { DefaultUser: usernamefield }),
                UserOktaId:
                    (isOktaUser ? userData.id : userData?.UserSub || userData?.updatedUserData?.Username) || assignments.PersonaDetails.UserOktaId,
                IsContact: assignments.PersonaDetails.defaultContact || '0',
                CreatedBy: userEmail,
                CreatedById: userOktaId,
                UpdatedById: userOktaId,
                FirstName:
                    isOktaUser && !defaultPersonaForEdit
                        ? userData.profile?.firstName
                        : assignments.PersonaDetails?.FirstName || assignments.PersonaDetails?.firstName,
                LastName:
                    isOktaUser && !defaultPersonaForEdit
                        ? userData.profile?.lastName || ''
                        : assignments.PersonaDetails?.LastName || assignments.PersonaDetails?.lastName,
                UserPhone:
                    isOktaUser && !defaultPersonaForEdit
                        ? userData.profile?.mobilePhone
                        : assignments.PersonaDetails?.UserPhone || assignments.PersonaDetails?.phone,
                UserEmail:
                    isOktaUser && !defaultPersonaForEdit
                        ? userData.profile?.email
                        : assignments.PersonaDetails?.UserEmail || assignments.PersonaDetails?.email,
                UserCountry: isOktaUser ? '' : assignments.PersonaDetails?.UserCountry || assignments.PersonaDetails?.country,
                UserLanguage:
                    isOktaUser && !defaultPersonaForEdit
                        ? userData.profile?.gpaLang || 'en'
                        : assignments.PersonaDetails?.UserLanguage || assignments.PersonaDetails.gpaLang,
                CreatePasswordUrl: '',
                ActivationTokenExpirationTime: '7 Days',
                WebAppUrl: '',
                AppleAppUrl: 'https://apps.apple.com/us/app/grower-portal/id1472773270',
                AndroidAppUrl: 'https://play.google.com/store/apps/details?id=com.driscolls'
            }
        };
        if (personaForm.assignments?.length > 0) {
            assignmentPayload[personaForm.assignments[0].field] = assignments[personaForm.assignments[0].field];
        }
        try {
            setLoading(true);
            const data = await Call(
                {
                    name: 'ManageUser',
                    url: APIEndPoints.POST_OKTA_MAKE_ASSIGNMENT(
                        isOktaUser ? userData.id : userData?.UserSub || userData?.updatedUserData?.Username,
                        !!defaultPersonaForEdit
                    ),
                    method: 'POST',
                    options: {},
                    data: assignmentPayload
                },
                token
            );
            if (data?.display?.status) {
                setShowToast({ toastMessage: 'Save Successful' });
                return true;
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: data?.errors[0] });
            }
        } catch (err) {
            setError(err);
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Server error' });
            logEvent(EventConstants.ASSIGN_ERROR, err);
        } finally {
            setLoading(false);
        }
    };

    return { loading, createAssignments, error };
};
export default useCreateAssignments;
