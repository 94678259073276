import { useState } from 'react';
// @mui
import { Check, Language } from '@mui/icons-material';
import { IconButton, ListItemIcon, MenuItem, Popover, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import useLang from 'hooks/useLang';
import { withLocalize } from 'react-localize-redux';
import DuCommonTranslations from '../../../components/shared/Utilities/DuCommonTranslations';

// ----------------------------------------------------------------------

const LanguagePopover = (props) => {
    const [open, setOpen] = useState(null);
    const { getCurrentLanguageSelected, changeLanguage: changeUserLanguage } = useLang();

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const changeLanguage = (lang) => {
        changeUserLanguage(lang.code);
        props.setActiveLanguage(lang.code);
        props.handleLangChange && props.handleLangChange(lang.code, lang);
        handleClose();
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <Language />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        // width: 120,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75
                        }
                    }
                }}
            >
                <Stack spacing={0.75}>
                    {DuCommonTranslations.LANGUAGES.map((option) => (
                        <MenuItem key={option.code} selected={option.code === props?.activeLanguage?.code} onClick={() => changeLanguage(option)}>
                            {option.code === getCurrentLanguageSelected() ? (
                                <ListItemIcon sx={{ color: (theme) => theme.palette.primary }}>
                                    <Check />
                                </ListItemIcon>
                            ) : (
                                <span style={{ width: 35 }} />
                            )}
                            {option.name + ' - ' + option.code}
                        </MenuItem>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default withLocalize(LanguagePopover);
