import React from 'react';
import UnderConstruction from '../../components/shared/generic/UnderConstruction';
import DrcContainer from '../../layouts/dashboard/DrcContainer';

function Feedback() {
    return (
        <DrcContainer heading="Feedback">
            <UnderConstruction />
        </DrcContainer>
    );
}

export default Feedback;
