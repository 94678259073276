import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import DriscollsToolTip from '../../../ui/DriscollsToolTip/DriscollsToolTip';

const CellRenderer = (props) => {
    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                  }
                : // repeated contextmenu when irt is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleClose = (action) => {
        setContextMenu(null);
        props.handleContextMenu(action);
    };

    return (
        <DriscollsToolTip description={props.description} arrow={true} placement="bottom" className={null}>
            <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                <span>{props.value}</span>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
                >
                    {(props.menu || []).map((item) => (
                        <MenuItem key={item.action} onClick={() => handleClose(item.action)}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </DriscollsToolTip>
    );
};

export default CellRenderer;
