import { Box, Container, styled } from '@mui/material';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import sorryImage from '../../../Images/Feeling sorry-bro.svg';
import TranslatedDrcButton from '../../../ui/translatedButton/TranslatedDrcButton';
import DrcLegend from './DrcLegend';

/**
 * @description This DrcImplicitCallback Component is used to get the token when logging in to Okta.
 * @example
 * <Route
 *      path="/implicit/callback"
 *       render={(props) => (
 *          <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
 *      )}
 * />
 * @donotprint true
 * @category Security
 * @tags security login okta callback redirect
 * @property {jsx} backgroundPng background image to show while loading that is a png
 * @property {jsx} backgroundWebP background image to show while loading that is a webp, this allows for higher compression without loss of image quality
 * @property {string} redirect the url to redirect to once complete, leaving this alone will default to /InitializeApplication/ which is good for most uses
 * @class DrcImplicitCallback
 * @extends {React.Component}
 */

const StyledDrcLegend = styled(DrcLegend)({
    width: '100%',
    marginTop: 0,
    padding: '0 0 5px',
    fontSize: '2rem',
    textAlign: 'center',
    fontFamily: 'Rubik'
});

function DrcImplicitCallback(props) {
    const location = useLocation();
    const { authState } = useOktaAuth();

    const [isError, setIsError] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const springs = useSpring({
        from: { x: -100 },
        to: { x: 0 }
    });

    useEffect(() => {
        if (!authState || authState?.isAuthenticated === true) {
            return;
        }

        const url = location.search;
        let isUrlError = false;

        if (url.indexOf('state=') < 0) {
            isUrlError = true;
        }

        const callbackDetails = url.substring(url.indexOf('state='));
        const callbackChunks = callbackDetails.split('&');

        let error = 'Unknown Error';
        let errorDescription = 'An unknown error has occurred, please try to log in again. If this continues please seek assistance.';

        try {
            callbackChunks.forEach((chunk) => {
                if (chunk.includes('error=')) {
                    isUrlError = true;
                    let errorTitle = chunk.split('=')[1];

                    errorTitle = errorTitle.replace(/_/gi, ' ').trim();
                    error = errorTitle.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
                } else if (chunk.includes('error_description=')) {
                    isUrlError = true;
                    let errorInfo = chunk.split('=')[1];

                    errorDescription = errorInfo.replace(/\+|#|\//gi, ' ').trim();
                }
            });
        } catch {
            errorDescription = 'An unknown error has occurred, please try to login again. If this continues please seek assistance.';
        }

        setTitle(isUrlError ? error : 'Successfully Authorized');
        setDescription(isUrlError ? errorDescription : 'You are now logged in, we will take you to the application now.');
        setIsError(isUrlError);
    }, [authState]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '98%' }}>
            <Container hideRoute sx={{ maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <StyledDrcLegend style={{ textAlign: 'center' }}>
                        {!authState ? "Hold tight, we're authorizing you now" : !isError ? 'Successfully Authorized' : title || 'Unknown Error'}
                    </StyledDrcLegend>
                    <p style={{ textAlign: 'center' }}>{description}</p>
                    <LoginCallback />
                    {!!authState ? (
                        authState.isAuthenticated ? (
                            <Navigate to={props.redirect || '/InitializeApplication/'} />
                        ) : isError ? (
                            <>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <animated.div
                                        style={{
                                            width: 400,
                                            borderRadius: 8,
                                            ...springs
                                        }}
                                    >
                                        <Box component="img" src={sorryImage} sx={{ width: 400 }} />
                                    </animated.div>
                                </div>
                                <TranslatedDrcButton customBtnStyles={{ maxWidth: '150px' }} isPrimary to="/">
                                    {'Back to Login'}
                                </TranslatedDrcButton>
                            </>
                        ) : null
                    ) : null}
                </div>
            </Container>
        </Box>
    );
}

export default DrcImplicitCallback;
