import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches supports
 * {@link APIEndPoints.GET_SUPPORT}
 *
 */

export interface ISupportResponse {
    RequestId: string;
    DistrictId: string;
    RequestCreatedAt: string;
    Status: string;
    RequestBody: string;
    Priority: string;
    RequestHeader: string;
    Resolution: string;
    UserLanguage: string;
    RequestAttachmentURL: string;
    ResponseAttachmentURL: string;
    RequestCreatedBy: string;
    ResponseUpdatedBy: string;
    ResponseBody: string;
    RequesterAuthId: string;
}

const useGetResponseForm = (oktaAuth: any, requestId: number) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [currentResponse, setCurrentResponse] = useState<ISupportResponse>({} as ISupportResponse);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);

    const fetch = async () => {
        setLoading(true);
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'ManageUsers',
                    url: APIEndPoints.GET_SUPPORT(),
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: userOktaId,
                        RequestIdFilter: requestId
                    }
                },
                token
            );
            if (!data.display.errorCode) {
                setCurrentResponse(data.display.Support[0]);
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
        } catch (error) {
            console.log(error);
            setError('error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return { currentResponse, error, loading };
};
export default useGetResponseForm;
