import ListItem from '@mui/material/ListItem';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { getCompiledRoute } from 'routes/routesUtils';
import DLink from '../Link';
import { ICalendarEvent } from './CalendarTypes';
import style from './Event.module.scss';
import useGetLocale from '../../../hooks/useGetLocale';
import {simpleDateFormatterHandler} from '../../../utils/dateFormatterHelper';

const Event = ({ event }: { event: ICalendarEvent }) => {
    const {locale} = useGetLocale();
    return (
        <div className={style.eventContainer}>
            <ListItem>
                <DLink href={getCompiledRoute(ROUTES.EVENT_DETAILS, '' + event.EventId)} underline="none">
                    <div>
                        <p>{simpleDateFormatterHandler (event.StartDate, locale)}</p>
                        <p>{event.Name}</p>
                    </div>
                </DLink>
            </ListItem>
        </div>
    );
};

export default Event;
