import { urls } from '../constants/urls';
import APIEndPoints from './api';
const GET_DRIVE_LIST_ITEMS = (
    useroktaid: string,
    sitename: string,
    subsitename: string,
    itemid: string,
    requesttype = 'folders',
    language = 'en'
) => {
    return `${APIEndPoints.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.DRIVES}/${urls.CHILDREN}?${urls.USER_OKTA_ID}=${useroktaid}&${
        urls.SITE_ID
    }=${sitename}&${urls.REQUEST_TYPE}=${requesttype}&${urls.LANGUAGE}=${language}${itemid ? `&${urls.ITEMID}=${itemid}` : ''}${
        subsitename ? `&${urls.SUB_SITE_ID}=${subsitename}` : ''
    }`;
};

const GET_BERRY_CONNECT_CONTENT = (useroktaid: string, sitename: string, language = 'en') => {
    return `${APIEndPoints.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.DRIVES}/${urls.CONTENT}?${urls.USER_OKTA_ID}=${useroktaid}&${
        urls.SITE_ID
    }=${sitename}&${urls.LANGUAGE}=${language}`;
};

const GET_BERRY_CONNECT_BUSINESS_UNITS = (sitename: string) => {
    return `${APIEndPoints.BASE()}${urls.SHAREPOINT}/${urls.SITES}?${urls.SITE_ID}=${sitename}`;
};

const GET_BERRY_CONNECT_SEARCH = (query: string, sitename: string, useroktaid: string) => {
    return `${APIEndPoints.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.SHAREPOINT_SEARCH}?${urls.USER_OKTA_ID}=${useroktaid}&${
        urls.SHAREPOINT_SEARCH
    }=${encodeURIComponent(query)}&${urls.SITE_ID}=${sitename}`;
};

export { GET_BERRY_CONNECT_BUSINESS_UNITS, GET_BERRY_CONNECT_CONTENT, GET_BERRY_CONNECT_SEARCH, GET_DRIVE_LIST_ITEMS };
