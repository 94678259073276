interface IAssignments {
    field?: string;
    label?: string;
    type?: string;
}

export interface IInviteForms {
    persona: number;
    personalinformation: Array<IPersonaInfoObj>;
    assignments: Array<IAssignments>;
    reports: boolean;
    assignmentType: AssignmentTypes | null;
}

export interface IPersonaInfoObj {
    field: string;
    label: string;
    type: string;
    value?: string;
    pattern?: string | RegExp;
    validator?: (value: string) => string;
    helpMessage?: string;
    valueField?: string;
    data?: IDataOptions[];
}

export interface IDataOptions {
    id: number;
    label: string;
    value: string;
}

export interface IPersonaType {
    Id: number;
    Name: string;
    AssignmentGroup?: string;
    title?: string;
    type?: 'ADMIN' | 'USER';
    idp?: 'okta' | 'cognito';
    allowed?: boolean;
    readOnly?: boolean;
    landingPage?: string;
    notifications?: string[];
}

export interface IInvitablePersona {
    id: number;
    permitted: Array<{ id: number }> | [];
}

export enum AssignmentTypes {
    DISTRICTS = 'districts',
    DISTRICT_COMPANY = 'districtCompany',
    DISTRICT_BERRIES = 'districtBerries',
    COMPANY_RANCH = 'companyRanch'
}
