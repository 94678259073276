import { createFormContext } from 'context/FormContext';

export interface IUserManagementForm {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    country?: string;
    gpaLang?: string;
    role?: string;
    usernamefield?: string;
    Login?: RegExp;
}

const UserManageFormContext = createFormContext<IUserManagementForm>();

export default UserManageFormContext;
