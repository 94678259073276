/* eslint-disable react-hooks/exhaustive-deps */
import { BerryConnectEntryPoint, LevelType, syncTranslation } from '@driscollsinc/berry-connect-module-ui';
import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Container } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import BerryConnectContext from 'context/BerryConnectContext';
import useBerryConnect from 'hooks/useBerryConnect';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import style from './berryconnect.module.css';

function BerryConnect(props: any) {
    const { oktaAuth } = useOktaAuth();

    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const [level, setLevel] = useState(LevelType.FOLDER);

    const { setSubsiteId, subsiteId, siteId } = useContext(BerryConnectContext);
    const { getContent, content, getDriveItemListing, getBusinessUnits, searchBerryConnectSite, documentList, loading, contentLoading } =
        useBerryConnect(oktaAuth, getCurrentLanguageSelected());

    useEffect(() => {
        getContent(siteId);
    }, []);

    useEffect(() => {
        syncTranslation(getCurrentLanguageSelected());
    }, [getCurrentLanguageSelected]);

    useEffect(() => {
        if (level === LevelType.BUSINESS_UNIT) {
            getBusinessUnits(siteId);
        } else {
            setSubsiteId('');
            getDriveItemListing(siteId);
        }
    }, [level]);

    const clickHandler = async (item: any) => {


        //Logging the google analytics
        if (!item.IsFolder && item.DownloadUrl) {
            window.open(item.DownloadUrl, '_blank');
            logEvent(EventConstants.BERRY_CONNECT_FILE_DOWNLOAD, { url: item.DownloadUrl });
        } else {
            logEvent(EventConstants.ADD_BERRY_CONNECT_ITEM_CLICK, { name: item.Name, level: level });
            let _subsiteId;
            let itemId: string = item['Id'] || item['id'];
            if (item.displayName) {
                _subsiteId = itemId;
                itemId = null;
                setSubsiteId(_subsiteId);
            } else if (item.SubsiteId && item.Name) {
                _subsiteId = item.SubsiteId;
                itemId = null;
                setSubsiteId(_subsiteId);
            } else if (item.SubsiteId) {
                //We are back in the root
                itemId = null;
                setSubsiteId(_subsiteId);
                getBusinessUnits(siteId);
                return;
            }
            getDriveItemListing(null, _subsiteId ? _subsiteId : subsiteId, itemId, level === LevelType.BUSINESS_UNIT ? 'global' : 'folders');
        }
    };

    const doSearch = async (value: string) => {

        logEvent(EventConstants.BERRY_CONNECT_SEARCH_EVENT, { search: value });
        searchBerryConnectSite(value);
    };

    return (
        <DrcContainer
            heading={getTextTranslated('Berry Connect', NAMESPACE.COMMON)}
            style={{ paddingBottom: '0px' }}
            gridStyle={{ paddingBottom: '0px' }}
            footerStyle={style.sectionFooter}
            logoClassName={style.logoContainer}
            footerImageStyle={style.footerImage}
            logo={true}
            hideRoute={true}
            footer={true}
            hidePersona={true}
            footerImage="/icons/berryconnect-footer.jpg"
            logoImage="/icons/berryconnect-logo.png"
        >
            {contentLoading && <DriscollsSpinner text="Initializing Berry Connect" nameSpacing={NAMESPACE.BERRYCONNECT} />}
            <Container>
                <BerryConnectEntryPoint
                    content={{
                        videos: content?.Videos?.map((vid) => {
                            return { ...vid, url: vid.DownloadUrl };
                        }),
                        videoConfig: { playing: false, controls: true },
                        messages: (content?.Messages || []).map((message) => {
                            return { ...message, text: message.Content };
                        })
                    }}
                    data={documentList}
                    loading={loading}
                    enableCustomIcons={true}
                    onClick={(item) => {
                        clickHandler(item);
                    }}
                    onClickMyFolder={() => {
                        setLevel(LevelType.FOLDER);
                    }}
                    onClickGlobal={() => {
                        setLevel(LevelType.BUSINESS_UNIT);
                    }}
                    onMyFolderSearch={(value) => {
                        doSearch(value);
                    }}
                />
            </Container>
        </DrcContainer>
    );
}

export default BerryConnect;
