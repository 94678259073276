import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DuAuthenticationUtilities from '../components/shared/Utilities/DuAuthenticationUtilities';
import { ROUTES } from '../constants/routes';

const useCheckUserToken = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { authState, oktaAuth } = useOktaAuth();

    const checkUserToken = useCallback(async () => {
        const userToken = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(oktaAuth);
        const expired = await DuAuthenticationUtilities.isTokenExpired(userToken);
        if (DuAuthenticationUtilities.getAuthProvider() === 'okta') {
            if (!authState) {
                return navigate(`${ROUTES.LOGIN}`);
            }
            if ((oktaAuth && !authState?.isAuthenticated) || expired) {
                return navigate(`${ROUTES.LOGIN}`);
            }
        } else {
            if (expired) {
                return navigate(`${ROUTES.LOGIN}`);
            }
        }
        setIsLoggedIn(true);
    }, [authState, navigate, oktaAuth]);

    useEffect(() => {
        checkUserToken();
    }, [checkUserToken]);

    return isLoggedIn;
};

export default useCheckUserToken;
