import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Divider, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import DrcDialog from 'components/shared/drc/DrcDialog';
import AssignmentEntityCard from 'components/usermanagement/AssignmentEntityCard';
import AssignmentUsersCard from 'components/usermanagement/AssignmentUsersCard';
import UserListComponent from 'components/usermanagement/UserListComponent';
import useAssignEntityToUsers from 'hooks/useAssignEntityToUsers';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetEntityAssignments from 'hooks/useGetEntityAssignments';
import useGetEntityRelatedUsers from 'hooks/useGetEntityRelatedUsers';
import { NAMESPACE } from 'i18n';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
function EntityAssignment(props) {
    useCheckMasterData();
    const location = useLocation();
    const path = location.pathname.split('/');
    const { userPersona } = useContext(ApplicationContext);
    const personaId = userPersona;
    const navigate = useNavigate();
    const { oktaAuth } = props;
    const entity = path[path.length - 1];
    const [selectedEntity, setSelectedEntity] = useState({});
    const { data: assignments, error: errorData, loading: getEntityLoading } = useGetEntityAssignments(oktaAuth, personaId, entity);
    const {
        assignedUsers,
        setAssignedUsers,
        getAssignedUSers,
        loading: getEntityUserLoading
    } = useGetEntityRelatedUsers(oktaAuth, personaId, entity);
    const { assignEntityToUsers, loading } = useAssignEntityToUsers(oktaAuth);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { getTextTranslated } = useLang();

    const goBackHandler = () => {
        navigate(`/${ROUTES.USER_MANAGEMENT}`);
    };

    const changeEvent = (selectedItem) => {
        setSelectedEntity(selectedItem);
        setAssignedUsers([]);
        getAssignedUSers(oktaAuth, personaId, entity);
    };

    const handleAssignment = (users) => {
        setSelectedUsers(users);
        setOpenCancelDialog(true);
    };

    const updateChanges = () => {
        setOpenCancelDialog(false);
        assignEntityToUsers(
            selectedUsers.map((user) => {
                return user.AuthId;
            }),
            entity,
            selectedEntity
        );
    };
    return (
        <DrcContainer heading={getTextTranslated('Assignment', NAMESPACE.USERS)} goBackHandler={goBackHandler}>
            <Grid sx={{ justifyContent: 'center' }} container spacing={2} columns={{ xs: 1, sm: 8, md: 12 }}>
                <Grid item xs={8} md={6}>
                    <AssignmentEntityCard entity={entity} assignments={assignments} loading={getEntityLoading} changeEvent={changeEvent} />
                </Grid>
                <Grid item xs={8} md={6}>
                    <AssignmentUsersCard users={assignedUsers} loading={getEntityUserLoading} handleAssignment={handleAssignment} />
                </Grid>
            </Grid>
            <DrcDialog
                scroll={'paper'}
                title={getTextTranslated('Assign Entity', NAMESPACE.USERS)}
                open={openCancelDialog}
                buttons={
                    <>
                        <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={() => setOpenCancelDialog(false)}>
                            {'No'}
                        </TranslatedDrcButton>
                        <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isPrimary onClick={() => updateChanges()}>
                            {'Yes'}
                        </TranslatedDrcButton>
                    </>
                }
            >
                <section>
                    <TypographyTranslated>
                        {getTextTranslated('Do you want to assign the entity to the selected users?', NAMESPACE.USERS)}
                    </TypographyTranslated>
                    <Divider />
                    <UserListComponent users={selectedUsers} />
                </section>
            </DrcDialog>
            {loading && <DriscollsSpinner text={getTextTranslated('Assigning entity to selected users', NAMESPACE.USERS)} />}
        </DrcContainer>
    );
}

export default withOktaAuth(EntityAssignment);
