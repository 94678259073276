import Announcement from './Announcement';
import ManageUser from './ManageUser';

function configureAllSetupCall() {
    ManageUser.configureSetupCall();
    Announcement.configureSetupCall();
}

const SetupCall = {
    configureAllSetupCall
};

export default SetupCall;
