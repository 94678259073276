import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { logError } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of assignments available for this user
 * {@link APIEndPoints.GET_NOTIFIED_ASSIGNMENTS}
 * {@param {Object} oktaAuth Okta authentication object}
 * {@param {number} personaId is the numeric persona id .See PersonaID for more}
 * {@param {string} filterEntity is the entity needed to be in the list.[Companies | Districts | Ranches]}
 *
 */
const useGetEntityAssignments = (oktaAuth, personaId, filterEntity) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);

    const getEntityName = (entity) => {
        switch (entity) {
            case 'ranch':
                return 'Ranches';
            case 'district':
                return 'Districts';
            case 'company':
                return 'Companies';
        }
    };

    const fetch = async () => {
        try {
            setLoading(true);
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: APIEndPoints.GET_NOTIFIED_ASSIGNMENTS(personaId, userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const formattedData = Object.entries(response.display || {})
                .map((item) => {
                    return getEntityName(filterEntity) ? (item[0] === getEntityName(filterEntity) ? item[1]?.Data : []) : item[1]?.Data;
                })
                .reduce((targetArray, currentValue) => targetArray.concat(currentValue), []);
            setData(formattedData);
        } catch (err) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Error getting entity assignments', nameSpacing: NAMESPACE.USERS });
            logError(EventConstants.GET_ENTITY_ASSIGNMENT_ERROR);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return { data, error, loading };
};
export default useGetEntityAssignments;
