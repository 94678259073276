import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useGetLocale from 'hooks/useGetLocale';
import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import DashboardSection from '../Section';
import style from './CalendarEvent.module.scss';
import { ICalendarEvent } from './CalendarTypes';
import RecentEvents from './RecentEvents';

function CalendarEvent({ events, classNameContainer }: { events: Array<ICalendarEvent> | []; classNameContainer?: string }) {
    const { locale } = useGetLocale();
    const [updatedLocale, setUpdatedLocale] = useState(locale);

    useEffect(() => {
        if (locale && locale.options) {
            locale.options.weekStartsOn = 0;
            setUpdatedLocale(locale);
        }
    }, [locale]);

    return (
        <div className={`${style.eventSectionContainer} ${classNameContainer || ''}`}>
            <DashboardSection classNameContainer={`${style.container}`}>
                <Card className={style.card}>
                    <CardContent className={style.cardContainer}>
                        <LocalizationProvider
                            adapterLocale={updatedLocale}
                            dateAdapter={AdapterDateFns}
                            localeText={{
                                calendarWeekNumberHeaderText: 'WK',
                                calendarWeekNumberText: (weekNumber) => `${weekNumber}.`
                            }}
                        >
                            <DateCalendar className={style.calendarContainer} displayWeekNumber />
                        </LocalizationProvider>

                        <RecentEvents events={events} />
                    </CardContent>
                </Card>
            </DashboardSection>
        </div>
    );
}

export default CalendarEvent;
