enum routesEnum {
    CALLBACK = 'implicit/callback',
    DASHBOARD = 'Dashboard',
    GROWER = 'grower',
    INITIALIZE_APPLICATION = 'InitializeApplication',
    INVITE = 'invite',
    LOGOUT = 'logOut',
    MAIN = 'main',
    PRINT = 'print',
    ROOT = '/',
    SLASH = '/',
    USER = 'user',
    WEATHER = 'weather'
}

export enum viewsPerRoute {
    DAILY_REPORT = 'daily_report',
    EVENT_DETAILS = 'event_details',
    EVENT_EDIT = 'event_edit',
    EVENT_VIEW = 'event_view',
    EVENT_CREATE = 'event_create',
    NON_MARKETABLE_REPORT = 'non_marketable_report',
    PACKAGING_ISSUANCE = 'packaging_issuance',
    SWEET_SPOT_DAILY = 'sweet_spot_daily',
    SWEET_SPOT_WEEKLY = 'sweet_spot_weekly',
    RECEIPTS = 'receipts',
    ALL_EVENTS = 'all_events'
}
export default routesEnum;
