export enum COMMODITY_LABEL {
    BLACKBERRY = 'Blackberry',
    BLUEBERRY = 'Blueberry',
    RASPBERRY = 'Raspberry',
    STRAWBERRY = 'Strawberry'
}

export enum COMMODITY_CODE {
    BLACKBERRY = 'BLACK',
    BLUEBERRY = 'BLUE',
    RASPBERRY = 'RASP',
    STRAWBERRY = 'STRAW'
}

export const commodityObj = {
    [COMMODITY_LABEL.BLACKBERRY]: { code: COMMODITY_CODE.BLACKBERRY, label: COMMODITY_LABEL.BLACKBERRY },
    [COMMODITY_LABEL.BLUEBERRY]: { code: COMMODITY_CODE.BLUEBERRY, label: COMMODITY_LABEL.BLUEBERRY },
    [COMMODITY_LABEL.RASPBERRY]: { code: COMMODITY_CODE.RASPBERRY, label: COMMODITY_LABEL.RASPBERRY },
    [COMMODITY_LABEL.STRAWBERRY]: { code: COMMODITY_CODE.STRAWBERRY, label: COMMODITY_LABEL.STRAWBERRY }
};
