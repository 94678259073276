import React, { useEffect, useRef, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import { NAMESPACE } from 'i18n';
import useLang from '../../../hooks/useLang';
import TranslatedDrcButton from '../../../ui/translatedButton/TranslatedDrcButton';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import DrcInformationBox from '../drc/DrcInformationBox';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent'
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.palette.action.hover
            }
        }
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative'
    }
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128'
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.3) : 'rgb(12, 45, 107)'}`,
            borderColor: theme.palette.mode === 'light' ? theme.palette.primary : '#388bfd'
        }
    }
}));

export default function FormAutoComplete(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElHelp, setAnchorElHelp] = useState(null);
    const addBtnRef = useRef(null);

    const [value, setValue] = useState(props.values);
    const [pendingValue, setPendingValue] = useState([]);
    const theme = useTheme();
    const { getTextTranslated } = useLang();

    useEffect(() => {
        setValue([]);
        setPendingValue([]);
    }, [props.values]);

    const handleClick = (event) => {
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (props.showHelp) {
            setAnchorElHelp(addBtnRef?.current);
        } else {
            setAnchorElHelp(null);
        }
    }, [props.showHelp]);

    const open = Boolean(anchorEl);
    const openHelperPopup = Boolean(anchorElHelp);
    const id = open ? 'github-label' : undefined;
    const headerText = props.headerText || `${getTextTranslated('Select items to add to the list', NAMESPACE.EVENTS)}`; //fallback when headerText prop is not set

    const selectAll = (select) => {
        handleChangeHandler(select ? props.values : []);
    };

    const handleChangeHandler = (newValue) => {
        setPendingValue(newValue);
        props.onChangeHandler && props.onChangeHandler(newValue);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {props.hideLegacyLabel && (
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h7" gutterBottom>
                        {props.label}
                    </TypographyTranslated>
                )}
                <Box
                    ref={addBtnRef}
                    sx={{
                        border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
                        width: '90%',
                        height: '56px',
                        borderRadius: '18px',
                        marginTop: '7px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                >
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h7" gutterBottom sx={{ width: '100%', textAlign: 'center' }}>
                        {props.label}
                    </TypographyTranslated>
                    <Typography>
                        {' :'}
                        {pendingValue.length}
                    </Typography>
                    <IconButton style={{ width: 40, height: 40 }} onClick={handleClick}>
                        <AddCircleIcon />
                    </IconButton>
                    <Popper id={id} open={openHelperPopup} anchorEl={anchorElHelp}>
                        <DrcInformationBox>{props.helpMessage}</DrcInformationBox>
                    </Popper>
                </Box>
            </div>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Box
                            sx={{
                                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                                padding: '8px 10px',
                                fontWeight: 600
                            }}
                        >
                            <TypographyTranslated>{headerText}</TypographyTranslated>
                        </Box>
                        <Autocomplete
                            sx={{ width: '100%' }}
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
                                    return;
                                }
                                handleChangeHandler(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText={`No ${props.label}` || 'No Data'}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Box
                                        component={DoneIcon}
                                        sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden'
                                        }}
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 14,
                                            height: 14,
                                            flexShrink: 0,
                                            borderRadius: '3px',
                                            mr: 1,
                                            mt: '2px'
                                        }}
                                        style={{ backgroundColor: option.color }}
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            '& span': {
                                                color: theme.palette.mode === 'light' ? '#586069' : '#8b949e'
                                            }
                                        }}
                                    >
                                        {getTextTranslated(option.label, 'main')}
                                        <br />
                                        <span>{option.description}</span>
                                    </Box>
                                    <Box
                                        component={CloseIcon}
                                        sx={{ opacity: 0.6, width: 18, height: 18 }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden'
                                        }}
                                    />
                                </li>
                            )}
                            options={[...props.values].sort((a, b) => {
                                // Display the selected labels first.
                                let ai = value.indexOf(a);
                                ai = ai === -1 ? value.length + props.values.indexOf(a) : ai;
                                let bi = value.indexOf(b);
                                bi = bi === -1 ? value.length + props.values.indexOf(b) : bi;
                                return ai - bi;
                            })}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Filter labels"
                                    label={!props.hideLegacyLabel ? props.label : ''}
                                />
                            )}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TranslatedDrcButton
                                nameSpacing={NAMESPACE.COMMON}
                                onClick={() => selectAll(pendingValue.length !== props.values.length)}
                            >
                                {pendingValue.length === props.values.length ? 'Unselect All' : 'Select All'}
                            </TranslatedDrcButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </>
    );
}
