import {format, parseISO, Locale} from 'date-fns';

/**
 * Formats a date string to a simple date string based on locale.
 *
 * @param {string} date - The ISO format date string to format
 * @param {string} localeLang - The locale Object
 * @returns {string} The formatted simple date string
 *
 * @uses Default date format of 'PPpp' https://date-fns.org/v2.29.3/docs/format

 * example: Apr 29, 1453, 12:00 AM
 */
export const simpleDateFormatterHandler = (
    date: string,
    localeLang: Locale
): string => {
    const DEFAULT_DATE_FORMAT = 'PPp';
    const parsedDate: Date = parseISO(date);
    return format(parsedDate, DEFAULT_DATE_FORMAT, {locale: localeLang});

};



/**
 * Formats a non-conventional date string to a formatted date string.
 * @format to convert "July 28th, 2023"
 * @param {string} date - The non-conventional date string to format
 * @param {Locale} localeLang - The locale object to use for formatting
 * @returns {string} The translated date string
 *
 * @converts non-conventional date string to ISO string and translates the dates
 * @uses default format of 'PPP'
 */

export const nonConventionalDateFormatter = (date: string, localeLang: Locale): string =>{
    if(!date || !localeLang) return;
    const DEFAULT_FORMAT = 'PPP';
    const d = new Date(date);
    const isoStringDate = d.toISOString();

    return format(new Date(isoStringDate), DEFAULT_FORMAT, {locale: localeLang});
};
