import {
    ButtonVariant,
    DriscollsAssignmentCard,
    DriscollsHContainer,
    DriscollsLoadingButton,
    DriscollsSpinner,
    DriscollsToastSeverity
} from '@driscollsinc/style-guide-web2.0';
import { Chip, Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { withOktaAuth } from '@okta/okta-react';
import FormCheckbox from 'components/shared/formcontrols/FormCheckbox';
import ExistingUserInvite, { IMultiUserObject } from 'components/usermanagement/ExistingUserInvite';
import RenderAssignments from 'components/usermanagement/RenderAssignments';
import RenderFormField from 'components/usermanagement/RenderFormField';
import SelectPersonaInviteUser from 'components/usermanagement/SelectPersonaInviteUser';
import getUserManageFieldsValidationSchema from 'components/usermanagement/UserManagementFieldsValidationSchema';
import { IInvitePayload } from 'components/usermanagement/UserManagementTypes';
import { IFormContext } from 'context/FormContext';
import UserManageFormContext, { IUserManagementForm } from 'context/UserManageFormContext';
import { AssignmentTypes, IInviteForms } from 'data/config/DataSetupTypes';
import { PERSONA_NAME } from 'data/config/persona';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useCheckUserEmail from 'hooks/useCheckUserEmail';
import useFetchUsers from 'hooks/useFetchUsers';
import useFormatPayload from 'hooks/useFormatPayload';
import useGetReports from 'hooks/useGetReports';
import useHandleAssignments from 'hooks/useHandleAssignments';
import useInviteUser, { exceptions } from 'hooks/useInviteUser';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import { NAMESPACE } from 'i18n';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DriscollsLegend from 'ui/DriscollsLegend/DriscollsLegend';
import getValidationResolver from 'utils/ValidationResolver';
import { isUSPhoneNumber } from 'utils/validations';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import DrcInformationBox from '../../components/shared/drc/DrcInformationBox';
import AssignedPersonaDialog from '../../components/usermanagement/AssignedPersonaDialog';
import ReportsAssignment from '../../components/usermanagement/ReportsAssignment';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import { getCountries, getInvitablePersonas, isPersonaIsOKTA } from '../../data/config/datasetup';
import { forms } from '../../data/config/inviteforms';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import classes from './usermanagement.module.scss';

function InviteUser(props) {
    const { userPersona, basicInformation, setShowToast } = useContext(ApplicationContext);
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const userLang = getCurrentLanguageSelected();
    useMapSuperAdminData();
    useCheckMasterData();
    const [personaSelectionOpenState, setPersonaSelectionOpenState] = useState(false);
    const [availablePersona, setAvailablePersona] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [selectedPersona, setSelectedPersona] = useState([]);
    const [selectedPersonaIds, setSelectedPersonaIds] = useState([]);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [personaFromSelectedUser, setPersonaFromSelectedUser] = useState<any>({});
    const [isUserExists, setIsUserExists] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const [personaForm, setPersonaInviteForm] = useState<IInviteForms | Record<string, never>>({});
    const [formInvalid, setFormInvalid] = useState(true);
    const [resetEnabled, setResetEnabled] = useState(false);
    const [assignedReports, setAssignedReports] = useState([]);
    const [openSearchDialogBox, setOpenSearchDialogBox] = useState<boolean>(false);
    const [cognitoExistingUserDetails, setCognitoExistingUserDetails] = useState<IMultiUserObject>({} as IMultiUserObject);
    const [payload, setPayload] = useState<IInvitePayload>({ usernamefield: 'phone', defaultContact: '0', isWhatsAppUser: true });
    const navigate = useNavigate();
    const { data: reportsList, getReports } = useGetReports(props.oktaAuth, userPersona, userLang);
    const allCountries = basicInformation.Countries;

    const { checkUserEmail, error: checkUserEmailError, loading: checkEmailLoading } = useCheckUserEmail(props.oktaAuth);
    const { inviteUser: inviteUserApi, error: inviteUserError, loading: inviteUserLoading } = useInviteUser(props.oktaAuth);
    const {
        selectedCompany,
        companyRanches,
        selectedDistrict,
        districtBerries,
        districtCompanies,
        onChangeDistrictHandler,
        onChangeCompanyHandler,
        onAddBerriesHandler,
        onAddCompaniesHandler,
        onAddRanchHandler,
        addAssignments,
        addRanchAssignments,
        removeItem,
        removeAllItems
    } = useHandleAssignments(personaForm, assignments, setAssignments);

    const { fetchUsers, loading: fetchUsersLoading } = useFetchUsers(props.oktaAuth);
    const { formatPayload, formattingCompanyRanchPayload, formattingDistrictCompanyPayload } = useFormatPayload(
        false,
        assignments,
        setAssignments,
        personaForm
    );

    useEffect(() => {
        setAssignedReports([]);
    }, [reportsList]);

    const getData = (pageNumber = 1) => {
        const personas = getInvitablePersonas(userPersona);
        const countries = getCountries(allCountries);
        setAvailableCountries(countries);
        setAvailablePersona(personas);
    };

    useEffect(() => {
        getData();
    }, []);

    useCallback(() => {
        getReports();
    }, [userLang]);

    useEffect(() => {
        setFormInvalid(
            selectedPersona.length === 0 ||
                (personaForm?.reports && assignedReports?.length === 0) ||
                (personaForm?.assignments?.length > 0 && assignments?.length === 0)
        );
    }, [assignedReports, assignments]);

    useEffect(() => {
        if (Object.keys(cognitoExistingUserDetails).length > 0) fetchAssignments();
    }, [cognitoExistingUserDetails]);

    const validationSchema = getUserManageFieldsValidationSchema(personaForm, payload);
    const resolver = getValidationResolver(validationSchema);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm({ resolver, mode: 'onBlur' });

    const validateField = (name, focus = false) => {
        trigger(name, { shouldFocus: focus });
    };

    const fetchAssignments = async () => {
        const { UserOktaId, PersonaName } = cognitoExistingUserDetails.UserDetails[0];
        const fetchUserPayload = {
            RequestorPersonaId: userPersona,
            AuthIdFilter: UserOktaId,
            PersonaId: PersonaName === PERSONA_NAME.GROWERADMIN ? PERSONA_NAME.RANCHADMIN : PersonaName
        };
        const data = await fetchUsers(fetchUserPayload);
        if (!(data?.errors?.length > 0)) {
            if (data.raw.data.Response.length > 0) {
                if (personaForm.assignmentType === AssignmentTypes.COMPANY_RANCH) {
                    if (data.raw.data.Response[0].Ranches && data.raw.data.Response[0].Companies)
                        formattingCompanyRanchPayload([...data.raw.data.Response[0].Ranches, ...data.raw.data.Response[0].Companies]);
                }
                if (personaForm.assignmentType === AssignmentTypes.DISTRICT_COMPANY) {
                    if (data.raw.data.Response[0].Districts && data.raw.data.Response[0].Companies)
                        formattingDistrictCompanyPayload([...data.raw.data.Response[0].Districts, ...data.raw.data.Response[0].Companies]);
                }
            }
        }
    };

    const addPayloadValue = (fieldName, value) => {
        payload[fieldName] = fieldName === 'phone' ? '+' + value : value;
        payload.defaultContact = fieldName === 'defaultContact' && value ? '1' : '0';
        setValue(fieldName, value);
        validateField(fieldName);
    };

    const formMemo = useMemo<IFormContext<IUserManagementForm>>(
        () => ({
            formFields: payload,
            errors,
            register,
            setValue: addPayloadValue,
            validate: validateField
        }),
        [payload, errors]
    );

    const handleChangePersona = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedUser(null);
        setPersonaFromSelectedUser(null);
        setAssignments([]);
        setResetEnabled(true);
        setAssignedReports([]);
        setPayload({ usernamefield: 'phone', defaultContact: '0', isWhatsAppUser: true }); // resetting to initial state
        if (value && value.length > 0) {
            const selectedIds = value.map((item) => {
                const objP = availablePersona.find((item2) => item2.label?.toLowerCase() === item.toLowerCase());
                return objP.id;
            });

            const selectedItem = availablePersona.find((item) => item.label?.toLowerCase() === value[0].toLowerCase());
            const currentForm = forms.find((item) => item.persona === selectedItem.id);
            const nexGroup =
                value.length > 1 ? availablePersona.find((item) => item.label?.toLowerCase() === value[value.length - 1]?.toLowerCase()).group : null;
            if (value.length === 1 || (value.length > 1 && nexGroup === selectedItem.group)) {
                setSelectedPersonaIds(selectedIds);
                setSelectedPersona(value);
                setPersonaInviteForm(currentForm);
                setPersonaSelectionOpenState(false);
            } else {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: 'Cannot add Persona with a different group',
                    nameSpacing: NAMESPACE.USERS
                });
            }
        } else {
            setSelectedPersonaIds([]);
            setSelectedPersona(value);
            setPersonaInviteForm({});
        }
    };

    const closeUserExistDialog = () => {
        setIsUserExists(false);
    };

    const inviteUser = async () => {
        const isOktaUser = isPersonaIsOKTA(personaForm.persona);
        const emailData =
            Object.keys(cognitoExistingUserDetails).length > 0
                ? cognitoExistingUserDetails
                : await checkUserEmail(payload.Login || payload.email, payload.phone, personaForm.persona, payload.usernamefield);

        if (checkUserEmailError) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
        } else {
            const userDetails = emailData?.UserDetails;
            if (emailData && emailData.UserDetails && userDetails && userDetails.length > 0) {
                if (isOktaUser ? emailData.UserDetails : userDetails[0]?.Username || userDetails[0][0]?.Username) {
                    setSelectedUser(emailData);
                    setPersonaFromSelectedUser(isOktaUser ? userDetails : Array.isArray(userDetails[0]) ? userDetails[0] : userDetails);
                    setIsUserExists(true);
                } else if (userDetails[0][0]?.Username) {
                    setShowToast({
                        severity: DriscollsToastSeverity.ERROR,
                        toastMessage: "User already exists, to add a persona to existing user please click on 'Invite An Existing User'!",
                        nameSpacing: NAMESPACE.USERS
                    });
                }
            } else {
                await continueInviteProcess(null, selectedPersonaIds);
            }
        }
    };

    const getExistingUserDetails = (data) => {
        setCognitoExistingUserDetails(data);
        const details = { ...data.UserDetails[0], phone: data.UserDetails[0].phone.slice(1), usernamefield: data.UserDetails[0].loginIdField };
        Object.entries(details).map((value) => addPayloadValue(value[0], value[1]));
    };

    const continueInviteProcess = async (defaultPersona = null, existingPersonas = null) => {
        setIsUserExists(false);
        const reportUrls = assignedReports.map((itm) => {
            return { ReportId: itm.ReportId, ReportURL: itm.ReportURL };
        });
        const cognitoUserDetails = selectedUser?.UserDetails
            ? Array.isArray(selectedUser?.UserDetails[0])
                ? selectedUser?.UserDetails[0][0]
                : selectedUser?.UserDetails[0]
            : null;
        const isSuccess = await inviteUserApi(
            selectedPersonaIds,
            {
                PersonaDetails: payload,
                ...(personaForm?.assignments?.length > 0 && {
                    [personaForm.assignments[0].field]: formatPayload()
                })
            },
            personaForm.reports ? reportUrls : null,
            payload.phone,
            payload.email || payload.Login || payload.Email,
            personaForm,
            selectedUser?.cognitoEdit,
            cognitoUserDetails?.Username,
            cognitoUserDetails,
            defaultPersona,
            existingPersonas
        );
        if (isSuccess === exceptions.UsernameExistsException) {
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: "User already exists, to add a persona to existing user please click on 'Invite An Existing User'!",
                nameSpacing: NAMESPACE.USERS
            });
        } else {
            goBackHandler();
        }
    };

    const resetUser = () => {
        setPayload({ usernamefield: 'phone', defaultContact: '0', isWhatsAppUser: true });
        setAssignments([]);
        setAssignedReports([]);
        setSelectedPersona([]);
        setSelectedPersonaIds([]);
        setSelectedUser(null);
        setPersonaFromSelectedUser(null);
        setPersonaInviteForm({});
        setResetEnabled(false);
    };

    const handleClose = () => {
        setPersonaSelectionOpenState(false);
    };

    const handleOpen = () => {
        setPersonaSelectionOpenState(true);
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.USER_MANAGEMENT}`);
    };

    return (
        <DrcContainer heading={getTextTranslated('Invite User', NAMESPACE.USERS)} goBackHandler={goBackHandler}>
            <Container>
                <SelectPersonaInviteUser
                    personaSelectionOpenState={personaSelectionOpenState}
                    handleChangePersona={handleChangePersona}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    availablePersona={availablePersona}
                    selectedPersona={selectedPersona}
                    selectedPersonaIds={selectedPersonaIds}
                />

                <Box sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2 }}>
                        <DrcInformationBox style={{ display: (selectedPersonaIds || []).length > 0 ? 'block' : 'none' }}>
                            {(selectedPersonaIds || []).map((item) => {
                                return (
                                    <p key={item}>
                                        <TypographyTranslated nameSpacing={NAMESPACE.USERS}>{`Persona${item}`}</TypographyTranslated>
                                    </p>
                                );
                            })}
                        </DrcInformationBox>
                    </Grid>
                    {(selectedPersonaIds || []).length > 0 && !isPersonaIsOKTA(selectedPersonaIds[0]) && (
                        <ExistingUserInvite
                            openSearchDialogBox={openSearchDialogBox}
                            setOpenSearchDialogBox={setOpenSearchDialogBox}
                            personaForm={personaForm}
                            selectedPersonaIds={selectedPersonaIds}
                            getExistingUserDetails={getExistingUserDetails}
                            setAssignedReports={setAssignedReports}
                        />
                    )}
                    <UserManageFormContext.Provider value={formMemo}>
                        <form onSubmit={handleSubmit(inviteUser)}>
                            <DriscollsLegend
                                title={<TypographyTranslated nameSpacing={NAMESPACE.COMMON}>Personal Information</TypographyTranslated>}
                                show={!(selectedPersonaIds.length === 0)}
                            >
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                    {(personaForm.personalinformation || []).map((item, index) => {
                                        return (
                                            <Grid item key={index} xs={2} sm={4} md={4}>
                                                <RenderFormField
                                                    item={item}
                                                    addPayloadValue={addPayloadValue}
                                                    availableCountries={availableCountries}
                                                    memo={formMemo}
                                                />
                                            </Grid>
                                        );
                                    })}
                                    {payload?.usernamefield === 'phone' && payload?.phone && isUSPhoneNumber(payload.phone) && (
                                        <Grid item xs={2} sm={4} md={4}>
                                            <FormCheckbox
                                                label={getTextTranslated('Receive OTP on WhatsApp', NAMESPACE.USERS)}
                                                defaultChecked={payload.isWhatsAppUser}
                                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    addPayloadValue('isWhatsAppUser', event.target.checked)
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </DriscollsLegend>
                            <DriscollsLegend
                                title={<TypographyTranslated nameSpacing={NAMESPACE.USERS}>Assignments</TypographyTranslated>}
                                show={!(selectedPersonaIds.length === 0) && personaForm?.assignments?.length > 0}
                            >
                                <DriscollsHContainer wrap>
                                    <RenderAssignments
                                        assignmentType={personaForm.assignmentType}
                                        districtCompanies={districtCompanies}
                                        onChangeDistrictHandler={onChangeDistrictHandler}
                                        addAssignments={addAssignments}
                                        selectedDistrict={selectedDistrict}
                                        selectedCompany={selectedCompany}
                                        addRanchAssignments={addRanchAssignments}
                                        onAddRanchHandler={onAddRanchHandler}
                                        onChangeCompanyHandler={onChangeCompanyHandler}
                                        companyRanches={companyRanches}
                                        onAddCompaniesHandler={onAddCompaniesHandler}
                                        districtBerries={districtBerries}
                                        onAddBerriesHandler={onAddBerriesHandler}
                                    />
                                </DriscollsHContainer>
                                <DriscollsHContainer wrap className={classes.assignments}>
                                    {personaForm?.assignments?.length > 0 &&
                                        (assignments.length > 0 ? (
                                            (assignments || []).map((item, index) => {
                                                return (
                                                    <DriscollsAssignmentCard
                                                        key={index}
                                                        data={item}
                                                        assignmentType={personaForm.assignmentType}
                                                        deleteItemHandler={removeItem}
                                                        removeAllHandler={removeAllItems}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <p className={classes.assignmentError}>
                                                {getTextTranslated("Assignments can't be empty!", NAMESPACE.USERS)}
                                            </p>
                                        ))}
                                </DriscollsHContainer>
                            </DriscollsLegend>

                            <DriscollsLegend
                                title={<TypographyTranslated nameSpacing={NAMESPACE.COMMON}>Reports</TypographyTranslated>}
                                show={!(selectedPersonaIds.length === 0) && personaForm.reports}
                            >
                                <Grid container className={classes.reportsGrid} columns={{ xs: 1, sm: 8, md: 12 }}>
                                    {personaForm?.reports && (
                                        <>
                                            <ReportsAssignment
                                                values={reportsList}
                                                onChangeHandler={(e) => setAssignedReports(e)}
                                                label={'Reports'}
                                            />
                                            {assignedReports?.length > 0 ? (
                                                (assignedReports || []).map((item, index) => (
                                                    <Box key={index} className={classes.reportsContainer}>
                                                        <Chip label={item.label} />
                                                    </Box>
                                                ))
                                            ) : (
                                                <p className={classes.assignmentError}>
                                                    {getTextTranslated("Reports can't be empty", NAMESPACE.USERS)}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </DriscollsLegend>

                            <Grid item xs={12} sm={12} md={12} gap={1} display="flex" justifyContent="right" alignItems="center">
                                <DriscollsLoadingButton
                                    variant={ButtonVariant.SECONDARY}
                                    onClick={resetUser}
                                    disabled={!resetEnabled}
                                    label={getTextTranslated('Reset', NAMESPACE.COMMON)}
                                />

                                <DriscollsLoadingButton
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={handleSubmit(inviteUser)}
                                    loading={checkEmailLoading || inviteUserLoading}
                                    type="submit"
                                    disabled={formInvalid || !isValid}
                                    label={getTextTranslated('Submit', NAMESPACE.COMMON)}
                                />
                            </Grid>
                        </form>
                        {fetchUsersLoading && <DriscollsSpinner text={getTextTranslated('Fetching User Info', NAMESPACE.USERS)} />}
                    </UserManageFormContext.Provider>
                </Box>
            </Container>
            {isUserExists && (
                <AssignedPersonaDialog
                    isOktaUser={isPersonaIsOKTA(personaForm.persona)}
                    selectedPersonaIds={selectedPersonaIds}
                    open={isUserExists}
                    closeUserExistDialog={closeUserExistDialog}
                    continueInviteProcess={continueInviteProcess}
                    UserDetails={personaFromSelectedUser}
                ></AssignedPersonaDialog>
            )}
        </DrcContainer>
    );
}

export default withOktaAuth(InviteUser);
