import { Done, Error } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { CircularProgress, IconButton } from '@mui/material';
import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import React from 'react';
import styles from './Report.module.css';

interface IFileUploadPreviewList {
    filelistlabel: string;
    files: any;
    fileUploadStatus: any[];
    deleteItem: (fileName: string) => void;
    editItem: (fileName: string) => void;
}

const Icon = ({ name }) => <ImageComponent size={'small'} src={`/icons/sharepoint/${name}.svg`} brokenImage={`/icons/sharepoint/genericfile.svg`} />;
const DeleteButton = ({ fileName, deleteItem }) => (
    <IconButton onClick={() => deleteItem(fileName)} color={'error'}>
        <Delete />
    </IconButton>
);
const EditButton = ({ error, fileName, editItem }) =>
    error ? (
        <IconButton onClick={() => editItem(fileName)} color={'primary'}>
            <Edit />
        </IconButton>
    ) : null;

const Status = ({ status, fileName, deleteItem }) => {
    switch (status) {
        case 'pending':
            return <DeleteButton fileName={fileName} deleteItem={deleteItem} />;
        case 'success':
            return <Done color="success" />;
        case 'uploading':
            return <CircularProgress size="16px" />;
        default:
            return <DeleteButton fileName={fileName} deleteItem={deleteItem} />;
    }
};

const ErrorMessage = ({ error }) => {
    return error ? (
        <IconButton color={'error'} title={error.toString()}>
            <Error />
        </IconButton>
    ) : null;
};
const FilePreviewList = (props: IFileUploadPreviewList) => {
    return (
        <article className={styles.FilePreviewContainer}>
            <span>{props.files && props.files.length > 0 && props.filelistlabel}</span>
            <ul className={styles.PreviewList}>
                {Object.keys(props.files || []).map((fileName) => {
                    const file = props.files[fileName];
                    const status = props.fileUploadStatus.find((item) => item.fileName === file.name)?.status;
                    const error = props.fileUploadStatus.find((item) => item.fileName === file.name)?.errorMessage;
                    return (
                        <li className={styles.dropLisItemContainer} key={fileName}>
                            <section className={styles.dropFileIcon}>
                                <Icon name="pdf" />
                            </section>
                            <section className={styles.dropFileName}>
                                <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{file?.name}</p>
                            </section>
                            <section className={styles.dropFileStatus}>
                                {error && <EditButton error={error} fileName={file.name} editItem={props.editItem} />}
                            </section>
                            <section className={styles.dropFileStatus}>
                                <ErrorMessage error={error} />
                            </section>
                            <section className={styles.dropFileStatus}>
                                <Status status={status} fileName={file.name} deleteItem={props.deleteItem} />
                            </section>
                        </li>
                    );
                })}
            </ul>
        </article>
    );
};

export default FilePreviewList;
