import React, { useMemo, useState } from 'react';
import BerryConnectContext from './BerryConnectContext';

const BerryConnectProvider = (props: any) => {
    const [siteId, setSiteId] = useState<string>(null);
    const [subsiteId, setSubsiteId] = useState<string>(null);

    const value = useMemo(
        () => ({
            siteId,
            setSiteId,
            subsiteId,
            setSubsiteId
        }),
        [siteId, subsiteId]
    );
    return <BerryConnectContext.Provider value={value}>{props.children}</BerryConnectContext.Provider>;
};

export default BerryConnectProvider;
