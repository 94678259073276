import { Download } from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import styles from './GenericRenderer.module.css';

interface IDownloadColumnRendererProps {
    data: { FileKey: string };
    downloadReport: (FileKey: string) => void;
}

const DownloadColumnRenderer = (props: IDownloadColumnRendererProps) => {
    return (
        <ButtonBase className={`${styles.actionButton} ${styles.primary}`} onClick={() => props?.downloadReport(props.data.FileKey)}>
            <Download />
        </ButtonBase>
    );
};

export default DownloadColumnRenderer;
