import React, { useEffect, useMemo, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { NAMESPACE } from 'i18n';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

export default function FormDriscollsUsers(props) {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(props.error);
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            debounce(async (request, callback) => {
                await props.service(request, callback);
            }, 400),
        []
    );

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Box
            component="div"
            sx={{
                '& .MuiTextField-root': { m: 1 }
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {props.hideLegacyLabel && (
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h7" gutterBottom>
                        {props.label}
                    </TypographyTranslated>
                )}
                <Autocomplete
                    id="driscolls-user-search"
                    sx={{ width: '100%', minWidth: 300, maxWidth: 350 }}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option?.profile?.email)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    noOptionsText={<TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'No Matching Users'}</TypographyTranslated>}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        props.handleChange && props.handleChange(newValue?.profile?.login); //TODO remove hard coding
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={<TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.label}</TypographyTranslated>}
                            fullWidth
                            error={error}
                            helperText={props.helperText}
                        />
                    )}
                    renderOption={(props, option) => {
                        const parts = option.profile;

                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <AccountCircleIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        <Box component="span" sx={{ fontWeight: 'regular' }}>
                                            {parts.email}
                                        </Box>

                                        <Typography variant="body2" color="text.secondary">
                                            {parts.firstName + ' ' + parts.lastName || ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            </div>
        </Box>
    );
}
