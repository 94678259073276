import { EventConstants } from "../constants/EventConstants";

export function logEvent(eventName, eventInfo) {
    window.config.logEvent(eventName, eventInfo);
}

export function logError(errorName, errorInfo) {
    window.config.logEvent(EventConstants.EXCEPTION, errorInfo);
}

export function logSearch(searchTerm) {
    window.config.logEvent(EventConstants.SEARCH, { search_term: searchTerm });
}
