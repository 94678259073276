import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useFetchUsers from 'hooks/useFetchUsers';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logSearch } from '../../analytics/AnalyticsHandler';
import GenericSearchBox from '../../components/shared/search/GenericSearchBox';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import { isPersonaHasReadOnlyAccess } from '../../data/config/datasetup';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import GenericFilterSideBar from '../../layouts/dashboard/filters/GenericFilterSideBar';
import Filter from '../../ui/DrcFilters/DrcFilter';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { getAppliedFiltersPost } from '../../utils/helper';
import UserGridContainer from './UserGridContainer';

function UserManagement(props) {
    const { oktaAuth } = props;
    useCheckMasterData();
    useMapSuperAdminData();
    const [searchInput, setSearchInput] = useState('');
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [page, setPage] = useState(1);
    const [advancedSearchOn, setAdvancedSearchOn] = useState(false);
    const [executionTime, setExecutionTime] = useState(0);
    const [filterPayload, setFilterPayload] = useState([]);
    const isDesktop = useResponsive('up', 'md');
    const navigate = useNavigate();
    const { userPersona, filters } = useContext(ApplicationContext);
    const isReadOnlyPersona = isPersonaHasReadOnlyAccess(userPersona);
    const { fetchUsers } = useFetchUsers(oktaAuth);

    const handlePageChange = (event, value) => {
        setPage(value);
        pageChanged(value);
    };

    const getData = async (pageNumber = 1, payload) => {
        setFilterPayload(payload);
        setUserList([]);
        setLoading(true);
        const startExecution = performance.now();
        const fetchUserPayload = {
            RequestorPersonaId: userPersona,
            Offset: pageNumber == 1 ? 0 : (pageNumber - 1) * 50 + 1,
            Limit: 50,
            ...payload
        };
        const data = await fetchUsers(fetchUserPayload);
        const endExecution = performance.now();
        setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);

        if (data.errors.length && !data.raw) {
            return console.log(data.errors[0]);
        } else {
            setPageCount(Math.round(data.display.totalUsers / 50));
            setTotalUsers(data.display.totalUsers);
            setUserList(data.display.Response || []);
        }
        setLoading(false);
    };

    useEffect(() => {
        getData(1, {});
    }, []);

    const resetFilters = (event) => {
        setAdvancedSearchOn(false);
        handlePageChange(event, 1); // reset to page 1
    };

    const pageChanged = (value) => {
        const payload = searchInput ? { GenericFilter: searchInput } : filters ? getAppliedFiltersPost(filters) : null;
        getData(value, payload);
    };

    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };
    const { getTextTranslated } = useLang();
    const advanceSearchHandler = () => {
        setAdvancedSearchOn(!advancedSearchOn);
    };
    return (
        <DrcContainer heading={getTextTranslated('User Management', NAMESPACE.USERS)}>
            {isDesktop ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, px: 1, width: '100%' }}>
                        <TranslatedDrcButton
                            customBtnStyles={{ width: '100%', maxWidth: 'max-content', padding: '1rem' }}
                            disableRipple
                            nameSpacing={NAMESPACE.COMMON}
                            color="inherit"
                            endIcon={<Icon icon={advancedSearchOn ? 'mdi:toggle-switch' : 'mdi:toggle-switch-off'} />}
                            onClick={advanceSearchHandler}
                        >
                            {'Advanced Search'}
                        </TranslatedDrcButton>
                        {!advancedSearchOn && (
                            <GenericSearchBox
                                style={{ width: '35vw' }}
                                value={searchInput}
                                setValue={setSearchInput}
                                onEnterPress={() => {
                                    logSearch(searchInput);
                                    getData(1, { GenericFilter: searchInput });
                                }}
                            />
                        )}
                        <div>
                            {!isReadOnlyPersona && (
                                <TranslatedDrcButton
                                    nameSpacing={NAMESPACE.USERS}
                                    variant="contained"
                                    endIcon={<Icon icon="typcn:user-add" />}
                                    onClick={() => {
                                        navigate(ROUTES.USER_INVITE);
                                    }}
                                >
                                    {'Invite'}
                                </TranslatedDrcButton>
                            )}
                        </div>
                    </Box>
                    {advancedSearchOn && (
                        <Grid container gap={2} sx={{ m: 2 }}>
                            <Filter
                                showFilter={advancedSearchOn}
                                applyFilter={() => {
                                    getData(1, getAppliedFiltersPost(filters));
                                    setAdvancedSearchOn(false);
                                }}
                                resetFilter={resetFilters}
                                filterNames={['FirstName', 'LastName', 'Persona', 'Email', 'Phone', 'Lang', 'District', 'Company', 'Ranch']}
                            />
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, px: 1, width: '100%' }}>
                        <GenericFilterSideBar
                            openFilter={openFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                            getData={getData}
                            filters={filters}
                            resetFilters={resetFilters}
                        />
                        <TranslatedDrcButton
                            nameSpacing={NAMESPACE.USERS}
                            variant="contained"
                            endIcon={<Icon icon="typcn:user-add" />}
                            onClick={() => {
                                navigate(ROUTES.USER_INVITE);
                            }}
                        >
                            {'Invite'}
                        </TranslatedDrcButton>
                    </Box>
                    <GenericSearchBox
                        style={{ mb: 2 }}
                        value={searchInput}
                        setValue={setSearchInput}
                        onEnterPress={() => {
                            logSearch(searchInput);
                            getData(1, { GenericFilter: searchInput });
                        }}
                    />
                </>
            )}
            <UserGridContainer
                isReadOnlyPersona={isReadOnlyPersona}
                handlePageChange={handlePageChange}
                page={page}
                totalUsers={totalUsers}
                executionTime={executionTime}
                loading={loading}
                userList={userList}
                pageCount={pageCount}
                userPersona={userPersona}
                oktaAuth={oktaAuth}
                filterPayload={filterPayload}
            />
        </DrcContainer>
    );
}

export default withOktaAuth(UserManagement);
