import { withOktaAuth } from '@okta/okta-react';
import Contacts from 'components/dashboard/Contacts';
import RecentReports from 'components/dashboard/RecentReports';
import React, { useContext } from 'react';
import CalendarEvent from '../../../components/dashboard/Calendar/CalendarEvent';
import NotificationsComponent from '../../../components/dashboard/Notifications/NotificationsComponent';
import WelcomeComponent from '../../../components/dashboard/Welcome/WelcomeComponent';
import ApplicationContext from '../../../context/ApplicationContext';
import DrcContainer from '../../../layouts/dashboard/DrcContainer';

import useFetchContact from 'hooks/useFetchContact';
import useFetchEvents from 'hooks/useFetchEvents';
import useFetchNotifications from 'hooks/useFetchNotifications';
import { NAMESPACE } from 'i18n';
import usePersona from 'services/personas/usePersona';
import useGetReports from '../../../hooks/useGetReports';
import useLang from '../../../hooks/useLang';
import style from './AdminDashboard.module.scss';

const AdminDashboard = (props) => {
    const { oktaAuth } = props;
    const { userPersona, userLang } = useContext(ApplicationContext);
    const { getPersonaWelcomeMessage } = usePersona(userPersona);
    const { data: contacts, loading: loadingContacts, error: errorContacts } = useFetchContact(oktaAuth);
    const { data: recentEvents, loading: loadingRecentEvents, error: errorRecentEvents } = useFetchEvents(oktaAuth, 4);
    const { data: theNotifications, loading: loadingNotifications, error: errorNotifications } = useFetchNotifications(oktaAuth, userPersona);
    const { getTextTranslated } = useLang();
    const { data: reports, loading: loadingReports, error: errorReports } = useGetReports(oktaAuth, userPersona, userLang);

    return (
        <DrcContainer heading={getTextTranslated('Admin Dashboard', NAMESPACE.COMMON)}>
            <article className={style.mainContainer}>
                <div className={style.row}>
                    <WelcomeComponent classNameContainer={style.welcomeContainer} message={getPersonaWelcomeMessage()} persona={userPersona} />
                    <NotificationsComponent
                        persona={userPersona}
                        loading={loadingNotifications}
                        error={errorNotifications}
                        notifications={theNotifications}
                        classNameContainer={style.notificationsContainer}
                    />
                    <CalendarEvent events={recentEvents} classNameContainer={style.calendarEventsContainer} />
                    <RecentReports classNameContainer={style.recentReportsSection} reports={reports} loading={loadingReports} error={errorReports} />
                    <Contacts
                        contactsType={'super admin'}
                        contacts={contacts}
                        loading={loadingContacts}
                        error={errorContacts}
                        classNameContainer={style.contactsContainer}
                    />
                </div>
            </article>
        </DrcContainer>
    );
};
export default withOktaAuth(AdminDashboard);
