import { Close, DateRange } from '@mui/icons-material';
import { Divider, IconButton, InputAdornment, TextField, styled } from '@mui/material';
import React from 'react';

// Material UI component for input field
// Event handlers and state management handled completely in parent component
// Defaults to variant outlined and type text
// Handles all props that TextField does
// can resize by assigning sm a value

const StyledTextField = styled(TextField)(({ theme }) => ({
    '@global': {
        '.MuiInputBase-root.MuiOutlinedInput-root legend span': {
            fontSize: '0.9rem',
            color:
                theme.palette.text.primary + (theme.palette.text.primary.includes('#') ? (theme.palette.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color:
                    theme.palette.text.secondary +
                    (theme.palette.text.secondary.includes('#') ? (theme.palette.text.secondary.length > 5 ? 'aa' : 'a') : '')
            }
        },
        '.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(14px, 12px) scale(1)',
            color:
                theme.palette.text.primary + (theme.palette.text.primary.includes('#') ? (theme.palette.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color:
                    theme.palette.text.secondary +
                    (theme.palette.text.secondary.includes('#') ? (theme.palette.text.secondary.length > 5 ? 'aa' : 'a') : '')
            }
        },
        '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -5px) scale(.9)',
            color:
                theme.palette.text.primary + (theme.palette.text.primary.includes('#') ? (theme.palette.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color:
                    theme.palette.text.secondary +
                    (theme.palette.text.secondary.includes('#') ? (theme.palette.text.secondary.length > 5 ? 'aa' : 'a') : '')
            }
        },

        '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
            [theme.darkTheme]: {
                borderColor: theme.palette.primary.dark
            }
        },
        '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
            [theme.darkTheme]: {
                borderColor: theme.palette.primary.dark
            }
        },
        '.MuiInputBase-root': {
            color: theme.palette.text.primary,
            height: '35px',
            [theme.darkTheme]: {
                color: theme.palette.text.secondary
            }
        },
        '.MuiInputBase-root .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        },
        marginTop: 7,
        marginBottom: 5
    }
}));

/**
 * @description DrcInput is a styled input to enter and edit text
 * @class DrcInput
 * @example
 *  <DrcInput
 *      label='InputLabel'
 *      name='input_label'
 *      placeholder={'Max 50 characters'}
 *      value=''
 *      onChange={() => {}}
 *      inputProps={{ maxLength: 50, readOnly: true }}
 *      required={false}
 *  </DrcInput>
 * @category Input
 * @tags input text number required important
 * @property {string} label name
 * @property {string} name Name of the input element
 * @property {string} placeholder Hint for input to be entered
 * @property {any} value The value of input element
 * @property {object} inputProps Attributes applied to the input element.
 * @property {boolean} required If true the input element will be required.
 *
 */
function DrcInput(props) {
    const {
        label,
        type = 'text',
        variant = 'outlined',
        width,
        style,
        classes,
        className,
        helperText,
        filterChanged,
        onChange,
        stickyLabel,
        InputProps,
        InputLabelProps,
        dispatch,
        required,
        isMoney,
        isDateRange,
        placeholder,
        ...other
    } = props;

    const onChangeHandler = (event) => {
        if (props.filterChanged) {
            props.filterChanged({ value: event.target.value, label: event.target.value, type: props.name });
        }

        if (props.onChange) {
            props.onChange(event);
        }
    };

    const onCloseHandler = (event) => {
        if (props.onClose) {
            props.onClose(event);
        }
    };

    return (
        <StyledTextField
            sx={style}
            fullWidth={true}
            margin="dense"
            label={
                required ? (
                    <span>
                        {label} <span className={classes.error}>&#9733;</span>
                    </span>
                ) : (
                    label
                )
            }
            placeholder={placeholder}
            variant={variant}
            type={type}
            helperText={helperText}
            error={(helperText || '').length > 0}
            onChange={onChangeHandler}
            InputLabelProps={InputLabelProps}
            InputProps={{
                startAdornment: isMoney ? (
                    <InputAdornment position="start">
                        $
                        <Divider orientation="vertical" style={{ height: 20, width: 2, marginLeft: 10 }} />
                    </InputAdornment>
                ) : null,
                endAdornment: isDateRange ? (
                    <InputAdornment position="end">
                        <IconButton aria-label="open date range picker" onClick={onCloseHandler} onMouseDown={onCloseHandler}>
                            <Close />
                        </IconButton>

                        <IconButton aria-label="open date range picker">
                            <DateRange />
                        </IconButton>
                    </InputAdornment>
                ) : null,

                ...InputProps
            }}
        />
    );
}

export default DrcInput;
