import { Call } from '@driscollsinc/driscolls-display-rules';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { logError } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

export interface IUpdateCognitoUserPayload {
    username: string;
    firstName: string;
    lastName: string;
    locale: string;
    groups: Array<string>;
    isWhatsAppUser: boolean;
}

/**
 * This hook updates the cognito user details in One Driscolls
 * {@link APIEndPoints.PUT_UPDATE_COGNITO_USER}
 */

const useUpdateCognitoUser = (oktaAuth: IOktaContext) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    let error: null;

    const updateCognitoUser = async (payload: IUpdateCognitoUserPayload) => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            setLoading(true);
            const data = await Call(
                {
                    name: 'ManageUser',
                    url: APIEndPoints.PUT_UPDATE_COGNITO_USER,
                    method: 'PUT',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors && data.errors.length > 0) {
                error = data.errors;
                return error;
            } else return false;
        } catch (err) {
            logError(EventConstants.UPDATE_COGNITO_USER_ERROR, error);
            return err;
        } finally {
            setLoading(true);
        }
    };

    return { updateCognitoUser, loading };
};
export default useUpdateCognitoUser;
