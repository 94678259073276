import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook removes a calendar event
 * {@link APIEndPoints.REMOVE_EVENT}
 */

const useDeleteAttachment = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [errorDeleteAttachment, setErrorDeleteAttachment] = useState(null);
    const [loading, setLoading] = useState(false);

    const { setShowToast } = useContext(ApplicationContext);
    const deleteAttachment = async (attachmentId: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        setLoading(true);
        try {
            const data = await Call(
                {
                    name: 'DeleteAttachment',
                    url: APIEndPoints.DELETE_ATTACHMENT(attachmentId),
                    method: 'DELETE',
                    options: {}
                },
                token
            );
            if (data.errors?.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            } else {
                setShowToast({ toastMessage: 'Attachment deleted successfully!' });
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            setErrorDeleteAttachment('error');
        } finally {
            setLoading(false);
        }
    };

    return { deleteAttachment, errorDeleteAttachment, loading };
};
export default useDeleteAttachment;
