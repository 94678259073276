import { Call } from '@driscollsinc/driscolls-display-rules';
import {
    FILTERDATA,
    IBerryType,
    IFormLoading,
    IGrower,
    IHarvestOrderList,
    ILoading,
    IManualItem,
    IPrintAuditIdResponse,
    IPrintPayload,
    IProducingArea,
    IPticRanch,
    IPticWarehouse
} from '@driscollsinc/pti-module-ui';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { PERSONAID } from 'data/config/persona';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook handles multiple data loading related to the ptic service
 * {@link APIEndPoints.GET_PRODUCING_AREAS}
 * {@link APIEndPoints.GET_GROWERS}
 * {@link APIEndPoints.GET_HARVEST_ORDERS}
 * {@link APIEndPoints.GET_PTIC_RANCHES}
 * {@link APIEndPoints.GET_WAREHOUSES}
 * {@link APIEndPoints.GET_HARVEST_ORDERS}
 *
 */
const usePTIService = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [producingAreas, setProducingAreas] = useState<Array<IProducingArea> | []>([]);
    const [warehouses, setWarehouses] = useState<Array<IPticWarehouse> | []>([]);
    const [berryTypes, setBerryTypes] = useState<Array<IBerryType> | []>([]);
    const [growers, setGrowers] = useState<Array<IGrower> | []>([]);
    const [ranches, setRanches] = useState<Array<IPticRanch> | []>([]);
    const [harvestOrders, setHarvestOrders] = useState({} as IHarvestOrderList);
    const [manualItems, setManualItems] = useState([] as Array<IManualItem>);
    const [ranchDetails, setRanchDetails] = useState({} as IPticRanch);
    const [ptiAuditDetails, setPtiAuditDetails] = useState<any>(); // TODO: Add Type
    const [loading, setLoading] = useState({} as ILoading);
    const [formLoading, setFormLoading] = useState({} as IFormLoading);
    const [error, setError] = useState(null);
    const { userPersona, setShowToast } = useContext(ApplicationContext);

    /**
     * @description cleans up the url by removing empty/null/undefined parameters
     * @param url the target url to be clean
     * @returns modified url as string
     */

    const cleanUpURL = (url: string): string => {
        const apiURL = new URL(url);
        const params = new URLSearchParams(apiURL.search);
        const newParams = new URLSearchParams();
        params.forEach((value, key) => {
            if (value !== undefined && value !== 'undefined' && value !== null && value !== '') {
                newParams.append(key, value);
            }
        });
        const context = apiURL.toString().split(apiURL.search)[0];
        return `${context}?${newParams}`;
    };

    const getProducingAreas = async (personaId: number) => {
        setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.PRODUCINGAREA]: true }));
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'GetProducingAreas',
                    url: cleanUpURL(APIEndPoints.GET_PRODUCING_AREAS(personaId, userOktaId)),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data.length > 0) {
                setProducingAreas(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.Code + '-' + item?.Descript };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.PRODUCINGAREA]: false }));
        }
    };

    const getWarehouses = async (producingAreaCode: string, ranchNumber: string) => {
        setWarehouses([]); // initialize with empty array
        setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.WAREHOUSE]: true }));
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'GetWareHouse',
                    url: cleanUpURL(APIEndPoints.GET_WAREHOUSES(producingAreaCode, ranchNumber)),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data.length > 0) {
                setWarehouses(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.WarehouseNbr + '-' + item?.WarehouseName };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.WAREHOUSE]: false }));
        }
    };

    const getBerryType = async (producingAreaCode: string) => {
        setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.COMMODITY]: true }));
        setBerryTypes([]);
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'GetBerryType',
                    url: APIEndPoints.GET_BERRY_TYPES(producingAreaCode, userOktaId, userPersona),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.length > 0) {
                setBerryTypes(data.display);
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.COMMODITY]: false }));
        }
    };

    const getGrowers = async (personaId: number, commodityCode: string, producingArea: string) => {
        setGrowers([]);
        setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.GROWER]: true }));
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'GetGrowers',
                    url: APIEndPoints.GET_GROWERS(userOktaId, personaId, commodityCode, producingArea),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data.length > 0) {
                setGrowers(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.GrowerNbr + '-' + item?.GrowerName };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.GROWER]: false }));
        }
    };

    const getRanches = async (producingAreaCode: string, commodityCode: string, growerNbr: string) => {
        setRanches([]);
        setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.RANCH]: true }));
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PticRanches',
                    url:
                        userPersona === PERSONAID.SUPERADMIN
                            ? APIEndPoints.GET_SUPERADMIN_RANCHES(producingAreaCode, commodityCode, growerNbr)
                            : APIEndPoints.GET_PTIC_RANCHES(userOktaId, producingAreaCode, commodityCode, growerNbr),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display?.Data) {
                setRanches(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.RanchNbr + '-' + item?.Name };
                    })
                );
            }
            if (data.display && data.display?.Payload) {
                setRanches(
                    data.display.Payload.map((item) => {
                        return { ...item, label: item?.RanchNbr + '-' + item?.RanchName, value: item?.RanchNbr };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setFormLoading((prevLoading) => ({ ...prevLoading, [FILTERDATA.RANCH]: false }));
        }
    };

    const getRanchesForSuperAdmin = async (growerNbr: string) => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PticRanches',
                    url: APIEndPoints.GET_SUPERADMIN_RANCHES(growerNbr),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data.length > 0) {
                setRanches(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.GrowerNbr + '-' + item?.GrowerName };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        }
    };

    const getHarvestOrders = async (
        date: string,
        pageSize: number,
        producingAreaCode: string,
        warehouseNumber: string,
        commodityCode: string,
        growerNbr: string,
        ranchNbr: string,
        startRow: number,
        personaId: number,
        cognitoUser = false
    ) => {
        setHarvestOrders({} as IHarvestOrderList); // to reset the table
        setLoading({
            isLoading: true,
            loadingMessage: 'Fetching Harvest Orders...'
        });
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'HarvestOrders',
                    url: APIEndPoints.GET_HARVEST_ORDERS(
                        date,
                        pageSize,
                        producingAreaCode,
                        warehouseNumber,
                        commodityCode,
                        growerNbr,
                        ranchNbr,
                        startRow,
                        personaId,
                        userOktaId,
                        cognitoUser
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display) {
                setHarvestOrders(data.display);
            } else {
                setHarvestOrders({} as IHarvestOrderList);
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading({ isLoading: false, loadingMessage: '' });
        }
    };

    const getManualItems = async (company: string, berryType: string) => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PticManualItems',
                    url: APIEndPoints.GET_MANUAL_ITEMS(company, berryType),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data.length > 0) {
                setManualItems(
                    data.display.Data.map((item) => {
                        return { ...item, label: item?.ItemNbr + '-' + item?.Description };
                    })
                );
            }
        } catch (error) {
            setError('ERROR');
        }
    };

    const getRanchDetailsByRanch = async (ranch: string) => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PticRanches',
                    url: APIEndPoints.GET_RANCH_DETAILS_BY_RANCH(ranch),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.Data) {
                setRanchDetails(data.display.Data);
            }
        } catch (error) {
            setError('ERROR');
        }
    };

    const printManualHarvestOrder = async (payload: IPrintPayload): Promise<IPrintAuditIdResponse> => {
        setLoading({ isLoading: true, loadingMessage: 'Printing Labels...' });
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PrintManualHarvestOrder',
                    url: APIEndPoints.POST_MANUAL_HARVEST_ORDER(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.display) {
                return data.display;
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading({ isLoading: false, loadingMessage: '' });
        }
    };

    const printHarvestOrder = async (payload: IPrintPayload): Promise<IPrintAuditIdResponse> => {
        setLoading({ isLoading: true, loadingMessage: 'Printing Labels...' });
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PrintHarvestOrder',
                    url: APIEndPoints.POST_PRINT_HARVEST_ORDER(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.display) {
                return data.display;
            }
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading({ isLoading: false, loadingMessage: '' });
        }
    };

    const getPrintAuditDetails = async (
        ptiAuditId: number,
        personaId: number,
        cognitoUser = false,
        useroktaid: string,
        printCount?: number,
        harvestOrder?: string,
        itemNbr?: string,
        packDate?: string
    ): Promise<any> => {
        setLoading({ isLoading: true, loadingMessage: 'Fetching PTI Audit Details...' });
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    name: 'PrintHarvestOrder',
                    url: APIEndPoints.GET_PTI_AUDIT_DETAILS(
                        ptiAuditId,
                        personaId,
                        cognitoUser,
                        useroktaid,
                        printCount,
                        harvestOrder,
                        itemNbr,
                        packDate
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.display && data.display.length > 0) {
                setPtiAuditDetails(data.display[0]);
                return data.display[0];
            } else setShowToast({ severity: DriscollsToastSeverity.ERROR, nameSpacing: NAMESPACE.COMMON, toastMessage: 'No Label Found!' });
        } catch (error) {
            setError('ERROR');
        } finally {
            setLoading({ isLoading: false, loadingMessage: '' });
        }
    };

    return {
        getProducingAreas,
        producingAreas,
        getWarehouses,
        warehouses,
        getBerryType,
        berryTypes,
        getGrowers,
        growers,
        ranches,
        getRanches,
        getRanchesForSuperAdmin,
        getHarvestOrders,
        harvestOrders,
        setHarvestOrders,
        manualItems,
        getManualItems,
        ranchDetails,
        getRanchDetailsByRanch,
        printManualHarvestOrder,
        printHarvestOrder,
        ptiAuditDetails,
        getPrintAuditDetails,
        loading,
        formLoading,
        error
    };
};
export default usePTIService;
