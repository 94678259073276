import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Announcement from './Announcement';
import CreateAnnouncement from './CreateAnnouncement';
import ViewAnnouncement from './ViewAnnouncement';

const AnnouncementRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Announcement />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.CREATE_ANNOUNCEMENT}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}>
                            <CreateAnnouncement />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={ROUTES.VIEW_ANNOUNCEMENT}
                element={
                    <ProtectedRoute>
                        <ViewAnnouncement />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AnnouncementRoutes;
