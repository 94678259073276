import { withOktaAuth } from '@okta/okta-react';
import React, { useContext } from 'react';

import CalendarEvent from 'components/dashboard/Calendar/CalendarEvent';
import NotificationsComponent from '../../../components/dashboard/Notifications/NotificationsComponent';
import WelcomeComponent from '../../../components/dashboard/Welcome/WelcomeComponent';
import ApplicationContext from '../../../context/ApplicationContext';
import DrcContainer from '../../../layouts/dashboard/DrcContainer';

import useCheckMasterData from 'hooks/useCheckMasterData';
import useFetchEvents from 'hooks/useFetchEvents';
import useFetchNotifications from 'hooks/useFetchNotifications';
import usePersona from 'services/personas/usePersona';
import useLang from '../../../hooks/useLang';

import QueryBuilderCreationComponent from 'components/dashboard/QueryBuilder/QueryBuilderCreationComponent';
import useGetReports from 'hooks/useGetReports';
import { NAMESPACE } from 'i18n';
import style from './SuperAdminDashboard.module.scss';

const SuperAdminDashboard = (props) => {
    useCheckMasterData();
    const { oktaAuth } = props;
    const { userPersona } = useContext(ApplicationContext);
    const { getPersonaWelcomeMessage } = usePersona(userPersona);
    const { data: theNotifications, loading: loadingNotifications, error: errorNotifications } = useFetchNotifications(oktaAuth, userPersona);
    const { data: recentEvents, loading: loadingRecentEvents, error: errorRecentEvents } = useFetchEvents(oktaAuth, 4);
    const { data: reports, loading: loadingReports, error: errorReports } = useGetReports(oktaAuth, 1, 'en', null, true);
    const { getTextTranslated } = useLang();

    return (
        <DrcContainer heading={getTextTranslated('Super Admin Dashboard', NAMESPACE.COMMON)}>
            <article className={style.mainContainer}>
                <div className={style.row}>
                    <WelcomeComponent message={getPersonaWelcomeMessage()} persona={userPersona} />
                    <NotificationsComponent
                        persona={userPersona}
                        loading={loadingNotifications}
                        error={errorNotifications}
                        notifications={theNotifications}
                        classNameContainer={style.notificationsContainer}
                    />
                    <CalendarEvent events={recentEvents} classNameContainer={style.calendarEventsContainer} />
                    <QueryBuilderCreationComponent reports={reports} loadingReports={loadingReports} errorReports={errorReports} />
                </div>
            </article>
        </DrcContainer>
    );
};

export default withOktaAuth(SuperAdminDashboard);
