import { Call } from '@driscollsinc/driscolls-display-rules';
import { IContactDisplay } from 'components/dashboard/Contacts/ContactTypes';
import { useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of contacts for an user
 * {@link APIEndPoints.GET_CONTACTS}
 *
 */
const useFetchContact = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [data, setData] = useState<Array<IContactDisplay> | []>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetch = async () => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const data = await Call(
                {
                    url: APIEndPoints.GET_CONTACTS(userOktaId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setData(data.display ?? []);
            setLoading(false);
        } catch (error) {
            setError('ERROR');
        }
    };
    useEffect(() => {
        fetch();
    }, []);
    return { data, loading, error };
};
export default useFetchContact;
