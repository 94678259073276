import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AvatarComponent from 'components/shared/AvatarComponent';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../../hooks/useLang';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import DrcDialog from '../shared/drc/DrcDialog';
export default function AssignedPersonaDialog(props) {
    const personaDuplicate = (props.UserDetails || []).find((item) => {
        return props.selectedPersonaIds.indexOf(item.PersonaId) > -1;
    });
    const defaultPersona = props.UserDetails?.[0].DefaultPersonaId;

    const existingPersonas = Array.from(
        new Set([
            ...(props.UserDetails || []).map((item) => {
                return item.PersonaId;
            }),
            ...props.selectedPersonaIds
        ])
    );
    const { getTextTranslated } = useLang();
    return (
        <DrcDialog
            title={
                props.UserDetails?.length > 1
                    ? `${getTextTranslated('User already has personas assigned!', NAMESPACE.USERS)}`
                    : `${getTextTranslated('Assign Persona', NAMESPACE.USERS)}`
            }
            open={props.open}
            buttons={
                <>
                    <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={props.closeUserExistDialog}>
                        {'Cancel'}
                    </TranslatedDrcButton>
                    <TranslatedDrcButton
                        nameSpacing={NAMESPACE.COMMON}
                        isPrimary
                        onClick={() => props.continueInviteProcess(defaultPersona, existingPersonas)}
                    >
                        {'Yes'}
                    </TranslatedDrcButton>
                </>
            }
        >
            <div>
                {props.UserDetails?.length && (
                    <span>
                        <TypographyTranslated nameSpacing={NAMESPACE.USERS}>{'Personas already assigned to the user:'}</TypographyTranslated>
                    </span>
                )}
                {(props.UserDetails || []).map((item) => {
                    const translatedPersonaName = getTextTranslated(item.PersonaName, NAMESPACE.COMMON);
                    return (
                        <List dense={true} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} key={item.PersonaName}>
                            <ListItem>
                                <ListItemAvatar>
                                    <AvatarComponent
                                        user={{ firstName: translatedPersonaName.split(' ')[0], lastName: translatedPersonaName.split(' ')[1] || '' }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={translatedPersonaName}
                                    sx={{ color: props.selectedPersonaIds.indexOf(item.PersonaId) > -1 ? 'red' : 'black' }}
                                />
                            </ListItem>
                        </List>
                    );
                })}
            </div>
            <span>
                {personaDuplicate ? (
                    <TypographyTranslated nameSpacing={NAMESPACE.USERS}>
                        {'Some personas you are trying to add are already assigned. Do you want to still continue?'}
                    </TypographyTranslated>
                ) : (
                    <TypographyTranslated nameSpacing={NAMESPACE.USERS}>
                        {'Do you want to add the selected persona(s) to the user?'}
                    </TypographyTranslated>
                )}
            </span>
        </DrcDialog>
    );
}
