import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import { isOktaUser } from 'utils/personasUtils';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the attached file url
 * {@link APIEndPoints.GET_ATTACHMENT_URL}
 *
 */
const useDownloadFile = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [error, setError] = useState(null);

    const downloadFile = async (fileKey: string) => {
        if (showAttachmentFeature()) {
            setIsFileLoading(true);
            try {
                const { token } = await getTokenAndId(oktaAuth);
                const data = await Call(
                    {
                        name: 'DownloadFile',
                        url: APIEndPoints.GET_ATTACHMENT_URL(fileKey, isOktaUser()),
                        method: 'GET',
                        options: {}
                    },
                    token
                );
                if (data.display?.url) {
                    return data.display.url;
                } else return null;
            } catch (error) {
                setError('ERROR');
            } finally {
                setIsFileLoading(false);
            }
        }
    };

    return { error, isFileLoading, downloadFile };
};
export default useDownloadFile;
