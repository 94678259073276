import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook marks the announcement as read
 * {@link APIEndPoints.READ_ANNOUNCEMENTS}
 *
 */
const useReadAnnouncement = (oktaAuth: any, announcementId: string, messageRead: number) => {
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const { setShowToast } = useContext(ApplicationContext);

    const fetch = async () => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            await Call(
                {
                    name: 'Announcement',
                    url: APIEndPoints.READ_ANNOUNCEMENTS(),
                    method: 'POST',
                    options: {},
                    data: {
                        RecipientAuthId: userOktaId,
                        AnnouncementId: announcementId
                    }
                },
                token
            );
        } catch (error) {
            setError('ERROR');
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
        }
    };
    useEffect(() => {
        messageRead === 0 && fetch();
    }, []);

    return { error };
};
export default useReadAnnouncement;
