import React, { useEffect, useRef, useState } from 'react';

import { NAMESPACE } from 'i18n';
import { TypographyTranslated } from '../../Typography/TypographyTranslated';

const CustomHeader = (props) => {
    const [descSort, setDescSort] = useState('inactive');
    const [sortOrder, setSortOrder] = useState('none'); //none,asc,desc
    const refButton = useRef(null);
    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current);
    };

    const onSortChanged = () => {
        let order = props.column.isSortAscending() ? 'asc' : 'desc';
        order = props.column.isSortDescending() ? 'desc' : order;
        order = !props.column.isSortAscending() && !props.column.isSortDescending() ? 'none' : order;
        setSortOrder(order);
    };

    const onSortRequested = (event) => {
        const order = props.column.sort === null || props.column.sort === undefined ? 'asc' : props.column.sort === 'asc' ? 'desc' : 'none';
        setSortOrder(order);
        props.setSort(order, event.shiftKey);
    };

    useEffect(() => {
        props.column.addEventListener('sortChanged', onSortChanged);
        props.eGridHeader.onclick = (event) => {
            if (event.target && event.target.className !== 'ag-header-cell-resize') {
                onSortRequested(event);
            }
        };
    }, []);

    let menu = null;
    if (props.enableMenu) {
        menu = (
            <div ref={refButton} className="customHeaderMenuButton" onClick={() => onMenuClicked()}>
                <i className={`ag-icon ${props.menuIcon}`}></i>
            </div>
        );
    }

    let sort = null;
    if (props.enableSorting) {
        sort = (
            <div style={{ display: 'inline-block' }} onClick={(event) => onSortRequested(event)} onTouchEnd={(event) => onSortRequested(event)}>
                <div className={`customSortUpLabel ${descSort}`}>
                    {sortOrder === 'desc' && <i className="ag-icon ag-icon-desc"></i>}
                    {sortOrder === 'asc' && <i className="ag-icon ag-icon-asc"></i>}
                </div>
            </div>
        );
    }

    return (
        <div>
            {menu}
            <div className="customHeaderLabel">
                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.displayName}</TypographyTranslated>
            </div>
            {sort}
        </div>
    );
};

export default CustomHeader;
