import { Typography, TypographyProps } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React, { PropsWithChildren } from 'react';
import useLang from '../../../hooks/useLang';
interface TypographyTranslatedProps extends TypographyProps {
    nameSpacing?: NAMESPACE;
}

export const TypographyTranslated = ({ children, nameSpacing = NAMESPACE.MAIN, ...rest }: PropsWithChildren<TypographyTranslatedProps>) => {
    const { getTextTranslated } = useLang();
    const str = children as string;
    return <Typography {...rest}>{getTextTranslated(str, nameSpacing as NAMESPACE)}</Typography>;
};
