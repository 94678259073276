import { Dialog, DialogActions, DialogProps, DialogTitle, styled } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React, { ReactNode } from 'react';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

const StyledDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 2),
    minWidth: '300px',
    color: theme.palette.text.primary
    // backgroundColor: theme.palette.primary
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 0, 1),
    color: theme.palette.text.primary,
    '& h2': {
        fontSize: '1.5rem'
    }
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    borderTop: '1px solid ' + theme.palette.grey[200],
    margin: 0,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 0, 0)
}));

const StyledWrapperDiv = styled('div')(({ theme }) => ({
    margin: theme.spacing(-1)
}));

/**
 * @description Dialog window with customizable translatedButton sent as children
 * @property {*} buttons elements sent as action translatedButton
 * @property {String} title title of the dialog window
 * @property {*} children elements send as content of the dialog window
 * @property {Boolean} open flag to open the dialog
 * @property {*} onClose callback handler on close
 * @property {isError} flag to indicate if it is an error to customize CSS
 * @property {Boolean} isConfirmation If true, clicking the backdrop or hitting escape will not fire the onClose callback.
 * @property {Boolean} fullActionWrapper will make width 100%
 * @example
 * <DrcDialog
        isError={this.isError}
        title={"Common Dialog"}
        open={this.showDialog}
        translatedButton={
            <DrcButton poly line isError onClick={this.closeDialog}>
                OK
            </DrcButton>
        }
    >
        <div>Dialog Content</div>
    </DrcDialog>
 * @donotprint true
 * @category Layout
 * @tags layout dialog popup modal show close hide
 */

interface IDrcDialogProps extends DialogProps {
    buttons?: ReactNode;
    isConfirmation?: boolean;
}

const DrcDialog = (props: IDrcDialogProps) => {
    const { buttons, title, children, open, onClose, className, isConfirmation, ...others } = props;

    return (
        <Dialog disableEscapeKeyDown={isConfirmation} open={open} onClose={onClose} className={className} {...others}>
            <StyledDiv>
                <StyledDialogTitle>
                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{title}</TypographyTranslated>
                </StyledDialogTitle>
                <div>{children}</div>
                <StyledDialogActions>
                    <StyledWrapperDiv>{buttons}</StyledWrapperDiv>
                </StyledDialogActions>
            </StyledDiv>
        </Dialog>
    );
};

export default DrcDialog;
