export const EventConstants = {
    WEB_APP_ERROR: 'Web_APP_error',
    EXCEPTION: 'exception',
    SEARCH: 'search',
    USER_LOGGED_IN: 'user_logged_in',
    LOGIN_ERROR: 'loginerror',
    GLOBAL_ERROR: 'globalError',
    GRID_COPY_MASTER: 'Grid_Copy_Manage_User',
    OKTA_USER_DEACTIVATION: 'OktaUserDeactivation',
    COGNITO_USER_DEACTIVATION: 'CognitoUserDeactivation',
    REMOVE_PERSONA: 'RemovePersona',
    REMOVE_PERSONA_ERROR: 'RemovePersonaError',
    CHANGE_LANGUAGE: 'change_language',
    UPDATE_PROFILE_LANGUAGE: 'update_profile_language',
    CHANGE_PERSONA: 'change_persona',
    CHANGE_PERSONA_ERROR: 'change_persona_error',
    GET_REPORTS: 'get_reports',
    GET_REPORTS_ERROR: 'get_reports_error',
    VIEW_REPORT: 'ViewReport',
    USER_PROFILE_ERROR: 'userProfileError',
    UPDATE_COGNITO_USER_ERROR: 'updateCognitoUserError',
    GET_USER_PERSONAS: 'get_user_personas',
    INVITE_USER: 'InviteUser',
    ASSIGN_REPORT: 'AssignReport',
    ASSIGN_ERROR: 'AssignError',
    DELETE_REPORT: 'DeleteReport',
    GET_OKTA_USERS: 'get_okta_users',
    CHECK_USER_EMAIL: 'check_user_email',
    INVITE_USER_ERROR: 'invite_user_error',
    INVITE_USER_ASSIGNMENT: 'invite_user_assignments',
    ASSIGN_REPORTS: 'assign_reports',
    ASSIGN_REPORTS_ERROR: 'assign_reports_error',
    EDIT_USER: 'editUser',
    PERSONA_LOADING_ERROR: 'personaLoadingError',
    OTP_ERROR: 'otpError',
    GET_ENTITY_ASSIGNMENT_ERROR: 'getEntityAssignmentError',
    ADD_BERRY_CONNECT_EVENT: 'addBerryConnectEvent',
    BERRY_CONNECT_FILE_DOWNLOAD: 'BerryConnectFileDownload',
    ADD_BERRY_CONNECT_ITEM_CLICK: 'BerryConnectItemClick',
    BERRY_CONNECT_SEARCH_EVENT: 'BerryConnectSearchEvent'
};
