import { ContactPhone } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React from 'react';
import DriscollsToolTip from 'ui/DriscollsToolTip/DriscollsToolTip';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import DuDateUtilities from '../../../utils/DuDateUtilities';
import CellRenderer from './CellRenderer';
import styles from './GenericRenderer.module.css';

const GenericRenderer = (props) => {
    const { getTextTranslated } = useLang();
    const handleContextMenu = () => {
        navigator.clipboard.writeText(props.value);
    };
    if (props.type === 'date') {
        return <span>{DuDateUtilities.ToPrettyDate(props.value)}</span>;
    } else if (props.type === 'datetime') {
        return <span>{DuDateUtilities.ToPrettyDateTime(props.value)}</span>;
    } else if (props.type === 'email') {
        return (
            props.value && (
                <div className={styles.contact}>
                    {props.data.IsContact === 1 && props?.column?.colId === 'Email' && (
                        <DriscollsToolTip description={getTextTranslated('This user is a contact for certain users!', NAMESPACE.USERS)}>
                            <ContactPhone className={styles.icon} />
                        </DriscollsToolTip>
                    )}
                    <span className={styles.overflowText}>{props.value}</span>
                </div>
            )
        );
    } else if (props.type === 'lang') {
        return props.value && <span className={styles.labels}>{props.value?.toUpperCase()}</span>;
    } else if (props.type === 'subject') {
        return props.value && <span className={!props.data.ResponseId && styles.boldTitle}>{props.value}</span>;
    } else if (props.type === 'attachments' && showAttachmentFeature() === true) {
        return (
            props.value && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', height: '42px', alignItems: 'center' }}>
                    <AttachFileIcon />
                </div>
            )
        );
    } else if (props.type === 'incident') {
        return props.value && <span className={styles.labels}>{props.value}</span>;
    } else if (props.type === 'status') {
        return (
            props.value && (
                <span
                    className={
                        ['CLOSED', 'CLOSE', 'RESOLVED'].includes(props.value)
                            ? styles.statusClose
                            : props.value === 'OPEN'
                            ? styles.statusOpen
                            : styles.MediumPriority
                    }
                >
                    {props.value}
                </span>
            )
        );
    } else if (props.type === 'priority') {
        return (
            props.value && (
                <span
                    className={
                        (props.value === 'High' && styles.statusClose) ||
                        (props.value === 'Medium' && styles.MediumPriority) ||
                        (props.value === 'Low' && styles.statusOpen)
                    }
                >
                    {props.value}
                </span>
            )
        );
    } else if (props.type === 'report') {
        return props.value && <span className={styles.reports}>{props.value}</span>;
    } else if (props.type === 'libraryemail') {
        return (
            <CellRenderer
                handleContextMenu={handleContextMenu}
                description={props.data?.createdBy?.user?.email}
                value={props.data?.createdBy?.user?.displayName}
                menu={[
                    { label: 'Copy', action: 'Copy' },
                    { label: 'Copy Email', action: 'Copy Email' }
                ]}
            />
        );
    }
};

export default GenericRenderer;
