import { Box, Chip } from '@mui/material';
import FormAutoComplete from 'components/shared/formcontrols/FormAutoComplete';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { Fragment } from 'react';
import classes from '../../pages/announcement/announcement.module.scss';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';

interface AssignmentTypes {
    label: string;
    DistrictId: string;
    CompanyNumber: string;
    Code: string;
}

const AnnouncementRecipients = ({
    selectedDistricts,
    selectedCompanies,
    selectedRanches,
    handleDelete,
    handleChange,
    districts,
    ranches,
    companies
}) => {
    const { getTextTranslated } = useLang();
    return (
        <Fragment>
            <Box className={classes.recipientContainer}>
                <Box className={classes.fieldsContainer}>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Recipients'}</TypographyTranslated> :{' '}
                    {(selectedDistricts || []).map((item: AssignmentTypes) => (
                        <Chip key={item.DistrictId} label={item.label || item.DistrictId} onDelete={() => handleDelete(item, 'District')} />
                    ))}
                    {(selectedCompanies || []).map((item: AssignmentTypes) => (
                        <Chip key={item.DistrictId} label={item.label || item.DistrictId} onDelete={() => handleDelete(item, 'Company')} />
                    ))}
                    {(selectedRanches || []).map((item: AssignmentTypes) => (
                        <Chip key={item.Code} label={item.label || item.Code} onDelete={() => handleDelete(item, 'Ranch')} />
                    ))}
                </Box>
            </Box>
            <Box className={classes.assignmentContainer}>
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    values={districts}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'District')}
                    label={getTextTranslated('Districts', NAMESPACE.ANNOUNCEMENT)}
                />
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    values={companies}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'Company')}
                    label={getTextTranslated('Companies', NAMESPACE.ANNOUNCEMENT)}
                />
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    values={ranches}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'Ranch')}
                    label={getTextTranslated('Ranches', NAMESPACE.ANNOUNCEMENT)}
                />
            </Box>
        </Fragment>
    );
};

export default AnnouncementRecipients;
