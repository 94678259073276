import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styles from './DrcAccordion.module.scss';

interface ICustomProps {
    header: string;
    icon: React.ReactNode;
    secondaryIcon?: React.ReactNode;
}

interface IAccordionProps extends ICustomProps, AccordionProps {}

const DrcAccordion = (props: React.PropsWithChildren<IAccordionProps>) => {
    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={styles.title}>
                    {props.icon} &nbsp; {props.header}
                </Typography>
                {props.secondaryIcon ? props.secondaryIcon : null}
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
};

export default DrcAccordion;
