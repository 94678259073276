import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useEffect, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

/**
 * This hook fetches the list of announcements
 * {@link APIEndPoints.GET_ANNOUNCEMENTS}
 * {@link APIEndPoints.GET_SENT_ANNOUNCEMENTS}
 *
 */

const useGetQAInspectionReports = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [reportsList, setReportList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useContext(ApplicationContext);
    const [executionTime, setExecutionTime] = useState(0);
    const ITEMS_PER_PAGE = 50;

    const fetch = async (page = 1, query = '', reporttype = '') => {
        setReportList([]);
        setLoading(true);
        const startExecution = Date.now();
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const currentPage = page == 1 ? 0 : (page - 1) * ITEMS_PER_PAGE + 1;
        try {
            const data = await Call(
                {
                    name: 'QAInspectionReports',
                    url: APIEndPoints.GET_QA_REPORTS(userOktaId, currentPage, ITEMS_PER_PAGE, query, reporttype),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
                return console.log(data.errors[0]);
            }
            const results = data.display?.Response;
            const totalResults = +data.display?.totalResults;
            const endExecution = Date.now();
            setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);
            setPageCount(Math.round(totalResults / ITEMS_PER_PAGE));
            setTotalReports(totalResults);
            setReportList(results || []);
        } catch (err) {
            setError(err);
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'An error occurred while fetching reports. Please try again',
                nameSpacing: NAMESPACE.REPORT
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetchWithFilters = (offset = 0, query = '', reporttype = '') => {
        fetch(offset, query, reporttype);
    };

    return { loading, executionTime, reportsList, pageCount, totalReports, error, fetchWithFilters };
};
export default useGetQAInspectionReports;
