import { Call } from '@driscollsinc/driscolls-display-rules';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';

export interface ICreateEventPayload {
    Name: string;
    Description: string;
    StartDate: string;
    EndDate: string;
    DistrictCode: string[];
}

/**
 * This hook fetches the list of contacts for an user
 * {@link APIEndPoints.POST_CREATEEVENT}
 */

const useCreateEvent = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setloading] = useState(false);
    let ret: null;
    let error: null;
    let saving = false;

    const create = async (payload: ICreateEventPayload) => {
        try {
            saving = true;
            const { token, userEmail } = await getTokenAndId(oktaAuth);
            setloading(true);
            const data = await Call(
                {
                    url: APIEndPoints.POST_CREATEEVENT(),
                    method: 'POST',
                    options: {},
                    data: {
                        Name: payload.Name,
                        Description: payload.Description,
                        StartDate: payload.StartDate,
                        EndDate: payload.EndDate,
                        DistrictCode: payload.DistrictCode,
                        CreatedBy: userEmail,
                        UpdatedBy: userEmail,
                        IsActive: '1'
                    }
                },
                token
            );
            if (data.errors && data.errors.length > 0) {
                error = data.errors;
            } else {
                ret = data.display;
            }
        } catch (err) {
            error = err;
        } finally {
            saving = false;
            setloading(false);
        }
        return { ret, saving, error };
    };

    return { create, loading };
};
export default useCreateEvent;
