import { LabelInfoDialog } from '@driscollsinc/pti-module-ui';
import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';

const LabelInfo = () => {
    const [openLabelInfo, setOpenLabelInfo] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenLabelInfo(true)}>
                <InfoOutlined />
            </IconButton>
            <LabelInfoDialog open={openLabelInfo} setOpen={setOpenLabelInfo} />
        </>
    );
};

export default LabelInfo;
