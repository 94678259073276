import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

import { NAMESPACE } from 'i18n';
import { TypographyTranslated } from '../Typography/TypographyTranslated';

export default function FormTextField(props) {
    const [textValue, setTextValue] =  useState(props.value);

    const [error, setError] = React.useState(props.error);
    const handleChange = (event) => {
        setTextValue(event.target.value);
        props.handleChange && props.handleChange(event.target.value);
    };


    useEffect(() =>{
        setTextValue(props.value);
    },[props.value]);

  

    React.useEffect(() => {
        setError(props.error);
    }, [props.error]);

    return (
        <Box
            component="div"
            sx={{
                '& .MuiTextField-root': { m: 1 }
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {props.hideLegacyLabel && (
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="h7" gutterBottom>
                        {props.label}
                    </TypographyTranslated>
                )}
                <TextField
                    label={!props.hideLegacyLabel ? <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{props.label}</TypographyTranslated> : ''}
                    error={error}
                    value={textValue || props.value}
                    sx={{ m: 1, width: '90%' }}
                    onChange={handleChange}
                    helperText={props.helperText || ''}
                    inputProps={props.inputProps}
                    InputLabelProps={{ shrink: props.value }}
                />
            </div>
        </Box>
    );
}
