import { Call } from '@driscollsinc/driscolls-display-rules';
import { logError, logEvent } from 'analytics/AnalyticsHandler';
import { EventConstants } from 'constants/EventConstants';
import { useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';
import useLang from './useLang';

/**
 * This hook creates a gep user and assigns different entities to the user
 * {@link APIEndPoints.GET_CONTACTS}
 *
 */
const useManageReports = (oktaAuth) => {
    const { getCurrentLanguageSelected } = useLang();
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const assignReports = async (userId, reportUrls) => {
        const { userOktaId, token } = await getTokenAndId(oktaAuth);
        setLoading(true);
        try {
            const data = await Call(
                {
                    name: 'AssignReports',
                    url: APIEndPoints.POST_REPORT_ASSIGN(),
                    method: 'POST',
                    options: {},
                    data: {
                        AuthId: userId,
                        Lang: getCurrentLanguageSelected(),
                        Reports: reportUrls,
                        Status: '1',
                        CreatedBy: userOktaId,
                        UpdatedBy: userOktaId
                    }
                },
                token
            );
            if (data.display) {
                logEvent(EventConstants.ASSIGN_REPORT, {
                    AuthId: userId,
                    Lang: getCurrentLanguageSelected(),
                    ReportURL: reportUrls,
                    Status: '1',
                    CreatedBy: userOktaId,
                    UpdatedBy: userOktaId
                });
                return true;
            }
        } catch (err) {
            setError(err);
            logError(EventConstants.ASSIGN_REPORTS_ERROR, err);
        } finally {
            setLoading(true);
        }
    };

    const deleteReports = async (userId, reportUrls) => {
        const { userOktaId, token } = await getTokenAndId(oktaAuth);
        setLoading(true);
        try {
            const data = await Call(
                {
                    name: 'RemoveReports',
                    url: APIEndPoints.POST_REPORT_ASSIGN(),
                    method: 'POST',
                    options: {},
                    data: {
                        AuthId: userId,
                        Lang: getCurrentLanguageSelected(),
                        Reports: reportUrls,
                        Status: '0',
                        CreatedBy: userOktaId,
                        UpdatedBy: userOktaId
                    }
                },
                token
            );
            if (data.display) {
                logEvent(EventConstants.DELETE_REPORT, {
                    AuthId: userId,
                    Lang: getCurrentLanguageSelected(),
                    ReportURL: reportUrls,
                    Status: '0',
                    CreatedBy: userOktaId,
                    UpdatedBy: userOktaId
                });
            }
        } catch (err) {
            setError(err);
            logError(EventConstants.ASSIGN_REPORTS_ERROR, error);
        } finally {
            setLoading(true);
        }
    };

    return { loading, error, assignReports, deleteReports };
};
export default useManageReports;
