import MasterDataUtilities from 'data/MasterDataUtilities';
import { useSelector } from 'react-redux';

const useCheckMasterData = () => {
    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
};

export default useCheckMasterData;
