import { Call } from '@driscollsinc/driscolls-display-rules';
import { withOktaAuth } from '@okta/okta-react';
import { ROUTES } from 'constants/routes';
import useCheckMasterData from 'hooks/useCheckMasterData';
import { NAMESPACE } from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logEvent } from '../../analytics/AnalyticsHandler';
import DuAuthenticationUtilities from '../../components/shared/Utilities/DuAuthenticationUtilities';
import { EventConstants } from '../../constants/EventConstants';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import APIEndPoints from '../../services/api';

function AuthoringReport(props) {
    useCheckMasterData();
    const location = useLocation();
    const reportUrl = location?.state?.data;
    const ref = useRef(null);
    const navigate = useNavigate();
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const [token, setToken] = useState(null);
    let tableauToken;
    let refreshIntervalID = 0;
    const getData = async () => {
        const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(props.oktaAuth);
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        const userId = DuAuthenticationUtilities.GetOktaId(token);
        try {
            const data = await Call(
                {
                    name: 'GET_TABLEAU_TOKEN',
                    url: APIEndPoints.GET_TABLEAU_EMBED_TOKEN(userId),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return console.log(data.errors[0]);
            }
            tableauToken = data.display?.token;

            if (!document.getElementById('tableauViz')) {
                setToken(tableauToken);
            }
            let currentUnix = Math.floor(Date.now() / 1000);
            let expiryAt = Number(data.display?.expiration) - currentUnix;
            refreshIntervalID = setTimeout(refreshToken, 5 * 60 * 1000);
            logEvent(EventConstants.VIEW_REPORT, { report: reportUrl });
        } catch (error) {
            console.log('error', error);
        } finally {
            console.log('error');
        }
    };

    const refreshToken = () => {
        clearTimeout(refreshIntervalID);
        getData();
    };

    const goBackHandler = (path) => {
        navigate(`${ROUTES.ROOT}${ROUTES.DASHBOARD}`);
    };

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        getData();
    }, []);

    const { getTextTranslated } = useLang();
    return (
        <DrcContainer heading={getTextTranslated('View Reports', NAMESPACE.REPORT)} ref={ref} goBackHandler={goBackHandler}>
            <div style={{ width: dimensions.width, height: dimensions.height }}>
                {token && <tableau-authoring-viz id="tableauViz" src={reportUrl} toolbar="top" token={token}></tableau-authoring-viz>}
            </div>
        </DrcContainer>
    );
}

export default withOktaAuth(AuthoringReport);
