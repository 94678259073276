import { Call } from '@driscollsinc/driscolls-display-rules';
import { Middleware } from '@driscollsinc/one-ring';
import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Grid } from '@mui/material';
import ApplicationContext from 'context/ApplicationContext';
import useEditUser from 'hooks/useEditUser';
import useGetApiTokens from 'hooks/useGetApiTokens';
import { NAMESPACE } from 'i18n';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logError, logEvent } from '../../analytics/AnalyticsHandler';
import DrcDialog from '../../components/shared/drc/DrcDialog';
import DrcInformationBox from '../../components/shared/drc/DrcInformationBox';
import { EventConstants } from '../../constants/EventConstants';
import { isPersonaIsOKTA } from '../../data/config/datasetup';
import useLang from '../../hooks/useLang';
import APIEndPoints from '../../services/api';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { getPersonaIdFromPersona } from '../../utils/helper';
interface IUserLifeCycleProps {
    migrationSectionOpen: boolean;
    setMigrationSectionOpen: (value: boolean) => void;
    selectedPersona: string;
    selectedUserOktaId: string;
    oktaAuth: any;
    personaId: number;
}

const UserLifeCycle = (props: IUserLifeCycleProps) => {
    const { selectedPersona, selectedUserOktaId } = props;
    const navigate = useNavigate();
    const [warningDialog, setWarningDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState('');
    const { getUserStatus, isActive } = useEditUser(props.oktaAuth);
    const { getTokenAndId } = useGetApiTokens();
    const { userProfile, setShowToast } = useContext(ApplicationContext);
    const { getTextTranslated } = useLang();

    useEffect(() => {
        getUserStatus(selectedUserOktaId);
    }, [selectedUserOktaId]);

    const activateDeactivateUser = async (okta = false) => {
        setLoading(true);
        const { token, userEmail } = await getTokenAndId(props.oktaAuth);
        const isOktaUser = isPersonaIsOKTA(props.personaId) || okta;
        const payload = isOktaUser
            ? {
                  Status: 0,
                  UserOktaId: selectedUserOktaId,
                  UpdatedBy: userEmail
              }
            : {
                  username: selectedUserOktaId
              };
        try {
            const data = await Call(
                {
                    name: 'user',
                    url: isActive
                        ? APIEndPoints.DEACTIVATE_USER(selectedUserOktaId, isOktaUser)
                        : APIEndPoints.ACTIVATE_USER(selectedUserOktaId, isOktaUser),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
                if (!isOktaUser) {
                    //TODO this is the case when we have an orphaned user in the grower db
                    //just delete the mapping from the db. We have to check teh error message before we do this though
                    await activateDeactivateUser(true);
                }
            } else {
                if (isOktaUser) {
                    logEvent(EventConstants.OKTA_USER_DEACTIVATION, { selectedUser: selectedUserOktaId });
                    setShowToast({ toastMessage: 'User Status Changed Successfully!', nameSpacing: NAMESPACE.USERS });
                    navigate(-1);
                } else {
                    removeCognitoUser(selectedUserOktaId, token);
                }
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            console.log(error);
        } finally {
            setLoading(false);
            setWarningDialog(false);
        }
    };

    const removeCognitoUser = async (cognitoId, token) => {
        try {
            const payload = { username: cognitoId };
            let res = await Middleware.Send('ManageUser', token, APIEndPoints.DELETE_COGNITOUSER(), 'DELETE', payload, {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            });
            if (res?.message && res?.message === 'Success') {
                logEvent(EventConstants.COGNITO_USER_DEACTIVATION, { selectedUser: cognitoId });
                activateDeactivateUser(true);
            } else {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: res?.message ?? 'An error occurred while removing Cognito user.Please try again',
                    nameSpacing: NAMESPACE.USERS
                });
            }
        } catch (error) {
            console.log('error');
        }
    };

    const removePersona = async () => {
        setLoading(true);
        const { token, userEmail } = await getTokenAndId(props.oktaAuth);
        const isOktaUser = isPersonaIsOKTA(getPersonaIdFromPersona(selectedPersona));
        const payload = isOktaUser
            ? {
                  Status: 0,
                  UserOktaId: selectedUserOktaId,
                  UpdatedBy: userEmail,
                  PersonaId: getPersonaIdFromPersona(selectedPersona)
              }
            : {
                  username: selectedUserOktaId
              };
        try {
            const data = await Call(
                {
                    name: 'user',
                    url: APIEndPoints.DEACTIVATE_USER(selectedUserOktaId, isOktaUser),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            } else {
                logEvent(EventConstants.REMOVE_PERSONA, { selectedUser: selectedUserOktaId, isOktaUser: isOktaUser, updatedBy: userEmail });
                setShowToast({ toastMessage: 'User Status Changed Successfully!', nameSpacing: NAMESPACE.USERS });
                navigate(-1);
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'An Error Occurred!' });
            logError(EventConstants.REMOVE_PERSONA_ERROR, { error: error });
        } finally {
            setLoading(false);
            setWarningDialog(false);
        }
    };

    return (
        <Fragment>
            <Grid item sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
                {isActive ? (
                    <TranslatedDrcButton
                        nameSpacing={NAMESPACE.USERS}
                        color="error"
                        onClick={() => {
                            setWarning('deactivate');
                            setWarningDialog(true);
                        }}
                        disabled={selectedUserOktaId === userProfile.id}
                    >
                        {'Delete User'}
                    </TranslatedDrcButton>
                ) : (
                    <TranslatedDrcButton
                        nameSpacing={NAMESPACE.USERS}
                        onClick={() => {
                            setWarning('activate');
                            setWarningDialog(true);
                        }}
                    >
                        {'Activate User'}
                    </TranslatedDrcButton>
                )}
                {/*
                  Disabling the feature for release-2023-06 - GEP-274
                 <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}>
                    {!isPersonaIsOkta && (
                        <TranslatedDrcButton nameSpacing={NAMESPACE.EVENTS} onClick={() => setMigrationSectionOpen(!migrationSectionOpen)}>
                            {'Change Email/Phone No.'}
                        </TranslatedDrcButton>
                    )}
                </PersonaBaseProtect> */}
            </Grid>

            <DrcDialog
                title={getTextTranslated('Are you sure?', NAMESPACE.COMMON)}
                open={warningDialog}
                buttons={
                    <>
                        <DriscollsLoadingButton
                            variant={ButtonVariant.SECONDARY}
                            onClick={() => setWarningDialog(false)}
                            label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                        />
                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            label={getTextTranslated('Yes', NAMESPACE.COMMON)}
                            loading={loading}
                            onClick={() => {
                                if (warning === 'deactivate' || warning === 'activate') {
                                    activateDeactivateUser();
                                } else {
                                    removePersona();
                                }
                            }}
                        />
                    </>
                }
            >
                {warning === 'deactivate' && (
                    <>
                        <div>{getTextTranslated('Do you want to de-activate the user?', NAMESPACE.USERS)}</div>
                        <DrcInformationBox
                            hasError={true}
                            style={{
                                display: 'block'
                            }}
                        >
                            <div>{getTextTranslated('Deactivating the user will remove all the associated personas.', NAMESPACE.USERS)}</div>
                            <div>{getTextTranslated('Deactivating the user will remove all the assignments as well.', NAMESPACE.USERS)}</div>
                        </DrcInformationBox>
                    </>
                )}
                {warning === 'remove' && (
                    <>
                        <div>{`${getTextTranslated('Do you want to remove the selected Persona', NAMESPACE.USERS)} - ${selectedPersona} ?`}</div>
                    </>
                )}
            </DrcDialog>
        </Fragment>
    );
};

export default UserLifeCycle;
