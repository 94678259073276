import { Home } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import ApplicationContext from 'context/ApplicationContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import charImage from '../../../Images/404 Error-bro.svg';
import { TypographyTranslated } from '../Typography/TypographyTranslated';
import { NAMESPACE } from 'i18n';

function DrcPageNotFound() {
    const navigate = useNavigate();
    const { landingPage } = useContext(ApplicationContext);
    const springs = useSpring({
        from: { x: -100 },
        to: { x: 0 }
    });
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton onClick={() => navigate(landingPage)} sx={{ position: 'fixed', top: 100, left: 100 }}>
                <Home fontSize="large" />
            </IconButton>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <animated.div
                    style={{
                        width: 400,
                        borderRadius: 8,
                        ...springs
                    }}
                >
                    <Box component="img" src={charImage} sx={{ width: 400 }} />
                    <aside
                        style={{
                            backgroundColor: 'rgba(255, 249, 235, 0.5)',
                            width: '80%',
                            borderColor: 'rgba(209, 142, 0, 0.3)',
                            padding: '10px',
                            borderRadius: '10px'
                        }}
                    >
                        <p style={{ textAlign: 'center' }}>
                            <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Page Not Found'}</TypographyTranslated>
                        </p>
                    </aside>
                </animated.div>
            </div>
        </div>
    );
}

export default DrcPageNotFound;
