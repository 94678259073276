import { Grid } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useLang from '../../hooks/useLang';
import FormMultiSelect from '../shared/formcontrols/FormMultiSelect';
interface IReportAssignmentProps {
    values: Array<IReportObject>;
    label: string;
    onChangeHandler: (e: Array<IReportObject>) => void;
}

interface IReportObject {
    ReportName?: string;
    Description?: string;
    ReportURL?: string;
    Category?: string;
}

export default function ReportsAssignment(props: IReportAssignmentProps) {
    const { getTextTranslated } = useLang();
    return (
        <Grid xs={2} sm={4} md={4}>
            <FormMultiSelect
                headerText={getTextTranslated('Select reports to add to the list', NAMESPACE.EVENTS)}
                values={props.values}
                onChangeHandler={props.onChangeHandler}
                label={props.label}
            />
        </Grid>
    );
}
